import { Button, Badge, Dropdown, Modal, Tabs } from 'flowbite-react';
import { useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../../components/flyout/FlyoutHeader';
import { FlyoutLink } from '../../../../components/links/FlyoutLink';
import { FinologyTable } from '../../../../components/table/FinologyTable';
import { useGlobalState } from '../../../../context/AppContext';
import {
  FederalLoanVersionView,
  useCopyFederalLoanVersionMutation,
  useDeleteFederalLoanVersionMutation,
  useGetClientFederalLoanVersionsLazyQuery,
  useGetClientInfoForLoanVersionsQuery,
  useGetSpouseFederalLoanVersionsLazyQuery,
  useSetFederalLoanVersionAsActiveMutation,
} from '../../../../graphql/generated';
import { useFlyoutNavigate } from '../../../../hooks/useFlyoutNavigate';
import useFinologyTranslation from '../../../../translations/useFinologyTranslation';
import { toDateShortString } from '../../../../util/date.formatter';
import { notification } from '../../../../util/notification.utils';
import { isClient } from '../../../../utils';

import type { ColumnsType } from '../../../../components/table/FinologyTable';

const SetVersionAsActiveModal = ({
  versionToSetAsActive,
  closeModal,
}: {
  versionToSetAsActive?: FederalLoanVersionView;
  closeModal: () => void;
}) => {
  const [setVersionAsActive] = useSetFederalLoanVersionAsActiveMutation();
  const [disableOkButton, setDisableOkButton] = useState(false);
  const { t } = useFinologyTranslation();
  return (
    <Modal show={versionToSetAsActive !== undefined}>
      <Modal.Header>Set Active Version</Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to set the version {versionToSetAsActive?.source} as an active
          version of this {t('borrower.lowercase')}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="light" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={disableOkButton}
          onClick={async () => {
            setDisableOkButton(true);

            await setVersionAsActive({
              variables: {
                input: {
                  id: versionToSetAsActive!.id,
                },
              },
              refetchQueries: [
                'GetClientDashboard',
                'GetClientFederalLoanVersions',
                'GetSpouseFederalLoanVersions',
                'Calculator',
              ],
            });

            setDisableOkButton(false);

            closeModal();

            notification.success({
              message: `${versionToSetAsActive?.source} was successfully set as an active version`,
              placement: 'bottom-center',
            });
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const SetVersionToCopyModal = ({
  versionToCopy,
  closeModal,
}: {
  versionToCopy?: FederalLoanVersionView;
  closeModal: () => void;
}) => {
  const [setVersionCopy] = useCopyFederalLoanVersionMutation();
  const [disableOkButton, setDisableOkButton] = useState(false);

  return (
    <Modal show={versionToCopy !== undefined} onClose={closeModal}>
      <Modal.Header>Copy Version</Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to make a copy of the version {versionToCopy?.source}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="light" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={disableOkButton}
          onClick={async () => {
            setDisableOkButton(true);

            await setVersionCopy({
              variables: {
                input: {
                  id: versionToCopy!.id,
                },
              },
              refetchQueries: ['GetClientFederalLoanVersions', 'GetSpouseFederalLoanVersions'],
            });

            setDisableOkButton(false);

            closeModal();

            notification.success({
              message: `A copy of the version ${versionToCopy?.source} was succesfully created.`,
              placement: 'bottom-center',
            });
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteVersionModal = ({
  versionToDelete,
  closeModal,
}: {
  versionToDelete?: FederalLoanVersionView;
  closeModal: () => void;
}) => {
  const [deleteVersion] = useDeleteFederalLoanVersionMutation();
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  return (
    <Modal show={versionToDelete !== undefined} onClose={closeModal}>
      <Modal.Header>Delete Version</Modal.Header>
      <Modal.Body>
        <p>Proceed with deleting of version {versionToDelete?.source}?</p>
        <p>This will remove all the federal loans tied to this version.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="light" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={disableDeleteButton}
          onClick={async () => {
            setDisableDeleteButton(true);

            await deleteVersion({
              variables: {
                input: {
                  id: versionToDelete!.id,
                },
              },
              refetchQueries: [
                'GetClientDashboard',
                'GetClientFederalLoanVersions',
                'GetSpouseFederalLoanVersions',
              ],
            });

            setDisableDeleteButton(false);

            closeModal();

            notification.success({
              message: `${versionToDelete?.source} was successfully deleted`,
              placement: 'bottom-center',
            });
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const getClientIdFromSearch = (search: string): number => {
  const params = new URLSearchParams(search);

  return Number.parseInt(params.get('clientId')!);
};

const FlyoutContent = () => {
  const { user } = useGlobalState();
  const { navigate } = useFlyoutNavigate();
  const [versionToDelete, setVersionToDelete] = useState<FederalLoanVersionView>();
  const [versionToSetAsActive, setVersionToSetAsActive] = useState<FederalLoanVersionView>();
  const [versionToCopy, setVersionToCopy] = useState<FederalLoanVersionView>();
  const { t } = useFinologyTranslation();
  const { closeTopFlyout } = useFlyoutContext();
  const { search } = useLocation();
  const clientId = getClientIdFromSearch(search);

  const federalLoansColumns: ColumnsType<any> = [
    {
      title: 'Version Name',
      dataIndex: 'source',
      key: 'source',
      className: 'min-w-max',
      render: (text, record) => (
        <FlyoutLink flyoutId="client-federal-loan-version" params={`versionId=${record.id}`}>
          {text}
        </FlyoutLink>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'requestDate',
      key: 'requestDate',
      className: 'w-40',
      render: (text) => toDateShortString(text),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (text) => (
        <div>
          {text ? (
            <Badge className=" w-min" color="green">
              Active
            </Badge>
          ) : (
            <Badge className="px-2" color="red">
              Inactive
            </Badge>
          )}
        </div>
      ),
    },
    {
      title: '',
      key: 'action',
      className: 'w-12',
      render: (_, record) => {
        const items = [
          {
            key: '1',
            className: '',
            onClick: () => navigate('client-federal-loan-version', `versionId=${record.id}`),
            label: 'Edit',
          },
        ];

        if (!record.isActive) {
          items.push({
            key: '2',
            className: '',
            onClick: () => setVersionToSetAsActive(record),
            label: 'Set as Active',
          });
        }

        items.push({
          key: '3',
          onClick: () => setVersionToCopy(record),
          className: 'text-fino-purple',
          label: 'Duplicate',
        });

        items.push({
          key: '4',
          onClick: () => setVersionToDelete(record),
          className: 'text-red',
          label: 'Delete',
        });

        return (
          <Dropdown inline arrowIcon={false} label={<HiDotsHorizontal />}>
            {items.map((i) => {
              return (
                <Dropdown.Item className={i.className} key={i.key} onClick={i.onClick}>
                  {i.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        );
      },
    },
  ];

  const [getClientVersions, clientVersionsQuery] = useGetClientFederalLoanVersionsLazyQuery();
  const [getSpouseVersions, spouseVersionsQuery] = useGetSpouseFederalLoanVersionsLazyQuery();
  const { data: clientInformation } = useGetClientInfoForLoanVersionsQuery({
    variables: {
      clientId: clientId,
    },
  });

  async function loadClientVersions(skip?: number, take?: number, filterValue?: string) {
    const { data } = await getClientVersions({
      variables: {
        clientId: clientId,
        skip: skip,
        take: take,
      },
    });

    return {
      rows: data!.versions!.items as FederalLoanVersionView[],
      totalCount: data!.versions!.totalCount,
    };
  }

  async function loadSpouseVersions(skip?: number, take?: number, filterValue?: string) {
    const { data } = await getSpouseVersions({
      variables: {
        clientId: clientId,
        skip: skip,
        take: take,
      },
    });

    return {
      rows: data!.spouseVersions!.items as FederalLoanVersionView[],
      totalCount: data!.spouseVersions!.totalCount,
    };
  }

  return (
    <>
      {versionToDelete ? (
        <DeleteVersionModal
          versionToDelete={versionToDelete}
          closeModal={() => setVersionToDelete(undefined)}
        />
      ) : null}
      {versionToSetAsActive ? (
        <SetVersionAsActiveModal
          versionToSetAsActive={versionToSetAsActive}
          closeModal={() => setVersionToSetAsActive(undefined)}
        />
      ) : null}
      {versionToCopy ? (
        <SetVersionToCopyModal
          closeModal={() => setVersionToCopy(undefined)}
          versionToCopy={versionToCopy}
        />
      ) : undefined}
      <FlyoutHeader
        label={'Versions'}
        rightContent={
          <Dropdown label="Add New Version">
            <Dropdown.Item onClick={() => navigate(`upload-nslds`, `&clientId=${clientId}`)}>
              Upload NSLDS file
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                navigate('upsert-federal-loan', `createNewVersion=true&clientId=${clientId}`)
              }
            >
              Add Federal Loan
            </Dropdown.Item>
          </Dropdown>
        }
        secondaryButton={{ onClick: closeTopFlyout, children: 'Close' }}
      />
      {clientInformation?.client ? (
        <Tabs style="underline">
          <Tabs.Item
            title={`${clientInformation?.client?.firstName} ${clientInformation?.client?.lastName} Versions`}
            key="client-versions"
          >
            <FinologyTable
              emptyText={() => (
                <div className="flex gap-32">
                  <span>This {t('borrower.lowercase')} has no federal loan versions.</span>
                </div>
              )}
              columns={federalLoansColumns}
              dataSourcePromise={loadClientVersions}
              reloadDependency={clientVersionsQuery.previousData}
            />
          </Tabs.Item>

          {clientInformation?.client?.spouse && !isClient(user) ? (
            <Tabs.Item
              title={`${clientInformation.client.spouse.firstName} ${clientInformation.client.spouse.lastName} Versions`}
              key="spouse-versions"
            >
              <FinologyTable
                emptyText={() => (
                  <div className="flex gap-32">
                    <span>
                      Spouse of this {t('borrower.lowercase')} has no federal loan versions.
                    </span>
                  </div>
                )}
                columns={federalLoansColumns}
                dataSourcePromise={loadSpouseVersions}
                reloadDependency={spouseVersionsQuery.previousData}
              />
            </Tabs.Item>
          ) : (
            <></>
          )}
        </Tabs>
      ) : null}
    </>
  );
};
export const ClientFederalLoanVersions = () => {
  return (
    <Flyout
      id={`client-federal-loan-versions`}
      size={'medium'}
      requiredParams={['clientId']}
      content={<FlyoutContent />}
    ></Flyout>
  );
};
