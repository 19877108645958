export const STATE_TAX_RATE: { [key: string]: string } = {
  AK: '0.00%',
  AL: '5.00%',
  AR: '6.90%',
  AZ: '4.54%',
  CA: '13.10%',
  CO: '4.63%',
  CT: '6.99%',
  DE: '6.60%',
  FL: '0.00%',
  GA: '5.75%',
  HI: '11.00%',
  IA: '8.53%',
  ID: '6.93%',
  IL: '4.95%',
  IN: '3.32%',
  KS: '5.70%',
  KY: '5.00%',
  LA: '6.00%',
  MA: '5.05%',
  MD: '5.75%',
  ME: '7.15%',
  MI: '4.25%',
  MN: '9.85%',
  MO: '5.40%',
  MS: '5.00%',
  MT: '6.90%',
  NC: '4.75%',
  ND: '2.90%',
  NE: '6.84%',
  NH: '5.00%',
  NJ: '10.75%',
  NM: '4.90%',
  NO: '2.90%',
  NV: '0.00%',
  NY: '8.82%',
  OH: '5.00%',
  OK: '5.00%',
  OR: '9.90%',
  PA: '3.07%',
  RI: '5.99%',
  SC: '7.00%',
  SD: '0.00%',
  TN: '2.00%',
  TX: '0.00%',
  UT: '4.95%',
  VA: '5.75%',
  VT: '8.75%',
  WA: '0.00%',
  WI: '7.65%',
  WV: '6.50%',
  WY: '0.00%',
};
