import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';

export const GraduatedRepaymentCalculation = () => {
  const loanExampleInformation = [
    { label1: 'Amount of Student Loan:', label2: '$73,000' },
    { label1: 'Interest Rate:', label2: '5.25%' },
    { label1: 'Term of loan (yrs):', label2: '10' },
    { label1: 'Installments per year:', label2: '12' },
    { label1: 'PMT Increase Ratio:', label2: '0.3151' },
    { label1: 'Standard Payment:', label2: '$783' },
    { label1: '1 - 24 month Payment Amount:', label2: '$445' },
    { label1: '25 - 48 month Payment Amount:', label2: '$585' },
    { label1: '49 - 72 month Payment Amount:', label2: '$770' },
    { label1: '73 - 96 month Payment Amount:', label2: '$1,012' },
    { label1: '97 - 120 month Payment Amount:', label2: '$1,331' },
    { label1: 'Overall Total Amount Paid:', label2: '$99,436' },
    { label1: 'Overall Total Principal Paid:', label2: '$73,000' },
    { label1: 'Overall Total Interest Paid:', label2: '$26,436' },
  ];

  return (
    <div className="text-gray-500 p-6">
      <div className="flex gap-20">
        <div className=" max-w-[600px]">
          <Heading title="Non-Consolidated Loans"></Heading>
          <p>Under this plan, your monthly payments:</p>
          <br />
          -- Start out low and increase every two years. <br />
          -- Are made for up to 10 years for all loan types except Direct Consolidation Loans and
          FFEL Consolidation Loans.
          <br />
          -- Will never be less than the amount of interest that accrues between your payments.{' '}
          <br /> -- Won&apos;t be more than three times greater than any other payment.
        </div>
        <div className=" max-w-[600px]">
          <p>
            <Heading title="Consolidated Loans"></Heading>
            <p>Under this plan, your monthly payments:</p>
            <br /> -- Start out low and increase every two years
            <br /> -- Are made for a period of between 10 and 30 years for Direct Consolidated Loans
            and FFEL Consolidation Loans
            <br /> -- Will never be less than the amount of interest that accrues between your
            payments and
            <br /> -- Won&apos;t be more than three times greater than any other payment
          </p>
        </div>
      </div>
      {/* <Heading title="Try a different example:" className="my-8" />
      <div className=" max-w-[500px]">
        {loanExampleInformation.map((data, index) => (
          <SpaceBetweenLabels key={index} {...data} />
        ))}
      </div> */}
      <Paragraph
        className="my-8"
        text="If you have a Direct Consolidation Loan or FFEL Consolidation Loan, the length of your repayment period will depend on the amount of your total education loan indebtedness. This total education loan indebtedness includes the amount of your consolidation loan and your other student loan debt. Other student loan debt includes any federal student loans that are not included in the consolidation loan, as well as private education loans that are not eligible for consolidation. The maximum amount of other student loan debt that may be considered in determining your repayment period may not exceed the loan amount you are consolidating."
      />
      <Paragraph text="To include other student loan debt in the determination of the repayment period for your Direct Consolidation Loan, be sure to list those loans on your consolidation application in the section for listing loans that you do not want to consolidate, but want considered in the determination of your repayment period." />
      <Paragraph text="The chart below shows the maximum repayment period for a Direct Consolidation Loan or FFEL Consolidation Loan under the Graduated Repayment Plan depending on total education loan indebtedness." />
      <table className="w-full table-auto">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2 text-center" colSpan={4}>
              If your Total Education Loan Indebtedness is your Repayment Period will be...
            </th>
          </tr>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 text-left">At Least</th>
            <th className="px-4 py-2 text-left">Less Than</th>
            <th className="px-4 py-2 text-left">Period</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">$7,500</td>
            <td className="border px-4 py-2">$10,000</td>
            <td className="border px-4 py-2">10 years</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$10,000</td>
            <td className="border px-4 py-2">$20,000</td>
            <td className="border px-4 py-2">12 years</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$20,000</td>
            <td className="border px-4 py-2">$40,000</td>
            <td className="border px-4 py-2">15 years</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$40,000</td>
            <td className="border px-4 py-2">$60,000</td>
            <td className="border px-4 py-2">20 years</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$60,000</td>
            <td className="border px-4 py-2"></td>
            <td className="border px-4 py-2">25 years</td>
          </tr>
          <tr>
            <td className="border px-4 py-2"></td>
            <td className="border px-4 py-2"></td>
            <td className="border px-4 py-2">30 years</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
