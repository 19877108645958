import { Avatar, Button, Modal, Tooltip } from 'flowbite-react';
import { useState } from 'react';
import { MdEdit, MdDelete } from 'react-icons/md';

import EmptySearchUsers from '../../assets/empty_search-users.svg';
import { MaritalStatusLabel } from '../../components/labels/MaritalStatusLabel';
import { FinoLink } from '../../components/links/FinoLink';
import { FinologyTable } from '../../components/table/FinologyTable';
import {
  ClientListView,
  ClientListViewSortInput,
  InputMaybe,
  SortEnumType,
  useDeleteClientMutation,
  useGetClientsLazyQuery,
} from '../../graphql/generated';
import { useFlyoutNavigate } from '../../hooks/useFlyoutNavigate';
import { useTableSortBy } from '../../hooks/useTableSortBy';
import useFinologyTranslation from '../../translations/useFinologyTranslation';
import { toDollars } from '../../util/currency.formatter';
import { notification } from '../../util/notification.utils';
import { getInitials } from '../../utils';

import type { ColumnsType } from '../../components/table/FinologyTable';

const DeleteClientModal = ({
  clientToDelete,
  closeModal,
}: {
  clientToDelete?: ClientListView;
  closeModal: () => void;
}) => {
  const [deleteClient] = useDeleteClientMutation();
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);
  const { t } = useFinologyTranslation();

  return (
    <Modal show={clientToDelete !== undefined} onClose={closeModal}>
      <Modal.Header>{`Delete ${t('borrower.uppercase')}`}</Modal.Header>
      <Modal.Body>
        <p>Proceed with deleting of {clientToDelete?.fullName}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="light" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={disableDeleteButton}
          onClick={async () => {
            setDisableDeleteButton(true);

            await deleteClient({
              variables: {
                clientId: clientToDelete!.id,
              },
              refetchQueries: ['GetClients'],
            });

            setDisableDeleteButton(false);

            closeModal();

            notification.success({
              message: `${clientToDelete?.fullName} was successfully deleted`,
              placement: 'bottom-center',
            });
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const MyClients = () => {
  const { navigate } = useFlyoutNavigate();
  const { t } = useFinologyTranslation();
  const [getClients, { loading, previousData }] = useGetClientsLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [clientToDelete, setClientToDelete] = useState<ClientListView>();

  const { sortBy, prepareSortColumns, triggerRefetch } = useTableSortBy<ClientListViewSortInput>({
    createdAt: SortEnumType.Desc,
  });

  const columns: ColumnsType<ClientListView> = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => (
        <div className="flex items-center justify-start gap-4">
          <Avatar rounded placeholderInitials={getInitials(text)} />
          <FinoLink to={`/clients/${record.id}`} label={text} />
        </div>
      ),
    },
    {
      dataIndex: 'federalLoansSum',
      key: 'federalLoansSum',
      ...prepareSortColumns('federalLoansSum', 'Federal Debt', triggerRefetch),
      render: (text) => toDollars(text),
    },
    {
      ...prepareSortColumns('privateLoansSum', 'Private Debt', triggerRefetch),
      dataIndex: 'privateLoansSum',
      key: 'privateLoansSum',
      render: (text) => toDollars(text),
    },
    {
      dataIndex: 'creditCardLoansSum',
      key: 'creditCardLoansSum',
      ...prepareSortColumns('creditCardLoansSum', 'Credit Card Debt', triggerRefetch),
      render: (text) => toDollars(text),
    },
    {
      title: 'Marital Status',
      dataIndex: 'maritalStatus',
      key: 'maritalStatus',
      render: (text) => <MaritalStatusLabel maritalStatus={text} />,
    },
    {
      title: '',
      key: 'action',
      className: 'w-32',
      render: (_, record) => (
        <div className="flex gap-2">
          <Tooltip content="Edit">
            <MdEdit
              size={24}
              className="hover:cursor-pointer hover:text-primary"
              onClick={() => navigate('upsert-client', `clientId=${record.id}`)}
            />
          </Tooltip>
          <Tooltip content="Delete">
            <MdDelete
              size={24}
              className="hover:cursor-pointer hover:text-primary"
              onClick={() => setClientToDelete(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  async function load(
    skip?: number,
    take?: number,
    filterValue?: string,
    sortBy?: InputMaybe<ClientListViewSortInput | ClientListViewSortInput[]>
  ) {
    const { data } = await getClients({
      variables: {
        filter: filterValue ? { fullName: { contains: filterValue } } : undefined,
        skip,
        take,
        sortBy,
      },
    });

    return {
      rows: data!.clients!.items as ClientListView[],
      totalCount: data!.clients!.totalCount,
    };
  }

  return (
    <>
      <DeleteClientModal
        clientToDelete={clientToDelete}
        closeModal={() => setClientToDelete(undefined)}
      />
      <FinologyTable
        emptyText={(filter) => {
          return (
            <>
              <img alt="Users Image" src={EmptySearchUsers} height={200} width={200} />

              {!filter ? (
                <>
                  <span>It looks like you don’t have any {t('borrowers.lowercase')} yet.</span>
                  <span>
                    To add a new {t('borrower.lowercase')} utilize the button in the top right.{' '}
                  </span>
                </>
              ) : (
                <span>No {t('borrowers.lowercase')} found that match your search criteria.</span>
              )}
            </>
          );
        }}
        searchVisible={true}
        columns={columns}
        dataSourcePromise={load}
        sortBy={sortBy}
        reloadDependency={previousData}
        searchPlaceholder="Search for clients..."
      />
    </>
  );
};
