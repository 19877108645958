import { Badge } from 'flowbite-react';

import { ClientAdvisorApplicationStatus } from '../../graphql/generated';

export const ClientAdvisorApplicationStatusLabel = ({
  status,
}: {
  status: ClientAdvisorApplicationStatus;
}) => {
  switch (status) {
    case ClientAdvisorApplicationStatus.Pending:
      return (
        <Badge className="inline py-4" color="orange">
          Pending
        </Badge>
      );

    case ClientAdvisorApplicationStatus.TurnedDown:
      return (
        <Badge className="inline py-4" color="red">
          Turned Down
        </Badge>
      );

    case ClientAdvisorApplicationStatus.Accepted:
      return (
        <Badge className="inline py-4" color="green">
          Accepted
        </Badge>
      );

    case ClientAdvisorApplicationStatus.Missed:
      return (
        <Badge className="inline py-4" color="orange">
          Missed
        </Badge>
      );

    case ClientAdvisorApplicationStatus.Withdrawn:
      return <Badge className="inline py-4">Withdrawn</Badge>;

    default:
      return <div></div>;
  }
};
