import React, { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { BreadcrumbsItem } from '../components/breadcrumbs';
interface Breadcrumb {
  to: string;
  rightArrow?: boolean;
  text: ReactNode;
}

interface Props {
  rightContent?: React.ReactNode;
  breadcrumbs: Breadcrumb[];
  back?: boolean;
}

export const PageHeader = ({ rightContent, breadcrumbs, back }: Props) => {
  const [rightContentElement, setRightContentElement] = useState<Element | null>(null);

  useEffect(() => {
    setRightContentElement(document.querySelector('#header-right-content'));
  }, []);

  return (
    <>
      {breadcrumbs.map((breadcrumb) => (
        <BreadcrumbsItem
          key={breadcrumb.to}
          to={breadcrumb.to}
          back={back}
          rightArrow={breadcrumb.rightArrow}
        >
          {breadcrumb.text}
        </BreadcrumbsItem>
      ))}
      {rightContentElement && createPortal(rightContent, rightContentElement!)}
    </>
  );
};
