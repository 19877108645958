import { MaritalStatus } from '../../graphql/generated';

const translations = {
  [MaritalStatus.Divorced]: 'Divorced',
  [MaritalStatus.LifePartner]: 'Life Partner',
  [MaritalStatus.Married]: 'Married',
  [MaritalStatus.Separated]: 'Separated',
  [MaritalStatus.Single]: 'Single',
  [MaritalStatus.Widowed]: 'Divorced',
};

export const MaritalStatusLabel = ({ maritalStatus }: { maritalStatus: MaritalStatus }) => {
  if (!maritalStatus) return null;

  const translation = translations[maritalStatus];

  return <>{translation}</>;
};
