import { Form, Formik, FormikErrors } from 'formik';
import { useRef, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';

import { AuthenticationService } from '../../auth';
import { useFlyoutContext } from '../../components/flyout/Flyout';
import { FlyoutHeader } from '../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../components/forms/FormErrorNotification';
import { CropImage } from '../../components/images/CropImage';
import { useGlobalActions } from '../../context/AppContext';
import {
  useGetUserQuery,
  UpdateUserInput,
  useUploadUserProfileImageMutation,
  useUpdateUserMutation,
  MembershipAssociation,
} from '../../graphql/generated';
import { notification } from '../../util/notification.utils';
import { dataURLtoFile } from '../../utils';
import { TextInputField } from '../formComponents/InputField';
import SelectField from '../formComponents/SelectField';

import { Avatar, Button, Label, Modal, Spinner } from 'flowbite-react';

type FormValues = UpdateUserInput;

export const UserProfileFlyoutContent = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [openEditProfilePhotoModal, setOpenEditProfilePhotoModal] = useState<boolean>(false);
  const [openCropModal, setOpenCropModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>();
  const [imageToUpload, setImageToUpload] = useState<string>();
  const { data } = useGetUserQuery();

  const actions = useGlobalActions();

  const [updateUser] = useUpdateUserMutation();
  const [uploadProfileImageMutation] = useUploadUserProfileImageMutation();

  const { closeTopFlyout } = useFlyoutContext();

  if (!data?.user) return <Spinner />;

  const getModalContent = (
    onUpload: (imageToUpload: string) => void,
    profileImageUrl?: string | null
  ) => {
    return (
      <div className="flex center justify-center flex-columns">
        {imageToUpload || profileImageUrl ? (
          <div>
            <div onClick={() => inputRef!.current?.click()} className="cursor-pointer">
              <Avatar img={imageToUpload || profileImageUrl || undefined} size="xl" rounded />
            </div>
            {imageToUpload && (
              <div className="mt-4 flex gap-x-4 justify-between">
                <Button
                  color="light"
                  onClick={() => {
                    setImageToUpload(undefined);
                    setSelectedImage(undefined);
                  }}
                >
                  Remove Photo
                </Button>
                <Button onClick={() => onUpload(imageToUpload)}>Save</Button>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: '#D9D9D9',
            }}
            className="cursor-pointer rounded-full flex align-center items-center justify-center w-[200px] h-[200px] flex-col  "
            onClick={() => inputRef!.current?.click()}
          >
            <div>Click here to</div>
            <div className="underline text-center">upload from your computer</div>
          </div>
        )}
      </div>
    );
  };

  const { user } = data;

  return (
    <Formik
      initialValues={{
        addressCity: user.addressCity,
        addressState: user.addressState,
        addressStreet: user.addressStreet,
        addressZipCode: user.addressZipCode,
        facebook: user.facebook,
        firstName: user.firstName,
        id: user.id,
        lastName: user.lastName,
        linkedIn: user.linkedIn,
        profileImageUrl: user.profileImageUrl,
        professions: user.professions,
        twitter: user.twitter,
        methodOfCompensation: user.methodsOfCompensation,
        membershipAssociations: user.membershipAssociations,
        designations: user.designations,
        showOnFindAnAdvisor: user.showOnFindAnAdvisor,
        cfpBoardInfo: user.cfpBoardInfo,
        phone: user.phone,
        socialMedia: user.socialMedia,
        title: user.title,
      }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.firstName) {
          errors.firstName = 'Required';
        }
        if (!values.lastName) {
          errors.lastName = 'Required';
        }

        if (values.firstName != undefined && values.firstName.length > 100) {
          errors.firstName = 'First Name must be less than 100 characters';
        }
        if (values.lastName != undefined && values.lastName.length > 100) {
          errors.lastName = 'Last Name must be less than 100 characters';
        }

        return errors;
      }}
      onSubmit={async (v) => {
        await updateUser({
          variables: {
            input: {
              addressCity: v.addressCity,
              addressState: v.addressState,
              addressStreet: v.addressStreet,
              addressZipCode: v.addressZipCode,
              facebook: v.facebook,
              firstName: v.firstName!,
              id: v.id,
              lastName: v.lastName!,
              linkedIn: v.linkedIn,
              profileImageUrl: v.profileImageUrl,
              professions: v.professions,
              twitter: v.twitter,
              methodsOfCompensation: v.methodOfCompensation,
              membershipAssociations: v.membershipAssociations,
              designations: v.designations,
              showOnFindAnAdvisor: v.showOnFindAnAdvisor,
              cfpBoardInfo: v.cfpBoardInfo,
              phone: v.phone,
              socialMedia: v.socialMedia,
              title: v.title,
            },
          },
          refetchQueries: ['GetUser'],
        });

        notification.success({
          placement: 'bottom-center',
          message: 'Profile information saved',
        });

        actions.setProfilePicture(v.profileImageUrl);

        await AuthenticationService.updateUser({ profile: { profile_picture: v.profileImageUrl } });

        closeTopFlyout();
      }}
    >
      {({ values, setFieldValue, setFieldTouched, isSubmitting }) => {
        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'edit-profile-form',
                children: 'Save',
                disabled: isSubmitting,
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={'Edit Profile'}
            />
            <Form id={'edit-profile-form'} className="pb-12">
              <div className="flex">
                <div className="flex flex-col mb-4 gap-4">
                  <Label>Profile picture</Label>
                  <Avatar
                    className="self-start"
                    rounded
                    size={'lg'}
                    onClick={() => setOpenEditProfilePhotoModal(true)}
                    img={values.profileImageUrl || undefined}
                  />
                </div>

                <Modal
                  show={openEditProfilePhotoModal}
                  // width={671}
                  onClose={() => setOpenEditProfilePhotoModal(false)}
                  // okButtonProps={{ hidden: true }}
                  // cancelButtonProps={{ hidden: true }}
                  // footer={false}
                >
                  <Modal.Header>Edit Profile Photo</Modal.Header>
                  <Modal.Body>
                    {getModalContent((image) => {
                      const newFile = dataURLtoFile(image, values.firstName + '-profile-image');
                      uploadProfileImageMutation({
                        variables: { file: newFile },
                        context: {
                          headers: {
                            'GraphQL-preflight': 1,
                          },
                        },
                      }).then((response) => {
                        if (response.data) {
                          const { data } = response;
                          setFieldValue(
                            'profileImageUrl',
                            data?.uploadUserProfileImage!.profileImageUrl
                          );
                          if (data?.uploadUserProfileImage!.profileImageUrl) {
                            setOpenEditProfilePhotoModal(false);
                          }
                        }
                      });
                    }, values.profileImageUrl)}
                  </Modal.Body>

                  <Modal show={openCropModal} onClose={() => setOpenCropModal(false)}>
                    <Modal.Header>Crop Photo</Modal.Header>
                    <Modal.Body>
                      <div className="flex items-center justify-center">
                        <CropImage
                          src={selectedImage}
                          onComplete={(image: string) => {
                            setImageToUpload(image);
                            setOpenCropModal(false);
                          }}
                        />
                      </div>
                    </Modal.Body>
                  </Modal>
                  <input
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    hidden
                    onChange={async (e) => {
                      setFieldTouched('logoUrl');

                      if (!e.target.files) return;

                      setSelectedImage(URL.createObjectURL(e.target.files[0]));
                      setOpenCropModal(true);
                    }}
                  />
                </Modal>
              </div>
              <div className="flex gap-3 flex-col">
                <TextInputField name="firstName" placeholder="First Name"></TextInputField>
                <TextInputField name="lastName" placeholder="Last Name"></TextInputField>
                <TextInputField name="addressStreet" placeholder="Street"></TextInputField>
                <TextInputField name="addressCity" placeholder="City"></TextInputField>
                <SelectField name="addressState" label="State">
                  <option value="AL"> Alabama</option>
                  <option value="AK"> Alaska</option>
                  <option value="AZ"> Arizona</option>
                  <option value="AR"> Arkansas</option>
                  <option value="CA"> California</option>
                  <option value="CO"> Colorado</option>
                  <option value="CT"> Connecticut</option>
                  <option value="DE"> Delaware</option>
                  <option value="DC"> District of Columbia (Washington DC)</option>
                  <option value="FL"> Florida</option>
                  <option value="GA"> Georgia</option>
                  <option value="HI"> Hawaii</option>
                  <option value="ID"> Idaho</option>
                  <option value="IL"> Illinois</option>
                  <option value="IN"> Indiana</option>
                  <option value="IA"> Iowa</option>
                  <option value="KS"> Kansas</option>
                  <option value="KY"> Kentucky</option>
                  <option value="LA"> Louisiana</option>
                  <option value="ME"> Maine</option>
                  <option value="MD"> Maryland</option>
                  <option value="MA"> Massachusetts</option>
                  <option value="MI"> Michigan</option>
                  <option value="MN"> Minnesota</option>
                  <option value="MS"> Mississippi</option>
                  <option value="MO"> Missouri</option>
                  <option value="MT"> Montana</option>
                  <option value="NE"> Nebraska</option>
                  <option value="NV"> Nevada</option>
                  <option value="NH"> New Hampshire</option>
                  <option value="NJ"> New Jersey</option>
                  <option value="NM"> New Mexico</option>
                  <option value="NY"> New York</option>
                  <option value="NC"> North Carolina</option>
                  <option value="ND"> North Dakota</option>
                  <option value="OH"> Ohio</option>
                  <option value="OK"> Oklahoma</option>
                  <option value="OR"> Oregon</option>
                  <option value="PA"> Pennsylvania</option>
                  <option value="PR"> Puerto Rico</option>
                  <option value="RI"> Rhode Island</option>
                  <option value="SC"> South Carolina</option>
                  <option value="SD"> South Dakota</option>
                  <option value="TN"> Tennessee</option>
                  <option value="TX"> Texas</option>
                  <option value="UT"> Utah</option>
                  <option value="US"> US Territory</option>
                  <option value="VT"> Vermont</option>
                  <option value="VA"> Virginia</option>
                  <option value="QA"> Washington</option>
                  <option value="WV"> West Virginia</option>
                  <option value="WI"> Wisconsin</option>
                  <option value="WY"> Wyoming</option>
                </SelectField>
                <TextInputField name="phone" placeholder="Phone"></TextInputField>
                {/*<TextInputField name="socialMedia" placeholder="Social Media"></TextInputField>
                <TextInputField name="cfpBoardInfo" placeholder="CFP Board Info"></TextInputField>*/}
                {/*    {globalUser?.accountType == AccountType.Organization ? (
                  <>
                    <MultiSelectField
                      name="designations"
                      label="Designations"
                      options={[
                        { label: 'CAIA', value: Designation.Caia },
                        { label: 'CCFC', value: Designation.Ccfc },
                        { label: 'CCPS', value: Designation.Ccps },
                        { label: 'CFA', value: Designation.Cfa },
                        { label: 'CFP', value: Designation.Cfp },
                        { label: 'CHFC', value: Designation.ChFc },
                        { label: 'CIC', value: Designation.Cic },
                        { label: 'CLU', value: Designation.Clu },
                        { label: 'CMA', value: Designation.Cma },
                        { label: 'CMFC', value: Designation.Cmfc },
                        { label: 'CPA', value: Designation.Cpa },
                        { label: 'CSLP', value: Designation.Cslp },
                        { label: 'FRC', value: Designation.Frm },
                      ]}
                    />
                    <MultiSelectField
                      name="professions"
                      label={`${t('borrower.uppercase')} Niche`}
                      limit={1}
                      options={[
                        {
                          label: 'Advanced Degree Holders',
                          value: Profession.AdvancedDegreeHolders,
                        },
                        { label: 'Airline Pilots', value: Profession.AirlinePilots },
                        { label: 'Artists', value: Profession.Artists },
                        { label: 'Attorneys', value: Profession.Attorneys },
                        { label: 'Automotive Engineers', value: Profession.AutomotiveEngineers },
                        { label: 'BioTech Professional', value: Profession.BioTechProfessional },
                        { label: 'Business Executives', value: Profession.BusinessExecutives },
                        { label: 'Business Owners', value: Profession.BusinessOwners },
                        {
                          label: 'Commission Irregular Income Employees',
                          value: Profession.CommissionIrregularIncomeEmployees,
                        },
                        { label: 'Consultants', value: Profession.Consultants },
                        { label: 'Dentists', value: Profession.Dentists },
                        { label: 'Educators and Teachers', value: Profession.EducatorsandTeachers },
                        { label: 'Engineers', value: Profession.Engineers },
                        {
                          label: 'Entertainment Industry Professionals',
                          value: Profession.EntertainmentIndustryProfessionals,
                        },
                        { label: 'Entrepreneurs', value: Profession.Entrepreneurs },
                        {
                          label: 'Federal Government Employees',
                          value: Profession.FederalGovernmentEmployees,
                        },
                        {
                          label: 'Financial Professionals',
                          value: Profession.FinancialProfessionals,
                        },
                        { label: 'Firefighters', value: Profession.Firefighters },
                        { label: 'Freelancers', value: Profession.Freelancers },
                        { label: 'Hr Professionals', value: Profession.HrProfessionals },
                        { label: 'LawEnforcement', value: Profession.LawEnforcement },
                        { label: 'Pharmacists', value: Profession.Pharmacists },
                        { label: 'Physicians', value: Profession.Physicians },
                        { label: 'Military', value: Profession.Military },
                        { label: 'Musicians', value: Profession.Musicians },
                        { label: 'NonProfit', value: Profession.NonProfit },
                        { label: 'Nurses', value: Profession.Nurses },
                        { label: 'Pastors', value: Profession.Pastors },
                        { label: 'Photographers', value: Profession.Photographers },
                        { label: 'Politicians', value: Profession.Politicians },
                        { label: 'Professional Athletes', value: Profession.ProfessionalAthletes },
                        { label: 'Psychologists', value: Profession.Psychologists },
                        {
                          label: 'RealEstate Professionals',
                          value: Profession.RealEstateProfessionals,
                        },
                        { label: 'Sales Professionals', value: Profession.SalesProfessionals },
                        { label: 'Startup Founders', value: Profession.StartupFounders },
                        { label: 'Technology Executives', value: Profession.TechnologyExecutives },
                        { label: 'Veterinarians', value: Profession.Veterinarians },
                      ]}
                    />
                    <MultiSelectField
                      name="membershipAssociations"
                      label="Membership Association"
                      options={[
                        {
                          label: 'American College of Financial Services',
                          value: MembershipAssociation.AmericanCollegeOfFinancialServices,
                        },
                        {
                          label: 'American Institute of Certified Public Accountants (AICPA)',
                          value:
                            MembershipAssociation.AmericanInstituteOfCertifiedPublicAccountants,
                        },
                        {
                          label: 'Association of Financial Counseling Planning Education (AFCPE)',
                          value:
                            MembershipAssociation.AssociationOfFinancialCounselingPlanningEducation,
                        },
                        {
                          label: 'Certified Financial Planner Board of Standards (CFP Board)',
                          value: MembershipAssociation.CertifiedFinancialPlannerBoardOfStandards,
                        },
                        {
                          label: 'CFA Institute',
                          value: MembershipAssociation.CfaInstitute,
                        },
                        {
                          label: 'Fee Only Network',
                          value: MembershipAssociation.FeeOnlyNetwork,
                        },
                        {
                          label: 'Financial Planning Association (FPA)',
                          value: MembershipAssociation.FinancialPlanningAssociation,
                        },
                        {
                          label: 'Garrett Planning Network',
                          value: MembershipAssociation.GarrettPlanningNetwork,
                        },
                        {
                          label: 'National Association of Personal Financial Advisors (NAPFA)',
                          value:
                            MembershipAssociation.NationalAssociationofPersonalFinancialAdvisors,
                        },
                        {
                          label: 'XY Planning Network',
                          value: MembershipAssociation.XyPlanningNetwork,
                        },
                      ]}
                    />
                    <MultiSelectField
                      name="methodOfCompensation"
                      label="Method of Compensation"
                      options={[
                        {
                          label: 'Assets Under Management (AUM)',
                          value: MethodOfCompensation.AssetsUnderManagement,
                        },
                        {
                          label: 'Commissions',
                          value: MethodOfCompensation.Commissions,
                        },
                        {
                          label: 'Fee-Based',
                          value: MethodOfCompensation.FeeBased,
                        },
                        {
                          label: 'Fee-Only',
                          value: MethodOfCompensation.FeeOnly,
                        },
                        {
                          label: 'Fixed Fee',
                          value: MethodOfCompensation.FixedFee,
                        },
                        {
                          label: 'Fixed Retainer Fee',
                          value: MethodOfCompensation.FixedRetainerFee,
                        },
                        {
                          label: 'Flat Fee',
                          value: MethodOfCompensation.FlatFee,
                        },
                        {
                          label: 'Hourly Fee',
                          value: MethodOfCompensation.HourlyFee,
                        },
                        {
                          label: 'Retainer Fee',
                          value: MethodOfCompensation.RetainerFee,
                        },
                        {
                          label: 'Subscription',
                          value: MethodOfCompensation.Subscription,
                        },
                      ]}
                    />
                    <CheckboxField
                      name="showOnFindAnAdvisor"
                      label="Enable Find an Advisor Listing"
                    />
                  </>
                ) : null} */}
                <div className="flex justify-end">
                  <Button
                    form="edit-profile-form"
                    disabled={isSubmitting}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    type="submit"
                    className="mt-4"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const MembershipAssociationsLabel = ({
  membershipAssociations,
}: {
  membershipAssociations: MembershipAssociation[] | null | undefined;
}) => {
  if (!membershipAssociations || membershipAssociations.length == 0) return null;

  return (
    <>
      {' '}
      {membershipAssociations
        .map((r) => {
          switch (r) {
            case MembershipAssociation.AmericanCollegeOfFinancialServices:
              return 'American College of Financial Services';
            case MembershipAssociation.AmericanInstituteOfCertifiedPublicAccountants:
              return 'American Institute of Certified Public Accountants (AICPA)';
            case MembershipAssociation.AssociationOfFinancialCounselingPlanningEducation:
              return 'Association of Financial Counseling Planning Education (AFCPE)';
            case MembershipAssociation.CertifiedFinancialPlannerBoardOfStandards:
              return 'Certified Financial Planner Board of Standards (CFP Board)';
            case MembershipAssociation.CfaInstitute:
              return 'CFA Institute';
            case MembershipAssociation.FeeOnlyNetwork:
              return 'Fee Only Network';
            case MembershipAssociation.FinancialPlanningAssociation:
              return 'Financial Planning Association (FPA)';
            case MembershipAssociation.GarrettPlanningNetwork:
              return 'Garrett Planning Network';
            case MembershipAssociation.NationalAssociationofPersonalFinancialAdvisors:
              return 'National Association of Personal Financial Advisors (NAPFA)';
            case MembershipAssociation.XyPlanningNetwork:
              return 'XY Planning Network';
          }
        })
        .join(', ')}
    </>
  );
};
