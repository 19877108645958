import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';

export const FederalTaxBrackets = () => {
  return (
    <div className=" p-6  ">
      <Heading title="Tax Brackets" />
      <Paragraph text="Tax brackets are a fundamental component of the tax system in many countries, including the United States. They determine how much income tax individuals owe based on their earnings. In this explanation, we'll focus on the tax brackets used in the U.S. federal income tax system as of 2023. Keep in mind that tax laws can change, so it's essential to consult the most current tax regulations or a tax professional for up-to-date information." />
      <Paragraph text="In the U.S., the federal income tax system is progressive, which means that as your income increases, you pay a higher percentage of your income in taxes. The tax brackets are divided into different income ranges, each with its associated tax rate. Understanding these brackets is crucial for accurately calculating and filing your income tax return." />
      <Heading title="10% Bracket:" />
      <Paragraph text="The lowest tax bracket is 10%. This bracket applies to individuals with taxable incomes up to a certain threshold, which is the lowest income range. In 2023, for single filers, this threshold was up to $11,000, and for married couples filing jointly, it was $22,000. For taxpayers within this bracket, the first portion of their income is taxed at 10%." />
      <Heading title="12% Bracket:" />
      <Paragraph text="The 12% bracket encompasses a higher income range. For single filers, it applies to incomes between $11,001 and $44,725 in 2023. For married couples filing jointly, the range is $22,001 to $89,450. Income falling within this bracket is taxed at a 12% rate." />
      <Heading title="22% Bracket:" />
      <Paragraph text="The 22% bracket includes a broader income range. In 2023, for single filers, it applies to incomes between $44,726 to $95,375. For married couples filing jointly, the range is $89,451 to $190,750. Incomes within this bracket are taxed at a 22% rate." />
      <Heading title="24% Bracket:" />
      <Paragraph text="The 24% bracket encompasses higher incomes. For single filers in 2023, it applies to incomes between $95,376 to $182,100. For married couples filing jointly, the range is $190,751 to $364,200. Income within this bracket is taxed at a 24% rate." />
      <Heading title="32% Bracket:" />
      <Paragraph text="The 32% bracket is for relatively high incomes. In 2023, for single filers, it applies to incomes between $182,101 to $231,250. For married couples filing jointly, the range is $364,201 to $462,500. Income within this bracket is taxed at a 32% rate." />
      <Heading title="35% Bracket:" />
      <Paragraph text="The 35% bracket is for even higher incomes. For single filers in 2023, it applies to incomes between $231,251 to $578,125. For married couples filing jointly, the range is $462,501 to $693,750. Income in this bracket is taxed at a 35% rate." />
      <Heading title="37% Bracket:" />
      <Paragraph text="The highest tax bracket is 37%. In 2023, for single filers, it applies to incomes over $578,126, and for married couples filing jointly, it applies to incomes over $693,751. Income exceeding these thresholds is taxed at a 37% rate." />
      <Paragraph text="It's important to note that these tax brackets are subject to change, and various factors such as filing status, deductions, and credits can affect the actual amount of taxes you owe. Additionally, state income tax rates may apply in addition to federal taxes, further impacting your overall tax liability. To accurately determine your tax liability, consult the most current tax regulations and consider seeking professional tax advice if needed." />
    </div>
  );
};
