import classNames from 'classnames';
import { Card } from 'flowbite-react';
import { Button } from 'flowbite-react';
import { ReactNode } from 'react';


export const HighlightedCard = ({
  label,
  value,
  color = 'bg-cyan-100',
  textColor = 'text-cyan-700',
}: {
  label: string;
  value: ReactNode;
  color?: string;
  textColor?: string;
}) => {
  return (
    <div className={`${color} rounded-lg shadow flex-col justify-end items-center inline-flex`}>
      <div className="self-stretch py-4 flex-col justify-center items-center gap-1 flex">
        <div className="flex-col justify-start items-start flex">
          <div className={`${textColor} text-2xl font-bold`}>{value}</div>
        </div>
      </div>
      <div className="self-stretch py-3 bg-white rounded-bl-lg rounded-br-lg justify-center items-start gap-2.5 inline-flex">
        <div className="text-gray-800 text-xs font-medium">{label}</div>
      </div>
    </div>
  );
};

export const CardWithItems = ({
  upperRowItems,
  lowerRowItems,
  className,
  upperRowClass,
}: {
  upperRowItems: { text: ReactNode; value?: ReactNode | null }[];
  lowerRowItems: { text: ReactNode; value?: ReactNode | null }[];
  className?: string;
  upperRowClass?: string;
}) => {
  return (
    <Card className={classNames('flex-1 min-w-min', className)}>
      <div className={upperRowClass || 'flex flex-wrap justify-between'}>
        {upperRowItems.map((item, index) => {
          return (
            <div key={index} className="flex flex-col">
              <span className="text-gray-500 text-sm font-medium">{item.text}</span>
              <span className="text-primary-800 text-5xl font-normal">
                {item.value === null || item.value === undefined ? 'n/a' : item.value}
              </span>
            </div>
          );
        })}
      </div>

      <div className={'flex justify-between gap-2'}>
        {lowerRowItems.map((item, index) => {
          return (
            <div key={index} className="flex flex-col">
              <span className="text-gray-500 text-sm font-medium">{item.text}</span>
              <span className="text-2xl font-bold text-primary-500">
                {item.value === null || item.value === undefined ? 'n/a' : item.value}
              </span>
            </div>
          );
        })}
      </div>
    </Card>
  );
};
export const LinkCard = ({
  title,
  text,
  linkText,
  linkIcon,
  onClick,
}: {
  title: string;
  text: string;
  linkText: string;
  linkIcon: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <Card>
      <div className="flex grow justify-between">
        <div className="flex flex-col">
          <span className="font-bold">{title}</span>
          <span className="font-normal text-sm">{text}</span>
        </div>

        <Button color="light" onClick={onClick} className="hover:bg-gray-100 text-teal-900">
          <div className="flex items-center gap-2">
            {linkIcon}
            {linkText}
          </div>
        </Button>
      </div>
    </Card>
  );
};
