import { PageHeader } from '../../components/PageHeader';
import { SuperAdminUsersList } from '../../features/super-admin/users/UsersList';
import { useFlyoutNavigate } from '../../hooks/useFlyoutNavigate';

export const SuperAdminUsersPage = () => {
  const { navigate } = useFlyoutNavigate();
  return (
    <>
      <PageHeader breadcrumbs={[{ to: '/super-admin/advisors', text: 'Advisors List' }]} />

      <SuperAdminUsersList />
    </>
  );
};
