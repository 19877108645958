import { TaxFilingType } from '../../graphql/generated';

const translations = {
  [TaxFilingType.HeadOfHousehold]: 'Head of Household',
  [TaxFilingType.MarriedFilingJointly]: 'Married Filing Jointly',
  [TaxFilingType.MarriedFilingSeparately]: 'Married Filing Separately',
  [TaxFilingType.Single]: 'Single',
};

export const TaxFilingTypeLabel = ({ taxFilingType }: { taxFilingType: TaxFilingType }) => {
  if (!taxFilingType) return null;

  const translation = translations[taxFilingType];

  return <>{translation}</>;
};
