import { Button } from 'flowbite-react';

import { AuthenticationService } from '../auth';
import { PageHeader } from '../components/PageHeader';
import { useGlobalState } from '../context/AppContext';
import { Settings } from '../features/settings/Settings';

export const SettingsPage = () => {
  const { user } = useGlobalState();

  return (
    <>
      <PageHeader
        breadcrumbs={[{ to: '/dashboard', text: 'Hi ' + user!.firstName }]}
        rightContent={
          <Button color="light" onClick={() => AuthenticationService.logout()}>
            Sign Out
          </Button>
        }
      />

      <Settings />
    </>
  );
};
