import { ToggleSwitch } from 'flowbite-react';
import { Field, useFormikContext } from 'formik';

const AntSwitch = ({ field, form, ...props }: any) => {
  return (
    <ToggleSwitch
      color="primary"
      id={field.name}
      label={props.label}
      checked={props.checked || field.value}
      disabled={props.disabled}
      sizing={props.sizing}
      style={props.style}
      onChange={(e) => {
        props.onChange && props.onChange(e);
        form.setFieldValue(field.name, e);
      }}
    />
  );
};

export const SwitchField = ({
  name,
  label,
  className,
  checked,
  onChange,
  disabled,
  style,
  sizing = 'md',
}: {
  name: string;
  label: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e?: any) => void;
  sizing?: 'sm' | 'md' | 'lg';
  style?: React.CSSProperties;
}) => {
  const form = useFormikContext<any>();

  const error = form.errors[name];

  if (error != undefined && typeof error !== 'string' && typeof error !== 'number')
    throw new Error('Error must be of type string');

  return (
    <div className={className + ' truncate'}>
      <Field
        name={name}
        label={label}
        component={AntSwitch}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        sizing={sizing}
        style={style}
      />
      {error && <p className=" text-sm text-red-700">{error} &nbsp;</p>}
    </div>
  );
};
