import { Paragraph } from '../../../components/Assumptions/Paragraph';

export const ExtendedRepaymentPlan = () => {
  return (
    <div className="text-gray-500 p-6">
      <Paragraph text="If you're a Direct Loan borrower, you must have had no outstanding balance on a Direct Loan as of October 7, 1998, or on the date you obtained a Direct Loan after October 7, 1998, and you must have more than $30,000 in outstanding Direct Loans." />
      <Paragraph text="If you're a FFEL borrower, to qualify for this plan you must have had no outstanding balance on a FFEL Program loan as of October 7, 1998, or on the date you obtained a FFEL Program loan after October 7, 1998, and you must have more than $30,000 in outstanding FFEL Program loans." />
      <Paragraph text="For example, if you have $35,000 in outstanding FFEL Program loans and $10,000 in outstanding Direct Loans, you can choose the Extended Repayment Plan for you FFEL Program Loans, but not for your Direct Loans." />
      <p>
        Under this plan, your monthly payments are <br />
        -- a fixed or graduated amount
        <br /> -- made for up to 25 years if fixed and 10 years if graduated <br />
        -- generally lower than payments made under the Standard and Graduated Repayment Plans
      </p>
    </div>
  );
};
