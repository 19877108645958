import dayjs from 'dayjs';

const internalApiHost = process.env.REACT_APP_INTERNAL_API_HOST;
const reactAppHost = process.env.REACT_APP_REACT_APP_HOST;

export enum AccountType {
  Borrower = 'Borrower',
  College = 'College',
  Organization = 'Organization',
}

export interface FinologyUser {
  id: number;
  finologyClientId?: number;
  hasActiveSubscription: boolean;
  subscriptionStatus: string | null;
  tenantId: number | null;
  isSuperAdmin: boolean;
  firstName?: string;
  lastName?: string;
  email: string;
  profilePicture?: string;
  companyLogo?: string;
  roles?: string[];
  accountType: AccountType;
  sid: string;
  createdAt: string;
  tenantName: string | null;
}

class AuthService {
  user?: FinologyUser;

  public async logout(returnUrl?: string) {
    const user = await this.getUser();

    window.location.href = `${internalApiHost}/bff/logout?sid=${user!.sid}${
      returnUrl ? `&returnUrl=${returnUrl}` : ''
    }`;
  }

  public login(returnUrl?: string) {
    window.location.href = `${internalApiHost}/bff/login?returnUrl=${returnUrl || reactAppHost}`;
  }

  private getClaimValue(claims: any[], type: string) {
    return claims.find((claim) => claim.type === type)?.value;
  }

  private getClaimValues(claims: any[], type: string) {
    return claims.filter((claim) => claim.type === type).map((r) => r.value);
  }

  public async getUser() {
    if (this.user) return this.user;

    try {
      const resp = await fetch(`${internalApiHost}/bff/user?slide=false`, {
        credentials: 'include',
        headers: new Headers({
          'x-csrf': '1',
        }),
      });

      if (!resp.ok) return null;

      const profile = await resp.json();

      const subscriptionValidUntil = this.getClaimValue(profile, 'subscription_valid_until') as
        | undefined
        | number;
      const roles = this.getClaimValues(profile, 'role') as string[];
      const finology_client_id = this.getClaimValue(profile, 'finology_client_id');

      const tenantId = this.getClaimValue(profile, 'tenant_id');

      this.user = {
        id: Number.parseInt(this.getClaimValue(profile, 'sub')),
        tenantId: tenantId ? Number.parseInt(tenantId) : null,
        firstName: this.getClaimValue(profile, 'first_name') as string,
        tenantName: this.getClaimValue(profile, 'tenant_name') as string | null,
        createdAt: this.getClaimValue(profile, 'created_at') as string,
        lastName: this.getClaimValue(profile, 'last_name') as string,
        email: this.getClaimValue(profile, 'email') as string,
        profilePicture: this.getClaimValue(profile, 'profile_picture') as string,
        isSuperAdmin: roles.includes('SuperAdmin'),
        companyLogo: this.getClaimValue(profile, 'company_logo') as string,
        subscriptionStatus: this.getClaimValue(profile, 'subscription_status') as string,
        accountType: this.getClaimValue(profile, 'account_type') as AccountType,
        roles: roles,
        finologyClientId: finology_client_id ? Number.parseInt(finology_client_id) : undefined,
        hasActiveSubscription:
          subscriptionValidUntil !== undefined &&
          dayjs.unix(subscriptionValidUntil).isAfter(dayjs.utc()),
        sid: this.getClaimValue(profile, 'sid'),
      };

      return this.user;
    } catch (e) {
      console.log('error checking user status');

      return null;
    }
  }

  public async updateUser(profile: any) {
    this.user = { ...this.user, ...profile };
  }
}

export const AuthenticationService = new AuthService();
