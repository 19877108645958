export const openDocument = (
  name: string,
  data: any,
  type: 'application/pdf' = 'application/pdf'
) => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);

  const anchor = window.document.createElement('a');
  anchor.download = name;
  anchor.href = url;
  anchor.click();
  anchor.remove();
};

export default openDocument;
