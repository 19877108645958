
import { PageHeader } from '../../components/PageHeader';
import { AdvisorApplicationsList } from '../../features/advisor-applications/AdvisorApplicationsList';

export const AdvisorApplicationsPage = () => {
  return (
    <>
      <PageHeader breadcrumbs={[{ to: '/advisor-applications', text: `Advisor Applications` }]} />

      <AdvisorApplicationsList />
    </>
  );
};
