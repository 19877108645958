import { Form, Formik, FormikErrors } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../components/forms/FormErrorNotification';
import { useLinkUploadedNsldsMutation } from '../../../graphql/generated';
import { notification } from '../../../util/notification.utils';
import { NsldsUploadField } from '../../formComponents/NsldsUploadField';

type FormValues = { uploadId?: number; source?: string };

const FlyoutContent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const clientId = Number.parseInt(params.get('clientId')!);
  const redirect = params.get('redirect');

  const navigate = useNavigate();

  const [linkUploadedNslds] = useLinkUploadedNsldsMutation();

  const initialValues: FormValues = {};

  const { closeTopFlyout } = useFlyoutContext();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (v, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        await linkUploadedNslds({
          variables: {
            input: {
              clientId: clientId,
              uploadId: v.uploadId!,
              source: v.source!,
            },
          },
          refetchQueries: [
            'GetClients',
            'GetClientDashboard',
            'GetClientFederalLoanVersions',
            'GetFederalLoans',
            'GetPrivateLoans',
          ],
        });

        notification.success({
          placement: 'bottom-center',
          message: 'New NSLDS version has been uploaded successfully',
        });

        closeTopFlyout();

        if (redirect) {
          navigate('dashboard', { replace: true });
        }
      }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.uploadId) {
          errors.uploadId = 'Required';
        }

        if (!values.source) {
          errors.source = 'Required';
        }

        return errors;
      }}
    >
      {({ isSubmitting }) => (
        <>
          <FormErrorNotification />
          <FlyoutHeader
            primaryButton={{
              form: 'upload-nslds-form',
              children: 'Save',
              disabled: isSubmitting,
            }}
            secondaryButton={{
              onClick: closeTopFlyout,
              children: 'Cancel',
            }}
            label={'Upload NSLDS'}
          />
          <Form id="upload-nslds-form">
            <NsldsUploadField name="uploadId" placeholder="NSLDS File" />
          </Form>
        </>
      )}
    </Formik>
  );
};

export const UploadNsldsFlyout = () => {
  return (
    <Flyout
      id={`upload-nslds`}
      requiredParams={['clientId', 'redirect']}
      size={'small'}
      content={<FlyoutContent />}
    ></Flyout>
  );
};
