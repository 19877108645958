import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { FinoLink } from './FinoLink';

export const FlyoutLink = ({
  flyoutId,
  params,
  children,
  className,
}: {
  flyoutId: string;
  params: string;
  children: ReactNode;
  className?: string;
}) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const flyoutLayers = searchParams.get('flyout')?.split(',') || [];

  searchParams.delete('flyout');

  const parentParams = searchParams.toString();

  const flyoutParam = [...flyoutLayers, flyoutId].join(',');

  return (
    <FinoLink
      to={`?flyout=${flyoutParam}${parentParams ? `&${parentParams}` : ''}${
        params ? `&${params}` : ''
      }`}
      className={className}
      label={children}
    />
  );
};
