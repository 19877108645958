import { Tooltip } from 'flowbite-react';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';

import EmptySearchUsers from '../../../assets/empty_search-users.svg';
import { AccountType } from '../../../auth';
import { FinologyTable } from '../../../components/table/FinologyTable';
import { useGlobalState } from '../../../context/AppContext';
import { useGetUsersLazyQuery, UserView } from '../../../graphql/generated';
import { useFlyoutNavigate } from '../../../hooks/useFlyoutNavigate';

import type { ColumnsType } from '../../../components/table/FinologyTable';

export const UsersList = () => {
  const [getClients, query] = useGetUsersLazyQuery();
  const { navigate } = useFlyoutNavigate();
  const { user } = useGlobalState();

  const columns: ColumnsType<UserView> = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      // render: (text, record) => <Link to={`?flyout=edit-profile&userId=${record.id}`}>{text}</Link>,
      render: (text, record) => text,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      // render: (text, record) => <Link to={`?flyout=edit-profile&userId=${record.id}`}>{text}</Link>,
      render: (text, record) => text,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => text,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <div className="flex-1">
          <Tooltip content="Manage Assistant">
            <MdEdit
              size={24}
              className="hover:cursor-pointer hover:text-primary"
              onClick={() => navigate('edit-advisor-assistant', `userId=${record.id}`)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  async function load(skip?: number, take?: number, filterValue?: string) {
    const { data } = await getClients({
      variables: {
        filter: filterValue
          ? {
              or: [
                { firstName: { contains: filterValue } },
                { lastName: { contains: filterValue } },
              ],
            }
          : undefined,
        skip: skip,
        take: take,
      },
    });

    return {
      rows: data!.users!.items as UserView[],
      totalCount: data!.users!.totalCount,
    };
  }

  const numberOfRemainingRows =
    (query.data?.tenant?.enforcedMaximumUsersCount || 0) - (query.data?.users?.totalCount || 0);

  return (
    <>
      <div className="flex">
        <div className="grow">
          <FinologyTable
            searchVisible={true}
            columns={columns}
            remainingRowsConfig={
              user?.accountType == AccountType.College
                ? {
                    numberOfRemainingRows,
                    option: 'fill',
                    rowTemplate: () => {
                      return (
                        <div className="flex justify-center">
                          You still have {numberOfRemainingRows} free slot
                          {numberOfRemainingRows == 1 ? '' : 's'}. &nbsp;{' '}
                          <Link to={`?flyout=invite-user`}>Add New Advisor</Link> &nbsp; to consume
                          it.
                        </div>
                      );
                    },
                  }
                : undefined
            }
            reloadDependency={query.previousData}
            dataSourcePromise={load}
            searchPlaceholder="Search for users..."
            emptyText={(filter) => {
              return (
                <>
                  <img alt="Users Image" src={EmptySearchUsers} height={200} width={200} />

                  {!filter ? (
                    <>
                      <span>
                        It looks like you don’t have any additional users in your organisation.
                      </span>
                      <span>
                        Click the Add New Advisor button on top to invite additional advisors.
                      </span>
                    </>
                  ) : (
                    <span>No users found that match your search criteria.</span>
                  )}
                </>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};
