import { Navbar } from 'flowbite-react';

import { Breadcrumbs } from '../../components/breadcrumbs';

export const Header = () => {
  return (
    <Navbar
      fluid
      className="sticky top-0 z-10 bg-white border border-gray-100"
    >
      <Breadcrumbs />
      <div id="header-right-content" className="flex gap-x-4"></div>
    </Navbar>
  );
};
