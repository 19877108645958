import { Profession } from '../../graphql/generated';

import SelectField from './SelectField';

export const ProfessionSelectField = ({
  name,
  label,
  className,
}: {
  name: string;
  label: string;
  className?: string;
}) => {
  return (
    <SelectField name={name} label={label} className={className}>
      <option value={Profession.AdvancedDegreeHolders}>
        Advanced DegreeHolders{' '}
      </option>
      <option value={Profession.AirlinePilots}>Airline Pilots </option>
      <option value={Profession.Artists}>Artists </option>
      <option value={Profession.Attorneys}>Attorneys </option>
      <option value={Profession.AutomotiveEngineers}>
        Automotive Engineers{' '}
      </option>
      <option value={Profession.BioTechProfessional}>
        Bio-tech Professional{' '}
      </option>
      <option value={Profession.BusinessExecutives}>
        Business Executives{' '}
      </option>
      <option value={Profession.BusinessOwners}>Business Owners </option>
      <option value={Profession.CommissionIrregularIncomeEmployees}>
        Commission/Irregular Income Employees{' '}
      </option>
      <option value={Profession.Consultants}>Consultants </option>
      <option value={Profession.Dentists}>Dentists </option>
      <option value={Profession.EducatorsandTeachers}>
        Educators and Teachers{' '}
      </option>
      <option value={Profession.Engineers}>Engineers </option>
      <option value={Profession.EntertainmentIndustryProfessionals}>
        Entertainment Industry Professionals{' '}
      </option>
      <option value={Profession.Entrepreneurs}>Entrepreneurs </option>
      <option value={Profession.FederalGovernmentEmployees}>
        Federal Government Employees{' '}
      </option>
      <option value={Profession.FinancialProfessionals}>
        Financial Professionals{' '}
      </option>
      <option value={Profession.Firefighters}>Firefighters </option>
      <option value={Profession.Freelancers}>Freelancers </option>
      <option value={Profession.HrProfessionals}>HR Professionals </option>
      <option value={Profession.LawEnforcement}>Law Enforcement </option>
      <option value={Profession.Pharmacists}>Pharmacists </option>
      <option value={Profession.Physicians}>Physicians </option>
      <option value={Profession.Military}>Military </option>
      <option value={Profession.Musicians}>Musicians </option>
      <option value={Profession.NonProfit}>NonProfit </option>
      <option value={Profession.Nurses}>Nurses </option>
      <option value={Profession.Pastors}>Pastors </option>
      <option value={Profession.Photographers}>Photographers </option>
      <option value={Profession.Politicians}>Politicians </option>
      <option value={Profession.ProfessionalAthletes}>
        Professional Athletes{' '}
      </option>
      <option value={Profession.Psychologists}>Psychologists </option>
      <option value={Profession.RealEstateProfessionals}>
        RealEstate Professionals{' '}
      </option>
      <option value={Profession.SalesProfessionals}>
        Sales Professionals{' '}
      </option>
      <option value={Profession.StartupFounders}>Startup Founders </option>
      <option value={Profession.TechnologyExecutives}>
        Technology Executives{' '}
      </option>
      <option value={Profession.Veterinarians}>Veterinarians </option>
    </SelectField>
  );
};
