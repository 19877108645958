import { Link } from 'react-router-dom';

import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';
import { FinologyTable } from '../../../components/table/FinologyTable';

import { DATA_FIXED_PLANS } from './table-data';

export const FixedRepaymentPlans = () => {
  const columns = [
    {
      title: 'Fixed Plans',
      dataIndex: 'plan',
      key: 'plan',
      render: (text: string) => text,
    },
    {
      title: 'Eligibility',
      dataIndex: 'eligibility',
      key: 'eligibility',
      render: (data: { title: string; bulletpoints: string[] }) => (
        <div>
          <p>{data.title}</p>
          <ul className="mt-4">
            {data.bulletpoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </div>
      ),
    },
    {
      title: 'Monthly Payment Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      render: (text: string) => text,
    },
  ];

  return (
    <div className="text-gray-500 p-6">
      <Heading title="Fixed Payment Repayment Plans" />
      <Paragraph text="The fixed payment repayment plans include the Standard Repayment Plan, the graduated Repayment Plan, and the Extended Repayment Plan. These plans base your monthly payment amount on how much you owe, your interest rate, and a fixed repayment time period. If you want to be placed on one of these plans, contact your loan servicer." />
      <Paragraph text="When you leave school, you will be automatically enrolled in the Standard Repayment Plan unless you pick a different repayment plan." />
      <Heading
        title="2024 Poverty Guidelines for the 48 Contiguous States and the District of Columbia"
        className="my-4"
      />
      <FinologyTable
        columns={columns}
        rows={DATA_FIXED_PLANS}
        hideFooter={true}
        type="no-promise"
      />
      <div className="mt-4"></div>
      This information was obtained from{' '}
      <Link
        to="https://studentaid.gov/manage-loans/repayment/plans&sa=D&source=editors&ust=1718707896262178&usg=AOvVaw2ngCCbmF5r6Uwv3DS37Ng4"
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        https://studentaid.gov
      </Link>
    </div>
  );
};
