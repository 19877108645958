import { Tooltip, TooltipProps } from 'flowbite-react';

interface Props {
  tooltip: {
    display?: boolean;
  } & TooltipProps;
  children: React.ReactNode;
}

export const WithTooltip = ({ tooltip, children }: Props) => {
  return tooltip.display != false ? <Tooltip {...tooltip}>{children}</Tooltip> : <>{children}</>;
};
