import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';
import { FinologyTable } from '../../../components/table/FinologyTable';

import { DATA_ALASKA, DATA_CONTIGUOUS_STATES, DATA_HAWAII } from './table-data';

export const PovertyGuidelines = () => {
  const columns = [
    {
      title: 'Persons in Family/Household',
      dataIndex: 'familySize',
      key: 'familySize',
      render: (text: string) => text,
    },
    {
      title: 'Poverty Guideline',
      dataIndex: 'povertyGuideline',
      key: 'povertyGuideline',
      render: (text: string) => text,
    },
  ];
  return (
    <div className="text-gray-500 p-6">
      <Heading title="Poverty Rate Guidelines" />
      <Paragraph text="Every year in January the Poverty Guidelines are updated by the U.S. Department of Health and Human Services.  If you use an Income Driven Repayment plan, then the updated guidelines are an important factor when determining your payment. " />
      <Paragraph text="When calculating an IDR payment the borrower's descretionary income is used to determine the payment amount." />
      <p className="my-4">
        The formula used will change based on what IDR plan you choose.
        <br />
        Start with the general income-based repayment formula
        <br />
        <br />
        <span className="font-semibold text-gray-800"> 1. Determine your discretionary income</span>
        <br />
        Start with AGI (Line 11 on the 1040 Tax Form) and subtract 150% of the federal poverty
        guideline level for your family size. With SAVE this will change to 225%.
        <br />
        AGI - Poverty Level = your discretionaty income on ICR.
        <br />
        AGI - (150% x Poverty Level) = your discretionaty income on PAYE or IBR.
        <br />
        AGI - (225% x Poverty Level) = your discretionaty income on SAVE.
        <br />
      </p>
      <Heading
        title="2024 Poverty Guidelines for the 48 Contiguous States and the District of Columbia"
        className="my-4"
      />
      <FinologyTable
        columns={columns}
        rows={DATA_CONTIGUOUS_STATES}
        hideFooter={true}
        type="no-promise"
      />
      <p className="mt-1">
        For families/households with more than 8 persons add $6,730 for each additional person.
      </p>
      <Heading title="2024 Poverty Guidelines for Alaska" className="my-4" />
      <FinologyTable columns={columns} rows={DATA_ALASKA} hideFooter={true} type="no-promise" />
      <p>For families/households with more than 8 persons add $6,730 for each additional person.</p>
      <Heading title="2024 Poverty Guidelines for Hawaii" className="my-4" />
      <FinologyTable columns={columns} rows={DATA_HAWAII} hideFooter={true} type="no-promise" />
      <p className="mt-1">
        For families/households with more than 8 persons add $6,190 for each additional person.
      </p>
      <Paragraph
        className="mt-4"
        text="The poverty guidelines (unlike the poverty thresholds) are designated by the year in which they are issued. For instance, the guidelines above issued in January 2024 are designated the 2024 poverty guidelines. However, the 2024 HHS poverty guidelines only reflect price changes through calendar year 2023; accordingly, they areapproximately equal to the Census Bureau poverty thresholds for calender year 2023."
      />
      <Paragraph text="There are two slightly different versions of the federal poverty measure: poverty thresholds and poverty guidelines." />
      <Paragraph text="The poverty thresholds are the original version of the federal poverty measure. They are updated each year by the Census Bureau. The thresholds are used mainly for statistical purposes -- for instance, preparing estimates of the number of Americans in poverty each year." />
      <Paragraph text="The poverty guidelines are the other version of the federal poverty measure. They are issued each year in the Federal Register by the Department of Health and Human Services (HHS). The guidelines are a simplification of the poverty thresholds for use for administrative purposes -- for instance, determining financial eligibility for certain federal programs." />
    </div>
  );
};
