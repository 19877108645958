export const STATE_TAXES = [
  {
    noIncomeTax: 'Alaska',
    flatIncomeTax: 'Arizona',
    graduatedRateIncomeTax: 'Alabama',
  },
  {
    noIncomeTax: 'Florida',
    flatIncomeTax: 'Colorado',
    graduatedRateIncomeTax: 'Arkansas',
  },
  {
    noIncomeTax: 'Nevada',
    flatIncomeTax: 'Georgia',
    graduatedRateIncomeTax: 'California',
  },
  {
    noIncomeTax: 'South Dakota',
    flatIncomeTax: 'Idaho',
    graduatedRateIncomeTax: 'Connecticut',
  },
  {
    noIncomeTax: 'Tennessee',
    flatIncomeTax: 'Illinois',
    graduatedRateIncomeTax: 'Delaware',
  },
  {
    noIncomeTax: 'Texas',
    flatIncomeTax: 'Indiana',
    graduatedRateIncomeTax: 'Hawaii',
  },
  {
    noIncomeTax: 'Wyoming',
    flatIncomeTax: 'Kentucky',
    graduatedRateIncomeTax: 'Iowa',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: 'Michigan',
    graduatedRateIncomeTax: 'Kansas',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: 'Mississippi',
    graduatedRateIncomeTax: 'Louisiana',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: 'New Hampshire*',
    graduatedRateIncomeTax: 'Maine',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: 'North Carolina',
    graduatedRateIncomeTax: 'Maryland',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: 'Pennsylvania',
    graduatedRateIncomeTax: 'Massachusetts',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: 'Utah',
    graduatedRateIncomeTax: 'Minnesota',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: 'Washington**',
    graduatedRateIncomeTax: 'Missouri',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'Montana',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'Nebraska',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'New Jersey',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'New Mexico',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'New York',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'North Dakota',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'Ohio',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'Oklahoma',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'Oregon',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'Rhode Island',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'South Carolina',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'Vermont',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'Virginia',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'West Virginia',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'Wisconsin',
  },
  {
    noIncomeTax: null,
    flatIncomeTax: null,
    graduatedRateIncomeTax: 'Washington, DC',
  },
];

export const STATE_INCOME_TAXES_AND_BRACKETS = [
  {
    state: 'Alabama (a, b, c)',
    singleFilerRates: '4% > $500.00',
    marriedFilingJointlyRates: '4% > $1,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Alabama',
    singleFilerRates: '5% > $3,000.00',
    marriedFilingJointlyRates: '5% > $6,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Alaska',
    singleFilerRates: 'none',
    marriedFilingJointlyRates: 'none',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'Arizona (e, f, u)',
    singleFilerRates: '3% > $0.00',
    marriedFilingJointlyRates: '3% > $0.00',
    standardDeductionSingle: '$14,600.00',
    standardDeductionCouple: '$29,200.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: '$100 credit',
  },
  {
    state: 'Arkansas (g, h, bb, ll)',
    singleFilerRates: '2% > $0.00',
    marriedFilingJointlyRates: '2% > $0.00',
    standardDeductionSingle: '$2,340.00',
    standardDeductionCouple: '$4,680.00',
    personalExemptionSingle: '$29 credit',
    personalExemptionCouple: '$58 credit',
    personalExemptionDependent: '$29 credit',
  },
  {
    state: 'Arkansas',
    singleFilerRates: '4% > $4,400.00',
    marriedFilingJointlyRates: '4% > $4,400.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Arkansas',
    singleFilerRates: '4% > $8,800.00',
    marriedFilingJointlyRates: '4% > $8,800.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'California (a, h, j, k, l, m, n, oo)',
    singleFilerRates: '1% > $0.00',
    marriedFilingJointlyRates: '1% > $0.00',
    standardDeductionSingle: '$5,363.00',
    standardDeductionCouple: '$10,726.00',
    personalExemptionSingle: '$144 credit',
    personalExemptionCouple: '$288 credit',
    personalExemptionDependent: '$446 credit',
  },
  {
    state: 'California',
    singleFilerRates: '2% > $10,412.00',
    marriedFilingJointlyRates: '2% > $20,824.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'California',
    singleFilerRates: '4% > $24,684.00',
    marriedFilingJointlyRates: '4% > $49,368.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'California',
    singleFilerRates: '6% > $38,959.00',
    marriedFilingJointlyRates: '6% > $77,918.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'California',
    singleFilerRates: '8% > $54,081.00',
    marriedFilingJointlyRates: '8% > $108,162.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'California',
    singleFilerRates: '9% > $68,350.00',
    marriedFilingJointlyRates: '9% > $136,700.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'California',
    singleFilerRates: '10% > $349,137.00',
    marriedFilingJointlyRates: '10% > $698,274.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'California',
    singleFilerRates: '11% > $418,961.00',
    marriedFilingJointlyRates: '11% > $837,922.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'California',
    singleFilerRates: '12% > $698,271.00',
    marriedFilingJointlyRates: '12% > $1,000,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'California',
    singleFilerRates: '13% > $1,000,000.00',
    marriedFilingJointlyRates: '13% > $1,396,542.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Colorado (a, o)',
    singleFilerRates: '4% > $0.00',
    marriedFilingJointlyRates: '4% > $0.00',
    standardDeductionSingle: '$14,600.00',
    standardDeductionCouple: '$29,200.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'Connecticut (i, p, q, r)',
    singleFilerRates: '2% > $0.00',
    marriedFilingJointlyRates: '2% > $0.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$15,000.00',
    personalExemptionCouple: '$24,000.00',
    personalExemptionDependent: '$0.00',
  },
  {
    state: 'Connecticut',
    singleFilerRates: '5% > $10,000.00',
    marriedFilingJointlyRates: '5% > $20,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Connecticut',
    singleFilerRates: '6% > $50,000.00',
    marriedFilingJointlyRates: '6% > $100,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Connecticut',
    singleFilerRates: '6% > $100,000.00',
    marriedFilingJointlyRates: '6% > $200,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Connecticut',
    singleFilerRates: '7% > $200,000.00',
    marriedFilingJointlyRates: '7% > $400,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Connecticut',
    singleFilerRates: '7% > $250,000.00',
    marriedFilingJointlyRates: '7% > $500,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Connecticut',
    singleFilerRates: '7% > $500,000.00',
    marriedFilingJointlyRates: '7% > $1,000,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Delaware',
    singleFilerRates: '2% > $2,000.00',
    marriedFilingJointlyRates: '2% > $2,000.00',
    standardDeductionSingle: '$3,250.00',
    standardDeductionCouple: '$6,500.00',
    personalExemptionSingle: '$110 credit',
    personalExemptionCouple: '$220 credit',
    personalExemptionDependent: '$110 credit',
  },
  {
    state: 'Delaware',
    singleFilerRates: '4% > $5,000.00',
    marriedFilingJointlyRates: '4% > $5,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Delaware',
    singleFilerRates: '5% > $10,000.00',
    marriedFilingJointlyRates: '5% > $10,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Delaware',
    singleFilerRates: '5% > $20,000.00',
    marriedFilingJointlyRates: '5% > $20,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Delaware',
    singleFilerRates: '6% > $25,000.00',
    marriedFilingJointlyRates: '6% > $25,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Delaware',
    singleFilerRates: '7% > $60,000.00',
    marriedFilingJointlyRates: '7% > $60,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Georgia',
    singleFilerRates: '5% > $0.00',
    marriedFilingJointlyRates: '5% > $0.00',
    standardDeductionSingle: '$12,000.00',
    standardDeductionCouple: '$24,000.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: '$3,000.00',
  },
  {
    state: 'Hawaii (m, t)',
    singleFilerRates: '1% > $0.00',
    marriedFilingJointlyRates: '1% > $0.00',
    standardDeductionSingle: '$2,200.00',
    standardDeductionCouple: '$4,400.00',
    personalExemptionSingle: '$1,144.00',
    personalExemptionCouple: '$2,288.00',
    personalExemptionDependent: '$1,144.00',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '3% > $2,400.00',
    marriedFilingJointlyRates: '3% > $4,800.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '6% > $4,800.00',
    marriedFilingJointlyRates: '6% > $9,600.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '6% > $9,600.00',
    marriedFilingJointlyRates: '6% > $19,200.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '7% > $14,400.00',
    marriedFilingJointlyRates: '7% > $28,800.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '7% > $19,200.00',
    marriedFilingJointlyRates: '7% > $38,400.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '8% > $24,000.00',
    marriedFilingJointlyRates: '8% > $48,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '8% > $36,000.00',
    marriedFilingJointlyRates: '8% > $72,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '8% > $48,000.00',
    marriedFilingJointlyRates: '8% > $96,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '9% > $150,000.00',
    marriedFilingJointlyRates: '9% > $300,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '10% > $175,000.00',
    marriedFilingJointlyRates: '10% > $350,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Hawaii',
    singleFilerRates: '11% > $200,000.00',
    marriedFilingJointlyRates: '11% > $400,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Idaho (m, u)',
    singleFilerRates: '5.80% > $4,489.00',
    marriedFilingJointlyRates: '5.80% > $8,978.00',
    standardDeductionSingle: '$14,600.00',
    standardDeductionCouple: '$29,200.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'Illinois (d, m, v)',
    singleFilerRates: '5% > $0.00',
    marriedFilingJointlyRates: '5% > $0.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$2,775.00',
    personalExemptionCouple: '$5,550.00',
    personalExemptionDependent: '$2,775.00',
  },
  {
    state: 'Indiana',
    singleFilerRates: '3% > $0.00',
    marriedFilingJointlyRates: '3% > $0.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$1,000.00',
    personalExemptionCouple: '$2,000.00',
    personalExemptionDependent: '$1,000.00',
  },
  {
    state: 'Iowa (a, d, h)',
    singleFilerRates: '4% > $0.00',
    marriedFilingJointlyRates: '4% > $0.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$40 credit',
    personalExemptionCouple: '$80 credit',
    personalExemptionDependent: '$40 credit',
  },
  {
    state: 'Iowa',
    singleFilerRates: '5% > $6,210.00',
    marriedFilingJointlyRates: '5% > $12,420.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Iowa',
    singleFilerRates: '6% > $31,050.00',
    marriedFilingJointlyRates: '6% > $62,100.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Kansas (a, m)',
    singleFilerRates: '3% > $0.00',
    marriedFilingJointlyRates: '3% > $0.00',
    standardDeductionSingle: '$3,500.00',
    standardDeductionCouple: '$8,000.00',
    personalExemptionSingle: '$2,250.00',
    personalExemptionCouple: '$4,500.00',
    personalExemptionDependent: '$2,250.00',
  },
  {
    state: 'Kansas',
    singleFilerRates: '5% > $15,000.00',
    marriedFilingJointlyRates: '5% > $30,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Kansas',
    singleFilerRates: '6% > $30,000.00',
    marriedFilingJointlyRates: '6% > $60,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Kentucky (a, d)',
    singleFilerRates: '4% > $0.00',
    marriedFilingJointlyRates: '4% > $0.00',
    standardDeductionSingle: '$3,160.00',
    standardDeductionCouple: '$6,320.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'Louisiana (x)',
    singleFilerRates: '2% > $0.00',
    marriedFilingJointlyRates: '2% > $0.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$4,500.00',
    personalExemptionCouple: '$9,000.00',
    personalExemptionDependent: '$1,000.00',
  },
  {
    state: 'Louisiana',
    singleFilerRates: '4% > $12,500.00',
    marriedFilingJointlyRates: '4% > $25,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Louisiana',
    singleFilerRates: '4% > $50,000.00',
    marriedFilingJointlyRates: '4% > $100,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Maine (u, y, bb)',
    singleFilerRates: '6% > $0.00',
    marriedFilingJointlyRates: '6% > $0.00',
    standardDeductionSingle: '$14,600.00',
    standardDeductionCouple: '$29,200.00',
    personalExemptionSingle: '$5,000.00',
    personalExemptionCouple: '$10,000.00',
    personalExemptionDependent: '$300 credit',
  },
  {
    state: 'Maine',
    singleFilerRates: '7% > $26,050.00',
    marriedFilingJointlyRates: '7% > $52,100.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Maine',
    singleFilerRates: '7% > $61,600.00',
    marriedFilingJointlyRates: '7% > $123,250.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Maryland (a, m, n, z, aa)',
    singleFilerRates: '2% > $0.00',
    marriedFilingJointlyRates: '2% > $0.00',
    standardDeductionSingle: '$2,550.00',
    standardDeductionCouple: '$5,150.00',
    personalExemptionSingle: '$3,200.00',
    personalExemptionCouple: '$6,400.00',
    personalExemptionDependent: '$3,200.00',
  },
  {
    state: 'Maryland',
    singleFilerRates: '3% > $1,000.00',
    marriedFilingJointlyRates: '3% > $1,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '$3,200.00',
    personalExemptionCouple: '$6,400.00',
    personalExemptionDependent: '$3,200.00',
  },
  {
    state: 'Maryland',
    singleFilerRates: '4% > $2,000.00',
    marriedFilingJointlyRates: '4% > $2,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Maryland',
    singleFilerRates: '5% > $3,000.00',
    marriedFilingJointlyRates: '5% > $3,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Maryland',
    singleFilerRates: '5% > $100,000.00',
    marriedFilingJointlyRates: '5% > $150,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Maryland',
    singleFilerRates: '5% > $125,000.00',
    marriedFilingJointlyRates: '5% > $175,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Maryland',
    singleFilerRates: '6% > $150,000.00',
    marriedFilingJointlyRates: '6% > $225,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Maryland',
    singleFilerRates: '6% > $250,000.00',
    marriedFilingJointlyRates: '6% > $300,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Massachusetts',
    singleFilerRates: '5% > $0.00',
    marriedFilingJointlyRates: '5% > $0.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$4,400.00',
    personalExemptionCouple: '$8,800.00',
    personalExemptionDependent: '$1,000.00',
  },
  {
    state: 'Massachusetts',
    singleFilerRates: '9% > $1,000,000.00',
    marriedFilingJointlyRates: '9% > $1,000,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Michigan (a, d, n)',
    singleFilerRates: '4% > $0.00',
    marriedFilingJointlyRates: '4% > $0.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$5,600.00',
    personalExemptionCouple: '$11,200.00',
    personalExemptionDependent: '$5,600.00',
  },
  {
    state: 'Minnesota (d, bb, cc, pp)',
    singleFilerRates: '5% > $0.00',
    marriedFilingJointlyRates: '5% > $0.00',
    standardDeductionSingle: '$14,575.00',
    standardDeductionCouple: '$29,150.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: '$5,050.00',
  },
  {
    state: 'Minnesota',
    singleFilerRates: '7% > $31,690.00',
    marriedFilingJointlyRates: '7% > $46,330.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Minnesota',
    singleFilerRates: '8% > $104,090.00',
    marriedFilingJointlyRates: '8% > $184,040.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Minnesota',
    singleFilerRates: '10% > $193,240.00',
    marriedFilingJointlyRates: '10% > $321,450.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Mississippi',
    singleFilerRates: '5% > $10,000.00',
    marriedFilingJointlyRates: '5% > $10,000.00',
    standardDeductionSingle: '$2,300.00',
    standardDeductionCouple: '$4,600.00',
    personalExemptionSingle: '$6,000.00',
    personalExemptionCouple: '$12,000.00',
    personalExemptionDependent: '$1,500.00',
  },
  {
    state: 'Missouri (a, b, j, m, u)',
    singleFilerRates: '2% > $1,273.00',
    marriedFilingJointlyRates: '2% > $1,207.00',
    standardDeductionSingle: '$14,600.00',
    standardDeductionCouple: '$29,200.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'Missouri',
    singleFilerRates: '3% > $2,546.00',
    marriedFilingJointlyRates: '3% > $2,414.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Missouri',
    singleFilerRates: '3% > $3,819.00',
    marriedFilingJointlyRates: '3% > $3,621.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Missouri',
    singleFilerRates: '4% > $5,092.00',
    marriedFilingJointlyRates: '4% > $4,828.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Missouri',
    singleFilerRates: '4% > $6,365.00',
    marriedFilingJointlyRates: '4% > $6,035.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Missouri',
    singleFilerRates: '5% > $7,638.00',
    marriedFilingJointlyRates: '5% > $7,242.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Missouri',
    singleFilerRates: '5% > $8,911.00',
    marriedFilingJointlyRates: '5% > $8,449.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Montana (b, d, u, bb)',
    singleFilerRates: '5% > $0.00',
    marriedFilingJointlyRates: '5% > $0.00',
    standardDeductionSingle: '$14,600.00',
    standardDeductionCouple: '$29,200.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'Montana',
    singleFilerRates: '6% > $20,500.00',
    marriedFilingJointlyRates: '6% > $41,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '$7,900.00',
    personalExemptionCouple: '$15,800.00',
    personalExemptionDependent: '$157 credit',
  },
  {
    state: 'Nebraska (d, h, m, bb)',
    singleFilerRates: '2% > $0.00',
    marriedFilingJointlyRates: '2% > $0.00',
    standardDeductionSingle: '$7,900.00',
    standardDeductionCouple: '$15,800.00',
    personalExemptionSingle: '$157 credit',
    personalExemptionCouple: '$314 credit',
    personalExemptionDependent: '$157 credit',
  },
  {
    state: 'Nebraska',
    singleFilerRates: '4% > $3,700.00',
    marriedFilingJointlyRates: '4% > $7,390.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Nebraska',
    singleFilerRates: '5% > $22,170.00',
    marriedFilingJointlyRates: '5% > $44,350.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Nebraska',
    singleFilerRates: '6% > $35,730.00',
    marriedFilingJointlyRates: '6% > $71,460.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Nevada',
    singleFilerRates: 'none',
    marriedFilingJointlyRates: 'none',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'New Hampshire (dd)',
    singleFilerRates: '3% on interest and dividends only',
    marriedFilingJointlyRates: '3% on interest and dividends only',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$2,400.00',
    personalExemptionCouple: '$4,800.00',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'New Jersey (a)',
    singleFilerRates: '1% > $0.00',
    marriedFilingJointlyRates: '1% > $0.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$1,000.00',
    personalExemptionCouple: '$2,000.00',
    personalExemptionDependent: '$1,500.00',
  },
  {
    state: 'New Jersey',
    singleFilerRates: '2% > $20,000.00',
    marriedFilingJointlyRates: '2% > $20,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New Jersey',
    singleFilerRates: '4% > $35,000.00',
    marriedFilingJointlyRates: '2% > $50,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New Jersey',
    singleFilerRates: '6% > $40,000.00',
    marriedFilingJointlyRates: '4% > $70,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New Jersey',
    singleFilerRates: '6% > $75,000.00',
    marriedFilingJointlyRates: '6% > $80,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New Jersey',
    singleFilerRates: '9% > $500,000.00',
    marriedFilingJointlyRates: '6% > $150,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New Jersey',
    singleFilerRates: '11% > $1,000,000.00',
    marriedFilingJointlyRates: '9% > $500,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New Mexico (m, u, kk)',
    singleFilerRates: '2% > $0.00',
    marriedFilingJointlyRates: '2% > $0.00',
    standardDeductionSingle: '$14,600.00',
    standardDeductionCouple: '$29,200.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: '$4,000.00',
  },
  {
    state: 'New Mexico',
    singleFilerRates: '3% > $5,500.00',
    marriedFilingJointlyRates: '3% > $8,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New Mexico',
    singleFilerRates: '5% > $11,000.00',
    marriedFilingJointlyRates: '5% > $16,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New Mexico',
    singleFilerRates: '5% > $16,000.00',
    marriedFilingJointlyRates: '5% > $24,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New Mexico',
    singleFilerRates: '6% > $210,000.00',
    marriedFilingJointlyRates: '6% > $315,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New York (a, i)',
    singleFilerRates: '4% > $0.00',
    marriedFilingJointlyRates: '4% > $0.00',
    standardDeductionSingle: '$8,000.00',
    standardDeductionCouple: '$16,050.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: '$1,000.00',
  },
  {
    state: 'New York',
    singleFilerRates: '5% > $8,500.00',
    marriedFilingJointlyRates: '5% > $17,150.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New York',
    singleFilerRates: '5% > $11,700.00',
    marriedFilingJointlyRates: '5% > $23,600.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New York',
    singleFilerRates: '6% > $13,900.00',
    marriedFilingJointlyRates: '6% > $27,900.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New York',
    singleFilerRates: '6% > $80,650.00',
    marriedFilingJointlyRates: '6% > $161,550.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New York',
    singleFilerRates: '7% > $215,400.00',
    marriedFilingJointlyRates: '7% > $323,200.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New York',
    singleFilerRates: '10% > $1,077,550.00',
    marriedFilingJointlyRates: '10% > $2,155,350.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New York',
    singleFilerRates: '10% > $5,000,000.00',
    marriedFilingJointlyRates: '10% > $5,000,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'New York',
    singleFilerRates: '11% > $25,000,000.00',
    marriedFilingJointlyRates: '11% > $25,000,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'North Carolina',
    singleFilerRates: '5% > $0.00',
    marriedFilingJointlyRates: '5% > $0.00',
    standardDeductionSingle: '$12,750.00',
    standardDeductionCouple: '$25,500.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'North Dakota (j, o, u)',
    singleFilerRates: '2% > $44,725.00',
    marriedFilingJointlyRates: '2% > $74,750.00',
    standardDeductionSingle: '$14,600.00',
    standardDeductionCouple: '$29,200.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'North Dakota',
    singleFilerRates: '3% > $225,975.00',
    marriedFilingJointlyRates: '3% > $275,100.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Ohio (a, j, n, ee)',
    singleFilerRates: '3% > $26,050.00',
    marriedFilingJointlyRates: '3% > $26,050.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$2,400.00',
    personalExemptionCouple: '$4,800.00',
    personalExemptionDependent: '$2,500.00',
  },
  {
    state: 'Ohio',
    singleFilerRates: '4% > $92,150.00',
    marriedFilingJointlyRates: '4% > $92,150.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Oklahoma (m)',
    singleFilerRates: '0% > $0.00',
    marriedFilingJointlyRates: '0% > $0.00',
    standardDeductionSingle: '$6,350.00',
    standardDeductionCouple: '$12,700.00',
    personalExemptionSingle: '$1,000.00',
    personalExemptionCouple: '$2,000.00',
    personalExemptionDependent: '$1,000.00',
  },
  {
    state: 'Oklahoma',
    singleFilerRates: '1% > $1,000.00',
    marriedFilingJointlyRates: '1% > $2,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Oklahoma',
    singleFilerRates: '2% > $2,500.00',
    marriedFilingJointlyRates: '2% > $5,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Oklahoma',
    singleFilerRates: '3% > $3,750.00',
    marriedFilingJointlyRates: '3% > $7,500.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Oklahoma',
    singleFilerRates: '4% > $4,900.00',
    marriedFilingJointlyRates: '4% > $9,800.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Oklahoma',
    singleFilerRates: '5% > $7,200.00',
    marriedFilingJointlyRates: '5% > $12,200.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Oregon (a, b, d, h, m, bb, ff, oo)',
    singleFilerRates: '5% > $0.00',
    marriedFilingJointlyRates: '5% > $0.00',
    standardDeductionSingle: '$2,745.00',
    standardDeductionCouple: '$5,495.00',
    personalExemptionSingle: '$249 credit',
    personalExemptionCouple: '$498 credit',
    personalExemptionDependent: '$249 credit',
  },
  {
    state: 'Oregon',
    singleFilerRates: '7% > $4,300.00',
    marriedFilingJointlyRates: '7% > $8,600.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Oregon',
    singleFilerRates: '9% > $10,750.00',
    marriedFilingJointlyRates: '9% > $21,500.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Oregon',
    singleFilerRates: '10% > $125,000.00',
    marriedFilingJointlyRates: '10% > $250,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Pennsylvania (a)',
    singleFilerRates: '3% > $0.00',
    marriedFilingJointlyRates: '3% > $0.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'Rhode Island (d, bb, gg)',
    singleFilerRates: '4% > $0.00',
    marriedFilingJointlyRates: '4% > $0.00',
    standardDeductionSingle: '$10,550.00',
    standardDeductionCouple: '$21,150.00',
    personalExemptionSingle: '$4,950.00',
    personalExemptionCouple: '$9,900.00',
    personalExemptionDependent: '$4,950.00',
  },
  {
    state: 'Rhode Island',
    singleFilerRates: '5% > $77,450.00',
    marriedFilingJointlyRates: '5% > $77,450.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Rhode Island',
    singleFilerRates: '6% > $176,050.00',
    marriedFilingJointlyRates: '6% > $176,050.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'South Carolina (d, o, u, bb)',
    singleFilerRates: '0% > $0.00',
    marriedFilingJointlyRates: '0% > $0.00',
    standardDeductionSingle: '$14,600.00',
    standardDeductionCouple: '$29,200.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: '$4,610 (o)',
  },
  {
    state: 'South Carolina',
    singleFilerRates: '3% > $3,460.00',
    marriedFilingJointlyRates: '3% > $3,460.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: '$4,610 (o)',
  },
  {
    state: 'South Carolina',
    singleFilerRates: '6% > $17,330.00',
    marriedFilingJointlyRates: '6% > $17,330.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Utah (d, h, hh)',
    singleFilerRates: '5% > $0.00',
    marriedFilingJointlyRates: '5% > $0.00',
    standardDeductionSingle: '$876 credit',
    standardDeductionCouple: '$1,752 credit',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: '$1,941.00',
  },
  {
    state: 'Vermont (j, n, ii, nn)',
    singleFilerRates: '3% > $0.00',
    marriedFilingJointlyRates: '3% > $0.00',
    standardDeductionSingle: '$7,000.00',
    standardDeductionCouple: '$14,050.00',
    personalExemptionSingle: '$4,850.00',
    personalExemptionCouple: '$9,700.00',
    personalExemptionDependent: '$4,850.00',
  },
  {
    state: 'Vermont',
    singleFilerRates: '7% > $45,400.00',
    marriedFilingJointlyRates: '7% > $75,850.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Vermont',
    singleFilerRates: '8% > $110,050.00',
    marriedFilingJointlyRates: '8% > $183,400.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Vermont',
    singleFilerRates: '9% > $229,550.00',
    marriedFilingJointlyRates: '9% > $279,450.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Virginia (n, mm)',
    singleFilerRates: '2% > $0.00',
    marriedFilingJointlyRates: '2% > $0.00',
    standardDeductionSingle: '$8,000.00',
    standardDeductionCouple: '$16,000.00',
    personalExemptionSingle: '$930.00',
    personalExemptionCouple: '$1,860.00',
    personalExemptionDependent: '$930.00',
  },
  {
    state: 'Virginia',
    singleFilerRates: '3% > $3,000.00',
    marriedFilingJointlyRates: '3% > $3,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '$8,000.00',
    personalExemptionCouple: '$16,000.00',
    personalExemptionDependent: '$930.00',
  },
  {
    state: 'Virginia',
    singleFilerRates: '5% > $5,000.00',
    marriedFilingJointlyRates: '5% > $5,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Virginia',
    singleFilerRates: '6% > $17,000.00',
    marriedFilingJointlyRates: '6% > $17,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Washington',
    singleFilerRates: '7% on capital gains income only',
    marriedFilingJointlyRates: '7% on capital gains income only',
    standardDeductionSingle: '$250,000.00',
    standardDeductionCouple: '$250,000.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'West Virginia',
    singleFilerRates: '2% > $0.00',
    marriedFilingJointlyRates: '2% > $0.00',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: '$2,000.00',
    personalExemptionCouple: '$4,000.00',
    personalExemptionDependent: '$2,000.00',
  },
  {
    state: 'West Virginia',
    singleFilerRates: '3% > $10,000.00',
    marriedFilingJointlyRates: '3% > $10,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'West Virginia',
    singleFilerRates: '4% > $25,000.00',
    marriedFilingJointlyRates: '4% > $25,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'West Virginia',
    singleFilerRates: '5% > $40,000.00',
    marriedFilingJointlyRates: '5% > $40,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'West Virginia',
    singleFilerRates: '5% > $60,000.00',
    marriedFilingJointlyRates: '5% > $60,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Wisconsin (d, m, bb, jj)',
    singleFilerRates: '4% > $0.00',
    marriedFilingJointlyRates: '4% > $0.00',
    standardDeductionSingle: '$13,230.00',
    standardDeductionCouple: '$24,490.00',
    personalExemptionSingle: '$700.00',
    personalExemptionCouple: '$1,400.00',
    personalExemptionDependent: '$700.00',
  },
  {
    state: 'Wisconsin',
    singleFilerRates: '4% > $14,320.00',
    marriedFilingJointlyRates: '4% > $19,090.00',
    standardDeductionSingle: '$13,230.00',
    standardDeductionCouple: '$24,490.00',
    personalExemptionSingle: '$700.00',
    personalExemptionCouple: '$1,400.00',
    personalExemptionDependent: '$700.00',
  },
  {
    state: 'Wisconsin',
    singleFilerRates: '5% > $28,640.00',
    marriedFilingJointlyRates: '5% > $38,190.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Wisconsin',
    singleFilerRates: '8% > $315,310.00',
    marriedFilingJointlyRates: '8% > $420,420.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Wyoming',
    singleFilerRates: 'none',
    marriedFilingJointlyRates: 'none',
    standardDeductionSingle: 'n.a.',
    standardDeductionCouple: 'n.a.',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'Washington, DC (u)',
    singleFilerRates: '4% > $0.00',
    marriedFilingJointlyRates: '4% > $0.00',
    standardDeductionSingle: '$14,600.00',
    standardDeductionCouple: '$29,200.00',
    personalExemptionSingle: 'n.a.',
    personalExemptionCouple: 'n.a.',
    personalExemptionDependent: 'n.a.',
  },
  {
    state: 'Washington, DC',
    singleFilerRates: '6% > $10,000.00',
    marriedFilingJointlyRates: '6% > $10,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Washington, DC',
    singleFilerRates: '7% > $40,000.00',
    marriedFilingJointlyRates: '7% > $40,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Washington, DC',
    singleFilerRates: '9% > $60,000.00',
    marriedFilingJointlyRates: '9% > $60,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Washington, DC',
    singleFilerRates: '9% > $250,000.00',
    marriedFilingJointlyRates: '9% > $250,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Washington, DC',
    singleFilerRates: '10% > $500,000.00',
    marriedFilingJointlyRates: '10% > $500,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
  {
    state: 'Washington, DC',
    singleFilerRates: '11% > $1,000,000.00',
    marriedFilingJointlyRates: '11% > $1,000,000.00',
    standardDeductionSingle: '',
    standardDeductionCouple: '',
    personalExemptionSingle: '',
    personalExemptionCouple: '',
    personalExemptionDependent: '',
  },
];

export const DATA_CONTIGUOUS_STATES = [
  {
    familySize: '1',
    povertyGuideline: '$15,060',
  },
  {
    familySize: '2',
    povertyGuideline: '$20,440',
  },
  {
    familySize: '3',
    povertyGuideline: '$25,820',
  },
  {
    familySize: '4',
    povertyGuideline: '$31,200',
  },
  {
    familySize: '5',
    povertyGuideline: '$36,580',
  },
  {
    familySize: '6',
    povertyGuideline: '$41,960',
  },
  {
    familySize: '7',
    povertyGuideline: '$47,340',
  },
  {
    familySize: '8',
    povertyGuideline: '$52,720',
  },
];

export const DATA_ALASKA = [
  {
    familySize: '1',
    povertyGuideline: '$18,810',
  },
  {
    familySize: '2',
    povertyGuideline: '$25,540',
  },
  {
    familySize: '3',
    povertyGuideline: '$32,270',
  },
  {
    familySize: '4',
    povertyGuideline: '$39,000',
  },
  {
    familySize: '5',
    povertyGuideline: '$45,730',
  },
  {
    familySize: '6',
    povertyGuideline: '$52,460',
  },
  {
    familySize: '7',
    povertyGuideline: '$59,190',
  },
  {
    familySize: '8',
    povertyGuideline: '$65,920',
  },
];

export const DATA_HAWAII = [
  {
    familySize: '1',
    povertyGuideline: '$17,310',
  },
  {
    familySize: '2',
    povertyGuideline: '$23,500',
  },
  {
    familySize: '3',
    povertyGuideline: '$29,690',
  },
  {
    familySize: '4',
    povertyGuideline: '$35,880',
  },
  {
    familySize: '5',
    povertyGuideline: '$42,070',
  },
  {
    familySize: '6',
    povertyGuideline: '$48,260',
  },
  {
    familySize: '7',
    povertyGuideline: '$54,450',
  },
  {
    familySize: '8',
    povertyGuideline: '$60,640',
  },
];

export const DATA_FIXED_PLANS = [
  {
    plan: 'Standard',
    eligibility: {
      title: 'These loan types are eligible:',
      bulletpoints: [
        '-- Direct Subsidized and Unsubsidized loans',
        '-- Subsidized and Unsubsidized Federal Stafford Loans',
        '-- All PLUS loans (Direct or FFEL)',
        '-- All Consolidated Loans (Direct or FFEL)',
      ],
    },
    paymentAmount: `Payments are a fixed amount that ensures your loans are paid off within 10 years (within 10 to 30 years for Consolidation Loans.)`,
  },
  {
    plan: 'Graduated',
    eligibility: {
      title: 'These loan types are eligible:',
      bulletpoints: [
        '-- Direct Subsidized and Unsubsidized loans',
        '-- Subsidized and Unsubsidized Federal Stafford Loans',
        '-- All PLUS loans (Direct or FFEL)',
        '-- All Consolidated Loans (Direct or FFEL)',
      ],
    },
    paymentAmount: `Payments are lower at first and then increase, usually every two years. Payment amounts are designed to ensure your loans are paid off within 10 years (within 10 to 30 years for Consolidation Loans).`,
  },
  {
    plan: 'Extended',

    eligibility: {
      title: `To qualify for this plan, you must have more than $30,000 in outstanding Direct Loans (if you're a Direct Loan borrower) or more than $30,000 in outstanding FFEL Program loans (if you're a FFEL borrower). These loan types are eligible:`,
      bulletpoints: [
        '-- Direct Subsidized and Unsubsidized loans',
        '-- Subsidized and Unsubsidized Federal Stafford Loans',
        '-- All PLUS loans (Direct or FFEL)',
        '-- All Consolidated Loans (Direct or FFEL)',
      ],
    },
    paymentAmount: `Payments can be fixed or graduated and will ensure that your loans are paid off within 25 years.`,
  },
];

export const DATA_IDR_PLANS = [
  {
    plan: 'SAVE Plan',
    eligibility: {
      title: 'These loan types are eligible:',
      bulletpoints: [
        '-- Direct Subsidized and Unsubsidized loans',
        '-- Direct PLUS Loans made to students',
        '-- Direct Consolidation Loans that do not include PLUS loans (Direct or FFEL) made to parents',
      ],
    },
    monthlyPaymentAmount: '10% of discretionary income',
  },
  {
    plan: 'PAYE Plan',
    eligibility: {
      title:
        'To be eligible, you must be a new borrower on or after Oct. 1 2007, and must have received a disbursement of a Direct Loan on or after Oct. 1 2011. These loan types are eligible:',
      bulletpoints: [
        '-- Direct Subsidized and Unsubsidized loans',
        '-- Direct PLUS Loans made to students',
        '-- Direct Consolidation Loans that do not include PLUS loans (Direct or FFEL) made to parents',
      ],
    },
    monthlyPaymentAmount:
      '10% of discretionary income but never more than what you would pay under the 10-year Standard Repayment Plan.',
  },
  {
    plan: 'IBR Plan',
    eligibility: {
      title: 'These loan types are eligible:',
      bulletpoints: [
        '-- Direct Subsidized and Unsubsidized loans',
        '-- Subsidized and Unsubsidized Federal Stafford Loans',
        '-- Direct and FFEL PLUS loans made to students',
        '-- Direct Consolidation Loans that do not include PLUS loans (Direct or FFEL) made to parents',
      ],
    },
    monthlyPaymentAmount:
      'Either 10% or 15% of your discretionary income (depending on when you received your first loans) but never more than what you would pay under the 10-year Standard Repayment Plan.',
  },
  {
    plan: 'ICR Plan',
    eligibility: {
      title: 'These loan types are eligible:',
      bulletpoints: [
        '-- Direct Subsidized and Unsubsidized loans',
        '-- Direct PLUS Loans made to students',
        '-- Direct Consolidation Loans (including those that repaid parent PLUS loans)',
      ],
    },
    monthlyPaymentAmount:
      'The lesser of -- 20% of your discretionary income or, -- the amount you would pay on a repayment plan with a fixed payment over 12 years, adjusted according to your income.',
  },
];

export const DATA_REPAYMENT_PLANS = [
  {
    plan: 'SAVE Plan',
    discretionaryIncome: '10.00%',
    repaymentPeriod:
      '20 for only undergraduate loans, 25 if you have any graduate or professional loans',
  },
  {
    plan: 'PAYE Plan',
    discretionaryIncome: '10.00%',
    repaymentPeriod: '20',
  },
  {
    plan: 'IBR Plan (borrowed after July 1, 2014)',
    discretionaryIncome: '10.00%',
    repaymentPeriod: '20',
  },
  {
    plan: 'IBR Plan (borrowed before July 1, 2014)',
    discretionaryIncome: '15.00%',
    repaymentPeriod: '25',
  },
  {
    plan: 'ICR Plan',
    discretionaryIncome: '20.00%',
    repaymentPeriod: '25',
  },
];
