import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';
import { FinologyTable } from '../../../components/table/FinologyTable';

import { STATE_TAXES } from './table-data';

export const StateTaxes = () => {
  const bigTableColumns = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (text: string) => text,
    },
    {
      title: 'Single Filer Rates and income',
      dataIndex: 'singleFilerRates',
      key: 'singleFilerRates',
      render: (text: string) => text,
    },

    {
      title: 'Married Filing Jointly Rates and income',
      dataIndex: 'marriedFilingJointlyRates',
      key: 'marriedFilingJointlyRates',
      render: (text: string) => text,
    },
    {
      title: 'Standard Deduction (Single)',
      dataIndex: 'standardDeductionSingle',
      key: 'standardDeductionSingle',
      render: (text: string) => text,
    },
    {
      title: 'Standard Deduction (Couple)',
      dataIndex: 'standardDeductionCouple',
      key: 'standardDeductionCouple',
      render: (text: string) => text,
    },
    {
      title: 'Personal Exemption (Single)',
      dataIndex: 'personalExemptionSingle',
      key: 'personalExemptionSingle',
      render: (text: string) => text,
    },
    {
      title: 'Personal Exemption (Couple)',
      dataIndex: 'personalExemptionCouple',
      key: 'personalExemptionCouple',
      render: (text: string) => text,
    },
    {
      title: 'Personal Exemption (Dependent)',
      dataIndex: 'personalExemptionDependent',
      key: 'personalExemptionDependent',
      render: (text: string) => text,
    },
  ];

  const columns = [
    {
      title: 'States with No Income Tax',
      dataIndex: 'noIncomeTax',
      key: 'noIncomeTax',
      render: (text: string) => text,
    },
    {
      title: 'States with a Flat Income Tax',
      dataIndex: 'flatIncomeTax',
      key: 'flatIncomeTax',
      render: (text: string) => text,
    },
    {
      title: 'States with a Graduated-Rate Income Tax',
      dataIndex: 'graduatedRateIncomeTax',
      key: 'graduatedRateIncomeTax',
      render: (text: string) => text,
    },
  ];

  return (
    <>
      <div className="p-6">
        <Heading
          title="State Individual Income Tax Rates and Brackets, as of January 1, 2024"
          className="mb-4"
        />
        <FinologyTable columns={columns} rows={STATE_TAXES} hideFooter={true} type="no-promise" />
        <br />
        <br />
        <Heading title="Notable 2024 State Individual Income Tax Changes" />
        <Paragraph text="Last year continued the historic pace of income tax rate reductions. In total, 26 states enacted individual income tax rate reductions from 2021 to 2023. Only Massachusetts and the District of Columbia increased their top marginal tax rates in those years. Several changes implemented later in 2023 were retroactive to January 1, 2023. However, a number of notable changes come into effect on January 1, 2024, or are set to occur on specific future dates, with rates phasing down incrementally over time. Some of the scheduled future rate reductions rely on tax triggers, where specific changes to tax rates will occur once certain revenue benchmarks are met. Notable changes to major individual income tax provisions already certified for 2024 include the following:	" />
        <Heading title="Arkansas" />
        <Paragraph text="Under S.B. 8, enacted in September 2023, the top individual income tax rate in Arkansas was reduced from 4.7 percent to 4.4 percent for tax years beginning on or after January 1, 2024. This top rate applies to incomes between $24,300 and $87,000 for taxpayers earning $87,000 or less and to incomes over $8,801 for taxpayers earning more than $87,000." />
        <Heading title="Connecticut" />
        <Paragraph text="As part of the state budget bill, H.B. 6941, Connecticut legislators reduced individual income tax rates for the two lowest brackets, from 3 percent to 2 percent and from 5 percent to 4.5 percent, respectively. The change comes into effect on January 1, 2024. The reduction will not affect taxpayers with an annual income of $150,000 or above ($300,000 or above for married couples filing a joint return)." />
        <Heading title="Georgia" />
        <Paragraph text="On January 1, 2024, Georgia transitions from a graduated individual income tax with a top rate of 5.75 percent to a flat tax with a rate of 5.49 percent. Additionally, the state significantly increased its personal exemption (to $12,000 for single taxpayers and $18,500 for married couples filing a joint return). These changes were enacted by H.B. 1437 in April 2022." />
        <Heading title="Indiana" />
        <Paragraph text="Under H.B. 1001, enacted in May 2023, Indiana accelerated its previously enacted tax rate reductions, lowering the individual income tax rate from 3.15 in 2023 to 3.05 percent in 2024. The law also repealed previously enacted tax triggers, instead prescribing a rate reduction to 3.0 percent in 2025, 2.95 percent in 2026, and 2.9 percent in 2027 and beyond." />
        <Heading title="Iowa" />
        <Paragraph text="As part of its comprehensive tax reform, effective January 1, 2024, Iowa consolidated its four individual income tax brackets into three (H.F. 2317). As a result, its top rate decreased from 6 percent to 5.7 percent. The state is currently transitioning to a flat income tax system with a rate of 3.9 percent by 2026." />
        <Heading title="Kentucky" />
        <Paragraph text="H.B. 1, signed into law in February 2023, reduced Kentucky’s flat individual income tax rate from 4.5 percent in 2023 to 4.0 percent starting in 2024, codifying a reduction that was triggered under the conditions established by H.B. 8, enacted in 2022." />
        <Heading title="Mississippi" />
        <Paragraph text="Under H.B. 531, enacted in April 2022, Mississippi will continue reducing its flat individual income tax rate from 2024 to 2026. Effective January 1, 2024, the tax rate decreased from 5 percent to 4.7 percent (applied on taxable income exceeding $10,000)." />
        <Heading title="Missouri" />
        <Paragraph text="Effective January 1, 2024, Missouri’s Department of Revenue reduced its top individual income tax rate from 4.95 percent to 4.8 percent as the respective revenue triggers were met in the previous fiscal year, per S.B. 3 enacted in October 2022." />
        <Heading title="Montana" />
        <Paragraph text="S.B. 121, enacted in March 2023, simplified the individual income tax system in Montana and, effective January 1, 2024, reduced the number of tax brackets from seven to two with the top tax rate of 5.9 percent. Additionally, starting in 2024, taxpayers will use their federal taxable income as a base for calculating Montana taxable income, implying that the federal standard deduction or the sum of itemized deductions will be automatically accounted for." />
        <Heading title="Nebraska" />
        <Paragraph text="L.B. 754, enacted in May 2023, reduced the top individual income tax rate from 6.64 percent in 2023 to 5.84 percent in 2024 and outlined the gradual reduction of the state’s top rate to 3.99 percent by 2027." />
        <Heading title="New Hampshire" />
        <Paragraph text="New Hampshire continues to phase out its interest and dividends tax. In 2024, per H.B. 2, the tax rate will go down from 4 percent to 3 percent. Starting in 2025, the tax will be repealed, two years earlier than initially planned." />
        <Heading title="North Carolina" />
        <Paragraph text="Under H.B. 259, enacted in September 2023, North Carolina accelerated the reduction of its flat individual income tax rate. Effective January 1, 2024, the tax rate decreased from 4.75 percent to 4.5 percent. The rate is scheduled to decline to 3.99 percent by 2026." />
        <Heading title="Ohio" />
        <Paragraph text="H.B. 33, enacted in July 2023, reduced the number of individual income tax brackets in Ohio from three to two and lowered the top rate from 3.75 percent to 3.5 percent, continuing the individual income tax rate reduction and simplification trend that state legislators started in 2021." />
        <Heading title="South Carolina" />
        <Paragraph text="Effective January 1, 2024, South Carolina reduced its top individual income tax rate from 6.5 percent to 6.4 percent, per S.B. 1087. Further reductions to 6 percent are scheduled but are subject to general fund revenue triggers. Governor McMaster’s executive budget assumes that the revenue trigger for the previous fiscal year was met, and the top rate must go down further to 6.3 percent, as per the statutory schedule. The change has not yet been confirmed by the state’s Department of Revenue." />
        {/* <div className=" w-full overflow-x-auto">
          <FinologyTable
            columns={bigTableColumns}
            rows={STATE_INCOME_TAXES_AND_BRACKETS}
            hideFooter={true}
            type="no-promise"
            rowClassName="bg-white"
          />{' '}
        </div> */}
      </div>
    </>
  );
};
