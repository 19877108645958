import { Button, Modal, Tooltip } from 'flowbite-react';
import { useMemo, useState } from 'react';
import { MdEdit, MdDelete } from 'react-icons/md';

import { HighlightedCard } from '../../../../components/cards/Cards';
import { FlyoutLink } from '../../../../components/links/FlyoutLink';
import { FinologyTable } from '../../../../components/table/FinologyTable';
import {
  CreditCardLoanView,
  CreditCardLoanViewSortInput,
  InputMaybe,
  PrivateLoanView,
  PrivateLoanViewSortInput,
  SortEnumType,
  useCreditCardAggregationsQuery,
  useDeletePrivateLoanMutation,
  useGetCreditCardLoansLazyQuery,
  useGetPrivateLoansLazyQuery,
} from '../../../../graphql/generated';
import { useFlyoutNavigate } from '../../../../hooks/useFlyoutNavigate';
import { useTableSortBy } from '../../../../hooks/useTableSortBy';
import { toDollars } from '../../../../util/currency.formatter';
import { notification } from '../../../../util/notification.utils';
import { toPercentage } from '../../../../util/number.formatter';

import type { ColumnsType } from '../../../../components/table/FinologyTable';

const DeletePrivateLoanModal = ({
  loanToDelete,
  closeModal,
}: {
  loanToDelete?: PrivateLoanView;
  closeModal: () => void;
}) => {
  const [deleteLoan] = useDeletePrivateLoanMutation();
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  return (
    <Modal show={loanToDelete !== undefined} onClose={closeModal}>
      <Modal.Header>Delete Private Loan</Modal.Header>
      <Modal.Body>
        <p>Proceed with deleting of {loanToDelete?.loanName}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="light" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={disableDeleteButton}
          onClick={async () => {
            setDisableDeleteButton(true);

            await deleteLoan({
              variables: {
                id: loanToDelete!.id,
              },
              refetchQueries: ['GetPrivateLoans', 'GetClientDashboard'],
            });

            setDisableDeleteButton(false);

            closeModal();

            notification.success({
              message: `${loanToDelete?.loanName} was successfully deleted`,
              placement: 'bottom-center',
            });
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const PrivateLoansGrid = ({ clientId }: { clientId: number }) => {
  const { navigate } = useFlyoutNavigate();
  const [privateLoanToDelete, setPrivateLoanToDelete] = useState<PrivateLoanView>();
  const { sortBy, prepareSortColumns, triggerRefetch } = useTableSortBy<PrivateLoanViewSortInput>({
    loanName: SortEnumType.Asc,
  });

  const {
    sortBy: sortByCreditCard,
    prepareSortColumns: prepareSortColumnsCreditCard,
    triggerRefetch: triggerRefetchCreditCard,
  } = useTableSortBy<any>({
    outstandingBalance: SortEnumType.Desc,
  });

  const [getPrivateLoans, getPrivateLoansQuery] = useGetPrivateLoansLazyQuery();
  const [getCreditCardLoans, getCreditCardLoansQuery] = useGetCreditCardLoansLazyQuery();
  const creditCardAggregations = useCreditCardAggregationsQuery({
    variables: { clientId: clientId },
  });

  const creditCardColumns: ColumnsType<any> = [
    {
      title: 'Bank Name',
      dataIndex: 'bankName',
      key: 'bankName',
      render: (text, record) => (
        <FlyoutLink flyoutId="upsert-credit-card" params={`creditCardId=${record.id}`}>
          {text}
        </FlyoutLink>
      ),
    },
    {
      dataIndex: 'outstandingBalance',
      key: 'outstandingBalance',
      render: (text) => toDollars(text) || '-',
      ...prepareSortColumnsCreditCard(
        'outstandingBalance',
        'Outstanding Balance',
        triggerRefetchCreditCard
      ),
    },
    {
      dataIndex: 'creditCardLimit',
      key: 'creditCardLimit',
      render: (text) => toDollars(text) || '-',
      ...prepareSortColumnsCreditCard('creditCardLimit', 'Credit Limit', triggerRefetchCreditCard),
    },
    {
      dataIndex: 'balanceTransferApr',
      key: 'balanceTransferApr',
      render: (text) => toPercentage(text ? text / 100 : text) || '-',
      ...prepareSortColumnsCreditCard(
        'balanceTransferApr',
        'Balance APR',
        triggerRefetchCreditCard
      ),
    },
    {
      dataIndex: 'minimumPayments',
      key: 'minimumPayments',
      render: (text) => toDollars(text) || '-',
      ...prepareSortColumnsCreditCard(
        'minimumPayments',
        'Min. Monthly Payment',
        triggerRefetchCreditCard
      ),
    },
  ];

  const privateLoansColumns: ColumnsType<PrivateLoanView> = [
    {
      title: 'Loan Name',
      dataIndex: 'loanName',
      key: 'loanName',
      render: (text, record) => (
        <FlyoutLink flyoutId="upsert-private-loan" params={`loanId=${record.id}`}>
          {text}
        </FlyoutLink>
      ),
    },
    {
      title: 'Lender',
      dataIndex: 'lender',
      key: 'lender',
      render: (text) => text || '-',
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => toDollars(text) || '-',
      ...prepareSortColumns('amount', 'Amount', triggerRefetch),
    },
    {
      dataIndex: 'interestRate',
      key: 'interestRate',
      render: (text) => toPercentage(text) || '-',
      ...prepareSortColumns('interestRate', 'Rate', triggerRefetch),
    },
    {
      dataIndex: 'monthlyPayment',
      key: 'monthlyPayment',
      ...prepareSortColumns('monthlyPayment', 'Monthly Payment', triggerRefetch),
      render: (text) => toDollars(text),
    },
    {
      dataIndex: `isClosed`,
      key: `isClosed`,
      render: (text) => (
        <div className="flex justify-center items-center w-12">
          {text ? (
            <span className="text-xs px-2 py-1 bg-red-200 text-red-800 rounded-full">Closed</span>
          ) : (
            <span className="text-xs px-2 py-1 bg-green-200 text-green-800 rounded-full">Open</span>
          )}
        </div>
      ),
      ...prepareSortColumns('isClosed', 'Status', triggerRefetch),
    },
    {
      title: '',
      key: 'action',
      clasName: 'w-20',
      render: (_, record) => (
        <div className="flex gap-2">
          <Tooltip content="Edit">
            <MdEdit
              size={24}
              className="hover:cursor-pointer hover:text-primary"
              onClick={() =>
                navigate('upsert-private-loan', `loanId=${record.id}&clientId=${clientId}`)
              }
            />
          </Tooltip>
          <Tooltip content="Delete">
            <MdDelete
              size={24}
              className="hover:cursor-pointer hover:text-primary"
              onClick={() => setPrivateLoanToDelete(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  async function loadCreditCards(
    skip?: number,
    take?: number,
    filterValue?: string,
    sortBy?: InputMaybe<CreditCardLoanViewSortInput | CreditCardLoanViewSortInput[]>
  ) {
    const { data } = await getCreditCardLoans({
      variables: {
        clientId: clientId,
        skip,
        take,
        sortBy,
      },
    });

    return {
      rows: data!.creditCardLoans!.items as CreditCardLoanView[],
      totalCount: data!.creditCardLoans!.totalCount,
    };
  }
  async function load(
    skip?: number,
    take?: number,
    filterValue?: string,
    sortBy?: InputMaybe<PrivateLoanViewSortInput | PrivateLoanViewSortInput[]>
  ) {
    const { data } = await getPrivateLoans({
      variables: {
        clientId: clientId,
        skip,
        take,
        sortBy,
      },
    });

    return {
      rows: data!.privateLoans!.items as PrivateLoanView[],
      totalCount: data!.privateLoans!.totalCount,
    };
  }

  const { totalAmount, weightedAverageRate, sumOfPayments } = useMemo(() => {
    let totalAmount = 0;
    let sumOfPayments = 0;
    const weightedFactorTotal =
      getPrivateLoansQuery.data?.privateLoans?.items?.reduce((acc, item) => {
        return acc + (item.amount || 0) * (item.interestRate || 0);
      }, 0) || 0;

    getPrivateLoansQuery.data?.privateLoans?.items?.forEach((item) => {
      totalAmount += item.amount;
      sumOfPayments += item.monthlyPayment;
    });
    const weightedAverageRate = weightedFactorTotal / totalAmount;

    return { totalAmount, weightedAverageRate, sumOfPayments };
  }, [getPrivateLoansQuery.data?.privateLoans?.items]);

  return (
    <>
      <DeletePrivateLoanModal
        loanToDelete={privateLoanToDelete}
        closeModal={() => setPrivateLoanToDelete(undefined)}
      />
      <div className="flex-1 gap-y-2">
        <div className="text-lg mt-10 mb-2">Private Loans</div>
        <FinologyTable
          emptyText={() => <span>No private loans found.</span>}
          columns={privateLoansColumns}
          dataSourcePromise={load}
          reloadDependency={getPrivateLoansQuery.previousData}
          searchPlaceholder="Search for private loans..."
          sortBy={sortBy}
          hideFooter={true}
        />
        {getPrivateLoansQuery.data?.privateLoans?.totalCount ? (
          <div className="grid grid-cols-3 gap-4 mt-8">
            <HighlightedCard label="Total Private Loan Amount" value={toDollars(totalAmount)} />
            <HighlightedCard
              label="Weighted Average Rate"
              value={toPercentage(weightedAverageRate)}
            />
            <HighlightedCard label="Sum of Payments" value={toDollars(sumOfPayments)} />
          </div>
        ) : null}
        <div className="text-lg mt-10 mb-2">Credit Cards</div>
        <FinologyTable
          emptyText={() => <span>No credit cards found.</span>}
          columns={creditCardColumns}
          dataSourcePromise={loadCreditCards}
          reloadDependency={getCreditCardLoansQuery.previousData}
          searchPlaceholder="Search for credit cards..."
          sortBy={sortByCreditCard}
          hideFooter={true}
        />
        {getCreditCardLoansQuery.data?.creditCardLoans?.totalCount ? (
          <div className="grid grid-cols-4 gap-4 mt-8">
            <HighlightedCard
              label="Total Credit Card Outstanding Balance"
              value={toDollars(
                creditCardAggregations.data?.creditCardAggregations?.outstandingBalance
              )}
            />
            <HighlightedCard
              label="Weighted Average Rate"
              value={toPercentage(
                creditCardAggregations.data?.creditCardAggregations?.averageInterestRate / 100
              )}
            />
            <HighlightedCard
              label="Sum of Min. Mo. Payment"
              value={toDollars(creditCardAggregations.data?.creditCardAggregations?.totalPayments)}
            />
            <HighlightedCard
              label="Credit Card Utilization"
              value={toPercentage(
                creditCardAggregations.data?.creditCardAggregations?.creditCardUtilization
              )}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export const PrivateLoans = ({ clientId }: { clientId: number }) => {
  return (
    <div className="py-8">
      <PrivateLoansGrid clientId={clientId} />
    </div>
  );
};
