import { useNavigate } from 'react-router-dom';

import { getQueryParams, getSearchParams } from '../components/flyout/Flyout.utils';

export const useFlyoutNavigate = () => {
  const navigate = useNavigate();

  return {
    navigate: (flyoutId: string, params?: string) => {
      const search = window.location.search;
      const flyoutLayers = getSearchParams(search);
      const parentParams = getQueryParams(search);

      navigate(
        `?flyout=${[...flyoutLayers, flyoutId].toString()}${
          params || parentParams
            ? `${params ? `&${params}` : ``}${parentParams ? `&${parentParams}` : ``}`
            : ''
        }`,
        {
          state: {
            flyoutId,
          },
        }
      );
    },
  };
};
