import { Button } from 'flowbite-react';
import { FaExternalLinkAlt } from 'react-icons/fa';

import AiccfcLogo from '../../assets/AICCFC.png';
import FpPathfinderLogo from '../../assets/fpPATHFINDER.png';
import InventLogo from '../../assets/invent.us.png';
import SimplyParaplannerLogo from '../../assets/paraplanner.png';
import { PageHeader } from '../../components/PageHeader';

const PartnerCard = ({
  logo,
  title,
  description,
  link,
}: {
  logo: string;
  title: string;
  description: string;
  link: string;
}) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 text-center flex items-center justify-between flex-col border border-gray-200 ">
      <div>
        <div className="mb-4">
          <img src={logo} alt={title} className="mx-auto max-h-20" />
        </div>
        <p className="text-gray-500 mb-6">{description}</p>
      </div>
      <Button
        color="light"
        onClick={() => {
          window.open(link, '_blank');
        }}
      >

        <FaExternalLinkAlt className="mr-1" />
        Visit website{' '}
      </Button>
    </div>
  );
};

export const Partners = () => {
  const partnersData = [
    {
      logo: InventLogo,
      title: 'invent.us',
      description:
        "Data from Finology Software's Liability Planner is available within the Invent Dashboard.",
      link: 'https://invent.us/',
    },
    {
      logo: FpPathfinderLogo,
      title: 'fpPATHFINDER',
      description:
        'fpPathfinder has insightful resources for advisors looking to establish the best processes for Student Loan Repayment Planning.',
      link: 'https://www.fppathfinder.com/',
    },
    {
      logo: AiccfcLogo,
      title: 'AICCFC',
      description:
        'Finology Software combined with the CCFC designation from AICCFC elevates your capabilities for advising on federal student debt.',
      link: 'https://www.aiccfc.org/',
    },
    {
      logo: SimplyParaplannerLogo,
      title: 'SimplyPARAPLANNER',
      description:
        'SimplyParaplanner is a great way to find your next paraplanner trained on Finology Software.',
      link: 'https://simplyparaplanner.com/',
    },
  ];

  return (
    <>
      <PageHeader back breadcrumbs={[{ to: '/resources', text: 'Partners' }]} />
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-center flex-col items-center">
          <h2 className="text-2xl font-bold mb-6 text-center">Partners</h2>
          <p className="text-gray-500 text-center mb-12 max-w-lg text-sm">
            The Finology Software platform is growing and includes strategic partners that provide
            ongoing education and data transfers
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {partnersData.map((partner, index) => (
            <PartnerCard
              key={index}
              logo={partner.logo}
              title={partner.title}
              description={partner.description}
              link={partner.link}
            />
          ))}
        </div>
        <div className="text-center mt-12">
          <p className="text-gray-500">
            If your firm desires a specific integration email us at{' '}
            <a href="mailto:integrations@finology.tech" className="text-blue-500 underline ">
              integrations@finology.tech
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
