import dayjs from 'dayjs';
import { Button, Modal, Tabs, Tooltip } from 'flowbite-react';
import React, { useEffect, useMemo, useState } from 'react';
import { HiInformationCircle } from 'react-icons/hi';

import { RepaymentPlanEnum } from '../../../components/calculator/Calculator.types';
import {
  getRepaymentlabel,
  hasAnyResults,
  isOnIdrPlan,
} from '../../../components/calculator/Calculator.utils';
import OverviewResultsGraph from '../../../components/calculator/OverviewResultsGraph';
import { FinologyTable } from '../../../components/table/FinologyTable';
import { TaxFilingType } from '../../../graphql/generated';
import { toDollars } from '../../../util/currency.formatter';

import RepaymentResultsGraph from './RepaymentResultsGraph';
import { chunkArray } from './results.helpers';

export const IdrPlanPrefix = ({ taxFillingType }: { taxFillingType: TaxFilingType }) => {
  if (taxFillingType == TaxFilingType.MarriedFilingJointly) return <span>MFS </span>;
  else if (taxFillingType == TaxFilingType.MarriedFilingSeparately) return <span>MFJ </span>;
  else return null;
};

const getColumns = (repaymentPlan: RepaymentPlanEnum) => {
  const isIdrPlan = isOnIdrPlan(repaymentPlan);
  let cols = [...COLUMNS];

  if (repaymentPlan === RepaymentPlanEnum.Save) {
    cols = cols.map((col) => {
      if (col.dataIndex === 'interestBalance') {
        return {
          ...col,
          render: (value: number) => {
            if (!value) return <span>-</span>;

            return <span>{toDollars(value)}</span>;
          },
        };
      }
      return col;
    });
  }

  if (!isIdrPlan) {
    cols = cols.filter((x) => x.key !== 'interestSubsidy');
  }
  return cols;
};

const COLUMNS: {
  title: React.ReactNode;
  dataIndex: string;
  key: string;
  render: (value: number) => JSX.Element;
}[] = [
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
    render: (value: number) => <span className="text-primary cursor-pointer">{value}</span>,
  },
  {
    title: (
      <div className="flex flex-row gap-1">
        Principal Balance{' '}
        <Tooltip
          content="Finology Software rounds down to the nearest dollar. Any discrepancies could result from that rounding."
          className="inline "
        >
          <HiInformationCircle />
        </Tooltip>
      </div>
    ),
    dataIndex: 'principalBalance',
    key: 'principalBalance',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
  {
    title: (
      <div className="flex flex-row gap-1">
        Interest Balance{' '}
        <Tooltip
          content="Finology Software rounds down to the nearest dollar. Any discrepancies could result from that rounding."
          className="inline "
        >
          <HiInformationCircle />
        </Tooltip>
      </div>
    ),
    dataIndex: 'interestBalance',
    key: 'interestBalance',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
  {
    title: (
      <div className="flex flex-row gap-1">
        Projected Monthly Payment{' '}
        <Tooltip
          content="Finology Software rounds down to the nearest dollar. Any discrepancies could result from that rounding."
          className="inline "
        >
          <HiInformationCircle />
        </Tooltip>
      </div>
    ),
    dataIndex: 'minPayment',
    key: 'minPayment',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
  {
    title: (
      <div className="flex flex-row gap-1">
        Monthly Interest Charged{' '}
        <Tooltip
          content="Finology Software rounds down to the nearest dollar. Any discrepancies could result from that rounding."
          className="inline "
        >
          <HiInformationCircle />
        </Tooltip>
      </div>
    ),
    dataIndex: 'interestCharged',
    key: 'interestCharged',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
  {
    title: (
      <div className="flex flex-row gap-1">
        Interest Subsidy{' '}
        <Tooltip
          content="Finology Software rounds down to the nearest dollar. Any discrepancies could result from that rounding."
          className="inline "
        >
          <HiInformationCircle />
        </Tooltip>
      </div>
    ),
    dataIndex: 'interestSubsidy',
    key: 'interestSubsidy',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
  {
    title: (
      <div className="flex flex-row gap-1">
        Extra Payment{' '}
        <Tooltip
          content="Finology Software rounds down to the nearest dollar. Any discrepancies could result from that rounding."
          className="inline "
        >
          <HiInformationCircle />
        </Tooltip>
      </div>
    ),
    dataIndex: 'extraPayment',
    key: 'extraPayment',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
  {
    title: (
      <div className="flex flex-row gap-1">
        Employer Contribution{' '}
        <Tooltip
          content="Finology Software rounds down to the nearest dollar. Any discrepancies could result from that rounding."
          className="inline "
        >
          <HiInformationCircle />
        </Tooltip>
      </div>
    ),
    dataIndex: 'employerContribution',
    key: 'employerContribution',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
  {
    title: (
      <div className="flex flex-row gap-1">
        Last Installment{' '}
        <Tooltip
          content="Finology Software rounds down to the nearest dollar. Any discrepancies could result from that rounding."
          className="inline "
        >
          <HiInformationCircle />
        </Tooltip>
      </div>
    ),
    dataIndex: 'monthly',
    key: 'monthly',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
];

const YearModal = ({
  yearResults,
  repaymentPlan,
  year,
  columns,
  repaymentStartMonth,
  maxYear,
  hasAmountForgiven,
}: {
  yearResults: { [key: number]: any }[];
  repaymentPlan: any;
  year: number;
  columns: any[];
  repaymentStartMonth: number;
  maxYear: number;
  hasAmountForgiven: boolean;
}) => {
  const isIdrPlan = isOnIdrPlan(repaymentPlan);
  let isLoanPaid = false;

  const rows: any[] = [];

  for (let i = 0; i < 12; i++) {
    const monthlyResultInYear = yearResults[i];

    if (!monthlyResultInYear) {
      break;
    }

    const formulaResultsInMonth = (monthlyResultInYear as any).filter(
      (x: any) => x.key === repaymentPlan
    )[0];

    if (!formulaResultsInMonth) {
      break;
    }

    if (formulaResultsInMonth.value.pb === 0 && maxYear === year + 1) {
      isLoanPaid = true;
      break;
    }
    const month = i + 1;

    rows.push({
      month: month + (year === 0 ? repaymentStartMonth : 0),
      principalBalance: formulaResultsInMonth.value.pb,
      interestBalance: formulaResultsInMonth.value.ib,
      minPayment: formulaResultsInMonth.value.mp,
      interestCharged: formulaResultsInMonth.value.ic,
      interestSubsidy: isIdrPlan ? formulaResultsInMonth.value.is : null,
      extraPayment: formulaResultsInMonth.value.ep,
      employerContribution: formulaResultsInMonth.value.ec,
      monthly: formulaResultsInMonth.value.mp + formulaResultsInMonth.value.ep,
    });
  }

  return (
    <div>
      <FinologyTable
        type={'no-promise'}
        rows={rows}
        columns={[
          {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
            render: (value: number) => <span>{value}.</span>,
          },
          ...columns.slice(1, columns.length - 1),
        ]}
      />
      {isLoanPaid && (
        <div className="p-4 text-center text-2xl font-bold bg-fino-grey-shade">
          {hasAmountForgiven ? `Loan(s) will discharge with a balance.` : ` Loan(s) are paid off.`}
        </div>
      )}
    </div>
  );
};

const additionalCheck = (i: number, differenceBetweenTimelineMonthAndCurrentMonth: number) => {
  if (differenceBetweenTimelineMonthAndCurrentMonth > 0) {
    return i === 0;
  }

  return false;
};

const RepaymentPlanTable = ({
  formulaResults,
  repaymentPlan,
  calculatorInput,
  timelineStartDate,
  hasAmountForgiven,
  simulationResult,
}: any) => {
  const isIdrPlan = isOnIdrPlan(repaymentPlan);
  //use for modal
  const [selectedYear, setSelectedYear] = useState<{ index: number; year: number } | null>(null);
  const [isShortenedTable, setIsShortenedTable] = useState(true);

  const columns = useMemo(() => {
    return getColumns(repaymentPlan);
  }, [isIdrPlan]);

  if (!formulaResults.length) return null;

  const rows: any[] = [];

  const timelineStartYear = dayjs(timelineStartDate).year();
  const timelineStartMonth = dayjs(timelineStartDate).month();
  const currentDateMonth = dayjs().month();
  const differenceBetweenTimelineMonthAndCurrentMonth = timelineStartMonth - currentDateMonth;

  let maxYear = 0;

  const itemsToSlice = 12 - timelineStartMonth;

  const firstYear = [formulaResults.slice(0, itemsToSlice)];
  const rest = formulaResults.slice(itemsToSlice);

  const chunks = chunkArray(rest, 12);
  const resultByYear = [...firstYear, ...chunks];

  const monthOffset = simulationResult.resultIndexForRepaymentStart % 12;

  formulaResults.forEach((monthlyResultInYear: any, i: number) => {
    if (
      additionalCheck(i, differenceBetweenTimelineMonthAndCurrentMonth) ||
      i % 12 === monthOffset
    ) {
      const formulaResultInYear = monthlyResultInYear.filter(
        (x: any) => x.key === repaymentPlan
      )[0];

      maxYear += 1;

      if (!formulaResultInYear) {
        return;
      }

      rows.push({
        year: Math.ceil(timelineStartYear + maxYear - 1),
        principalBalance: formulaResultInYear.value.pb,
        interestBalance: formulaResultInYear.value.ib,
        minPayment: formulaResultInYear.value.mp,
        interestCharged: formulaResultInYear.value.ic,
        interestSubsidy: isIdrPlan ? formulaResultInYear.value.is : null,
        extraPayment: formulaResultInYear.value.ep,
        employerContribution: formulaResultInYear.value.ec,
        monthly: formulaResultInYear.value.mp + formulaResultInYear.value.ep,
      });
    }
  });

  const shouldAddLastElement = formulaResults.length % 12 !== monthOffset;
  if (shouldAddLastElement) {
    rows.push({
      year: Math.ceil(timelineStartYear + maxYear),
      principalBalance: 0,
      interestBalance: 0,
      minPayment: 0,
      interestCharged: 0,
      interestSubsidy: isIdrPlan ? 0 : null,
      extraPayment: 0,
      employerContribution: 0,
      monthly: 0,
    });
  }

  return (
    <>
      <FinologyTable
        type="no-promise"
        rows={rows.filter((row) => {
          if (isShortenedTable) {
            const isRepaymentStartYearOr5YearsFromIt =
              calculatorInput.repaymentStartDate.$y <= row.year &&
              row.year < calculatorInput.repaymentStartDate.$y + 5;

            return isRepaymentStartYearOr5YearsFromIt;
          }

          return true;
        })}
        onRowClick={(record: any, index) => {
          setSelectedYear({ index: rows.indexOf(record), year: record.year });
        }}
        columns={columns}
      />
      {rows.length >= 5 && (
        <Button color="light" className="mt-4" onClick={() => setIsShortenedTable((val) => !val)}>
          {isShortenedTable ? 'Show All Years' : 'Collapse'}
        </Button>
      )}
      <Modal show={selectedYear !== null} size={'5xl'} onClose={() => setSelectedYear(null)}>
        <Modal.Header>Results for year {selectedYear?.year}</Modal.Header>
        <Modal.Body>
          <YearModal
            year={selectedYear?.index || 0}
            yearResults={resultByYear[selectedYear?.index || 0]}
            repaymentPlan={repaymentPlan}
            columns={columns}
            repaymentStartMonth={timelineStartMonth}
            maxYear={maxYear}
            hasAmountForgiven={hasAmountForgiven}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setSelectedYear(null)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const TabElement: React.FC<any> = ({ simulationResult, calculatorInput, repaymentPlan }) => {
  if (!simulationResult) return null;

  const results = simulationResult?.formulaWithLoanCounts.find(
    (x: any) => x.formulaType == repaymentPlan
  );

  return (
    <div className="rounded-lg shadow">
      <div className="p-4">
        <RepaymentResultsGraph
          calculatorInput={calculatorInput}
          formulaResults={simulationResult.fr}
          repaymentPlan={repaymentPlan}
          simulationResult={simulationResult}
        />
        <RepaymentPlanTable
          formulaResults={simulationResult.fr}
          repaymentPlan={repaymentPlan}
          calculatorInput={calculatorInput}
          simulationResult={simulationResult}
          timelineStartDate={simulationResult.timelineStartDate}
          hasAmountForgiven={simulationResult.amountForgiven}
        />
      </div>
      {results.totalCount == results.paidOutCount && results.forgivenCount == 0 && (
        <div
          className={`p-4 text-center text-lg font-semibold rounded bg-green-100 text-green-700`}
        >
          Loan(s) are paid off.
        </div>
      )}

      {results.totalCount == results.paidOutCount + results.forgivenCount &&
        results.forgivenCount > 0 && (
          <div
            className={`p-4 text-center text-lg font-semibold rounded text-orange-700 bg-orange-200`}
          >
            Loan(s) will discharge with a balance.
          </div>
        )}
    </div>
  );
};

export const RepaymentPlansResultsTable: React.FC<any> = ({
  simulationResult,
  calculatorInput,
}) => {
  if (!simulationResult) return null;

  // TODO, handle it over Tabs.Item active property
  const [activeKey, setActiveKey] = useState('0');
  useEffect(() => {
    if (tabs.length) {
      setActiveKey(tabs[0].key);
    }
  }, [simulationResult]);
  const tabs = useMemo(() => {
    const tabs = [];

    if (simulationResult.fr?.length && simulationResult.fr[0].length > 1) {
      tabs.push({
        title: 'OVERVIEW',
        key: '0',
        content: (
          <OverviewResultsGraph
            calculatorInput={calculatorInput}
            formulaResults={simulationResult.fr}
          />
        ),
      });
    }

    if (hasAnyResults(simulationResult, RepaymentPlanEnum.Repaye)) {
      tabs.push({
        title: getRepaymentlabel(RepaymentPlanEnum.Repaye),
        key: '1',
        content: (
          <TabElement
            simulationResult={simulationResult}
            calculatorInput={calculatorInput}
            repaymentPlan={RepaymentPlanEnum.Repaye}
          />
        ),
      });
    }

    if (hasAnyResults(simulationResult, RepaymentPlanEnum.Save)) {
      tabs.push({
        title: getRepaymentlabel(RepaymentPlanEnum.Save),
        key: '2',
        content: (
          <TabElement
            simulationResult={simulationResult}
            calculatorInput={calculatorInput}
            repaymentPlan={RepaymentPlanEnum.Save}
          />
        ),
      });
    }

    if (hasAnyResults(simulationResult, RepaymentPlanEnum.Paye)) {
      tabs.push({
        title: getRepaymentlabel(RepaymentPlanEnum.Paye),
        key: '3',
        content: (
          <TabElement
            simulationResult={simulationResult}
            calculatorInput={calculatorInput}
            repaymentPlan={RepaymentPlanEnum.Paye}
          />
        ),
      });
    }

    if (hasAnyResults(simulationResult, RepaymentPlanEnum.Ibr)) {
      tabs.push({
        title: getRepaymentlabel(RepaymentPlanEnum.Ibr),
        key: '4',
        content: (
          <TabElement
            simulationResult={simulationResult}
            calculatorInput={calculatorInput}
            repaymentPlan={RepaymentPlanEnum.Ibr}
          />
        ),
      });
    }

    if (hasAnyResults(simulationResult, RepaymentPlanEnum.Icr)) {
      tabs.push({
        title: getRepaymentlabel(RepaymentPlanEnum.Icr),
        key: '5',
        content: (
          <TabElement
            simulationResult={simulationResult}
            calculatorInput={calculatorInput}
            repaymentPlan={RepaymentPlanEnum.Icr}
          />
        ),
      });
    }

    if (hasAnyResults(simulationResult, RepaymentPlanEnum.Standard)) {
      tabs.push({
        title: getRepaymentlabel(RepaymentPlanEnum.Standard),
        key: '6',
        content: (
          <TabElement
            simulationResult={simulationResult}
            calculatorInput={calculatorInput}
            repaymentPlan={RepaymentPlanEnum.Standard}
          />
        ),
      });
    }

    if (hasAnyResults(simulationResult, RepaymentPlanEnum.Extended)) {
      tabs.push({
        title: getRepaymentlabel(RepaymentPlanEnum.Extended),
        key: '7',
        content: (
          <TabElement
            simulationResult={simulationResult}
            calculatorInput={calculatorInput}
            repaymentPlan={RepaymentPlanEnum.Extended}
          />
        ),
      });
    }

    if (hasAnyResults(simulationResult, RepaymentPlanEnum.Graduated)) {
      tabs.push({
        title: getRepaymentlabel(RepaymentPlanEnum.Graduated),
        key: '8',
        content: (
          <TabElement
            simulationResult={simulationResult}
            calculatorInput={calculatorInput}
            repaymentPlan={RepaymentPlanEnum.Graduated}
          />
        ),
      });
    }

    return tabs;
  }, [simulationResult, calculatorInput]);

  return (
    <div className="mt-8" id="simulation-results-overview">
      <Tabs style="underline" key={activeKey}>
        {tabs.map((tab) => (
          <Tabs.Item key={tab.key} title={tab.title}>
            <>{tab.content}</>
          </Tabs.Item>
        ))}
      </Tabs>
    </div>
  );
};
