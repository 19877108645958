import { Alert, Button, Spinner } from 'flowbite-react';

import { toDollarsWithDecimals } from '../../util/currency.formatter';

interface Props {
  isLoading: boolean;
  isTrialing: boolean;
  hasSubscription: boolean;
  subscriptionInfo?: {
    nextBillingAmount?: any | null;
    nextBillingAt?: any | null;
    subscriptionStatus?: string | null;
    subscriptionValidUntil: Date;
  };
}

export const SubscriptionDetails = ({
  isLoading,
  isTrialing,
  subscriptionInfo,
  hasSubscription,
}: Props) => {
  const redirectToSubscriptionCreate = () => {
    window.location.href = `${process.env.REACT_APP_INTERNAL_API_HOST}/Redirect/ToSubscribe`;
  };

  const subscriptionStatus = subscriptionInfo?.subscriptionStatus || '';

  const config = [
    { label: 'Current Plan:', value: 'Finology Yearly Subscription' },
    { label: 'Renews On:', value: new Date(subscriptionInfo?.nextBillingAt).toLocaleString() },
    {
      label: 'Billing Amount:',
      value: toDollarsWithDecimals(subscriptionInfo?.nextBillingAmount / 100),
    },
    {
      label: 'Status:',
      value: subscriptionStatus.charAt(0).toUpperCase() + subscriptionStatus.slice(1),
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isTrialing ? (
        <Alert color="info" className="relative mt-1 p-4">
          <div className="flex flex-col space-y-2">
            <div className="font-bold">Your Trial is Ending Soon!</div>
            <div>
              Your trial period will end on{' '}
              <b>{new Date(subscriptionInfo!.subscriptionValidUntil).toLocaleDateString()}</b>.
              Click Subscribe to continue using Finology Software.
            </div>
          </div>
          <div className="absolute right-4 top-5">
            <Button
              onClick={redirectToSubscriptionCreate}
              color="blue"
              className="flex items-center space-x-2"
            >
              Subscribe
            </Button>
          </div>
        </Alert>
      ) : hasSubscription ? (
        <div className="flex gap-1 flex-col pb-4 text-sm mt-4">
          {config.map((x) => (
            <div key={x.label} className="flex justify-start">
              <div className="text-gray-500 min-w-[150px]">{x.label}</div>
              <div>{x.value}</div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};
