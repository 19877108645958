import { Suspense, lazy } from 'react';

const LazyPieChart = lazy(() => import('./PieChart'));
const LazyMultipleLineChart = lazy(() => import('./MultipleLineChart'));
const LazyStackColumnChart = lazy(() => import('./StackColumnChart'));

export const PieChart = ({ data }: { data?: { type: string; amount: number }[] }) => {
  return (
    <Suspense fallback="Loading pie chart...">
      <LazyPieChart data={data} />
    </Suspense>
  );
};

export const MultipleLineChart = ({ data, config }: { data?: any[]; config?: any }) => {
  return (
    <Suspense fallback="Loading multiple line chart...">
      <div>
        <LazyMultipleLineChart data={data} configProps={config} />
      </div>
    </Suspense>
  );
};

export const StackColumnChart = ({ data, config }: { data?: any[]; config?: any }) => {
  return (
    <Suspense fallback="Loading stacked column line chart...">
      <LazyStackColumnChart data={data} configProps={config} />
    </Suspense>
  );
};
