import * as loom from '@loomhq/loom-embed';
import { Button, Spinner } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NotificationRingIcon from '../assets/notification-ring.svg';
import { PageHeader } from '../components/PageHeader';
import { useGlobalState } from '../context/AppContext';
import useFinologyTranslation from '../translations/useFinologyTranslation';

const DEMO_LINK = 'https://www.loom.com/share/1c1c80c5b83547b8a9e4af7773407ea6';

export const Demo = () => {
  const { user } = useGlobalState();
  const [videoLink, setVideoLink] = useState<string>('');
  const { t } = useFinologyTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const videoLink = await loom.oembed(DEMO_LINK);
      setVideoLink(videoLink.html);
    })();
  }, []);

  return (
    <>
      <PageHeader
        breadcrumbs={[{ to: '', text: `` }]}
        rightContent={
          <div className="flex">
            Welcome aboard{user?.firstName || user?.lastName ? ', ' : ''}&nbsp;
            <span className=" underline font-bold">
              {`${user?.firstName}${user?.lastName ? ' ' + user?.lastName : ''}`}
            </span>
          </div>
        }
      />
      <div className="mt-12 flex gap-16 flex-col h-full">
        <div className="flex gap-6 flex-col max-w-3xl">
          <div className="text-4xl ">
            Hey {`${user?.firstName}${user?.lastName ? ' ' + user?.lastName : ''}`}, <br /> Welcome
            to Finology Software!
          </div>
          <div className="text-2xl">
            To learn how to add your first {t('borrower.lowercase')} and their loans, watch this
            short informational demo.
          </div>
          {videoLink ? (
            <div dangerouslySetInnerHTML={{ __html: videoLink }}></div>
          ) : (
            <div className="flex justify-center items-center h-96">
              <Spinner size="xl" />
            </div>
          )}
          <div className="flex items-start">
            <img src={NotificationRingIcon} className="mr-2 mt-1"></img>
            <div className="text-md">
              If you skip right to the Dashboard, don’t worry this video will be available in the
              navigation bar on the left.
            </div>
          </div>
        </div>
        <div>
          <Button color="primary" className=" float-right" onClick={() => navigate('/dashboard')}>
            Skip to Dashboard
          </Button>
        </div>
      </div>
    </>
  );
};
