import { Label, Spinner, Button } from 'flowbite-react';
import { useFormikContext } from 'formik';
import { createRef, useState } from 'react';
import { HiCheckCircle } from 'react-icons/hi';
import { PiUploadFill } from 'react-icons/pi';

import { ReactComponent as FileIcon } from '../../../../assets/file.svg';
import { CreditCardLoanDtoInput } from '../../../../graphql/generated';

interface Props {
  name: string;
  onUpload: (response: CreditCardLoanDtoInput[]) => void;
}

export const UploadField = ({ name, onUpload }: Props) => {
  const context = useFormikContext<any>();

  const dropRef = createRef<HTMLDivElement>();

  const [files, setFiles] = useState<{ sofi: File | null; ccReport: File | null }>({
    ccReport: null,
    sofi: null,
  });
  const [uploading, setUploading] = useState(false);

  const error =
    (!!context.touched[name] || context.submitCount) && !!context.errors[name] && !uploading;

  const uploadFiles = async () => {
    if (files.ccReport) {
      const formData = new FormData();
      formData.append('ccReport', files.ccReport);
      setUploading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_INTERNAL_API_HOST}/CreditCardReport/Extract`,
          {
            method: 'POST',
            body: formData,
            credentials: 'include',
            headers: {
              'x-csrf': '1',
            },
          }
        );

        if (response.ok) {
          const result = await response.json();
          onUpload(result);
        } else {
          context.setErrors({ [name]: `Upload failed.` });
        }
      } catch (error: any) {
        context.setErrors({ [name]: error.message });
      } finally {
        setUploading(false);
      }
    }
  };

  const handleFileSelected = async (f: FileList | null, property: 'ccReport' | 'sofi') => {
    context.setFieldTouched(name);
    if (!f) return;

    setFiles({ ...files, [property]: f[0] });
  };

  const UploadButton = ({
    propertyName,
    label,
  }: {
    propertyName: 'ccReport' | 'sofi';
    label: string;
  }) => {
    return (
      <Button
        size="sm"
        color="secondaryLight"
        onClick={() => {
          const fileInput = document.createElement('input');
          fileInput.type = 'file';
          fileInput.accept = '.pdf';
          fileInput.onchange = async (e) => {
            await handleFileSelected((e.target as HTMLInputElement).files, propertyName);
          };
          fileInput.click();
        }}
      >
        <div>
          <PiUploadFill size={24} className="fill-blue-700 mr-2" />
        </div>
        <div className="text-blue-700">{label}</div>
      </Button>
    );
  };

  const UploadedFile = ({ fileName, onRemove }: { fileName: string; onRemove: () => void }) => {
    return (
      <div className="flex grow justify-between bg-gray-100 rounded p-2 items-center">
        <div className="flex gap-x-2 items-center">
          <FileIcon className="fill-gray-500" />
          <div>
            <div>{fileName}</div>
            <button
              type="button"
              onClick={onRemove}
              className="border-0 p-0 cursor-pointer text-gray-500 hover:text-gray-800 "
            >
              Remove
            </button>
          </div>
        </div>

        <HiCheckCircle size={20} className=" fill-green-500" />
      </div>
    );
  };

  const UploadSection = () => {
    const ccReport = files.ccReport;

    return (
      <div>
        <div className=" text-xs mb-1">Credit Report</div>
        {ccReport ? (
          <UploadedFile
            fileName={ccReport.name}
            onRemove={() => {
              setFiles({ ...files, ccReport: null });
            }}
          />
        ) : (
          <UploadButton propertyName="ccReport" label="Upload Credit Report" />
        )}
        <Label className=" text-gray-500">This file can be found at equifax.</Label>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-3" ref={dropRef}>
      <div>
        {<UploadSection />}
        {files.ccReport && (
          <div className="flex justify-center items-center mt-4">
            <Button onClick={uploadFiles}>Upload</Button>
          </div>
        )}
        {!!error && <div className="text-red-500 mt-1">We could not parse the uploaded files</div>}
      </div>
      {uploading ? (
        <div className="flex items-center gap-x-2">
          <Spinner size={'sm'} />
          <span>Processing file...</span>
        </div>
      ) : null}
    </div>
  );
};
