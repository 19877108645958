import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_INTERNAL_API_HOST,
});

export function useApiClient() {
  return {
    get: <T = any, D = any>(
      url: string,
      config?: AxiosRequestConfig<D>
    ): Promise<AxiosResponse<T>> => {
      return instance.get(url, {
        ...config,
        withCredentials: true,
      });
    },

    post: <T = any, D = any>(
      url: string,
      body: D,
      config?: AxiosRequestConfig<D>
    ): Promise<AxiosResponse<T>> => {
      return instance.post(url, body, {
        ...config,
        withCredentials: true,
      });
    },
  };
}
