import dayjs from 'dayjs';

import {
  CalculatorViewInput,
  LeftoverIncomeToUseTowardsLoansType,
  LoansToPrioritize,
  PaydownMethods,
  TaxFilingType,
} from '../../graphql/generated';

import { CalculatorView } from './../../graphql/generated';
import { CalculatorFederalLoanFormValue, CalculatorFormValues } from './form/EditCalculatorDetails';

const OTHER_CHILDREN = [
  'firstChildBirthYear',
  'secondChildBirthYear',
  'thirdChildBirthYear',
  'fourthChildBirthYear',
  'fifthChildBirthYear',
  'sixthChildBirthYear',
  'seventhChildBirthYear',
  'eighthChildBirthYear',
  'ninthChildBirthYear',
  'tenthChildBirthYear',
];

export const convertScenarioJsonToFormData = (jsonData: string): CalculatorFormValues => {
  const parsedData = JSON.parse(jsonData);

  return {
    ...parsedData,
    teacherLoanForgivenessLimit: parsedData.teacherLoanForgivenessLimit || 5000,
    repaymentStartDate: dayjs.utc(parsedData.repaymentStartDate),
    loans: parsedData.loans.map((l: any) => {
      return {
        ...l,
        loanDate: l.loanDate ? dayjs.utc(l.loanDate) : null,
        idrMonthsInRepayment: l.idrMonthsInRepayment || 0,
        repaymentBeginDate: l.repaymentBeginDate ? dayjs.utc(l.repaymentBeginDate) : null,
      };
    }),
  };
};

export const convertFormDataToGqlScenarioSave = (
  values: CalculatorFormValues,
  scenarioId: number | null,
  clientId: number,
  scenarioName: string
) => {
  const {
    isProjectionsModalVisible,
    isAssumptionsModalVisible,
    isSimulationForClient,
    isSimulationForSpouse,
    ...cleanedValues
  } = values;

  return {
    id: scenarioId,
    clientId: clientId,
    name: scenarioName,
    jsonData: JSON.stringify(cleanedValues),
  };
};

export const convertFormDataToGqlSimulateData = (data: any): CalculatorViewInput => {
  const isMarriedFilingJointly = data.taxFilingType === TaxFilingType.MarriedFilingJointly;

  return {
    startIteratingFromRepaymentBeginDate: false,
    clientAgiProjections: data.clientAgiProjections,
    id: data.isSimulationForClient ? data.id : data.spouseId!,
    isPslfActive: data.isPslfActive,
    isSelfEmployed: data.isSelfEmployed,
    isTeacherForgivnessActive: data.isTeacherForgivnessActive,
    isTimeBasedForgivnessActive: data.isTimeBasedForgivnessActive,
    makeExtraPaymentsWhenCashFlowAllows:
      !!data.employerMonthlyContribution ||
      !!data.addedExcessCashFromOtherSources ||
      !!data.leftoverIncomeToUseTowardsLoans ||
      // make it true so we can run private loans if we don't have any fed loans
      !data.loans.length
        ? true
        : false,
    employerMonthlyContribution: data.employerMonthlyContribution,
    employerContributionLifetimeMaximum: data.employerContributionLifetimeMaximum,
    repaymentStartDate: data.repaymentStartDate,
    spouseAgiProjections: data.spouseAgiProjections,
    taxFilingType: data.taxFilingType,
    addedExcessCashFromOtherSources: data.addedExcessCashFromOtherSources,
    annualSalaryDeduction: data.annualSalaryDeduction,
    currentSavingTowardsTaxBomb: data.currentSavingTowardsTaxBomb,
    firstChildBirthYear: data.firstChildBirthYear,
    secondChildBirthYear: data.secondChildBirthYear,
    thirdChildBirthYear: data.thirdChildBirthYear,
    fourthChildBirthYear: data.fourthChildBirthYear,
    fifthChildBirthYear: data.fifthChildBirthYear,
    sixthChildBirthYear: data.sixthChildBirthYear,
    seventhChildBirthYear: data.seventhChildBirthYear,
    eighthChildBirthYear: data.eighthChildBirthYear,
    ninthChildBirthYear: data.ninthChildBirthYear,
    tenthChildBirthYear: data.tenthChildBirthYear,
    estimatedMonthlySavingTowardsTaxBomb: data.estimatedMonthlySavingTowardsTaxBomb,
    itemizedDeductions: data.itemizedDeductions,
    leftoverIncomeToUseTowardsLoansType: data.leftoverIncomeToUseTowardsLoansType
      ? LeftoverIncomeToUseTowardsLoansType.Percentage
      : LeftoverIncomeToUseTowardsLoansType.Fixed,
    leftoverIncomeToUseTowardsLoans: data.leftoverIncomeToUseTowardsLoans,
    loansToPrioritize: data.loansToPrioritize,
    monthlyExpenses: data.monthlyExpenses ? Number(data.monthlyExpenses) : undefined,
    paydownMethod: data.paydownMethod,
    spouseAnnualSalaryDeduction: data.spouseAnnualSalaryDeduction,
    spouseItemizedDeductions: data.spouseItemizedDeductions,
    state: data.state,
    teacherLoanForgivenessLimit: data.teacherLoanForgivenessLimit,
    annualIncreaseOfMonthlyExpensesPercentage: data.annualIncreaseOfMonthlyExpensesPercentage
      ? data.annualIncreaseOfMonthlyExpensesPercentage / 100
      : undefined,
    rateOfReturnOnTaxSavingsBomb:
      data.rateOfReturnOnTaxSavingsBomb / 100 ? data.rateOfReturnOnTaxSavingsBomb : undefined,
    privateLoans: data.privateLoans.map((l: any) => {
      return {
        amount: l.amount,
        extraPayout: l.extraPayout,
        interestRate: l.interestRate / 100,
        monthlyPayment: l.monthlyPayment,
        name: l.name,
        startDate: l.startDate,
      };
    }),
    creditCards: (data.creditCards || []).map((cc: any, i: any) => {
      return {
        minimumPayments:
          (cc.newPaymentAmount != null || cc.newPaymentAmount != undefined) &&
          !Number.isNaN(cc.newPaymentAmount)
            ? Number(cc.newPaymentAmount ?? 0)
            : cc.minimumPayments ?? 0,
        purchaseApr: cc.purchaseApr / 100,
        balanceTransferApr:
          (cc.newBalanceApr != null || cc.newBalanceApr != undefined
            ? cc.newBalanceApr ?? 0
            : cc.balanceTransferApr) / 100,
        outstandingBalance: cc.outstandingBalance,
        balanceTransferRate: cc.balanceTransferRate / 100,
        monthsToPay:
          (cc.newMonthsToPay != null || cc.newMonthsToPay != undefined) &&
          !Number.isNaN(cc.newMonthsToPay)
            ? cc.newMonthsToPay ?? 0
            : cc.minimumPayments ?? 0,
        type: cc.type,
        bankName: cc.bankName,
      };
    }),

    loans: data.loans.map((x: CalculatorFederalLoanFormValue) => ({
      id: x.id,
      assignedRepaymentPlanType: x.assignedRepaymentPlanType || 'EXTENDED',
      graduate: x.graduate,
      pslfCount: x.pslfCount,
      interestRate: x.interestRate,
      principalBalance: x.principalBalance,
      interestBalance: x.interestBalance,
      type: x.type,
      repaymentPlanType: x.repaymentPlanType,
      loanSubsidizedUsageInYears: x.loanSubsidizedUsageInYears,
      disbursedAmount: x.disbursedAmount,
      idrMonthsInRepayment: x.idrMonthsInRepayment,
      repaymentBeginDate: x.repaymentBeginDate,
      loanDate: x.loanDate,
      teacherLoanForgivenessMonthsOfCredit: x.teacherLoanForgivenessMonthsOfCredit,
      statuses: x.statuses.map((s) => {
        return {
          effectiveDate: s.effectiveDate,
          status: s.status,
        };
      }),
    })),
    startingHouseholdSize:
      1 +
      Number(data.dependentsNumber || 0) +
      Number(data.otherDependentsNumber || 0) +
      (isMarriedFilingJointly ? 1 : 0),
  };
};

export const createInitialValues = (initialData: CalculatorView) => {
  const initialValues: CalculatorFormValues = {
    annualIncreaseOfMonthlyExpensesPercentage: undefined,
    spouseAnnualSalaryDeduction: undefined,
    state: initialData.state,
    annualSalaryDeduction: undefined,
    monthlyExpenses: undefined,
    id: initialData.id,
    isItemizedDeductionDisabled: !!initialData.agiInflation,
    isSpouseItamizedDeductionDisabled: false,
    addedExcessCashFromOtherSources: undefined,
    employerMonthlyContribution: undefined,
    employerContributionLifetimeMaximum: undefined,
    currentSavingTowardsTaxBomb: undefined,
    estimatedMonthlySavingTowardsTaxBomb: undefined,
    isPslfActive: false,
    isSelfEmployed: false,
    isTeacherForgivnessActive: false,
    leftoverIncomeToUseTowardsLoans: undefined,
    leftoverIncomeToUseTowardsLoansType: false,
    loansToPrioritize: LoansToPrioritize.Federal,
    makeExtraPaymentsWhenCashFlowAllows: false,
    paydownMethod: PaydownMethods.Avalanche,
    teacherLoanForgivenessLimit: 5000,
    firstChildBirthYear: initialData.firstChildBirthYear,
    secondChildBirthYear: initialData.secondChildBirthYear,
    thirdChildBirthYear: initialData.thirdChildBirthYear,
    fourthChildBirthYear: initialData.fourthChildBirthYear,
    fifthChildBirthYear: initialData.fifthChildBirthYear,
    sixthChildBirthYear: initialData.sixthChildBirthYear,
    seventhChildBirthYear: initialData.seventhChildBirthYear,
    eighthChildBirthYear: initialData.eighthChildBirthYear,
    ninthChildBirthYear: initialData.ninthChildBirthYear,
    tenthChildBirthYear: initialData.tenthChildBirthYear,
    itemizedDeductions: undefined,
    spouseItemizedDeductions: undefined,
    agiInflation: initialData.agiInflation,
    spouseAgiInflation: initialData.spouseAgiInflation,
    maritalStatus: initialData.maritalStatus,
    spouseAnnualSalary: initialData.spouseAnnualSalary,
    clientSalary: initialData.clientSalary,
    dependentsNumber: initialData.dependentsNumber,
    otherDependentsNumber: initialData.otherDependentsNumber,
    taxFilingType: initialData.taxFilingType,
    pslfAndIdrField: true,
    privateLoans: initialData.privateLoans.map((l) => {
      return {
        amount: l.amount,
        extraPayout: l.extraPayout,
        interestRate: l.interestRate * 100,
        monthlyPayment: l.monthlyPayment,
        name: l.name,
        startDate: l.startDate,
      };
    }),
    creditCards: initialData.creditCards.map((cc) => {
      return {
        minimumPayments: cc.minimumPayments,
        purchaseApr: cc.purchaseApr,
        balanceTransferApr: cc.balanceTransferApr,
        outstandingBalance: cc.outstandingBalance,
        balanceTransferRate: cc.balanceTransferRate,
        monthsToPay: cc.monthsToPay ?? 0,
        type: cc.type,
        bankName: cc.bankName,
        last4digits: cc.last4Digits,
      };
    }),
    loans: initialData.loans.map((l) => {
      return {
        assignedRepaymentPlanType: l.assignedRepaymentPlanType,
        disbursedAmount: l.disbursedAmount,
        eligibleRepaymentPlanTypes: l.eligibleRepaymentPlanTypes,
        graduate: !!l.type?.toLowerCase()?.includes('graduate'),
        id: l.id,
        interestBalance: l.interestBalance,
        interestRate: l.interestRate,
        loanSubsidizedUsageInYears: l.loanSubsidizedUsageInYears,
        principalBalance: l.principalBalance,
        pslfCount: l.pslfCount,
        teacherLoanForgivenessMonthsOfCredit: l.teacherLoanForgivenessMonthsOfCredit,
        loanDate: l.loanDate ? dayjs.utc(l.loanDate) : null,
        idrMonthsInRepayment: l.idrMonthsInRepayment,
        repaymentBeginDate: l.repaymentBeginDate ? dayjs.utc(l.repaymentBeginDate) : null,
        repaymentPlanType: l.repaymentPlanType,
        type: l.type,
        statuses: l.statuses.map((s) => {
          return {
            effectiveDate: s.effectiveDate,
            status: s.status,
          };
        }),
      };
    }),
    clientAgiProjections: getInitialProjections(initialData.agiInflation, initialData.clientSalary),
    spouseAgiProjections: getInitialProjections(
      initialData.spouseAgiInflation,
      initialData.spouseAnnualSalary
    ),
    plannedChildren: OTHER_CHILDREN.reduce(
      (accumulator, propertyName) =>
        (initialData as any)[propertyName] ? accumulator + 1 : accumulator,
      0
    ),
    repaymentStartDate: dayjs.utc(initialData.repaymentStartDate),
    spouseId: initialData.spouseId,
    isTimeBasedForgivnessActive: true,
    rateOfReturnOnTaxSavingsBomb: initialData.investmentReturn,
    isProjectionsModalVisible: false,
    isAssumptionsModalVisible: false,
  };

  return initialValues;
};

const getInitialProjections = (salaryIncrease?: number, salary?: number) => {
  const newProjections = [];

  if (!salary) {
    return Array.from(Array(30).keys()).map((x) => x - x);
  }

  let previousValue = salary;

  for (let index = 0; index < 30; index++) {
    if (index == 0) {
      newProjections.push(salary);
      continue;
    }
    const newValue = Math.round(previousValue * (1 + (salaryIncrease || 0) / 100));
    previousValue = newValue;
    newProjections.push(newValue);
  }

  return newProjections;
};
