import { Spinner } from 'flowbite-react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import {
  CalculatorViewInput,
  LeftoverIncomeToUseTowardsLoansType,
  TaxFilingType,
  useCalculatorQuery,
  useCalculatorScenariosQuery,
  useGetCalculatorOptionsMutation,
} from '../../graphql/generated';
import { useApiClient } from '../../hooks/useApiClient';

import {
  CalculatorFederalLoanFormValue,
  CalculatorFormValues,
  EditCalculatorDetails,
} from './form/EditCalculatorDetails';

export const Calculator = ({
  calculatorDetails,
  clientId,
}: {
  calculatorDetails: any | undefined;
  clientId: number;
}) => {
  const navigate = useNavigate();
  const [paymentOptions] = useGetCalculatorOptionsMutation();

  const { loading, data: initialData } = useCalculatorQuery({
    variables: { clientId: clientId },
  });

  const { loading: loadingCalculatorScenarios, data: calculatorScenarios } =
    useCalculatorScenariosQuery({
      variables: { clientId: clientId },
    });

  const { get } = useApiClient();

  const getPaymentOptions = (data: CalculatorFormValues) => {
    const isMarriedFilingJointly = data.taxFilingType === TaxFilingType.MarriedFilingJointly;

    const input: CalculatorViewInput = {
      startIteratingFromRepaymentBeginDate: false,
      clientAgiProjections: data.clientAgiProjections,
      id: data.id,
      isPslfActive: data.isPslfActive,
      isSelfEmployed: data.isSelfEmployed,
      isTeacherForgivnessActive: data.isTeacherForgivnessActive,
      isTimeBasedForgivnessActive: data.isTimeBasedForgivnessActive,
      makeExtraPaymentsWhenCashFlowAllows:
        !!data.employerMonthlyContribution ||
        !!data.addedExcessCashFromOtherSources ||
        !!data.leftoverIncomeToUseTowardsLoans ||
        // make it true so we can run private loans if we don't have any fed loans
        !data.loans.length
          ? true
          : false,
      employerMonthlyContribution: data.employerMonthlyContribution,
      employerContributionLifetimeMaximum: data.employerContributionLifetimeMaximum,
      repaymentStartDate: data.repaymentStartDate,
      spouseAgiProjections: data.spouseAgiProjections,
      taxFilingType: data.taxFilingType,
      addedExcessCashFromOtherSources: data.addedExcessCashFromOtherSources,
      annualSalaryDeduction: data.annualSalaryDeduction,
      currentSavingTowardsTaxBomb: data.currentSavingTowardsTaxBomb,
      firstChildBirthYear: data.firstChildBirthYear,
      secondChildBirthYear: data.secondChildBirthYear,
      thirdChildBirthYear: data.thirdChildBirthYear,
      fourthChildBirthYear: data.fourthChildBirthYear,
      fifthChildBirthYear: data.fifthChildBirthYear,
      sixthChildBirthYear: data.sixthChildBirthYear,
      seventhChildBirthYear: data.seventhChildBirthYear,
      eighthChildBirthYear: data.eighthChildBirthYear,
      ninthChildBirthYear: data.ninthChildBirthYear,
      tenthChildBirthYear: data.tenthChildBirthYear,
      estimatedMonthlySavingTowardsTaxBomb: data.estimatedMonthlySavingTowardsTaxBomb,
      itemizedDeductions: data.itemizedDeductions,
      leftoverIncomeToUseTowardsLoansType: data.leftoverIncomeToUseTowardsLoansType
        ? LeftoverIncomeToUseTowardsLoansType.Percentage
        : LeftoverIncomeToUseTowardsLoansType.Fixed,
      leftoverIncomeToUseTowardsLoans: data.leftoverIncomeToUseTowardsLoans,
      loansToPrioritize: data.loansToPrioritize,
      monthlyExpenses: data.monthlyExpenses ? Number(data.monthlyExpenses) : undefined,
      paydownMethod: data.paydownMethod,
      spouseAnnualSalaryDeduction: data.spouseAnnualSalaryDeduction,
      spouseItemizedDeductions: data.spouseItemizedDeductions,
      state: data.state,
      teacherLoanForgivenessLimit: data.teacherLoanForgivenessLimit || 5000,
      annualIncreaseOfMonthlyExpensesPercentage: data.annualIncreaseOfMonthlyExpensesPercentage
        ? data.annualIncreaseOfMonthlyExpensesPercentage / 100
        : undefined,
      rateOfReturnOnTaxSavingsBomb:
        data.rateOfReturnOnTaxSavingsBomb / 100 ? data.rateOfReturnOnTaxSavingsBomb : undefined,
      privateLoans: data.privateLoans.map((l) => {
        return {
          amount: l.amount ?? 0,
          extraPayout: l.extraPayout ?? 0,
          interestRate: l.interestRate / 100,
          monthlyPayment: l.monthlyPayment,
          name: l.name ?? '',
          startDate: l.startDate,
        };
      }),
      creditCards: (data.creditCards || []).map((cc, i) => {
        return {
          minimumPayments:
            (cc.newPaymentAmount != null || cc.newPaymentAmount != undefined) &&
            !Number.isNaN(cc.newPaymentAmount)
              ? Number(cc.newPaymentAmount ?? 0)
              : cc.minimumPayments ?? 0,
          purchaseApr: cc.purchaseApr / 100,
          balanceTransferApr:
            (cc.newBalanceApr != null || cc.newBalanceApr != undefined
              ? cc.newBalanceApr ?? 0
              : cc.balanceTransferApr) / 100,
          outstandingBalance: cc.outstandingBalance,
          balanceTransferRate: cc.balanceTransferRate / 100,
          monthsToPay:
            (cc.newMonthsToPay != null || cc.newMonthsToPay != undefined) &&
            !Number.isNaN(cc.newMonthsToPay)
              ? cc.newMonthsToPay ?? 0
              : cc.minimumPayments ?? 0,
          type: cc.type,
          bankName: cc.bankName,
        };
      }),

      loans: data.loans.map((x: CalculatorFederalLoanFormValue) => ({
        id: x.id,
        assignedRepaymentPlanType: x.assignedRepaymentPlanType || 'EXTENDED',
        graduate: x.graduate,
        pslfCount: x.pslfCount,
        interestRate: x.interestRate,
        principalBalance: x.principalBalance,
        interestBalance: x.interestBalance,
        type: x.type,
        repaymentPlanType: x.repaymentPlanType,
        loanSubsidizedUsageInYears: x.loanSubsidizedUsageInYears,
        disbursedAmount: x.disbursedAmount,
        idrMonthsInRepayment: x.idrMonthsInRepayment,
        repaymentBeginDate: x.repaymentBeginDate,
        loanDate: x.loanDate,
        teacherLoanForgivenessMonthsOfCredit: x.teacherLoanForgivenessMonthsOfCredit,
        statuses: x.statuses.map((s) => {
          return {
            effectiveDate: s.effectiveDate,
            status: s.status,
          };
        }),
      })),
      startingHouseholdSize:
        1 +
        Number(data.dependentsNumber || 0) +
        Number(data.otherDependentsNumber || 0) +
        (isMarriedFilingJointly ? 1 : 0),
    };

    paymentOptions({
      variables: {
        input,
      },
    }).then((response) => {
      if (response.data) {
        navigate(`?flyout=simulation-results`, {
          state: {
            calculatorInput: { ...input, agiInflation: data.agiInflation },
            initialData: calculatorDetails,
            simulationResults: response.data,
          },
        });
      } else {
        //TODO
        console.log('Error');
      }
    });
  };

  if (loading || loadingCalculatorScenarios || !calculatorDetails) return <Spinner />;

  return (
    <div>
      {!loading && initialData?.calculatorInput && (
        <>
          {createPortal(
            calculatorDetails?.client?.fullName,
            document.getElementById('calculator-client-name')!
          )}
          <EditCalculatorDetails
            getPaymentOptions={getPaymentOptions}
            initialData={initialData!.calculatorInput}
            clientName={calculatorDetails?.client?.fullName}
            loadedCalculatorScenarios={calculatorScenarios!.calculatorScenarios}
          />
        </>
      )}
    </div>
  );
};
