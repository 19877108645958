import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import { AuthenticationService } from '../auth';

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-csrf': 1,
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink
    .concat(
      onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ extensions }) => {
            if (extensions.code == 'AUTH_NOT_AUTHORIZED') AuthenticationService.login(window.location.href);
          });
        }
      })
    )
    .concat(
      createUploadLink({
        uri: `${process.env.REACT_APP_INTERNAL_API_HOST}/graphql`,
        credentials: 'include',
      })
    ),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
