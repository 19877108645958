import classNames from 'classnames';
import { Label, Spinner } from 'flowbite-react';
import { useFormikContext } from 'formik';
import { createRef, useEffect, useRef, useState } from 'react';
import {
  HiOutlineDocument,
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
} from 'react-icons/hi';

import { useUploadNsldsMutation } from '../../graphql/generated';

import { TextInputField } from './InputField';

interface Props {
  name: string;
  placeholder: string;
}

export const NsldsUploadField = ({ name, placeholder }: Props) => {
  const [uploadNsldsMutation] = useUploadNsldsMutation();

  const context = useFormikContext<any>();
  const value = context.values[name];

  const dropRef = createRef<HTMLDivElement>();
  const [isDragging, setIsDragging] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setSelectedFileName] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);

  const error =
    (!!context.touched[name] || context.submitCount) && !!context.errors[name] && !uploading;

  const preventDefaults = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: DragEvent) => {
    preventDefaults(e);
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) return;
    setIsDragging(true);
  };

  const handleDragOver = (e: DragEvent) => {
    preventDefaults(e);
  };

  const handleDragOut = (e: DragEvent) => {
    preventDefaults(e);
    setIsDragging(false);
  };

  const handleDrop = async (e: DragEvent) => {
    preventDefaults(e);
    setIsDragging(false);
    if (
      e.dataTransfer &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length > 0 &&
      e.dataTransfer.files[0].type.includes('text')
    ) {
      await handleFileSelected(e.dataTransfer.files);

      e.dataTransfer.clearData();
    }
  };

  const handleFileSelected = async (files: FileList | null) => {
    context.setFieldTouched(name);

    if (!files) return;

    setSelectedFileName(files[0].name);

    setUploading(true);

    const { data } = await uploadNsldsMutation({
      variables: { file: files[0] },
      context: {
        headers: {
          'GraphQL-preflight': 1,
        },
      },
    });

    setUploading(false);

    context.setFieldValue(name, data?.uploadNslds!.uploadId);
    context.setFieldValue('source', data?.uploadNslds!.source);
  };

  useEffect(() => {
    const div = dropRef.current;
    if (div) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('drop', handleDrop);
      div.addEventListener('dragover', handleDragOver);
    }

    return () => {
      if (div) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('drop', handleDrop);
        div.removeEventListener('dragover', handleDragOver);
      }
    };
  }, []);

  const UploadSection = () => {
    return (
      <div className="flex py-7 px-6 justify-center">
        <span>Drag &amp; Drop or&nbsp;</span>
        <button
          type="button"
          onClick={() => {
            inputRef.current!.click();
          }}
          className="underline hover:text-primary"
        >
          click here
        </button>
        <input
          type="file"
          ref={inputRef}
          hidden
          onChange={async (e) => {
            await handleFileSelected(e.target.files);
          }}
        />
        <span>&nbsp;to upload a file</span>
      </div>
    );
  };

  const FileNameSection = () => {
    return (
      <div className="flex grow justify-between">
        <div className="flex gap-x-2 items-center">
          <HiOutlineDocument />
          {fileName}
        </div>

        <button
          type="button"
          onClick={() => {
            context.setFieldValue(name, undefined);
            context.setFieldValue('source', undefined);

            setSelectedFileName(null);

            inputRef.current!.files = null;
          }}
          className="border-0 p-0 underline cursor-pointer hover:text-primary"
        >
          Remove
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-3" ref={dropRef}>
      <Label>{placeholder}</Label>
      <div
        className={classNames(
          'flex bg-gray-50 rounded-lg border-2 border-gray-200 border-dashed py-7 px-6 items-center',
          {
            'border-red': error && !isDragging,
            'border-gray-200': !error && !isDragging,
            'border-gray-600': isDragging,
          }
        )}
      >
        {fileName ? <FileNameSection /> : <UploadSection />}
      </div>
      {uploading ? (
        <div className="flex items-center gap-x-2">
          <Spinner size={'sm'} />
          <span>Processing file...</span>
        </div>
      ) : value ? (
        <div className="flex flex-col gap-y-9 text-green-500">
          <div className="flex items-center gap-x-2">
            <HiOutlineCheckCircle />
            <span>This is a valid NSLDS file</span>
          </div>

          <TextInputField name="source" placeholder="Version" />
        </div>
      ) : value === null ? (
        <div className="flex items-center gap-x-2 text-red-500">
          <HiOutlineExclamationCircle />
          <span>
            File processing failed <br />
            Please check your file or try using another one
          </span>
        </div>
      ) : null}
    </div>
  );
};
