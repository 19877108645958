import * as loom from '@loomhq/loom-embed';
import { Button } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';

import { PageHeader } from '../../components/PageHeader';

const VideoCard = ({
  title,
  description,
  link,
}: {
  title: string;
  description: string;
  link: string;
}) => {
  const [videoLink, setVideoLink] = useState<string>('');

  useEffect(() => {
    (async () => {
      const videoLink = await loom.oembed(link);
      setVideoLink(videoLink.html);
    })();
  }, []);

  return (
    <div className="bg-white shadow-md rounded-lg p-4 border border-gray-200">
      <div className="relative w-full h-48 mb-4">
        <div dangerouslySetInnerHTML={{ __html: videoLink }}></div>
      </div>
      <h3 className="font-semibold text-gray-800">{title}</h3>
      <p className="text-gray-500 text-xs">{description}</p>
    </div>
  );
};

const DownloadCard = ({
  title,
  description,
  link,
}: {
  title: string;
  description: string;
  link: string;
}) => {
  const handleDownload = () => {
    window.open(link, '_blank');
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 text-center flex justify-between flex-col border border-gray-200 ">
      <div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
      </div>
      <Button color="light" className="w-full" onClick={handleDownload}>
        <div className="flex gap-1 items-center">
          <AiOutlineDownload /> Download
        </div>
      </Button>
    </div>
  );
};

export const VideosAndDownloads = () => {
  const videoCardsData = [
    {
      title: 'Finology Software Initial Onboarding',
      description:
        'Welcome to Finology Software. Overview of the Main Dashboard and how to download information into the core application.',
      link: 'https://www.loom.com/share/f4bb5a4f313342fbb35590c2352f98ce',
    },
    {
      title: 'Client Details Page Overview of Loans Tab and Versions',
      description:
        'Access the loan information from the NSLDS and create a Version for your clients.',
      link: 'https://www.loom.com/share/8cb6faf1f2cd4ade9f3e9ecbfcc35b1b',
    },
    {
      title: 'Exploring the New Liability Planner Features',
      description:
        'A walk through the liability planner feature, showcasing how we can project future income, manage household details, and utilize forgiveness options efficiently.',
      link: 'https://www.loom.com/share/a574b4c4ae4444c199cd18ce6f023ddb',
    },

    {
      title: 'IDR Comparison Tool',
      description:
        'Showcasing the new IDR Comparison tool with the Liability Planner. We delve into comparing scenarios, highlighting changes, lowest monthly payments, highest forgiveness amounts, taxes owed, and more. Watch to explore the enhancements and details of this highly requested feature.',
      link: 'https://www.loom.com/share/07362a4703264b57ac094a34fc41d3c9',
    },
  ];

  const downloadCardsData = [
    {
      title: 'NSLDS',
      link: 'https://drive.google.com/file/d/1DZTkkmYItkfnh_UqKxVG8ihCYdjFM1YU/view?usp=drive_link',
      description:
        'Client facing document for sourcing their loan data from the National Student Loan Data System.',
    },
    {
      title: 'Student Loan Guide',
      link: 'https://drive.google.com/file/d/1HV-O6UggYoSmt4j-1uOiIMPROGxLPt49/view?usp=sharing',
      description: 'Best practices for student loan repayment planning designed by fpPathfinder.',
    },
    {
      title: 'Compliance',
      link: 'https://drive.google.com/file/d/1v5PaekhDrFKxegRkmPfH2YRDbvIvb5Q1/view?usp=sharing',
      description: 'Due diligence materials for your firm and decision makers.',
    },
  ];

  return (
    <>
      <PageHeader back breadcrumbs={[{ to: '/resources', text: 'Videos and Downloads' }]} />
      <div className="max-w-6xl mx-auto">
        <div className="flex items-center justify-center  flex-col">
          <h2 className="text-2xl font-extrabold mb-4">Knowledge Base</h2>
          <h3 className="text-sm text-gray-500 mb-6">
            Helpful video{`'`}s to learn more about Finology Software
          </h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {videoCardsData.map((video, index) => (
            <VideoCard key={index} {...video} />
          ))}
        </div>
        <div className="flex items-center justify-center flex-col">
          <h2 className="text-2xl mt-12 mb-4 font-extrabold">Downloads</h2>
          <h3 className="text-sm text-gray-500 mb-6">
            Useful information for your clients or your firm processes and compliance.
          </h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {downloadCardsData.map((download, index) => (
            <DownloadCard key={index} {...download} />
          ))}
        </div>
      </div>
    </>
  );
};
