import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';

export const ICRCalculation = () => {
  const loanInformationLabels = [
    {
      label1: 'Spouse 1 Student Loan Balance (Higher of both debt amounts)',
      label2: '$100,000',
    },
    {
      label1: 'Spouse 2 Student Loan Balance, if applicable (Lower of both debt amounts)',
      label2: '$0',
    },
    {
      label1: 'Average Interest Rate',
      label2: '6.00%',
    },
    {
      label1: 'Inflation Rate Assumption',
      label2: '3.00%',
    },
    {
      label1: 'Paying Loan since what year?',
      label2: '2013',
    },
    {
      label1: 'Do you work for a not-for-profit or government employer?',
      label2: 'No',
    },
    {
      label1: 'Did you borrow before October 1, 2007?',
      label2: 'No',
    },
    {
      label1: 'What percent of your loans are from grad school?',
      label2: '0%',
    },
    {
      label1: 'Total Years of Repayment for the ICR plan',
      label2: '25',
    },
    {
      label1: 'Number of Years in Repayment thus far',
      label2: '11',
    },
    {
      label1: 'Term of loan (yrs):',
      label2: '12',
    },
    {
      label1: 'Installments per year:',
      label2: '12',
    },
  ];

  const dataPersonalInformation = [
    {
      label1: 'Where do you live',
      label2: 'Alaska',
    },
    {
      label1: 'Current Family Size',
      label2: '3',
    },
    {
      label1: 'Tax Filing Status when Married',
      label2: 'Married Filing Jointly',
    },
    {
      label1: 'Poverty Level',
      label2: '$32,270',
    },
  ];

  const dataIncomeInformation = [
    {
      label1: 'What is your expected AGI for 2024?',
      label2: '$120,000',
    },
    {
      label1: "What is your spouse's expected AGI for 2024?",
      label2: '',
    },
    {
      label1: 'What is your expected salary growth rate?',
      label2: '3.00%',
    },
    {
      label1: "What is your spouse's expected salary growth rate?",
      label2: '',
    },
    {
      label1: 'Your discretionary income is...',
      label2: '$87,730.00',
    },
  ];

  return (
    <div className="text-gray-500 p-6">
      <Paragraph text="The Income-Contingent Repayment (ICR) Plan costs more each month than other income-driven student loan repayment plans offered. ICR caps monthly payments at 20% of your discretionary income and lasts 25 years before you can get your remaining debt forgiven." />
      <Paragraph text="ICR is the only income-driven plan available for parent PLUS loans. Before signing up for the plan, parent PLUS borrowers must first consolidate the studen loan into a federal direct loan.	" />
      <Paragraph text="Any unpaid interest is added to your principal (capitalized) each year when new income documentation is due. There is a cap on the amount of unpaid interest that can be capitalized, equal to 10% of your starting repayment balance. Once that cap is reached, further unpaid interest accrues, but does not capitalize.	" />
      <Heading title="How is a Basic ICR Payment Calculated?" />
      <p>
        Income Based Repayment Calculations depend on your income.
        <br />
        While the definition of income can change depending on the loan type, for student loans
        Adjusted Gross Income (AGI) is what matters.
        <br />
        Adjusted Gross Income
        <br />
        Example:
        <br />
        Bob&apos;s Income:
        <br />
        1. $50,000 salary/wages
        <br />
        2. $12,000 in rental income
        <br />
        3. $8,500 wages earned as a part-time Uber driver
        <br />
        4. $500 from interest from bonds
        <br />
        Gross Income = $71,000
        <br />
        <br />
        Adjustment from his gross income:
        <br />
        1. $250 in educator expenses
        <br />
        2. $2,500 in student loan interest
        <br />
        Adjustments = $2,750
        <br />
        <br />
        Subtracting allowable adjustments ($2,750) from the Total Income ($71,000), Bob&apos;s
        Adjusted Gross Income (AGI) is $68,250
        <br />
        <br />
        The formula used will change based on what IDR plan you choose.
        <br />
        Start with the general income-based repayment formula
        <br />
        1. Determine your discretionary income
        <br />
        Start with AGI (Line 11 on the 1040 Tax Form) and subtract 100% of the federal poverty
        guideline level for your family size.
        <br />
        AGI - (100% x Poverty Level) = your discretionaty income on ICR.
        <br />
        <br />
        2. Multiple the discretionary income by 20%.
        <br />
        [AGI - (100% x Poverty Level)] x (number between 20%) = annual payment.
        <br />
        <br />
        3. Divide the total from number 2 by 12 for monthly payments.
      </p>
      {/* <Heading className="my-9" title="Calculating your ICR monthly payment" />
      <Heading title="Loan Information" />
      {loanInformationLabels.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))}
      <Heading title="Personal Information" className="my-8" />
      {dataPersonalInformation.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))}

      <Heading title="Income Information" className="my-8" />
      {dataIncomeInformation.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))}
      <Heading title="Calculating your ICR monthly payment:" />
      <Paragraph text="Step 1: Calculating ICR payment based on 20% of discretionary income" />
      <div className="max-w-40">
        <SpaceBetweenLabels label1="Annual ICR Payment:" label2="$17,546.00" />
        <SpaceBetweenLabels label1="Monthly ICR Payment:" label2="$1,462.17" />
      </div>
      <Paragraph text="Given the information entered above your ICR payment will be:" />
      <div className=" font-extrabold">$1,070.84</div>
      <Paragraph text="The fixed 12-year monthly payment adjusted for income calculated in step 2 is less than the discretionary income calculation in step 1." />

      <Heading title="Additional:" />
      <p>
        discretionary income $113,546 <br />
        Annual ICR Payment: $22,709 <br />
        Monthly ICR Payment: $1,892
        <br />
        <br />
        Example of how ICR Discretionary Income is different
        <br />
        Monthly ICR Payment: $1,462.17 ICR Discretionary Income is : AGI - Poverty Guidelines
        <br />
        Then
        <br />
        20% * ICR Discretionary Income divided by 12
        <br />
        <br />
        PAYE Discretionary Income is: AGI - (1.5 * Poverty Guidelines) <br /> a. $33,382 then <br />
        b. 15.60% 10% * PAYE Discretionary Income divided by 12
      </p>

      <Heading title="Calculating your ICR monthly payment Explained:" />
      <p>
        ICR has the most complicated monthly repayment calculation. The resulting monthly payment is
        the lesser of two calculations.
        <br />
        <span className=" font-semibold text-gray-800">Step 1)</span> The easier of the calculatons
        is 20% of your discretionary income:
        <br />
        ICR Discretionary Income = Adjusted Gross Income - HHS Federal Poverty Guidelines
        <br />
        20% * ICR Discretionary Income divided by 12 = payment per month
        <br />
        <br />
        Step 1 Example: Let&apos;s say you are calculating your 2024 ICR Discretionary Income for a
        family size of 3 with a most recent 2023 AGI of $120,000. Your student loan balance is
        $100,000 at 6%.
        <br />
        <br />
        ICR Discretionary Income = $120,000 - $25,820 = $94,180
        <br />
        <br />
        Your monthly ICR payment might be 20% of your Discretionary Income divided by 12:
        <br />
        <br />
        Example ICR Payment = 0.20 * $94,180 = $18,836 annually or $18,836 / 12 = $1,569.67
        <br />
        <br />
        <span className=" font-semibold text-gray-800">Step 2)</span> The more complicated of the
        calculations has two parts:
        <br />
        <br />
        <div className="flex items-start gap-8">
          <div>
            2a) Fixed 12-year monthly payment:
            <br />
            M = (P * R) / 1-(1+R) ^ -N
            <br />
            M = monthly payment
            <br />
            P = principal balance when entering repayment
            <br />R = Monthly Interest Rate N = Number of monthy payments
          </div>
          <img src={ICRCalculations} alt="ICR-calculations" />
        </div>
        <br />
        Step 2 Example: Your student loan balance is $100,000 at 6%
        <br /> <br />
        M = (100,000 * (0.06 / 12)) / [ 1 - (1 + (0.06 / 12)) ^ -(12/12) ]
        <br />
        M = 500 / (1 - 0.4876)
        <br />
        M = $976 / mo
        <br /> <br />
        <span className=" font-semibold text-gray-800">2b)</span> The income factor multiplied by
        the fixed 12-year monthly payment
      </p>
      <Heading title="Income Percentage Factors for 2024" />
      <table className="table-auto my-8">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2 text-left">Single</th>
            <th className="px-4 py-2 text-left"></th>
            <th className="px-4 py-2 text-left" colSpan={2}>
              Married/Head of Household
            </th>
          </tr>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 text-left">AGI</th>
            <th className="px-4 py-2 text-left">% Factor</th>
            <th className="px-4 py-2 text-left">AGI</th>
            <th className="px-4 py-2 text-left">% Factor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">$13,736</td>
            <td className="border px-4 py-2">55.00%</td>
            <td className="border px-4 py-2">$13,736</td>
            <td className="border px-4 py-2">50.52%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$18,900</td>
            <td className="border px-4 py-2">57.79%</td>
            <td className="border px-4 py-2">$21,672</td>
            <td className="border px-4 py-2">56.68%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$24,319</td>
            <td className="border px-4 py-2">60.57%</td>
            <td className="border px-4 py-2">$25,826</td>
            <td className="border px-4 py-2">59.56%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$29,861</td>
            <td className="border px-4 py-2">66.23%</td>
            <td className="border px-4 py-2">$33,764</td>
            <td className="border px-4 py-2">67.79%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$35,153</td>
            <td className="border px-4 py-2">71.89%</td>
            <td className="border px-4 py-2">$41,828</td>
            <td className="border px-4 py-2">75.22%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$41,828</td>
            <td className="border px-4 py-2">80.33%</td>
            <td className="border px-4 py-2">$52,536</td>
            <td className="border px-4 py-2">87.61%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$52,536</td>
            <td className="border px-4 py-2">88.77%</td>
            <td className="border px-4 py-2">$65,889</td>
            <td className="border px-4 py-2">100.00%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$65,890</td>
            <td className="border px-4 py-2">100.00%</td>
            <td className="border px-4 py-2">$79,249</td>
            <td className="border px-4 py-2">100.00%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$79,249</td>
            <td className="border px-4 py-2">100.00%</td>
            <td className="border px-4 py-2">$99,285</td>
            <td className="border px-4 py-2">109.40%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$95,245</td>
            <td className="border px-4 py-2">111.80%</td>
            <td className="border px-4 py-2">$132,667</td>
            <td className="border px-4 py-2">125.00%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$121,958</td>
            <td className="border px-4 py-2">123.50%</td>
            <td className="border px-4 py-2">$179,409</td>
            <td className="border px-4 py-2">140.60%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$172,734</td>
            <td className="border px-4 py-2">141.20%</td>
            <td className="border px-4 py-2">$250,911</td>
            <td className="border px-4 py-2">150.00%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$198,056</td>
            <td className="border px-4 py-2">150.00%</td>
            <td className="border px-4 py-2">$410,007</td>
            <td className="border px-4 py-2">200.00%</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">$352,771</td>
            <td className="border px-4 py-2">200.00%</td>
            <td className="border px-4 py-2"></td>
            <td className="border px-4 py-2"></td>
          </tr>
        </tbody>
      </table>
      <Paragraph text="Essentially, if your taxable income is between $65,890 and $79,249 for a single or married household, then your ICR calculation will equal the fixed 12-year plan payment. Below that range and your ICR calculation will be less than the fixed 12-year plan payment. Above that range and your ICR calcuation will be more than the fixed 12-year plan payment." />
      <Paragraph
        text="51
Let's return to our previous example of a family size of 3 with a taxable income of $120,000. According to the table, the factor we need is between 109.4 and 125 and will be closer to 125 than 109.4. We do a linear interpolation to arrive at the exact factor."
      />
      <p>
        (Table, AGI) $132,667 - $99,285 = $33,382 (a)
        <br />
        <br />
        (Table, % Factor) 125% - 109.4% = 15.6% (b)
        <br />
        <br />
        $120,000 (borrower AGI) - $99,285 (Table,, AGI) = $20,715 (c)
        <br />
        <br />
        $20,715 (c) / $33,382 (a) = 62.05% (d)
        <br />
        <br />
        15.6% (b) * 62.05% (d) = 9.68% (e)
        <br />
        <br />
        109.4% (Table, % Factor) + 9.68% (e) = 119.08% (f)
        <br />
        <br />
        $976/mo (12-year plan amount) * 1.2 (f) = $1,171/mo
      </p>
      <Paragraph text="In this example, the fixed 12-year montly payment adjusted for income calculated in step 2 is less than the discretionary income calculation in step 1; thus the ICR minimum monthly payment would be $1,200/mo for the next 12 months.	" /> */}
    </div>
  );
};
