import { Button } from 'flowbite-react';

import { AuthenticationService } from '../auth';
import { PageHeader } from '../components/PageHeader';
import { useGlobalState } from '../context/AppContext';
import { Profile } from '../features/profile/Profile';
import { isClient } from '../utils';

export const ProfilePage = () => {
  const { user } = useGlobalState();
  return (
    <>
      <PageHeader
        breadcrumbs={[{ to: isClient(user) ? '/' : '/settings', text: 'Manage Profile' }]}
        rightContent={
          isClient(user) ? (
            <Button color="light" onClick={() => AuthenticationService.logout()}>
              Sign Out
            </Button>
          ) : null
        }
        back
      />
      <Profile />
    </>
  );
};
