import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { AuthenticationService, FinologyUser } from '../auth';

const ACTIONS = {
  SET_AUTHENTICATED_USER: 'SET_AUTHENTICATED_USER',
  SET_COMPANY_LOGO: 'SET_COMPANY_LOGO',
  SET_PROFILE_PICTURE: 'SET_PROFILE_PICTURE',
  PUSH_BREADCRUMB: 'PUSH_BREADCRUMB',
  POP_BREADCRUMB: 'POP_BREADCRUMB',
  COLLAPSE_SIDENAVIGATION: 'COLLAPSE_SIDENAVIGATION',
};

interface BreadCrumb {
  to: string;
  rightArrow?: boolean;
  children: ReactNode;
  back?: boolean;
}

interface State {
  user: FinologyUser | null;
  breadcrumbs: BreadCrumb[];
  collapseSideNavigation: boolean;
}

interface Actions {
  setAuthenticatedUser: (user: FinologyUser | null) => void;
  setProfilePicture: (profilePictureUrl?: string | null) => void;
  setCompanyLogo: (logoUrl?: string | null) => void;

  pushBreadcrumb: (breadcrumb: BreadCrumb) => void;
  popBreadcrumb: () => void;
  triggerCollapseSideNavigation: (value: boolean) => void;
}

const initialState: State = {
  user: null,
  breadcrumbs: [],
  collapseSideNavigation: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const searchReducer = (state = initialState, action: { payload?: any; type: string }) => {
  switch (action.type) {
    case ACTIONS.SET_AUTHENTICATED_USER: {
      return { ...state, user: action.payload };
    }
    case ACTIONS.SET_COMPANY_LOGO: {
      return { ...state, user: { ...state.user, companyLogo: action.payload } };
    }
    case ACTIONS.SET_PROFILE_PICTURE: {
      return { ...state, user: { ...state.user, profilePicture: action.payload } };
    }
    case ACTIONS.PUSH_BREADCRUMB: {
      return { ...state, breadcrumbs: [...state.breadcrumbs, action.payload] };
    }
    case ACTIONS.POP_BREADCRUMB: {
      const breadcrumbs = [...state.breadcrumbs];

      breadcrumbs.pop();

      return { ...state, breadcrumbs: breadcrumbs };
    }
    case ACTIONS.COLLAPSE_SIDENAVIGATION: {
      return { ...state, collapseSideNavigation: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const StateContext = createContext<State>(initialState);
const ActionsContext = createContext<Actions>({} as Actions);

const useGlobalState = (): State => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useState must be used within a Provider');
  }
  return context;
};

const useGlobalActions = (): Actions => {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('useActions must be used within a Provider');
  }

  return context;
};

const AppContextProvider = ({ children }: { children: JSX.Element }): ReactElement => {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  useEffect(() => {
    async function initialize() {
      const user = await AuthenticationService.getUser();

      if (!user) {
        AuthenticationService.login();

        return;
      }

      actions.setAuthenticatedUser(user);
    }

    initialize();
  }, []);

  const actions = useMemo(
    () => ({
      setAuthenticatedUser: (payload: FinologyUser | null) => {
        dispatch({ type: ACTIONS.SET_AUTHENTICATED_USER, payload });
      },
      setCompanyLogo: (payload?: string | null) => {
        dispatch({ type: ACTIONS.SET_COMPANY_LOGO, payload });
      },
      setProfilePicture: (payload?: string | null) => {
        dispatch({ type: ACTIONS.SET_PROFILE_PICTURE, payload });
      },
      pushBreadcrumb: (payload: BreadCrumb) => {
        dispatch({ type: ACTIONS.PUSH_BREADCRUMB, payload });
      },
      popBreadcrumb: () => {
        dispatch({ type: ACTIONS.POP_BREADCRUMB });
      },
      triggerCollapseSideNavigation: (payload?: boolean) => {
        dispatch({ type: ACTIONS.COLLAPSE_SIDENAVIGATION, payload });
      },
    }),
    []
  );

  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions as Actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
};

export { useGlobalActions, useGlobalState, AppContextProvider };
