import dayjs from 'dayjs';
import { Button, Card, Spinner } from 'flowbite-react';
import { FormikErrors, Form, Formik } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../components/forms/FormErrorNotification';
import { useGlobalState } from '../../../context/AppContext';
import {
  ClientFullUpsertInput,
  MaritalStatus,
  TaxFilingType,
  Title,
  useFullUpsertClientMutation,
  useGetEditClientFormDetailsLazyQuery,
  useGetEditClientFormDetails_SpouseLazyQuery,
} from '../../../graphql/generated';
import { useScrollIntoView } from '../../../hooks/useScrollIntoView';
import useFinologyTranslation from '../../../translations/useFinologyTranslation';
import { notification } from '../../../util/notification.utils';
import { isClient } from '../../../utils';
import { ClientSelectorField } from '../../formComponents/ClientSelectorField';
import { CurrencyField } from '../../formComponents/CurrencyField';
import { DateField } from '../../formComponents/DateField';
import FutureChildrenInput from '../../formComponents/FutureChildrenInput';
import { TextInputField } from '../../formComponents/InputField';
import { PercentageField } from '../../formComponents/PercentageField';
import { ProfessionSelectField } from '../../formComponents/ProfessionSelectField';
import SelectField from '../../formComponents/SelectField';
import { TextAreaField } from '../../formComponents/TextAreaField';

const STATE_TAX_RATE = [
  { value: 0.05, id: 'AL', label: 'AL-5.00%' },
  { value: 0, id: 'AK', label: 'AK-0.00%' },
  { value: 0.0454, id: 'AZ', label: 'AZ-4.54%' },
  { value: 0.069, id: 'AR', label: 'AR-6.90%' },
  { value: 0.131, id: 'CA', label: 'CA-13.10%' },
  { value: 0.0463, id: 'CO', label: 'CO-4.63%' },
  { value: 0.0699, id: 'CT', label: 'CT-6.99%' },
  { value: 0.1075, id: 'DC', label: 'DC-10.75%' },
  { value: 0.066, id: 'DE', label: 'DE-6.60%' },
  { value: 0, id: 'FL', label: 'FL-0.00%' },
  { value: 0.0575, id: 'GA', label: 'GA-5.75%' },
  { value: 0.11, id: 'HI', label: 'HI-11.00%' },
  { value: 0.0853, id: 'IA', label: 'IA-8.53%' },
  { value: 0.0693, id: 'ID', label: 'ID-6.93%' },
  { value: 0.0495, id: 'IL', label: 'IL-4.95%' },
  { value: 0.0332, id: 'IN', label: 'IN-3.32%' },
  { value: 0.057, id: 'KS', label: 'KS-5.70%' },
  { value: 0.05, id: 'KY', label: 'KY-5.00%' },
  { value: 0.06, id: 'LA', label: 'LA-6.00%' },
  { value: 0.0575, id: 'MD', label: 'MD-5.75%' },
  { value: 0.0715, id: 'ME', label: 'ME-7.15%' },
  { value: 0.0505, id: 'MA', label: 'MA-5.05%' },
  { value: 0.0425, id: 'MI', label: 'MI-4.25%' },
  { value: 0.0985, id: 'MN', label: 'MN-9.85%' },
  { value: 0.05, id: 'MS', label: 'MS-5.00%' },
  { value: 0.054, id: 'MO', label: 'MO-5.40%' },
  { value: 0.069, id: 'MT', label: 'MT-6.90%' },
  { value: 0.0475, id: 'NC', label: 'NC-4.75%' },
  { value: 0.029, id: 'ND', label: 'ND-2.90%' },
  { value: 0.0684, id: 'NE', label: 'NE-6.84%' },
  { value: 0.05, id: 'NH', label: 'NH-5.00%' },
  { value: 0.1075, id: 'NJ', label: 'NJ-10.75%' },
  { value: 0.049, id: 'NM', label: 'NM-4.90%' },
  { value: 0.029, id: 'NO', label: 'NO-2.90%' },
  { value: 0.0, id: 'NV', label: 'NV-0.00%' },
  { value: 0.0882, id: 'NY', label: 'NY-8.82%' },
  { value: 0.05, id: 'OH', label: 'OH-5.00%' },
  { value: 0.05, id: 'OK', label: 'OK-5.00%' },
  { value: 0.099, id: 'OR', label: 'OR-9.90%' },
  { value: 0.0307, id: 'PA', label: 'PA-3.07%' },
  { value: 0.33, id: 'PR', label: 'PR-33.00%' },
  { value: 0.0599, id: 'RI', label: 'RI-5.99%' },
  { value: 0.07, id: 'SC', label: 'SC-7.00%' },
  { value: 0.0, id: 'SD', label: 'SD-0.00%' },
  { value: 0.02, id: 'TN', label: 'TN-2.00%' },
  { value: 0, id: 'TX', label: 'TX-0.00%' },
  { value: 0.0495, id: 'UT', label: 'UT-4.95%' },
  { value: 0.0875, id: 'VT', label: 'VT-8.75%' },
  { value: 0.0575, id: 'VA', label: 'VA-5.75%' },
  { value: 0, id: 'WA', label: 'WA-0.00%' },
  { value: 0.065, id: 'WV', label: 'WV-6.50%' },
  { value: 0.0765, id: 'WI', label: 'WI-7.65%' },
  { value: 0, id: 'WY', label: 'WY-0.00%' },
];

type FormValues = { spouse?: number | null } & Omit<
  ClientFullUpsertInput,
  'taxFilingType' | 'maritalStatus'
> & {
    maritalStatus?: MaritalStatus;
    taxFilingType?: TaxFilingType;
  };

const PanelHeader = ({ children }: { children: ReactNode }) => {
  return <span className="uppercase">{children}</span>;
};

export const UpsertClientForm = () => {
  const { search } = useLocation();
  const { user } = useGlobalState();
  const params = new URLSearchParams(search);
  const clientId = params.get('clientId');
  const spouseId = params.get('spouseId');
  const { t } = useFinologyTranslation();
  const parsedClientId = clientId ? Number.parseInt(clientId) : null;
  const parsedSpouseId = spouseId ? Number.parseInt(spouseId) : null;

  const [initialValues, setInitialValues] = useState<FormValues | undefined>();
  const { scrollIntoId: formErrors, setScrollIntoId: setFormErrors } =
    useScrollIntoView('form__field_');

  const [lazyClientQuery] = useGetEditClientFormDetailsLazyQuery();
  const [lazySpouseQuery] = useGetEditClientFormDetails_SpouseLazyQuery();
  const [upsertClient] = useFullUpsertClientMutation();

  const { closeTopFlyout } = useFlyoutContext();

  useEffect(() => {
    const load = async () => {
      if (parsedClientId) {
        const { data } = await lazyClientQuery({ variables: { clientId: parsedClientId } });

        if (!data || !data.client) return;

        const { client } = data;

        setInitialValues({
          id: client.id,

          firstName: client.firstName,
          lastName: client.lastName,
          middleName: client.middleName,
          title: client.title,

          email: client.email,
          homePhoneNumber: client.homePhoneNumber,
          cellPhoneNumber: client.cellPhoneNumber,

          dateOfBirth: client.dateOfBirth ? dayjs.utc(client.dateOfBirth) : null,
          occupation: client.occupation,
          profession: client.profession,

          streetAddress: client.streetAddress,
          streetUnitNumber: client.streetUnitNumber,

          city: client.city,
          stateCode: client.stateCode!,
          zipCode: client.zipCode,

          salary: client.salary,
          salaryIncreaseRate: client.salaryIncreaseRate,
          stateTaxRate: STATE_TAX_RATE.find((x) => x.id === client.stateCode)?.id,

          investmentReturn: client.investmentReturn,
          federalLoanMonthlyPayment: client.federalLoanMonthlyPayment,

          taxFilingType: client.taxFilingType!,
          maritalStatus: client.maritalStatus!,
          spouse: client.spouse?.id,

          firstChildBirthYear: client.firstChildBirthYear,
          secondChildBirthYear: client.secondChildBirthYear,
          thirdChildBirthYear: client.thirdChildBirthYear,
          fourthChildBirthYear: client.fourthChildBirthYear,
          fifthChildBirthYear: client.fifthChildBirthYear,
          sixthChildBirthYear: client.sixthChildBirthYear,
          seventhChildBirthYear: client.seventhChildBirthYear,
          eighthChildBirthYear: client.eighthChildBirthYear,
          ninthChildBirthYear: client.ninthChildBirthYear,
          tenthChildBirthYear: client.tenthChildBirthYear,

          otherDependentsNumber: client.otherDependentsNumber,
          notes: client.notes,
          dependentsNumber: client.dependentsNumber,
        });
      } else if (parsedSpouseId) {
        const { data } = await lazySpouseQuery({ variables: { spouseId: parsedSpouseId } });

        if (!data || !data.client) return;

        setInitialValues({
          firstName: '',
          lastName: '',
          maritalStatus: MaritalStatus.Married,
          taxFilingType: TaxFilingType.MarriedFilingJointly,
          stateCode: 'CA',
          spouse: parsedSpouseId,
        });
      } else {
        setInitialValues({
          firstName: '',
          lastName: '',
          stateCode: 'CA',
          spouse: null,
        });
      }
    };

    load();
  }, [clientId]);

  if (!initialValues) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={{
        firstName: 'Required',
        lastName: 'Required',
        zipCode: 'Required',
        maritalStatus: 'Required',
        taxFilingType: 'Required',
      }}
      onSubmit={async (v, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        const input: ClientFullUpsertInput = {
          id: v.id,

          firstName: v.firstName,
          lastName: v.lastName,
          middleName: v.middleName,
          title: v.title,

          email: v.email,
          homePhoneNumber: v.homePhoneNumber,
          cellPhoneNumber: v.cellPhoneNumber,

          dateOfBirth: v.dateOfBirth,
          occupation: v.occupation,
          profession: v.profession,

          streetAddress: v.streetAddress,
          streetUnitNumber: v.streetUnitNumber,

          city: v.city,
          stateCode: v.stateCode,
          zipCode: v.zipCode,

          salary: v.salary,
          salaryIncreaseRate: v.salaryIncreaseRate,
          stateTaxRate: STATE_TAX_RATE.find((x) => x.id === v.stateTaxRate)?.value,

          investmentReturn: v.investmentReturn,
          loanStartDate: v.loanStartDate,
          federalLoanMonthlyPayment: v.federalLoanMonthlyPayment,

          didIncomeSignificantlyChangeSinceLastFiling:
            v.didIncomeSignificantlyChangeSinceLastFiling,

          didClientFileFederalIncomeTaxInTheLastTwoYears:
            v.didClientFileFederalIncomeTaxInTheLastTwoYears,

          didSpouseIncomeSignificantlyChangeSinceLastFiling:
            v.didSpouseIncomeSignificantlyChangeSinceLastFiling,

          isRecentTaxFilingFiledJointlyWithSpouse: v.isRecentTaxFilingFiledJointlyWithSpouse,
          hasSchoolClosedBeforeReceivingDegree: v.hasSchoolClosedBeforeReceivingDegree,
          isTotallyAndPermanentlyDisabled: v.isTotallyAndPermanentlyDisabled,
          areLoansResultOfIdentityTheft: v.areLoansResultOfIdentityTheft,

          isEmployed: v.isEmployed,
          employerName: v.employerName,
          organizationType: v.organizationType,
          organizationName: v.organizationName,
          federalEmployerIdentificationNumber: v.federalEmployerIdentificationNumber,
          employerAddress: v.employerAddress,
          employerWebsite: v.employerWebsite,
          employmentStartDate: v.employmentStartDate,
          employmentEndDate: v.employmentEndDate,
          employmentStatus: v.employmentStatus,
          workHoursPerWeek: v.workHoursPerWeek,
          employerPurposes: v.employerPurposes,

          taxFilingType: v.taxFilingType!,
          maritalStatus: v.maritalStatus!,
          spouseId: v.spouse,

          firstChildBirthYear: v.firstChildBirthYear,
          secondChildBirthYear: v.secondChildBirthYear,
          thirdChildBirthYear: v.thirdChildBirthYear,
          fourthChildBirthYear: v.fourthChildBirthYear,
          fifthChildBirthYear: v.fifthChildBirthYear,
          sixthChildBirthYear: v.sixthChildBirthYear,
          seventhChildBirthYear: v.seventhChildBirthYear,
          eighthChildBirthYear: v.eighthChildBirthYear,
          ninthChildBirthYear: v.ninthChildBirthYear,
          tenthChildBirthYear: v.tenthChildBirthYear,

          dependentsNumber: v.dependentsNumber,
          otherDependentsNumber: v.otherDependentsNumber,
          notes: v.notes,
          isPslfActive: v.isPslfActive,
          certificationDate: v.certificationDate,
        };

        await upsertClient({
          variables: { input: input },
          refetchQueries: ['GetClients', 'GetClientDashboard'],
        });

        if (clientId)
          notification.success({
            message: `${t('notifications.client_updated')}`,
            placement: 'bottom-center',
          });
        else
          notification.success({
            message: `${t('notifications.client_created')}`,
            placement: 'bottom-center',
          });

        closeTopFlyout();
      }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};
        if (!values.firstName) {
          errors.firstName = 'Required';
        }
        if (!values.lastName) {
          errors.lastName = 'Required';
        }
        
        if (!values.zipCode) {
          errors.zipCode = 'Required';
        }

        if (!values.maritalStatus) {
          errors.maritalStatus = 'Required';
        }
        if (!values.taxFilingType) {
          errors.taxFilingType = 'Required';
        }

        if (values.firstName != undefined && values.firstName.length > 100) {
          errors.firstName = 'First Name must be less than 100 characters';
        }

        if (values.lastName != undefined && values.lastName.length > 100) {
          errors.lastName = 'Last Name must be less than 100 characters';
        }

        const phoneRegExp = /^(\(\d{3}\)\s?|\d{3}[-\s]?)\d{3}[-\s]?\d{4}$/;

        if (values.homePhoneNumber && !phoneRegExp.test(values.homePhoneNumber)) {
          errors.homePhoneNumber = 'Home Phone number is not valid';
        }
        if (values.cellPhoneNumber && !phoneRegExp.test(values.cellPhoneNumber)) {
          errors.cellPhoneNumber = 'Cell Phone number is not valid';
        }

        if (values.salaryIncreaseRate === 0) {
          errors.salaryIncreaseRate = 'Annual Salary % Increase cannot be 0';
        }

        return errors;
      }}
    >
      {({ values, isSubmitting, errors, initialErrors, setFieldValue }) => {
        const flyoutHeaderLabel = isClient(user) ? 'My Details' : `Edit ${t('borrower.uppercase')}`;

        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'upsert-client-form',
                children: 'Save',
                disabled: isSubmitting,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                },
                onClick: () => {
                  setFormErrors(!errors ? Object.keys(initialErrors) : Object.keys(errors));
                },
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={clientId ? flyoutHeaderLabel : `New ${t('borrower.uppercase')}`}
            />

            <Form id="upsert-client-form" className="pb-16">
              <div className="flex-1 text-gray-700 text-sm font-bold">Contact Information</div>
              <div className="grid grid-cols-6 gap-y-2 gap-x-9 my-4">
                <TextInputField name="firstName" placeholder="First Name*" className="col-span-2" />

                <TextInputField name="lastName" placeholder="Last Name*" className="col-span-2" />
                <TextInputField name="middleName" placeholder="Middle Name" />

                <SelectField name="title" label="Title">
                  <option value={Title.Dr}>Dr</option>
                  <option value={Title.Miss}>Miss</option>
                  <option value={Title.Mr}>Mr</option>
                  <option value={Title.Mrs}>Mrs</option>
                  <option value={Title.Ms}>Ms</option>
                </SelectField>

                <TextInputField
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="col-span-2"
                />
                <TextInputField
                  name="homePhoneNumber"
                  placeholder="Home Phone"
                  className="col-span-2"
                />
                <TextInputField
                  name="cellPhoneNumber"
                  placeholder="Cell Phone"
                  className="col-span-2"
                />

                <DateField
                  name="dateOfBirth"
                  placeholder="Date of Birth"
                  className="col-span-2"
                  sizing="md"
                />

                <ProfessionSelectField
                  name="occupation"
                  label="Occupation"
                  className="col-span-2"
                ></ProfessionSelectField>

                <TextInputField name="streetAddress" placeholder="Address" className="col-span-4" />
                <TextInputField name="streetUnitNumber" placeholder="Unit" />

                <TextInputField name="city" placeholder="City" className="col-span-2" />
                <SelectField
                  name="stateCode"
                  label="State"
                  allowClear={false}
                  onChange={(selectedValue) => {
                    const state = STATE_TAX_RATE.find((x) => x.id === selectedValue);
                    setFieldValue('stateCode', selectedValue);
                    setFieldValue('stateTaxRate', state?.id);
                  }}
                >
                  <option value="AL"> Alabama</option>
                  <option value="AK"> Alaska</option>
                  <option value="AZ"> Arizona</option>
                  <option value="AR"> Arkansas</option>
                  <option value="CA"> California</option>
                  <option value="CO"> Colorado</option>
                  <option value="CT"> Connecticut</option>
                  <option value="DE"> Delaware</option>
                  <option value="DC">District of Columbia (Washington DC)</option>
                  <option value="FL"> Florida</option>
                  <option value="GA"> Georgia</option>
                  <option value="HI"> Hawaii</option>
                  <option value="ID"> Idaho</option>
                  <option value="IL"> Illinois</option>
                  <option value="IN"> Indiana</option>
                  <option value="IA"> Iowa</option>
                  <option value="KS"> Kansas</option>
                  <option value="KY"> Kentucky</option>
                  <option value="LA"> Louisiana</option>
                  <option value="ME"> Maine</option>
                  <option value="MD"> Maryland</option>
                  <option value="MA"> Massachusetts</option>
                  <option value="MI"> Michigan</option>
                  <option value="MN"> Minnesota</option>
                  <option value="MS"> Mississippi</option>
                  <option value="MO"> Missouri</option>
                  <option value="MT"> Montana</option>
                  <option value="NE"> Nebraska</option>
                  <option value="NV"> Nevada</option>
                  <option value="NH"> New Hampshire</option>
                  <option value="NJ"> New Jersey</option>
                  <option value="NM"> New Mexico</option>
                  <option value="NY"> New York</option>
                  <option value="NC"> North Carolina</option>
                  <option value="ND"> North Dakota</option>
                  <option value="OH"> Ohio</option>
                  <option value="OK"> Oklahoma</option>
                  <option value="OR"> Oregon</option>
                  <option value="PA"> Pennsylvania</option>
                  <option value="PR"> Puerto Rico</option>
                  <option value="RI"> Rhode Island</option>
                  <option value="SC"> South Carolina</option>
                  <option value="SD"> South Dakota</option>
                  <option value="TN"> Tennessee</option>
                  <option value="TX"> Texas</option>
                  <option value="US"> US Territory</option>
                  <option value="UT"> Utah</option>
                  <option value="VT"> Vermont</option>
                  <option value="VA"> Virginia</option>
                  <option value="WA"> Washington</option>
                  <option value="WV"> West Virginia</option>
                  <option value="WI"> Wisconsin</option>
                  <option value="WY"> Wyoming</option>
                </SelectField>
                <TextInputField name="zipCode" placeholder="ZIP Code" className="col-span-2" />
              </div>
              <div className="flex-1 text-gray-700 text-sm font-bold">Income Information</div>
              <div className="grid grid-cols-3 gap-y-2 gap-x-9 my-4">
                <CurrencyField name="salary" placeholder="Annual Salary" sizing="md" />

                <PercentageField
                  name="salaryIncreaseRate"
                  placeholder="Annual Salary % Increase"
                  sizing="md"
                />
                <SelectField
                  allowClear={false}
                  name="stateTaxRate"
                  label="State Tax Rate"
                  onChange={(selectedValue) => {
                    setFieldValue('stateCode', selectedValue);
                    setFieldValue('stateTaxRate', selectedValue);
                  }}
                >
                  {STATE_TAX_RATE.map((stateTaxRate) => (
                    <option key={stateTaxRate.id} value={stateTaxRate.id}>
                      {stateTaxRate.label}
                    </option>
                  ))}
                </SelectField>

                <CurrencyField
                  name="federalLoanMonthlyPayment"
                  placeholder="Federal Loan Monthly Payment"
                  sizing="md"
                />
                <PercentageField
                  name="investmentReturn"
                  tooltip="% rate of return used to calculate the annual asset increase to save up for the pro forma tax at the end of the IDR plan"
                  placeholder="Investment Return"
                  sizing="md"
                />
              </div>
              <div className="flex-1 text-gray-700 text-sm font-bold">Family</div>
              <div className="grid grid-cols-6 gap-y-2 gap-x-9 mt-4">
                <SelectField
                  allowClear={false}
                  className="col-span-2"
                  name="maritalStatus"
                  label="Marital Status*"
                >
                  <option value={MaritalStatus.Divorced}>Divorced </option>
                  <option value={MaritalStatus.LifePartner}>Life Partner </option>
                  <option value={MaritalStatus.Married}>Married </option>
                  <option value={MaritalStatus.Separated}>Separated </option>
                  <option value={MaritalStatus.Single}>Single </option>
                  <option value={MaritalStatus.Widowed}>Widowed </option>
                </SelectField>

                <SelectField
                  allowClear={false}
                  className="col-span-2"
                  name="taxFilingType"
                  label="Tax Filing Type*"
                >
                  <option value={TaxFilingType.HeadOfHousehold}>Head Of Household </option>
                  <option value={TaxFilingType.MarriedFilingJointly}>
                    Married Filing Jointly{' '}
                  </option>
                  <option value={TaxFilingType.MarriedFilingSeparately}>
                    Married Filing Separately{' '}
                  </option>
                  <option value={TaxFilingType.Single}>Single </option>
                </SelectField>

                {isClient(user) ? null : (
                  <ClientSelectorField
                    className="col-span-2"
                    name="spouse"
                    placeholder="Spouse"
                    selectPlaceholder={'Select Spouse'}
                    idToExclude={parsedClientId}
                    onValueChanged={async (selectedId) => {
                      if (selectedId) {
                        if (values.maritalStatus == MaritalStatus.Single)
                          setFieldValue('maritalStatus', MaritalStatus.Married);
                      } else {
                        setFieldValue('maritalStatus', MaritalStatus.Single);
                      }
                    }}
                  />
                )}

                <div className="grid grid-cols-6 gap-y-2 gap-x-9 col-start-1 col-span-6">
                  <SelectField
                    type="number"
                    className="col-span-2"
                    name="dependentsNumber"
                    label="Number of Children"
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </SelectField>

                  <SelectField
                    type="number"
                    className="col-span-2"
                    name="otherDependentsNumber"
                    label="Other Qualifying Dependents"
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </SelectField>

                  <FutureChildrenInput className="col-span-2" />

                  <TextAreaField
                    placeholder="Notes"
                    name="notes"
                    className="col-span-6"
                  ></TextAreaField>

                  <Card className="col-span-6 mt-4 text-gray-700">
                    <h4 className="underline">Qualifying Dependents</h4>
                    <p>
                      To be a qualifying dependent, they must be a U.S. Citizens, U.S. national, or
                      a resident alien, and have a taxpayer identification number, and they must
                      have lived with the {t('borrower.lowercase')} for the entire year, cannot be
                      claimed by another person on their taxes, and also have earned less than
                      $4,150 for the entire year.
                    </p>
                    <h4 className="underline">Qualifying Child</h4>
                    <p>
                      To be a qualifying child, they must have lived with your{' '}
                      {t('borrower.lowercase')} for at least 6 months of the year, be 18 or younger
                      and not a full-time student; or 24 years of age or younger if a full-time
                      student.
                    </p>
                    <h4 className="underline">Planned children</h4>
                    <p>
                      Identifying years potential children will be born to deliver a pro forma of
                      the loan repayment with the new family size.
                    </p>
                  </Card>
                </div>
              </div>
              <div className="flex justify-end">
                <Button
                  form="upsert-client-form"
                  disabled={isSubmitting}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  type="submit"
                  className="mt-4"
                >
                  Save
                </Button>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export const UpsertClientFlyout = () => {
  return (
    <Flyout
      id={`upsert-client`}
      requiredParams={['clientId', 'spouseId']}
      content={<UpsertClientForm />}
    ></Flyout>
  );
};
