import { useEffect, useState } from 'react';

function useDebouncedState<T>(
  initialValue: T,
  delay?: number,
  deps?: any
): [T, T, React.Dispatch<React.SetStateAction<T>>] {
  const [currentValue, setCurrentValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(currentValue);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(currentValue), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [...(deps ? deps : []), currentValue, delay]);

  return [currentValue, debouncedValue, setCurrentValue];
}

export default useDebouncedState;
