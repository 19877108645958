import { useState } from 'react';
import { FaSort } from 'react-icons/fa6';

import { SortEnumType } from '../graphql/generated';

interface SortBy {
  [key: string]: SortEnumType;
}

interface SortIconProps {
  type?: SortEnumType;
}

const SortIcon = ({ type }: SortIconProps) => {
  return (
    <>
      {type === SortEnumType.Desc && <span className="ml-2 text-xxs text-gray-400">▲</span>}
      {type === SortEnumType.Asc && <span className="ml-2 text-xxs text-gray-400">▼</span>}
    </>
  );
};

const prepareSortBy = (currentState: { [key: string]: SortEnumType }, field: string) => {
  if (currentState) {
    return {
      [field]: currentState[field] === SortEnumType.Asc ? SortEnumType.Desc : SortEnumType.Asc,
    };
  } else {
    return {
      [field]: SortEnumType.Desc,
    };
  }
};

export function useTableSortBy<T>(initialValue: SortBy) {
  const [sortBy, setSortBy] = useState<{ [key: string]: SortEnumType }>(initialValue);

  const triggerRefetch = (newSortBy: any) => {
    setSortBy((current) => ({ ...current, newSortBy }));
  };

  const prepareSortColumns = (
    field: string,
    label: string,
    triggerRefetch: (
      x: (currentState: { [key: string]: SortEnumType }) => { [key: string]: SortEnumType }
    ) => void
  ) => {
    return {
      title: (
        <div
          onClick={() => {
            triggerRefetch((currentState: { [key: string]: SortEnumType }) =>
              prepareSortBy(currentState, field)
            );
            setSortBy((currentState: { [key: string]: SortEnumType }) =>
              prepareSortBy(currentState, field)
            );
          }}
          className="flex gap-1 flex-row whitespace-nowrap"
        >
          {label}
          {sortBy[field] ? <SortIcon type={sortBy[field]} /> : <FaSort className="text-gray-400" />}
        </div>
      ),
    };
  };

  return { sortBy, setSortBy, prepareSortColumns, triggerRefetch };
}
