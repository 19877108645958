import { Dropdown } from 'flowbite-react';
import { useLocation } from 'react-router';

import EmptySearchUsers from '../../../assets/empty_search-users.svg';
import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FinologyTable } from '../../../components/table/FinologyTable';
import { ClientRepaymentPdfView, useGetRepaymentPdfsLazyQuery } from '../../../graphql/generated';
import { useApiClient } from '../../../hooks/useApiClient';
import openDocument from '../../../util/pdf.utils';

import type { ColumnsType } from '../../../components/table/FinologyTable';

export const RepaymentPdfs = ({ clientId }: { clientId: number }) => {
  const { get } = useApiClient();

  const [getRepaymentPdfs, query] = useGetRepaymentPdfsLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const downloadDocument = async (recordId: number) => {
    const { data: pdf } = await get(
      `RepaymentPdf/DownloadRepaymentPdf?repaymentPdfId=${recordId}`,
      {
        responseType: 'arraybuffer',
      }
    );

    openDocument(`Repayment Plan.pdf`, pdf);
  };

  const columns: ColumnsType<ClientRepaymentPdfView> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div onClick={async () => await downloadDocument(record.id)}>{text}</div>
      ),
    },
    {
      title: 'Tax Filling Status',
      dataIndex: 'taxFillingStatus',
      key: 'taxFillingStatus',
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
    },
    {
      title: 'Income Level',
      dataIndex: 'incomeLevel',
      key: 'incomeLevel',
    },
    {
      title: 'Simulation Date',
      dataIndex: 'simulationDate',
      key: 'simulationDate',
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <Dropdown label="...">
          <Dropdown.Item onClick={async () => await downloadDocument(record.id)}>
            Download
          </Dropdown.Item>
        </Dropdown>
      ),
    },
  ];

  async function load(skip?: number, take?: number, filterValue?: string) {
    const { data } = await getRepaymentPdfs({
      variables: {
        filter: filterValue
          ? {
              clientId: { eq: clientId },
              or: [{ name: { contains: filterValue } }],
            }
          : {
              clientId: { eq: clientId },
            },
        skip: skip,
        take: take,
      },
    });

    return {
      rows: data!.repaymentPdfs!.items as any[],
      totalCount: data!.repaymentPdfs!.totalCount,
    };
  }

  return (
    <>
      <div className="flex">
        <div className="grow">
          <FinologyTable
            emptyText={(filter) => {
              return (
                <div className="flex gap-16 text-fino-grey">
                  <img alt="Users Image" src={EmptySearchUsers} height={200} width={200} />

                  {!filter ? (
                    <>
                      <span>
                        There are no simulations available. Ask your advisor to publish simulations
                        to visualize them here.
                      </span>
                    </>
                  ) : (
                    <span>No PDFS found that match your search criteria.</span>
                  )}
                </div>
              );
            }}
            searchVisible={true}
            columns={columns}
            dataSourcePromise={load}
            reloadDependency={query.previousData}
            searchPlaceholder="Search for pdfs..."
            // TODO TYPES
          />
        </div>
      </div>
    </>
  );
};

const FlyoutContent = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const clientId = Number.parseInt(params.get('clientId')!);

  const { closeTopFlyout } = useFlyoutContext();

  return (
    <>
      <FlyoutHeader
        secondaryButton={{
          onClick: closeTopFlyout,
          children: 'Close',
        }}
        label={'Published Documents'}
      />
      <RepaymentPdfs clientId={clientId} />
    </>
  );
};

export const RepaymentPdfsFlyout = () => {
  return (
    <Flyout
      id={`client-published-documents`}
      requiredParams={['clientId']}
      content={<FlyoutContent />}
    ></Flyout>
  );
};
