import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';

export const IBR2014Calculation = () => {
  const loanInformation = [
    { label1: 'Spouse 1 Student Loan Balance (Higher of both debt amounts)', label2: '$310,000' },
    {
      label1: 'Spouse 2 Student Loan Balance, if applicable (Lower of both debt amounts)',
      label2: '$0',
    },
    { label1: 'Average Interest Rate', label2: '5.75%' },
    { label1: 'Inflation Rate Assumption', label2: '3.00%' },
    { label1: 'Paying Loan since what year?', label2: '2013' },
    { label1: 'Do you work for a not-for-profit or government employer?', label2: 'No' },
    { label1: 'Did you borrow before October 1, 2007?', label2: 'No' },
    { label1: 'What percent of your loans are from grad school?', label2: '0%' },
    { label1: 'Total Years of Repayment for the ICR plan', label2: '25' },
    { label1: 'Number of Years in Repayment thus far', label2: '11' },
    { label1: 'Term of loan (yrs):', label2: '20' },
    { label1: 'Installments per year:', label2: '12' },
  ];

  const personalInformation = [
    { label1: 'Where do you live', label2: '48 Contiguous States' },
    { label1: 'Current Family Size', label2: '1' },
    { label1: 'Tax Filing Status when Married', label2: 'Married Filing Jointly' },
    { label1: 'Poverty Level', label2: '$15,060' },
  ];

  const incomeInformation = [
    { label1: 'What is your expected AGI for 2024?', label2: '$100,000' },
    { label1: "What is your spouse's expected AGI for 2024?", label2: '' },
    { label1: 'What is your expected salary growth rate?', label2: '3.00%' },
    { label1: "What is your spouse's expected salary growth rate?", label2: '' },
    { label1: 'Your discretionary income is...', label2: '$77,410.00' },
  ];

  const ibrCalculation = [
    { label1: 'Calculating IBR, 2014 payment based on 10% of discretionary income', label2: '' },
    { label1: 'Annual PAYE Payment:', label2: '$7,741.00' },
    { label1: 'Monthly PAYE Payment:', label2: '$645.08' },
  ];

  return (
    <div className="text-gray-500 p-6">
      <Paragraph text="IBR is a federal income-driven repayment plan for student loans that first became eligible to borrowers in October 2009. In 2014, IBR was updated by law for new borrowers as of July 1, 2014. This is sometimes referred to as New IBR.	" />
      <Paragraph text="Payments under IBR 2014 are based on your income and are made for a maximum of 240 monthly payments over 20 years. Any amount remaining after 240 monthly payments is forgiven.	" />
      <Heading title="Two factors determine your eligibility to repay under IBR 2014:" />
      <Paragraph text="--- New Borrower: Only Direct loans disbursed to new borrowers as of July 1, 2014 qualify for payment under IBR 2014. Federal Perkins, Loans for Disadvantaged Students, and Health Professions Student Loans are also eligible if consolidated into a Federal Direct Consolidation loan, provided the borrower still meets the IBR, 2014 new borrower requirement. Check studentaid.gov to confirm your loan types. Upload your student aid data file into the VIN Foundation My Student Loans tool to determine your income-driven repayment plan eligibility.	" />
      <Paragraph text="--- Partial financial hardship: You have a partial financial hardship (PFH) if the payments due under IBR 2014 are less than the payments that would be due under a standard 10-year repayment plan. A rule of thumb: If your student debt exceeds your income, you likely demonstrate a PFH.	" />
      <Paragraph text="IBR 2014 vs. PAYE: Those who qualify for IBR 2014 should also qualify for PAYE. If you qualify for PAYE, choose PAYE over IBR 2014. PAYE and IBR 2014 are almost identical plans except PAYE caps the amount of unpaid interest that can be capitalized, ie added to your principal balance, at 10% of your starting repayment balance. IBR does not cap the amount of unpaid interest that can be added to your principal. The unpaid interest capitalization cap that PAYE provides acts as additional insurance against a growing principal balance and makes PAYE a better plan than IBR 2014.	" />
      <p>
        How is a Basic IBR Payment Calculated?
        <br />
        Income Based Repayment Calculations depend on your income.
        <br />
        While the definition of income can change depending on the loan type, for student loans
        Adjusted Gross Income (AGI) is what matters.
        <br />
        Adjusted Gross Income
        <br />
        Example:
        <br />
        Bob&apos;s Income:
        <br />
        1. $50,000 salary/wages
        <br />
        2. $12,000 in rental income
        <br />
        3. $8,500 wages earned as a part-time Uber driver
        <br />
        4. $500 from interest from bonds
        <br />
        Gross Income = $71,000
        <br />
        <br />
        Adjustment from his gross income:
        <br />
        1. $250 in educator expenses
        <br />
        2. $2,500 in student loan interest
        <br />
        Adjustments = $2,750
        <br />
        <br />
        Subtracting allowable adjustments ($2,750) from the Total Income ($71,000), Bob&apos;s
        Adjusted Gross Income (AGI) is $68,250
        <br />
        <br />
        The formula used will change based on what IDR plan you choose.
        <br />
        Start with the general income-based repayment formula
        <br />
        1. Determine your discretionary income
        <br />
        Start with AGI (Line 11 on the 1040 Tax Form) and subtract 150% of the federal poverty
        guideline level for your family size.
        <br />
        AGI - (150% x Poverty Level) = your discretionaty income on IBR.
        <br />
        <br />
        2. Determine if loans are 100% Undergraduate loans or a combination of Undergraduate and
        Graduate Loans.
        <br />
        If loans are all Graduate Loans or a combination of both kinds, multiply discretionary
        income by 15% for IBR.
        <br />
        [AGI - (150% x Poverty Level)] x 10% = annual payment for New IBR
        <br />
        <br />
        3. Divide the total from number 2 by 12 for monthly payments.
      </p>
      {/* <Heading title="Calculating your IBR, 2014 monthly payment" />
      {loanInformation.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))}
      <br />
      {personalInformation.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))}
      <br />
      {incomeInformation.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))}
      <br />
      {ibrCalculation.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))} */}
      <Heading className="my-8" title="Calculating your IBR, 2014 monthly payment Explained:" />
      <p>
        The IBR, 2014 payment is 10% of your Discretionary Income, a government measure based on
        poverty guidelines.
        <br />
        <br />
        For the IBR, 2014 payment your Discretionary Income is the difference between your taxable
        income and 150 percent of the HHS Poverty Guideline amout for your family size and state.
        <br />
        <br />
        Discretionary Income = Adjusted Gross Income - (150% x HHS federal poverty guidelines)
        <br />
        <br />
        Using the above formula to calculate your 2024 Disretionary Income for a family size of one,
        living in the 48 Contiguous States and a taxable income of $100,000:
        <br />
        <br />
        Discretionary Income = $100,000 - (1.5 x $15,060) = $77,410
        <br />
        <br />
        The IBR, 2014 monthly payment 10% of your Discretionary Income divided by 12
        <br />
        <br />
        IBR, 2014 = 0.10 x $77,410 = $7,741 per year or $645.08 per month
      </p>
    </div>
  );
};
