import { useTranslation } from 'react-i18next';

import { useGlobalState } from '../context/AppContext';

export const useFinologyTranslation = () => {
  const { user } = useGlobalState();
  const { t } = useTranslation();

  return {
    t: (key: string, options?: Record<string, unknown>) => {
      return t(key, { ...options, context: user?.accountType });
    },
  };
};

export default useFinologyTranslation;
