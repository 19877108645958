export const SpaceBetweenLabels = ({
  label1,
  label2,
  color,
  bold,
}: {
  label1: string;
  label2: string;
  bold?: boolean;
  color?: string;
}) => {
  return (
    <div className={`flex justify-between`}>
      <label>{label1}</label>
      <label className={`flex justify-between ${color} ${bold ? `font-extrabold` : ``}`}>
        {label2}
      </label>
    </div>
  );
};
