import { Label, TextInput, Tooltip } from 'flowbite-react';
import { Field, useFormikContext } from 'formik';
import { CSSProperties, FC } from 'react';
import { ReactNode } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi';

const AntNumberInput = ({ field, form, helperText, ...props }: any) => {
  const error = !!form.errors[field.name];

  const { onChange, inputStyle, ...rest } = props;

  return (
    <TextInput
      color={error ? 'failure' : undefined}
      id={`form__field_${field.name}`}
      onKeyDown={(e) => {
        // Prevent form submission on enter
        if (e.key === 'Enter') {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        const value = e.target.value
          ? Number.parseFloat(e.target.value!.replace(/\$\s?|(,*)/g, ''))
          : e.target.value;

        const isNaN = Number.isNaN(value);

        if (isNaN) {
          form.setFieldValue(field.name, ``);
          return;
        }

        if (rest.max && value > props.max) {
          form.setFieldValue(field.name, rest.max);
          onChange && onChange(rest.max);

          return;
        }

        form.setFieldValue(field.name, value);

        onChange && onChange(value);
      }}
      sizing={'lg'}
      style={inputStyle}
      onFocus={(e) => form.setFieldTouched(field.name)}
      status={error ? 'error' : undefined}
      value={
        field.value != null && field.value !== undefined
          ? `${field.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : null
      }
      icon={BsCurrencyDollar}
      prefix={`$`}
      helperText={helperText}
      {...rest}
    ></TextInput>
  );
};

const CurrencyInputField = ({
  name,
  placeholder,
  className,
  type,
  suffix,
  prefix,
  onChange,
  step,
  disabled,
  id,
  tooltip,
  helperText,
  label,
  max,
  inputStyle,
  sizing = 'lg',
}: {
  name: string;
  placeholder: string;
  type?: 'text' | 'email' | 'number';
  suffix?: ReactNode;
  prefix?: ReactNode;
  className?: string;
  onChange?: (value?: number) => void;
  step?: string;
  disabled?: boolean;
  id?: string;
  tooltip?: ReactNode;
  helperText?: ReactNode;
  label?: string;
  max?: number;
  inputStyle?: CSSProperties;
  sizing?: 'sm' | 'md' | 'lg';
}) => {
  const form = useFormikContext<any>();
  const error = form.errors[name];

  if (error != undefined && typeof error !== 'string' && typeof error !== 'number')
    throw new Error('Error must be of type string');

  return (
    <div className={className + ' truncate'} id={id}>
      <Label color={error ? 'failure' : undefined} htmlFor={`form__field_${name}`}>
        {tooltip ? (
          <>
            <Tooltip
              theme={{
                target: 'flex justify-between',
              }}
              content={tooltip}
            >
              {label || placeholder}&nbsp;
              <HiOutlineQuestionMarkCircle className="inline" />
            </Tooltip>
          </>
        ) : (
          label || placeholder
        )}
      </Label>
      <Field
        helperText={helperText}
        type={type}
        name={name}
        suffix={suffix}
        preffix={prefix}
        placeholder={placeholder}
        component={AntNumberInput}
        onChange={onChange}
        step={step}
        disabled={disabled}
        max={max}
        inputStyle={inputStyle}
        sizing={sizing}
      />
      {error && !error ? null : <p className=" text-sm text-red-700">{error} &nbsp;</p>}
    </div>
  );
};

export const CurrencyField: FC<{
  name: string;
  placeholder: string;
  onChange?: (value?: number) => void;
  disabled?: boolean;
  tooltip?: ReactNode;
  helperText?: ReactNode;
  label?: string;
  max?: number;
  className?: string;
  inputStyle?: CSSProperties;
  sizing?: 'sm' | 'md' | 'lg';
}> = ({
  name,
  placeholder,
  onChange,
  disabled,
  tooltip,
  helperText,
  label,
  max,
  className,
  inputStyle,
  sizing,
}) => (
  <CurrencyInputField
    placeholder={placeholder}
    name={name}
    helperText={helperText}
    tooltip={tooltip}
    onChange={onChange}
    disabled={disabled}
    label={label}
    max={max}
    className={className}
    inputStyle={inputStyle}
    sizing={sizing}
  />
);
