export const english = {
  'borrower.lowercase:Organization': 'client',
  'borrower.uppercase:Organization': 'Client',
  'borrower.lowercase:College': 'borrower',
  'borrower.uppercase:College': 'Borrower',
  'borrowers.lowercase:Organization': 'clients',
  'borrowers.uppercase:Organization': 'Clients',
  'borrowers.lowercase:College': 'borrowers',
  'borrowers.uppercase:College': 'Borrowers',

  'breadcrums.manage_tenant:College': 'Manage College',
  'breadcrums.manage_tenant:Organization': 'Manage Company',

  //Client Dashboard

  'notifications.client_updated:Borrower': "Profile updated",
  'notifications.client_updated:College': "Borrower updated",
  'notifications.client_updated:Organization': "Client updated",

  'notifications.client_created:Borrower': "Profile created",
  'notifications.client_created:College': "Borrower created",
  'notifications.client_created:Organization': "Client created",

  'pages.client_dashboard.labels.edit_profile:Borrower': 'Edit Profile',
  'pages.client_dashboard.labels.edit_profile:College': 'Edit Borrower',
  'pages.client_dashboard.labels.edit_profile:Organization': 'Edit Client',

  'pages.client_dashboard.labels.client_name:College': 'Borrower Name',
  'pages.client_dashboard.labels.client_name:Organization': 'Client Name',
  'pages.client_dashboard.labels.client_name:Borrower': 'Name',

  'pages.client_dashboard.tooltips.household_size:College': 'Borrower + Spouse + Children/Dependants',
  'pages.client_dashboard.tooltips.household_size:Organization': 'Client + Spouse + Children/Dependants',
  'pages.client_dashboard.tooltips.household_size:Borrower': 'You + Spouse + Children/Dependants',

  
  'pages.client_dashboard.tooltips.multiple_repayment_types:College': 'Borrower has multiple repayment plan types.',
  'pages.client_dashboard.tooltips.multiple_repayment_types:Organization': 'Client has multiple repayment plan types.',
  'pages.client_dashboard.tooltips.multiple_repayment_types:Borrower': "You have multiple repayment plan types.",

  'pages.client_dashboard.tooltips.multiple_pslf:College': 'Borrower multiple PSLF cumulative matched months.',
  'pages.client_dashboard.tooltips.multiple_pslf:Organization': 'Client multiple PSLF cumulative matched months.',
  'pages.client_dashboard.tooltips.multiple_pslf:Borrower': "You have multiple PSLF cumulative matched months.",

  'pages.settings.labels.tenant_title:College': 'My College',
  'pages.settings.labels.tenant_title:Organization': 'My Company',

  'pages.settings.labels.tenant_label:College': 'Manage your College information',
  'pages.settings.labels.tenant_label:Organization': 'Manage your Company information',

  'pages.settings.buttons.manage_tenant:College': 'Manage College',
  'pages.settings.buttons.manage_tenant:Organization': 'Manage Company',

  'pages.tenant_settings.header:College': 'My College',
  'pages.tenant_settings.header:Organization': 'My Company',

  //List of Loans
  'pages.list_of_loans.title:College': 'Borrower Loans',
  'pages.list_of_loans.title:Organization': 'Client Loans',
  'pages.list_of_loans.title:Borrower': 'My Loans',
};
