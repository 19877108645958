import { Card, Spinner, Table } from 'flowbite-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CardWithItems } from '../../components/cards/Cards';
import { FinoLink } from '../../components/links/FinoLink';
import { useGetAdvisorDashboardQuery, useGetRecentClientsQuery } from '../../graphql/generated';
import useFinologyTranslation from '../../translations/useFinologyTranslation';
import { toDollars } from '../../util/currency.formatter';
import { toPercentage } from '../../util/number.formatter';
import { getLabelForType } from '../../util/privateLoans.utils';

export const AdvisorDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useFinologyTranslation();

  const { data: dashboardQuery, loading: dashboardLoading } = useGetAdvisorDashboardQuery();
  const { data: recentClientsQuery, loading: recentClientsLoading } = useGetRecentClientsQuery();

  if (dashboardLoading || recentClientsLoading) return <Spinner />;

  return (
    <>
      {/* Top */}
      <div className="flex flex-col gap-8">
        <div className="flex gap-10 flex-row flex-wrap">
          <CardWithItems
            upperRowItems={[
              {
                text: `No. of ${t('borrowers.uppercase')}`,
                value: `${dashboardQuery?.userDashboard?.totalClients}`,
              },
            ]}
            lowerRowItems={[
              {
                text: 'No. of Loans',
                value: dashboardQuery?.userDashboard?.totalNumberOfLoans.toString(),
              },
              {
                text: `No. of Federal Loans`,
                value: dashboardQuery?.userDashboard?.numberOfFederalLoans.toString(),
              },
              {
                text: `No. of Private Loans`,
                value: dashboardQuery?.userDashboard?.numberOfPrivateLoans.toString(),
              },
            ]}
          />
          <CardWithItems
            upperRowItems={[
              {
                text: 'Current Debt Advised on',
                value: toDollars(dashboardQuery?.userDashboard?.totalDebt),
              },
            ]}
            lowerRowItems={[
              {
                text: `Average per ${t('borrower.uppercase')}`,
                value: toDollars(dashboardQuery?.userDashboard?.averageDebtPerClient),
              },
              {
                text: 'Average Interest Rate',
                value: toPercentage(dashboardQuery?.userDashboard?.averageInterestRate),
              },
              {
                text: 'All Time Debt',
                value: `${toDollars(
                  dashboardQuery?.userDashboard?.totalPrivateDebt +
                    dashboardQuery?.userDashboard?.allTimeDebt
                )}`,
              },
            ]}
          />
        </div>

        <div className="flex gap-10 flex-row flex-wrap">
          <CardWithItems
            upperRowItems={[
              {
                text: 'Federal Debt',
                value: toDollars(dashboardQuery?.userDashboard?.totalFederalDebt),
              },
            ]}
            lowerRowItems={[
              {
                text: 'Weighted Interest Rate',
                value: toPercentage(dashboardQuery?.userDashboard?.weightedFederalInterestRate),
              },
            ]}
          />
          <CardWithItems
            upperRowItems={[
              {
                text: 'PSLF Avg. No. of Payments',
                value: `${dashboardQuery?.userDashboard?.averageNumberOfPayments?.toString()}/120`,
              },
            ]}
            lowerRowItems={[
              {
                text: 'No. of Clients in PSLF',
                value: dashboardQuery?.userDashboard?.numberOfClientsInPslf,
              },
            ]}
          />

          <CardWithItems
            upperRowItems={[
              {
                text: 'Private Debt',
                value: toDollars(dashboardQuery?.userDashboard?.totalPrivateDebt),
              },
            ]}
            lowerRowItems={[
              {
                text: 'Most Loans of Type',
                value: getLabelForType(dashboardQuery?.userDashboard?.mostPrivateLoansOfType),
              },
            ]}
          />
        </div>
      </div>

      <div className="pt-6">
        <div className="mt-4 grid gap-4 grid-cols-2 ">
          {recentClientsQuery?.clients?.items?.length ? (
            <Card
              className="grow min-w-min"
              theme={{
                root: {
                  children: 'p-0',
                },
              }}
            >
              <div className="px-6 py-4 flex-col justify-center">
                <div className="text-lg font-semibold">Recent {t('borrowers.uppercase')}</div>
              </div>
              <Table>
                <Table.Head>
                  <Table.HeadCell>Name</Table.HeadCell>
                  <Table.HeadCell>Federal Loans</Table.HeadCell>
                  <Table.HeadCell>Federal Debt</Table.HeadCell>
                  <Table.HeadCell>Private Loans</Table.HeadCell>
                  <Table.HeadCell>Private Debt</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {recentClientsQuery.clients.items!.map((client) => {
                    return (
                      <Table.Row key={client.id}>
                        <Table.Cell className="max-w-[250px]">
                          <FinoLink
                            label={client.fullName}
                            to={`${location.pathname}/clients/${client.id}`}
                          />
                        </Table.Cell>
                        <Table.Cell>{client.federalLoansCount}</Table.Cell>
                        <Table.Cell>{toDollars(client.federalLoansSum)}</Table.Cell>
                        <Table.Cell>{client.privateLoansCount}</Table.Cell>
                        <Table.Cell>{toDollars(client.privateLoansSum)}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Card>
          ) : null}
          {/* <div className={`flex flex-col shadow-fino flex-col`}>
            <div className="flex justify-between mt-6 mx-4">
              <span className="text-2xl font-semibold">Recent Activity</span>
              <Link className="font-semibold" to="/activity-log">
                View More
              </Link>
            </div>
            <div className="mt-14 flex flex-col font-normal">
              {activityLog.map((al) => {
                return (
                  <div key={0} className="flex mx-5 mb-6">
                    <div className="w-4/5 font-normal">{al.text}</div>
                    <div className="w-1/5 text-end">
                      <span className="px-2 py-1 bg-fino-blue rounded-md text-white">
                        {al.label}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
