import { useState } from 'react';
import { Link } from 'react-router-dom';

import { PageHeader } from '../../components/PageHeader';

import { BasicIDRCalculation } from './Assumptions/BasicIDRCalculation';
import { ExtendedRepaymentPlan } from './Assumptions/ExtendedRepaymentPlan';
import { FederalLoanTypes } from './Assumptions/FederalLoanTypes';
import { FederalTaxBrackets } from './Assumptions/FederalTaxBrackets';
import { FixedRepaymentPlans } from './Assumptions/FixedRepaymentPlans';
import { GraduatedRepaymentCalculation } from './Assumptions/GraduatedRepaymentCalculation';
import { IBR2009Calculation } from './Assumptions/IBR2009Calculation';
import { IBR2014Calculation } from './Assumptions/IBR2014Calculation';
import { ICRCalculation } from './Assumptions/ICRCalculation';
import { IncomeDrivenRepaymentPlan } from './Assumptions/IncomeDrivenRepaymentPlan';
import { PAYECalculation } from './Assumptions/PAYECalculation';
import { PovertyGuidelines } from './Assumptions/PovertyGuidelines';
import { SaveCalculations } from './Assumptions/SaveCalculations';
import { StandardRepaymentCalculation } from './Assumptions/StandardRepaymentCalculation';
import { StateTaxes } from './Assumptions/StateTaxes';

const SIDEBAR_LINKS = [
  //   { name: 'Glossary' },
  { name: 'State Taxes' },
  //   { name: 'State Tax Calculation' },
  { name: 'Federal Tax Brackets' },
  //   { name: 'Federal Tax Calculation' },
  { name: 'Poverty Rate Guidelines' },
  { name: 'Federal Loan Types' },
  //   { name: 'Teacher Loan Forgiveness' },
  { name: 'Fixed Repayment Plans' },
  { name: 'Standard Repayment Calculation' },
  { name: 'Graduated Repayment Calculation' },
  { name: 'Extended Repayment Plan' },
  { name: 'Income-Driven Repayment (IDR) Plans' },
  { name: 'Basic IDR Calculation' },
  { name: 'ICR Calculation' },
  { name: 'SAVE Calculation' },
  { name: 'PAYE Calculation' },
  { name: 'IBR, 2009 Calculation' },
  { name: 'IBR, 2014 Calculation' },
];

export const Assumptions = () => {
  const [activeLink, setActiveLink] = useState(SIDEBAR_LINKS[0].name);

  const renderContent = (activeLink: string) => {
    switch (activeLink) {
      case 'State Taxes':
        return <StateTaxes />;
      case 'Federal Tax Brackets':
        return <FederalTaxBrackets />;
      case 'Poverty Rate Guidelines':
        return <PovertyGuidelines />;
      case 'Federal Loan Types':
        return <FederalLoanTypes />;
      case 'Fixed Repayment Plans':
        return <FixedRepaymentPlans />;
      case 'Standard Repayment Calculation':
        return <StandardRepaymentCalculation />;
      case 'Graduated Repayment Calculation':
        return <GraduatedRepaymentCalculation />;
      case 'Extended Repayment Plan':
        return <ExtendedRepaymentPlan />;
      case 'Income-Driven Repayment (IDR) Plans':
        return <IncomeDrivenRepaymentPlan />;
      case 'Basic IDR Calculation':
        return <BasicIDRCalculation />;
      case 'ICR Calculation':
        return <ICRCalculation />;
      case 'SAVE Calculation':
        return <SaveCalculations />;
      case 'PAYE Calculation':
        return <PAYECalculation />;
      case 'IBR, 2009 Calculation':
        return <IBR2009Calculation />;
      case 'IBR, 2014 Calculation':
        return <IBR2014Calculation />;
      default:
        return <div>404</div>;
    }
  };

  const scrollToTop = () => {
    scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <PageHeader back breadcrumbs={[{ to: '/resources', text: 'Assumptions' }]} />
      <div className="flex justify-center flex-col items-center border border-t-0 border-x-0 border-b-1 border-gray-200">
        <h2 className="text-2xl font-bold mb-6 text-center">
          A Guide to Calculations within the Liability Planner
        </h2>
        <p className="text-gray-500 text-center mb-12 max-w-2xl text-sm">
          A comprehensive insight into the details of how the projections of client income, tax,
          dependents for current and previously available student loan repayment plans, forgiveness
          programs and debt optimization. This methodology is used throughout the Liability Planner
          in the Federal Loan Simulator, IDR Comparison, Private Loan Planner and Credit Card
          Optimizer.
        </p>
      </div>
      {/*  add a sticky sidenavigation with random links on the left, and on the right content. It should be separated with border  */}
      <div className="flex gap-6">
        <div className="w-3/12">
          <div className="sticky top-0">
            <div className=" overflow-y-auto min-h-screen">
              <div className=" p-6 text-center flex flex-col border border-gray-200 border-l-0 border-b-0 min-h-screen">
                <div className="flex flex-col gap-4">
                  {SIDEBAR_LINKS.map((link) => (
                    <div
                      onClick={() => {
                        setActiveLink(link.name);
                        scrollToTop();
                      }}
                      className={` cursor-pointer flex justify-start items-start text-start hover:text-blue-800 pl-1.5 py-2 rounded-lg ${
                        activeLink === link.name ? 'text-blue-800 bg-primary-200' : 'text-gray-500'
                      }`}
                      key={link.name}
                    >
                      <Link
                        key={link.name}
                        to="#"
                        onClick={() => {
                          setActiveLink(link.name);
                          scrollToTop();
                        }}
                      >
                        {link.name}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-9/12">{renderContent(activeLink)}</div>
      </div>
    </>
  );
};
