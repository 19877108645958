import { Heading } from '../../../components/Assumptions/Heading';

export const BasicIDRCalculation = () => {
  return (
    <div className="text-gray-500 p-6">
      <Heading title="How is a Basic IDR Payment Calculated?" />
      Income Based Repayment Calculations depend on your income.
      <br />
      While the definition of income can change depending on the loan type, for student loans
      Adjusted Gross Income (AGI) is what matters.
      <br />
      Adjusted Gross Income
      <br />
      Example: Only Direct Loan based on income and debt
      <br />
      <br />
      Bob&apos;s Income:
      <br />
      1. $50,000 salary/wages
      <br />
      2. $12,000 in rental income
      <br />
      3. $8,500 wages earned as a part-time Uber driver
      <br />
      4. $500 from interest from bonds
      <br />
      <br />
      Gross Income = $71,000 Standard/Extended Debt Years
      <br />
      <br />
      Adjustment from his gross income:
      <br />
      1. $250 in educator expenses
      <br />
      2. $2,500 in student loan interest
      <br />
      Adjustments = $2,750
      <br />
      <br />
      Subtracting allowable adjustments ($2,750) from the Total Income ($71,000), Bob&apos;s
      Adjusted Gross Income (AGI) is $68,250
      <br />
      <br />
      The formula used will change based on what IDR plan you choose.
      <br />
      Start with the general income-based repayment formula
      <br />
      <br />
      1. Determine your discretionary income
      <br />
      Start with AGI (Line 11 on the 1040 Tax Form) and subtract 150% of the federal poverty
      guideline level for your family size. With SAVE this will change to 225%.
      <br />
      AGI - (150% x Poverty Level) = your discretionaty income on PAYE or IBR.
      <br />
      AGI - (225% x Poverty Level) = your discretionaty income on SAVE.
      <br />
      <br />
      2. Determine if loans are 100% Undergraduate loans or a combination of Undergraduate and
      Graduate Loans.
      <br />
      If all loans are Undergraduate loans, multiply discretionary income by 5% if on SAVE.
      <br />
      If loans are all Graduate Loans or a combination of both kinds, multiply discretionary income
      by either 10% for SAVE or PAYE, or 15% for IBR.
      <br />
      [AGI - (150% x Poverty Level)] x 10% = annual payment for PAYE and New IBR
      <br />
      [AGI - (150% x Poverty Level)] x 15% = annual payment for Old IBR
      <br />
      [AGI - (225% x Poverty Level)] x (number between 5% and 10%) = annual payment for SAVE plan.
      <br />
      It is important to know the multiplier for the SAVE plan will flucuate. This number is
      calculated based on how much undergrad debt you have relative to graduate debt.
      <br />
      <br />
      3. Divide the total from number 2 by 12 for monthly payments.
      <br />
      <br />
      <Heading title="Other considerations when calculating you IDR payment" />
      1. Your eligibility for each repayment plan.
      <br />
      2. Your retirement savings options.
      <br />
      3. How to file your taxes (joint versus separately)
    </div>
  );
};
