import React from 'react';

import { HighlightedCard } from '../../../components/cards/Cards';
import { toDollars } from '../../../util/currency.formatter';
import { toPercentage } from '../../../util/number.formatter';
import { formatDate } from '../../../utils';

export const Recap: React.FC<any> = ({ results }) => {
  return (
    <>
      <div className="grid grid-cols-4 gap-4 mt-8">
        <HighlightedCard
          label="Projected Payoff Date"
          value={formatDate(results.finalIterationDate) || `N/A`}
        />
        <HighlightedCard
          label="Sum of Future Payment"
          value={toDollars(results.totalOutOfPocket)}
        />
        <HighlightedCard
          label="Projected New Monthly Payment"
          value={toDollars(results.currentMonthlyPayment)}
        />
        <HighlightedCard
          label="Projected Forgiveness Amount"
          value={toDollars(results.amountForgiven)}
        />
      </div>
      <div className="grid grid-cols-3 gap-4 mt-8">
        <HighlightedCard
          label="Original Disbursed Amount"
          value={toDollars(results.totalDisbursedAmount)}
          color="bg-gray-100"
          textColor="text-gray-700"
        />
        <HighlightedCard
          label="Current Federal Loans Amount"
          value={toDollars(results.loansSum)}
          color="bg-gray-100"
          textColor="text-gray-700"
        />

        <HighlightedCard
          label="Weighted Avg. Interest Rate"
          value={toPercentage(results.averageInterestRate || 0)!}
          color="bg-gray-100"
          textColor="text-gray-700"
        />
      </div>
    </>
  );
};
