import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';

export const IBR2009Calculation = () => {
  const loanInformation = [
    { label1: 'Spouse 1 Student Loan Balance (Higher of both debt amounts)', label2: '$310,000' },
    {
      label1: 'Spouse 2 Student Loan Balance, if applicable (Lower of both debt amounts)',
      label2: '$0',
    },
    { label1: 'Average Interest Rate', label2: '5.75%' },
    { label1: 'Inflation Rate Assumption', label2: '3.00%' },
    { label1: 'Paying Loan since what year?', label2: '2013' },
    { label1: 'Do you work for a not-for-profit or government employer?', label2: 'No' },
    { label1: 'Did you borrow before October 1, 2007?', label2: 'No' },
    { label1: 'What percent of your loans are from grad school?', label2: '0%' },
    { label1: 'Total Years of Repayment for the ICR plan', label2: '25' },
    { label1: 'Number of Years in Repayment thus far', label2: '11' },
    { label1: 'Term of loan (yrs):', label2: '20' },
    { label1: 'Installments per year:', label2: '12' },
  ];

  const personalInformation = [
    { label1: 'Where do you live', label2: '48 Contiguous States' },
    { label1: 'Current Family Size', label2: '1' },
    { label1: 'Tax Filing Status when Married', label2: 'Married Filing Jointly' },
    { label1: 'Poverty Level', label2: '$15,060' },
  ];

  const incomeInformation = [
    { label1: 'What is your expected AGI for 2024?', label2: '$100,000' },
    { label1: "What is your spouse's expected AGI for 2024?", label2: '' },
    { label1: 'What is your expected salary growth rate?', label2: '3.00%' },
    { label1: "What is your spouse's expected salary growth rate?", label2: '' },
    { label1: 'Your discretionary income is...', label2: '$77,410.00' },
  ];

  const ibrCalculation = [
    { label1: 'Calculating IBR, 2009 payment based on 15% of discretionary income', label2: '' },
    { label1: 'Annual PAYE Payment:', label2: '$11,611.50' },
    { label1: 'Monthly PAYE Payment:', label2: '$967.63' },
  ];

  return (
    <div className="text-gray-500 p-6">
      <Paragraph text="Under the IBR Plan your monthly payment will never be more than the 10-year Standard Repayment Plan amount. This is why this plan is referred to as the IBR Capped Payment plan.	" />
      <Paragraph text="IDR Plans calculate your monthly payment amount based on our income and family size. So if your income increases, so does your payment amount. On IBR, the payment is limited or capped so that even if your income increases, your payments never go higher than what you'd pay on the Standard Plan." />
      <Paragraph text="If your income goes down again, your servicer will recalculate your payment when your recertify (update your income information), and you'll go back to making payments that are based on your income again. You can always recertify earlier than your annual recertification date.	" />
      <Heading title="IBR Income Cap" />
      <Paragraph text="To qualify for the IBR plan, your monthly payment must be less than what you would pay under the Standard Repayment Plan with a 10-year repayment period. If your payment would be more under the Standard Plan, you wouldn't benefit from the IBR Plan. That's because you'd end up paying off your loan before you'd be able to get IDR plan forgiveness.	" />
      <Paragraph text="Generally, you'll meet this requirement if your federal student loan debt is higher than your annual discretionary income or if it represents a significant portion of your annual income.	" />
      <Paragraph text="Monthly IBR payments due are often less than the interest that accrues each month. This is called negative amortization. With a low enough income (i.e. during an internship, residency or leave of absence, etc), it is even possible to have a monthly payment equal to zero under IBR. These zero amount payments still count towards the 300 monthly payments due before forgiveness." />
      <Paragraph text="With negative amortization, you may have principal and unpaid interest amounts remaining after 300 monthly payments over 25 years under IBR. If that is the case, your remaining debt is forgiven or “canceled” and treated as taxable income. The taxable amount will depend on the amount forgiven and your marginal income tax rate during the year of forgiveness.	" />
      <Heading title="How is a Basic IBR Payment Calculated?" />
      <p>
        Income Based Repayment Calculations depend on your income.
        <br />
        While the definition of income can change depending on the loan type, for student loans
        Adjusted Gross Income (AGI) is what matters.
        <br />
        Adjusted Gross Income
        <br />
        Example:
        <br />
        Bob&apos;s Income:
        <br />
        1. $50,000 salary/wages
        <br />
        2. $12,000 in rental income
        <br />
        3. $8,500 wages earned as a part-time Uber driver
        <br />
        4. $500 from interest from bonds
        <br />
        Gross Income = $71,000
        <br />
        <br />
        Adjustment from his gross income:
        <br />
        1. $250 in educator expenses
        <br />
        2. $2,500 in student loan interest
        <br />
        Adjustments = $2,750
        <br />
        <br />
        Subtracting allowable adjustments ($2,750) from the Total Income ($71,000), Bob&apos;s
        Adjusted Gross Income (AGI) is $68,250
        <br />
        <br />
        The formula used will change based on what IDR plan you choose.
        <br />
        Start with the general income-based repayment formula
        <br />
        1. Determine your discretionary income
        <br />
        Start with AGI (Line 11 on the 1040 Tax Form) and subtract 150% of the federal poverty
        guideline level for your family size.
        <br />
        AGI - (150% x Poverty Level) = your discretionaty income on IBR.
        <br />
        <br />
        2. Determine if loans are 100% Undergraduate loans or a combination of Undergraduate and
        Graduate Loans.
        <br />
        If loans are all Graduate Loans or a combination of both kinds, multiply discretionary
        income by 15% for IBR.
        <br />
        [AGI - (150% x Poverty Level)] x 15% = annual payment for Old IBR
        <br />
        <br />
        3. Divide the total from number 2 by 12 for monthly payments.
      </p>
      {/* <Heading className="my-8" title="Calculating your IBR, 2009 monthly payment" />
      {loanInformation.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))}
      <br />
      {personalInformation.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))}
      <br />
      {incomeInformation.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))}
      <br />
      {ibrCalculation.map((data, index) => (
        <SpaceBetweenLabels key={index} {...data} />
      ))} */}
      <Heading className="my-8" title="Calculating your IBR, 2009 monthly payment Explained:" />
      <p>
        The IBR, 2009 payment is 15% of your Discretionary Income, a government measure based on
        poverty guidelines.
        <br />
        <br />
        For the IBR, 2009 payment your Discretionary Income is the difference between your taxable
        income and 150 percent of the HHS Poverty Guideline amout for your family size and state.
        <br />
        <br />
        Discretionary Income = Adjusted Gross Income - (150% x HHS federal poverty guidelines)
        <br />
        <br />
        Using the above formula to calculate your 2024 Disretionary Income for a family size of one,
        living in the 48 Contiguous States and a taxable income of $100,000:
        <br />
        <br />
        Discretionary Income = $100,000 - (1.5 x $15,060) = $77,410
        <br />
        <br />
        The IBR, 2009 monthly payment 15% of your Discretionary Income divided by 12
        <br />
        <br />
        IBR, 2009 = 0.15 x $77,410 = $11,611.50 per year or $967.63 per month
      </p>
    </div>
  );
};
