import { useEffect, useRef } from 'react';

export const useFlyoutPortal = (): Element => {
  const flyoutElRef = useRef<Element | null>(null);

  useEffect(() => {
    const parentElement = document.querySelector(`#flyout-root`);
    if (flyoutElRef.current) parentElement?.appendChild(flyoutElRef.current);
  }, []);

  const getRootElem = () => {
    if (!flyoutElRef.current) {
      flyoutElRef.current = document.createElement('div');
    }
    return flyoutElRef.current;
  };

  return getRootElem();
};
