import { useEffect } from 'react';

export const useGtag = () => {
  useEffect(() => {
    const useGtag = process.env.REACT_APP_USE_GTAG === 'true';
    if (!useGtag) return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-Y0TB5EFXDB';

    document.body.appendChild(script);

    const anyWindow = window as any;
    anyWindow.dataLayer = anyWindow.dataLayer || [];
    anyWindow.gtag = function gtag(...args: any) {
      anyWindow.dataLayer.push(args);
    };
    anyWindow.gtag('js', new Date());
    anyWindow.gtag('config', 'G-Y0TB5EFXDB');
  }, []);
};
