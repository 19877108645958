import dayjs from 'dayjs';
import { Avatar, Button, Modal } from 'flowbite-react';
import { useState } from 'react';

import { ClientAdvisorApplicationStatusLabel } from '../../components/labels/ClientAdvisorApplicationStatusLabel';
import { FlyoutLink } from '../../components/links/FlyoutLink';
import { FinologyTable } from '../../components/table/FinologyTable';
import {
  ClientAdvisorApplicationStatus,
  ClientFindAnAdvisorApplicationView,
  ClientListViewSortInput,
  InputMaybe,
  useChangeClientAdvisorApplicationStatusMutation,
  useGetClientAdvisorApplications_AdvisorLazyQuery,
} from '../../graphql/generated';
import { toDollars } from '../../util/currency.formatter';
import { notification } from '../../util/notification.utils';
import { getInitials } from '../../utils';

import type { ColumnsType } from '../../components/table/FinologyTable';

const ChangeApplicationStatusModal = ({
  applicationToChange,
  closeModal,
}: {
  applicationToChange?: {
    application: ClientFindAnAdvisorApplicationView;
    targetState:
      | ClientAdvisorApplicationStatus.Accepted
      | ClientAdvisorApplicationStatus.TurnedDown;
  };
  closeModal: () => void;
}) => {
  const [changeStatus] = useChangeClientAdvisorApplicationStatusMutation();
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  const label =
    applicationToChange?.targetState == ClientAdvisorApplicationStatus.Accepted
      ? 'Accept'
      : 'Reject';

  return (
    <Modal show={applicationToChange !== undefined} onClose={closeModal}>
      <Modal.Header>{`${label} Application`}</Modal.Header>
      <Modal.Body>
        {applicationToChange?.targetState == ClientAdvisorApplicationStatus.Accepted ? (
          <>
            <p>
              Are you sure you want to <strong>accept</strong>{' '}
              {applicationToChange.application.clientFullName}&apos;s application?
            </p>
            <p>
              By confirming, you are converting {applicationToChange.application.clientFullName}{' '}
              into a client.
            </p>
          </>
        ) : (
          <p>
            Are you sure you want to <strong>reject</strong>{' '}
            {applicationToChange?.application.clientFullName}
            &apos;s application?
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button color="light" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={disableDeleteButton}
          onClick={async () => {
            setDisableDeleteButton(true);

            await changeStatus({
              variables: {
                input: {
                  applicationId: applicationToChange!.application.id,
                  targetStatus: applicationToChange!.targetState,
                },
              },
              refetchQueries: ['GetClientAdvisorApplications_Advisor', 'GetClients'],
            });

            setDisableDeleteButton(false);

            closeModal();

            notification.success({
              message: `Application for ${applicationToChange?.application.clientFullName} was ${
                applicationToChange?.targetState == ClientAdvisorApplicationStatus.Accepted
                  ? 'accepted'
                  : 'rejected'
              }.`,
              placement: 'bottom-center',
            });
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const MyProspects = () => {
  const [getApplications, query] = useGetClientAdvisorApplications_AdvisorLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [applicationToChange, setApplicationToChange] = useState<{
    application: ClientFindAnAdvisorApplicationView;
    targetState:
      | ClientAdvisorApplicationStatus.Accepted
      | ClientAdvisorApplicationStatus.TurnedDown;
  }>();

  const columns: ColumnsType<ClientFindAnAdvisorApplicationView> = [
    {
      title: 'Name',
      dataIndex: 'clientFullName',
      key: 'clientFullName',
      render: (text, record) => (
        <div className="flex items-center justify-start gap-4">
          <Avatar rounded placeholderInitials={getInitials(text)} />
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: 'Connection Request Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (text ? dayjs(text).format('YYYY/MM/DD HH:mm') : null),
    },
    {
      title: 'Loan Total',
      dataIndex: 'loansSum',
      key: 'loansSum',
      render: (text) => toDollars(text),
    },
    {
      title: 'Income',
      dataIndex: 'salary',
      key: 'salary',
      render: (text) => toDollars(text),
    },
    {
      title: 'Status',
      key: 'action',
      render: (_, record) => {
        if (record.status === ClientAdvisorApplicationStatus.Pending)
          return (
            <div className="flex gap-2">
              <Button
                color="light"
                onClick={() =>
                  setApplicationToChange({
                    application: record,
                    targetState: ClientAdvisorApplicationStatus.TurnedDown,
                  })
                }
              >
                Decline
              </Button>
              <Button
                onClick={() =>
                  setApplicationToChange({
                    application: record,
                    targetState: ClientAdvisorApplicationStatus.Accepted,
                  })
                }
              >
                Accept
              </Button>
            </div>
          );

        return <ClientAdvisorApplicationStatusLabel status={record.status} />;
      },
    },
  ];

  async function load(
    skip?: number,
    take?: number,
    filterValue?: string,
    sortBy?: InputMaybe<ClientListViewSortInput | ClientListViewSortInput[]>
  ) {
    const { data } = await getApplications({
      variables: {
        filter: filterValue ? { clientFullName: { contains: filterValue } } : undefined,
        skip,
        take,
        sortBy,
      },
    });

    return {
      rows: data!.clientAdvisorApplications!.items as ClientFindAnAdvisorApplicationView[],
      totalCount: data!.clientAdvisorApplications!.totalCount,
    };
  }

  return (
    <>
      <ChangeApplicationStatusModal
        applicationToChange={applicationToChange}
        closeModal={() => setApplicationToChange(undefined)}
      />
      <div className="flex">
        <div className="grow">
          <FinologyTable
            emptyText={(filter) => {
              return !filter ? (
                <>
                  <p>Looks like you don&apos;t have any prospects. </p>
                  <p>
                    Enable Find An Advisor Listing{' '}
                    <FlyoutLink flyoutId="edit-profile" params={''}>
                      here
                    </FlyoutLink>{' '}
                    and start receiving potential prospects.
                  </p>
                </>
              ) : (
                <span>No prospects found that match your search criteria.</span>
              );
            }}
            searchVisible={true}
            searchPlaceholder={'Search for prospects...'}
            columns={columns}
            reloadDependency={query.previousData?.clientAdvisorApplications?.items}
            dataSourcePromise={load}
          />
        </div>
      </div>
    </>
  );
};
