import { Form, Formik, FormikErrors } from 'formik';
import { useLocation } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../components/forms/FormErrorNotification';
import { useLinkSpouseMutation } from '../../../graphql/generated';
import useFinologyTranslation from '../../../translations/useFinologyTranslation';
import { notification } from '../../../util/notification.utils';
import { ClientSelectorField } from '../../formComponents/ClientSelectorField';

type FormValues = { spouse: number | null };

const FlyoutContent = () => {
  const [linkSpouseMutation] = useLinkSpouseMutation();
  const { t } = useFinologyTranslation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const clientId = params.get('clientId');
  const parsedClientId = Number.parseInt(clientId!);
  const initialValues: FormValues = { spouse: null };

  const { closeTopFlyout } = useFlyoutContext();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (v, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        await linkSpouseMutation({
          variables: {
            input: {
              clientId: parsedClientId,
              spouseId: v.spouse!,
            },
          },
          refetchQueries: ['GetClientDashboard'],
        });

        notification.success({
          placement: 'bottom-center',
          message: `Spouse was successfully linked`,
        });

        closeTopFlyout();
      }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.spouse) {
          errors.spouse = 'Required';
        }

        return errors;
      }}
    >
      {({ isSubmitting }) => {
        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'link-spouse-form',
                children: 'Link Spouse',
                disabled: isSubmitting,
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={`Link Spouse`}
            />
            <Form id="link-spouse-form">
              <ClientSelectorField
                placeholder={`Choose a ${t('borrower.uppercase')}`}
                className="grow"
                name="spouse"
                selectPlaceholder={`Select ${t('borrower.uppercase')}`}
                idToExclude={parsedClientId}
              ></ClientSelectorField>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export const PickSpouseFlyout = () => {
  return (
    <Flyout
      id={`pick-spouse`}
      size={'small'}
      requiredParams={['clientId']}
      content={<FlyoutContent />}
    ></Flyout>
  );
};
