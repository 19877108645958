import { Button, Modal, Tooltip } from 'flowbite-react';
import { useState } from 'react';
import { MdEdit, MdDelete } from 'react-icons/md';

import { HighlightedCard } from '../../../../components/cards/Cards';
import { FlyoutLink } from '../../../../components/links/FlyoutLink';
import { FinologyTable } from '../../../../components/table/FinologyTable';
import {
  FederalLoanView,
  FederalLoanViewSortInput,
  InputMaybe,
  SortEnumType,
  useDeleteFederalLoanMutation,
  useGetFederalLoanAggregationsQuery,
  useGetFederalLoansLazyQuery,
} from '../../../../graphql/generated';
import { useFlyoutNavigate } from '../../../../hooks/useFlyoutNavigate';
import { useTableSortBy } from '../../../../hooks/useTableSortBy';
import useFinologyTranslation from '../../../../translations/useFinologyTranslation';
import { toDollars } from '../../../../util/currency.formatter';
import { toDateShortString } from '../../../../util/date.formatter';
import { notification } from '../../../../util/notification.utils';
import { toPercentage } from '../../../../util/number.formatter';

import type { ColumnsType } from '../../../../components/table/FinologyTable';

const DeleteFederalLoanModal = ({
  loanToDelete,
  closeModal,
}: {
  loanToDelete?: FederalLoanView;
  closeModal: () => void;
}) => {
  const [deleteLoan] = useDeleteFederalLoanMutation();
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  return (
    <Modal show={loanToDelete !== undefined} onClose={closeModal}>
      <Modal.Header>Delete Federal Loan</Modal.Header>
      <Modal.Body>
        <p>Proceed with deleting of federal loan?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button color="light" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          disabled={disableDeleteButton}
          onClick={async () => {
            setDisableDeleteButton(true);

            await deleteLoan({
              variables: {
                id: loanToDelete!.id,
              },
              refetchQueries: [
                'GetFederalLoans',
                'GetClientDashboard',
                'GetFederalLoanAggregations',
              ],
            });

            setDisableDeleteButton(false);

            closeModal();

            notification.success({
              message: 'Federal loan was successfully deleted',
              placement: 'bottom-center',
            });
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const FederalLoansGrid = ({ clientId }: { clientId: number }) => {
  const [getLoans, query] = useGetFederalLoansLazyQuery();
  const { navigate } = useFlyoutNavigate();
  const [federalLoanToDelete, setFederalLoanToDelete] = useState<FederalLoanView>();
  const { t } = useFinologyTranslation();

  const getFederalLoanAggregationsQuery = useGetFederalLoanAggregationsQuery({
    variables: { clientId: clientId },
  });
  const { sortBy, prepareSortColumns, triggerRefetch } = useTableSortBy<FederalLoanViewSortInput>({
    type: SortEnumType.Asc,
  });

  const federalLoansColumns: ColumnsType<FederalLoanView> = [
    {
      dataIndex: 'type',
      key: 'type',
      ...prepareSortColumns('type', 'Type', triggerRefetch),
      render: (text, record) => (
        <FlyoutLink
          flyoutId="upsert-federal-loan"
          params={`loanId=${record.id}&clientId=${clientId}`}
        >
          {text}
        </FlyoutLink>
      ),
    },
    {
      dataIndex: 'contactName',
      key: 'contactName',
      ...prepareSortColumns('contactName', 'Contact Name', triggerRefetch),
      render: (text) => text,
    },
    {
      dataIndex: 'interestRate',
      key: 'interestRate',
      ...prepareSortColumns('interestRate', 'Rate', triggerRefetch),
      render: (text) => toPercentage(text || 0),
    },
    {
      dataIndex: 'date',
      key: 'date',
      ...prepareSortColumns('date', 'Origination', triggerRefetch),
      render: (text) => toDateShortString(text),
    },
    {
      dataIndex: 'principalBalance',
      key: 'principalBalance',
      ...prepareSortColumns('principalBalance', 'Principal Balance', triggerRefetch),
      render: (text) => toDollars(text),
    },
    {
      dataIndex: 'interestBalance',
      key: 'interestBalance',
      ...prepareSortColumns('interestBalance', 'Interest Balance', triggerRefetch),
      render: (text) => toDollars(text),
    },
    {
      dataIndex: 'totalBalance',
      key: 'totalBalance',
      ...prepareSortColumns('totalBalance', 'Current Balance', triggerRefetch),
      render: (text) => toDollars(text),
    },
    {
      dataIndex: 'pslfCumulativeMatchedMonths',
      key: 'pslfCumulativeMatchedMonths',
      ...prepareSortColumns('pslfCumulativeMatchedMonths', 'PSLF Months', triggerRefetch),
      render: (text) => text || 0,
    },
    {
      dataIndex: 'repaymentPlanScheduledAmount',
      key: 'repaymentPlanScheduledAmount',
      ...prepareSortColumns('repaymentPlanScheduledAmount', 'Current Payment', triggerRefetch),
      render: (text) => toDollars(text),
    },
    {
      title: '',
      key: 'action',
      clasName: 'w-20',
      render: (_, record) => (
        <div className="flex gap-2">
          <Tooltip content="Edit">
            <MdEdit
              size={24}
              className="hover:cursor-pointer hover:text-primary"
              onClick={() =>
                navigate('upsert-federal-loan', `loanId=${record.id}&clientId=${clientId}`)
              }
            />
          </Tooltip>
          <Tooltip content="Delete">
            <MdDelete
              size={24}
              className="hover:cursor-pointer hover:text-primary"
              onClick={() => setFederalLoanToDelete(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  async function load(
    skip?: number,
    take?: number,
    filterValue?: string,
    sortBy?: InputMaybe<FederalLoanViewSortInput | FederalLoanViewSortInput[]>
  ) {
    const { data } = await getLoans({
      variables: {
        clientId: clientId,
        skip,
        take,
        sortBy,
      },
    });

    return {
      rows: data!.federalLoans!.items as FederalLoanView[],
      totalCount: data!.federalLoans!.totalCount,
    };
  }

  return (
    <>
      <DeleteFederalLoanModal
        loanToDelete={federalLoanToDelete}
        closeModal={() => setFederalLoanToDelete(undefined)}
      />
      <div className="flex-1">
        <FinologyTable
          emptyText={() => <span>No federal loans found.</span>}
          columns={federalLoansColumns}
          dataSourcePromise={load}
          reloadDependency={query.previousData}
          searchPlaceholder="Search for federal loans..."
          sortBy={sortBy}
          hideFooter={true}
        />
        {query.data?.federalLoans?.totalCount ? (
          <div className="grid grid-cols-3 gap-4 mt-8">
            <HighlightedCard
              label="Total Federal Loan Amount"
              value={toDollars(
                getFederalLoanAggregationsQuery.data?.federalLoanAggregations?.totalBalance
              )}
            />
            <HighlightedCard
              label="Weighted Average Rate"
              value={toPercentage(
                getFederalLoanAggregationsQuery.data?.federalLoanAggregations?.weightedAverageRate
              )}
            />
            <HighlightedCard
              label="Sum of Payments"
              value={toDollars(
                getFederalLoanAggregationsQuery.data?.federalLoanAggregations?.sumOfPayments
              )}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export const FederalLoans = ({ clientId }: { clientId: number }) => {
  return (
    <div className="py-8">
      <div className="mb-4">Federal Loans</div>
      <FederalLoansGrid clientId={clientId} />
    </div>
  );
};
