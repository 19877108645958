import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';
import { SpaceBetweenLabels } from '../../../components/Assumptions/SpaceBetweenLabels';

export const StandardRepaymentCalculation = () => {
  const loanExampleInformation = [
    { label1: 'Amount of Student Loan:', label2: '$100,000.00', color: 'text-red-500' },
    { label1: 'Interest Rate:', label2: '6.00%' },
    { label1: 'Term of loan (yrs):', label2: '10' },
    { label1: 'Installments per year:', label2: '12' },
    { label1: 'Monthly Payment:', label2: '$1,110.21', bold: true },
    { label1: 'Overall Total Amount Paid:', label2: '$133,224.60' },
    { label1: 'Overall Total Principal Paid:', label2: '$100,000.00' },
    { label1: 'Overall Total Interest Paid:', label2: '$33,224.60' },
  ];

  const loanExampleInformation2 = [
    { label1: 'Amount of Student Loan:', label2: '$151,505.00', color: 'text-red-500' },
    { label1: 'Interest Rate:', label2: '5.70%' },
    { label1: 'Term of loan (yrs):', label2: '30' },
    { label1: 'Installments per year:', label2: '12' },
    { label1: 'Monthly Payment:', label2: '$879.34', bold: true },
    { label1: 'Overall Total Amount Paid:', label2: '$316,560.84' },
    { label1: 'Overall Total Principal Paid:', label2: '$151,505.00' },
    { label1: 'Overall Total Interest Paid:', label2: '$165,055.84' },
  ];

  return (
    <div className="p-6 text-gray-500">
      <div className="flex gap-20 xl:flex-row flex-col">
        <div className=" xl:max-w-[45%] max-w-[100%]">
          <Heading title="Non-Consolidated Loans" />
          <SpaceBetweenLabels label1="-- Repayment length:" label2="10 years" />
          <SpaceBetweenLabels label1="-- Number of payments:" label2="120" />
          <SpaceBetweenLabels label1="-- Payment amounts:" label2="The same amount each month	" />
          <SpaceBetweenLabels
            label1="-- Other qualifications:"
            label2="Must have federal student loans"
          />
          <Paragraph
            className="mt-8"
            text="Standard repayment divides the amount you owe into 120 level payments so you pay the same amount each month for 10 years."
          />
          <Paragraph text="Under this plan, payments can't be less than $50." />
          {/* <Heading title="Try a different example:" />
          {loanExampleInformation.map((data, index) => (
            <SpaceBetweenLabels key={index} {...data} />
          ))} */}
        </div>
        <div className=" ">
          <Heading title="Consolidated Loans" />
          <div className="mb-1">-- Repayment length:</div>
          <SpaceBetweenLabels
            label1="Student Loan Debt Amount:"
            label2="Standard Plan Repayment Period:"
          />
          <SpaceBetweenLabels label1="Less than $7,500" label2="10 years" />
          <SpaceBetweenLabels label1="$7,500–9,999" label2="12 years" />
          <SpaceBetweenLabels label1="$10,000–19,999" label2="15 years" />
          <SpaceBetweenLabels label1="$$20,000–39,999" label2="20 years" />
          <SpaceBetweenLabels label1="$$40,000–59,999" label2="25 years" />
          <SpaceBetweenLabels label1="More than $60,000" label2="30 years" />
          <br />
          <SpaceBetweenLabels
            label1="-- Number of payments:"
            label2="Number of years * 12 months"
          />
          <SpaceBetweenLabels label1="-- Payment amounts:" label2="The same amount each month" />
          <SpaceBetweenLabels
            label1="-- Other qualifications:"
            label2="Must have federal student loans"
          />
          <Paragraph
            className="mt-8"
            text="Standard repayment divides the amount you owe into 120 level payments so you pay the same amount each month for 10 years."
          />
          <Paragraph text="Under this plan, payments can't be less than $50." />
          {/* <Heading title="Try a different example:" />
          {loanExampleInformation2.map((data, index) => (
            <SpaceBetweenLabels key={index} {...data} />
          ))} */}
        </div>
      </div>
    </div>
  );
};
