import { Spinner } from 'flowbite-react';

import { CardWithItems } from '../../../components/cards/Cards';
import { useGetSuperAdminDashboardQuery } from '../../../graphql/generated';
import { toDollars } from '../../../util/currency.formatter';
import { toPercentage } from '../../../util/number.formatter';

export const SuperAdminDashboard = () => {
  const { data: dashboardQuery, loading: dashboardLoading } = useGetSuperAdminDashboardQuery();

  return (
    <>
      {dashboardLoading ? (
        <Spinner />
      ) : (
        <div className="shadow-sm flex flex-col gap-8">
          <div className="flex gap-4 flex-row">
            <CardWithItems
              upperRowItems={[]}
              lowerRowItems={[
                {
                  text: 'Federal Debt',
                  value: toDollars(dashboardQuery?.superAdmin_Dashboard?.totalFederalDebt),
                },
                {
                  text: 'Federal Debt (PSLF)',
                  value: toDollars(
                    dashboardQuery?.superAdmin_Dashboard?.pslfActiveTotalFederalDebt
                  ),
                },
                {
                  text: 'Private Debt',
                  value: toDollars(dashboardQuery?.superAdmin_Dashboard?.totalPrivateDebt),
                },
              ]}
            />
            <CardWithItems
              upperRowItems={[]}
              lowerRowItems={[
                {
                  text: 'Num. of Federal Loans',
                  value: dashboardQuery?.superAdmin_Dashboard?.numberOfFederalLoans.toString(),
                },
                {
                  text: 'Num. of Versions',
                  value: dashboardQuery?.superAdmin_Dashboard?.numberOfLoanVersions.toString(),
                },
                {
                  text: 'Num. of Private Loans',
                  value: dashboardQuery?.superAdmin_Dashboard?.numberOfPrivateLoans.toString(),
                },
              ]}
            />
          </div>

          <div className="flex gap-4 flex-row">
            <CardWithItems
              upperRowItems={[]}
              lowerRowItems={[
                {
                  text: 'Num. of Advisors',
                  value: dashboardQuery?.superAdmin_Dashboard?.numberOfAdvisors.toString(),
                },
                {
                  text: 'Num. of Clients',
                  value: dashboardQuery?.superAdmin_Dashboard?.numberOfClients.toString(),
                },
              ]}
            />
            <CardWithItems
              upperRowItems={[]}
              lowerRowItems={[
                {
                  text: 'Avg. Federal Debt',
                  value: toDollars(dashboardQuery?.superAdmin_Dashboard?.averageFederalLoansDebt),
                },
                {
                  text: 'Avg. Federal Interest Rate',
                  value: toPercentage(
                    dashboardQuery?.superAdmin_Dashboard?.averageFederalInterestRate
                  ),
                },
                {
                  text: 'Avg. Private Debt',
                  value: toDollars(dashboardQuery?.superAdmin_Dashboard?.averagePrivateLoansDebt),
                },
              ]}
            />
          </div>
          {/* <div className="flex gap-4 flex-row">
            <CardWithItems
              title="Federal Debt"
              mainText={toDollars(dashboardQuery?.userDashboard?.totalFederalDebt)}
              items={[
                {
                  text: 'Weighted Interest Rate',
                  value: toPercentage(dashboardQuery?.userDashboard?.weightedFederalInterestRate),
                },
              ]}
            />
            <CardWithItems
              title="Private Debt"
              mainText={toDollars(dashboardQuery?.userDashboard?.totalPrivateDebt)}
              items={[]}
            />
            <CardWithItems
              title="Potential to Refinance"
              mainText={toDollars(dashboardQuery?.userDashboard?.totalRefinanceableDebt)}
              items={[
                {
                  text: 'Federal',
                  value: toDollars(dashboardQuery?.userDashboard?.federalRefinanceableDebt),
                },
                {
                  text: 'Private',
                  value: toDollars(dashboardQuery?.userDashboard?.totalPrivateDebt),
                },
              ]}
            />
            <CardWithItems
              title="PSLF"
              mainText={toDollars(dashboardQuery?.userDashboard?.totalPslfDebt)}
              items={[
                {
                  text: 'Avg. No. of Payments',
                  value: dashboardQuery?.userDashboard?.averageNumberOfPayments?.toString(),
                },
              ]}
            />
          </div> */}
        </div>
      )}
    </>
  );
};
