import { PageHeader } from '../../components/PageHeader';
import { SuperAdminClientsList } from '../../features/super-admin/clients/ClientsList';

export const SuperAdminClientsPage = () => {
  return (
    <>
      <PageHeader breadcrumbs={[{ to: '/super-admin/clients', text: 'Clients List' }]} />

      <SuperAdminClientsList />
    </>
  );
};
