import { useState, useEffect } from 'react';
export const useScrollIntoView = (prefix?: string) => {
  const [scrollIntoId, setScrollIntoId] = useState<string[] | undefined>();

  useEffect(() => {
    if (scrollIntoId?.length) {
      document
        .getElementById(`${prefix}${scrollIntoId[0]}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollIntoId]);

  return { scrollIntoId, setScrollIntoId };
};
