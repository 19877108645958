import { PageHeader } from '../components/PageHeader';
import { Company } from '../features/company/Company';
import useFinologyTranslation from '../translations/useFinologyTranslation';

export const CompanyPage = () => {
  const { t } = useFinologyTranslation();

  return (
    <>
      <PageHeader breadcrumbs={[{ to: '/settings', text: t('breadcrums.manage_tenant') }]} back />

      <Company />
    </>
  );
};
