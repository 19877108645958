import { Button } from 'flowbite-react';

import { PageHeader } from '../components/PageHeader';
import { UsersList } from '../features/users/clients/UsersList';
import { useFlyoutNavigate } from '../hooks/useFlyoutNavigate';

export const UsersPage = () => {
  const { navigate } = useFlyoutNavigate();
  return (
    <>
      <PageHeader
        breadcrumbs={[{ to: '/settings', text: 'Manage Users' }]}
        back
        rightContent={<Button onClick={() => navigate('invite-user')}>Add New Advisor</Button>}
      />

      <UsersList />
    </>
  );
};
