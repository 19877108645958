import dayjs from 'dayjs';
import { Spinner } from 'flowbite-react';
import { Formik, Form, FormikErrors } from 'formik';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../../components/forms/FormErrorNotification';
import {
  useGetFederalLoanVersionLazyQuery,
  useUpdateFederalLoanVersionMutation,
} from '../../../../graphql/generated';
import { notification } from '../../../../util/notification.utils';
import { CheckboxField } from '../../../formComponents/CheckboxField';
import { DateField } from '../../../formComponents/DateField';
import { TextInputField } from '../../../formComponents/InputField';

type FormValues = Partial<{ id: number; source: string; requestDate: any }>;

const getVersionIdFromSearch = (search: string): number => {
  const params = new URLSearchParams(search);

  return Number.parseInt(params.get('versionId')!);
};

const FlyoutContent = () => {
  const { search } = useLocation();

  const versionId = getVersionIdFromSearch(search);

  const [getVersion, query] = useGetFederalLoanVersionLazyQuery();
  const [updateVersion] = useUpdateFederalLoanVersionMutation();

  useEffect(() => {
    async function load(skip?: number, take?: number, filterValue?: string) {
      await getVersion({
        variables: {
          id: versionId,
        },
      });
    }

    load();
  }, []);

  const { closeTopFlyout } = useFlyoutContext();

  if (!query.data?.version) return <Spinner />;

  return (
    <Formik
      initialValues={{
        id: query.data.version.id,
        source: query.data.version.source,
        requestDate: dayjs.utc(query.data.version.requestDate),
        isActive: query.data.version.isActive,
      }}
      onSubmit={async (v) => {
        await updateVersion({
          variables: {
            input: {
              id: v.id,
              source: v.source!,
              requestDate: v.requestDate!,
              isActive: v.isActive,
            },
          },
          refetchQueries: [
            'GetClientDashboard',
            'GetClientFederalLoanVersions',
            'GetSpouseFederalLoanVersions',
          ],
        });

        notification.success({
          placement: 'bottom-center',
          message: 'Version updated successfully',
        });

        closeTopFlyout();
      }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.source) {
          errors.source = 'Required';
        }

        if (!values.requestDate) {
          errors.requestDate = 'Required';
        }

        return errors;
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <>
          <FormErrorNotification />
          <FlyoutHeader
            primaryButton={{
              onClick: submitForm,
              children: 'Save',
              disabled: isSubmitting,
            }}
            secondaryButton={{
              onClick: closeTopFlyout,
              children: 'Cancel',
            }}
            label={'Version'}
          />
          <Form id={'edit-client-federal-loan-version'}>
            <TextInputField name="source" placeholder="Version Name"></TextInputField>
            <DateField name="requestDate" placeholder="Date"></DateField>
            <CheckboxField
              name="isActive"
              label="Use this version as a source of all calculations"
            />
          </Form>
        </>
      )}
    </Formik>
  );
};
export const ClientFederalLoanVersionFlyout = () => {
  return (
    <Flyout
      id={`client-federal-loan-version`}
      requiredParams={['versionId', 'clientId']}
      size={'small'}
      content={<FlyoutContent />}
    ></Flyout>
  );
};
