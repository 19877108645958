import { Label, Textarea } from 'flowbite-react';
import { Field, useFormikContext } from 'formik';
import { ReactNode } from 'react';

const AntInput = ({ field, form, ...props }: any) => {
  const error = !!form.errors[field.name];

  const { onChange, maxLength, ...rest } = props;

  return (
    <>
      <Textarea
        sizing={'lg'}
        id={`form__field_${field.name}`}
        onKeyDown={(e) => {
          // Prevent form submission on enter
          if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.value);

          onChange && onChange(e);
        }}
        autoSize
        onFocus={(e) => form.setFieldTouched(field.name)}
        status={error ? 'error' : undefined}
        value={field.value}
        maxLength={maxLength}
        {...rest}
      ></Textarea>
      {maxLength && !!field.value?.length && (
        <p className=" text-sm text-gray-500">
          {maxLength - (field.value?.length ?? 0)} characters remaining
        </p>
      )}
    </>
  );
};

export const TextAreaField = ({
  name,
  placeholder,
  className,
  suffix,
  prefix,
  onChange,
  step,
  disabled,
  id,
  label,
  maxLength,
}: {
  name: string;
  placeholder: string;
  suffix?: ReactNode;
  prefix?: ReactNode;
  className?: string;
  onChange?: (e: Event) => void;
  step?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  maxLength?: number;
}) => {
  const form = useFormikContext<any>();
  const error = form.errors[name];

  if (error != undefined && typeof error !== 'string' && typeof error !== 'number')
    throw new Error('Error must be of type string');

  return (
    <div className={className} id={id}>
      <Label
        color={error ? 'failure' : undefined}
        htmlFor={`form__field_${name}`}
        className="truncate"
      >
        {label ?? placeholder}
      </Label>
      <Field
        name={name}
        suffix={suffix}
        prefix={prefix}
        placeholder={placeholder}
        component={AntInput}
        onChange={onChange}
        step={step}
        disabled={disabled}
        maxLength={maxLength}
      />
      {error && <p className=" text-sm text-red-700">{error} &nbsp;</p>}
    </div>
  );
};
