import { Table, Tabs } from 'flowbite-react';
import React from 'react';

import { formatCurrency } from '../../utils';

import { STATE_TAX_RATE } from './constants';

const chunk = (array: [string, string][], size: number) => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }

  return chunkedArray;
};

export const Assumptions: React.FC = () => {
  const taxRateChunks = chunk(Object.entries(STATE_TAX_RATE), 11);

  return (
    <Tabs style="underline">
      <Tabs.Item title="Poverty Line 2024">
        <div className="flex gap-16">
          <div>
            <div className="text-md pb-4">48 Contiguous States and the District of Columbia</div>
            <Table>
              <Table.Head>
                <Table.HeadCell>Household Size</Table.HeadCell>
                <Table.HeadCell>Poverty Guideline</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {Array.from(Array(10).keys())
                  .filter((r) => r > 0)
                  .map((index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{index}</Table.Cell>
                      <Table.Cell>{formatCurrency(15060 + 5380 * (index - 1))}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
          <div>
            <div className="text-md pb-4">Alaska</div>
            <Table>
              <Table.Head>
                <th className="pr-12">Household Size</th>
                <th>Poverty Guideline</th>
              </Table.Head>
              <Table.Body>
                {Array.from(Array(10).keys())
                  .filter((r) => r > 0)
                  .map((index) => (
                    <Table.Row
                      style={index != 9 ? { borderBottom: '1px solid #d9d9d9' } : undefined}
                      key={index}
                    >
                      <Table.Cell>{index}</Table.Cell>
                      <Table.Cell>{formatCurrency(18810 + 6730 * (index - 1))}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
          <div>
            <div className="text-md pb-4">Hawaii</div>
            <Table className="table  ">
              <Table.Head>
                <th className="pr-12">Household Size</th>
                <th>Poverty Guideline</th>
              </Table.Head>
              <Table.Body>
                {Array.from(Array(10).keys())
                  .filter((r) => r > 0)
                  .map((index) => (
                    <Table.Row
                      style={index != 9 ? { borderBottom: '1px solid #d9d9d9' } : undefined}
                      key={index}
                    >
                      <Table.Cell>{index}</Table.Cell>
                      <Table.Cell>{formatCurrency(17310 + 6190 * (index - 1))}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
        </div>
        <div className="mt-2">All plans have 150% (SAVE 225%) Poverty Guideline Protection</div>
      </Tabs.Item>
      <Tabs.Item title="Filing Status Deductions">
        <div className="flex gap-16 pb-12">
          <div>
            <div className="text-md pb-4">Standard Deduction Rates</div>
            <Table className="table  text-left w-96">
              <Table.Head>
                <th>Filing Status</th>
                <th>Standard Deduction</th>
              </Table.Head>
              <Table.Body>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>Married Filing Joint</Table.Cell>
                  <Table.Cell>$24,800</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>Married Filing Separate</Table.Cell>
                  <Table.Cell>$12,400</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>Single</Table.Cell>
                  <Table.Cell>$12,400</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Head of Household</Table.Cell>
                  <Table.Cell>$18,650.00</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          <div>
            <div className="text-md pb-4">FICA Tax Rates</div>
            <Table className="table  text-left w-96">
              <Table.Head>
                <th>SS</th>
                <th></th>
              </Table.Head>
              <Table.Body>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>To</Table.Cell>
                  <Table.Cell>$137,700</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>Rate</Table.Cell>
                  <Table.Cell>6.20% (12.40% if self employed)</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Medicare</Table.Cell>
                  <Table.Cell>1.45% (2.90% if self employed)</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
        <div>
          <div>
            <div className="text-md pb-4">Over 65 or Blind</div>
            <Table className="table  text-left w-96">
              <Table.Head>
                <th>Filing Status</th>
                <th>Standard Deduction</th>
              </Table.Head>
              <Table.Body>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>Married Filing Joint</Table.Cell>
                  <Table.Cell>$26,100</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Single</Table.Cell>
                  <Table.Cell>$14,050</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
      </Tabs.Item>
      <Tabs.Item title="Federal Tax Brackets">
        <div className="flex gap-16 pb-12">
          <div>
            <div className="text-md pb-4">Single</div>
            <Table className="table w-96 text-left">
              <Table.Head>
                <th>Taxable Income</th>
                <th>Tax Rate </th>
                <th>Plus</th>
              </Table.Head>
              <Table.Body>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$0</Table.Cell>
                  <Table.Cell>10%</Table.Cell>
                  <Table.Cell>$0</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$9,875.00</Table.Cell>
                  <Table.Cell>12%</Table.Cell>
                  <Table.Cell>$987.50</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$40,125.00</Table.Cell>
                  <Table.Cell>22%</Table.Cell>
                  <Table.Cell>$4,617.50</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$85,525.00</Table.Cell>
                  <Table.Cell>24%</Table.Cell>
                  <Table.Cell>$14,605.50</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$163,300.00</Table.Cell>
                  <Table.Cell>32%</Table.Cell>
                  <Table.Cell>$33,271.50</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$207,350.00</Table.Cell>
                  <Table.Cell>35%</Table.Cell>
                  <Table.Cell>$47,367.50</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>$518,400.00</Table.Cell>
                  <Table.Cell>37%</Table.Cell>
                  <Table.Cell>$156,235.00</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>

          <div>
            <div className="text-md pb-4">Married Filing Joint</div>

            <Table className="table w-96 text-left">
              <Table.Head>
                <th>Taxable Income</th>
                <th>Tax Rate</th>
                <th>Plus</th>
              </Table.Head>
              <Table.Body>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$0</Table.Cell>
                  <Table.Cell>10%</Table.Cell>
                  <Table.Cell>$0</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$19,750.00</Table.Cell>
                  <Table.Cell>12%</Table.Cell>
                  <Table.Cell>$1,975.00</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$80,250.00</Table.Cell>
                  <Table.Cell>22%</Table.Cell>
                  <Table.Cell>$9,235.00</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$171,050.00</Table.Cell>
                  <Table.Cell>24%</Table.Cell>
                  <Table.Cell>$29,211.00</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$326,600.00</Table.Cell>
                  <Table.Cell>32%</Table.Cell>
                  <Table.Cell>$66,543.00</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$414,700.00</Table.Cell>
                  <Table.Cell>35%</Table.Cell>
                  <Table.Cell>$94,735.00</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>$622,050.00</Table.Cell>
                  <Table.Cell>37%</Table.Cell>
                  <Table.Cell>$167,307.00</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
        <div className="flex gap-16 pb-12">
          <div>
            <div className="text-md pb-4">Head of Household</div>
            <Table className="table w-96 text-left">
              <Table.Head>
                <th>Taxable Income</th>
                <th>Tax Rate </th>
                <th>Plus</th>
              </Table.Head>
              <Table.Body>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$0</Table.Cell>
                  <Table.Cell>10%</Table.Cell>
                  <Table.Cell>$0</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$14,100.00</Table.Cell>
                  <Table.Cell>12%</Table.Cell>
                  <Table.Cell>$1,410.00</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$53,700.00</Table.Cell>
                  <Table.Cell>22%</Table.Cell>
                  <Table.Cell>$6,162.00</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$85,500.00</Table.Cell>
                  <Table.Cell>24%</Table.Cell>
                  <Table.Cell>$13,158.00</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$163,300.00</Table.Cell>
                  <Table.Cell>32%</Table.Cell>
                  <Table.Cell>$31,830.00</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$207,350.00</Table.Cell>
                  <Table.Cell>35%</Table.Cell>
                  <Table.Cell>$45,926.00</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>$518,400.00</Table.Cell>
                  <Table.Cell>37%</Table.Cell>
                  <Table.Cell>$154,793.00</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>

          <div>
            <div className="text-md pb-4">Married Filling Separate</div>

            <Table className="table w-96 text-left">
              <Table.Head>
                <th>Taxable Income</th>
                <th>Tax Rate </th>
                <th>Plus</th>
              </Table.Head>
              <Table.Body>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$0</Table.Cell>
                  <Table.Cell>10%</Table.Cell>
                  <Table.Cell>$0</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$9,875.00</Table.Cell>
                  <Table.Cell>12%</Table.Cell>
                  <Table.Cell>$987.50</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$40,125.00</Table.Cell>
                  <Table.Cell>22%</Table.Cell>
                  <Table.Cell>$4,617.50</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$85,525.00</Table.Cell>
                  <Table.Cell>24%</Table.Cell>
                  <Table.Cell>$14,605.50</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$163,300.00</Table.Cell>
                  <Table.Cell>32%</Table.Cell>
                  <Table.Cell>$33,271.50</Table.Cell>
                </Table.Row>
                <Table.Row style={{ borderBottom: '1px solid #d9d9d9' }}>
                  <Table.Cell>$207,350.00</Table.Cell>
                  <Table.Cell>35%</Table.Cell>
                  <Table.Cell>$47,367.50</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>$518,400.00</Table.Cell>
                  <Table.Cell>37%</Table.Cell>
                  <Table.Cell>$156,235.00</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
      </Tabs.Item>
      <Tabs.Item title="State Tax Rate 2022">
        <div className="flex flex-row gap-8 text-sm ">
          {taxRateChunks.map((chunk, index) => (
            <div
              key={index}
              className="h-min border-solid border-1 border-fino-grey-light rounded-lg	 overflow-hidden [&>*:nth-child(odd)]:bg-fino-grey-dark-shade bg-white"
            >
              {chunk.map(([state, taxRate]: string[], i: number) => (
                <div key={state} className="flex">
                  <div
                    style={{
                      borderRightWidth: 0.5,
                    }}
                    className="font-bold p-3 w-14 border-solid border-l-0 border-t-0 border-b-0 border-fino-grey-light "
                  >
                    {state}
                  </div>
                  <div className="p-3 w-24">{taxRate}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Tabs.Item>
    </Tabs>
  );
};
