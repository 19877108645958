import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';
import { FinologyTable } from '../../../components/table/FinologyTable';

import { DATA_IDR_PLANS, DATA_REPAYMENT_PLANS } from './table-data';

export const IncomeDrivenRepaymentPlan = () => {
  const columns = [
    {
      title: 'IDR Plans	',
      dataIndex: 'plan',
      key: 'plan',
      render: (text: string) => text,
    },
    {
      title: 'Eligibility',
      dataIndex: 'eligibility',
      key: 'eligibility',
      render: (data: { title: string; bulletpoints: string[] }) => (
        <div>
          <p>{data.title}</p>
          <ul className="mt-4">
            {data.bulletpoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </div>
      ),
    },
    {
      title: 'Monthly Payment Amount',
      dataIndex: 'monthlyPaymentAmount',
      key: 'monthlyPaymentAmount',
      render: (text: string) => text,
    },
  ];

  const IDRColumns = [
    {
      title: 'Repayment Plan',
      dataIndex: 'plan',
      key: 'plan',
      render: (text: string) => text,
    },
    {
      title: '% of Discretionary Income',
      dataIndex: 'discretionaryIncome',
      key: 'discretionaryIncome',
      render: (text: string) => text,
    },
    {
      title: 'Repayment Period (in years)',
      dataIndex: 'repaymentPeriod',
      key: 'repaymentPeriod',
      render: (text: string) => text,
    },
  ];

  return (
    <div className="text-gray-500 p-6">
      <Heading title="Income-Driven Repayment (IDR) Plans" />
      <p>
        IDR plans base your monthly payment amount on how much money you make and your family size.
        There are four IDR Plans:
        <br />
        -- Saving on a Valuable Education (SAVE) Plan -- formaly the REPAYE plan
        <br />
        -- Pay as You Earn (PAYE) Repayment plan
        <br />
        -- Income-Based Repayment (IBR) Plan
        <br />
        -- Income-Contingent Repayment (ICR) Plan
      </p>
      <Paragraph text="After satisfying a certain number of months of qualifying payments on an IDR plan, you can get the remaining balance of your loan(s) forgiven." />
      <Paragraph text="Because payments are based on your income and family size, you must provide your loan servicer with updated income and family size information each year so that your servicer can recalculate your payment amount. This process is called recertification. Your must recertify your plan even if there has been no change in your income or family size." />
      <Paragraph
        className="mb-8"
        text="If you agree to the secure disclosure of your tax information, the Federal Student Aid office and your loan servicer will automatically recertfy your enrollment in IDR and adjust your monthly payment amount once a year. You'll be notified when your payment is changing, and you'll always be able to recertify your plan manually."
      />
      <FinologyTable columns={columns} rows={DATA_IDR_PLANS} hideFooter={true} type="no-promise" />
      <Heading title="Compare IDR Plans	" className="my-8" />
      <FinologyTable
        columns={IDRColumns}
        rows={DATA_REPAYMENT_PLANS}
        hideFooter={true}
        type="no-promise"
      />
    </div>
  );
};
