import { useEffect } from 'react';

export const useInputWheelEventListener = () => {
  useEffect(() => {
    document.addEventListener('wheel', () => {
      if ((document.activeElement as HTMLInputElement)!.type === 'number') {
        (document.activeElement as HTMLInputElement)!.blur();
      }
    });

    return () => {
      document.removeEventListener('wheel', () => {
        if ((document.activeElement as HTMLInputElement)!.type === 'number') {
          (document.activeElement as HTMLInputElement)!.blur();
        }
      });
    };
  }, []);
};
