import { Label, Spinner, Tooltip } from 'flowbite-react';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { HiInformationCircle } from 'react-icons/hi';
import Select, { components } from 'react-select';

interface Styles {
  control?: any;
  value?: any;
  indicatorContainer?: any;
}

interface ItemProps {
  label: string;
  value: string;
}

interface SelectFieldProps {
  name: string;
  label?: string;
  className?: string;
  children: React.ReactNode;
  onChange?: (value: any) => void;
  type?: 'text' | 'number';
  disabled?: boolean;
  props?: any;
  allowClear?: boolean;
  tooltip?: React.ReactNode;
  removeErrorPlaceholder?: boolean;
  styles?: Styles;
  size?: 'sm' | 'md' | 'lg';
  isSearchable?: boolean;
  onLoadMore?: () => void;
  hasMore?: boolean;
  loading?: boolean;
  onInputChange?: (e: string) => void;
}

const CustomMenuList = (props: any) => {
  const { children, selectProps } = props;

  return (
    <components.MenuList {...props}>
      {children}
      {selectProps.loading && (
        <div className="flex items-center justify-center p-2">
          <Spinner />
        </div>
      )}
      {!selectProps.loading && selectProps.hasMore && (
        <div
          className="flex items-center justify-center p-2 text-primary-500 hover:text-primary-700 cursor-pointer hover:bg-primary-50"
          onClick={selectProps.onLoadMore}
        >
          Load More
        </div>
      )}
    </components.MenuList>
  );
};

const AntSelect = ({ field, form, options, ...props }: any) => {
  const opt = Array.isArray(props.children)
    ? props.children
        .flat()
        .filter((x: any) => x)
        .map((c: any) => ({
          value: c.props.value,
          label: c.props.children,
        }))
    : props.children.map((c: any) => ({
        value: c.props.value,
        label: c.props.children,
      })); //HACK

  const selected = opt.filter((option: ItemProps) => field.value == option.value);

  return (
    <Select
      {...props}
      isClearable={props.allowClear === undefined ? true : props.allowClear}
      id={`form__field_${field.name}`}
      value={selected}
      placeholder={props.placeholder}
      isDisabled={props.disabled}
      className={props.className}
      isSearchable={props.isSearchable}
      options={opt}
      components={{ MenuList: CustomMenuList }}
      loading={props.loading}
      hasMore={props.hasMore}
      onInputChange={(e) => props.onInputChange && props.onInputChange(e)}
      onLoadMore={props.onLoadMore}
      styles={{
        control: (provided, state) => ({
          ...provided,
          flexGrow: 1,
          borderRadius: '0.5rem',
          backgroundColor: props?.styles?.control?.backgroundColor || 'rgb(249, 250, 251)',
          minHeight: 'unset',
          margin: 0,
          padding: '2px',
          ...props?.styles?.control,
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: props?.styles?.value?.color || 'inherit',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          fontSize: '14px',
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          backgroundColor:
            props?.styles?.indicatorContainer?.backgroundColor || 'rgb(249, 250, 251)',
        }),
      }}
      onKeyDown={(e) => {
        // Prevent form submission on enter
        if (e.key === 'Enter') {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onChange={(e: { value: any }) => {
        let val: any = e?.value;

        if (props.type == 'number' && e != null && e != undefined) val = Number.parseInt(val);

        props.onChange ? props.onChange(val) : form.setFieldValue(field.name, val);
      }}
    />
  );
};

export const SelectField: React.FC<SelectFieldProps> = ({
  name,
  label,
  className,
  children,
  onChange,
  type,
  disabled,
  tooltip,
  ...props
}: SelectFieldProps) => {
  const form = useFormikContext<any>();

  const error = form.errors[name];

  if (error != undefined && typeof error !== 'string' && typeof error !== 'number')
    throw new Error('Error must be of type string');

  return (
    <div className={`${className} mt-1`}>
      {label ? (
        <Label color={error ? 'failure' : undefined} htmlFor={`form__field_${name}`}>
          <div className="truncate">
            {tooltip ? (
              <>
                <Tooltip
                  theme={{
                    target: 'flex justify-between',
                  }}
                  content={tooltip}
                >
                  {label}&nbsp;
                  <HiInformationCircle className="inline" />
                </Tooltip>
              </>
            ) : (
              label
            )}
          </div>
        </Label>
      ) : null}
      <Field
        name={name}
        type={type || 'text'}
        onChange={onChange}
        placeholder={label}
        component={AntSelect}
        disabled={disabled}
        className={className}
        isSearchable={props.isSearchable}
        {...props}
      >
        {children}
      </Field>
      {error && !error ? null : <p className=" text-sm text-red-700">{error} &nbsp;</p>}
    </div>
  );
};

export default SelectField;
