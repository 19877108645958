import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';

export const PAYECalculation = () => {
  const loanInformation = [
    { label1: 'Spouse 1 Student Loan Balance (Higher of both debt amounts)', label2: '$310,000' },
    {
      label1: 'Spouse 2 Student Loan Balance, if applicable (Lower of both debt amounts)',
      label2: '$0',
    },
    { label1: 'Average Interest Rate', label2: '5.75%' },
    { label1: 'Inflation Rate Assumption', label2: '3.00%' },
    { label1: 'Paying Loan since what year?', label2: '2013' },
    { label1: 'Do you work for a not-for-profit or government employer?', label2: 'No' },
    { label1: 'Did you borrow before October 1, 2007?', label2: 'No' },
    { label1: 'What percent of your loans are from grad school?', label2: '0%' },
    { label1: 'Total Years of Repayment for the ICR plan', label2: '25' },
    { label1: 'Number of Years in Repayment thus far', label2: '11' },
    { label1: 'Term of loan (yrs):', label2: '20' },
    { label1: 'Installments per year:', label2: '12' },
  ];

  const personalInformation = [
    { label1: 'Where do you live', label2: 'Hawaii' },
    { label1: 'Current Family Size', label2: '1' },
    { label1: 'Tax Filing Status when Married', label2: 'Married Filing Jointly' },
    { label1: 'Poverty Level', label2: '$17,310' },
  ];

  const incomeInformation = [
    { label1: 'What is your expected AGI for 2024?', label2: '$99,000' },
    { label1: "What is your spouse's expected AGI for 2024?", label2: '' },
    { label1: 'What is your expected salary growth rate?', label2: '3.00%' },
    { label1: "What is your spouse's expected salary growth rate?", label2: '' },
    { label1: 'Your discretionary income is...', label2: '$73,035.00' },
  ];

  return (
    <div className="text-gray-500 p-6">
      <Paragraph text="Pay As You Earn (PAYE) is a repayment plan for federal student loans that caps month payments at 10% of your discretionary income. This is based on poverty guidelines, and the calculation reflects your minimum monthly payment. Your payments are generally equal to 10% of your discretionary income, divided by 12, but never more than the 10-year Standard Repayment amount. You can always pay more if it makes financial sense to do so. To remain in PAYE you must provide yearly documentation of your income.	" />
      <Paragraph text="IDR Plans calculate your monthly payment amount based on our income and family size. So if your income increases, so does your payment amount. On PAYE, the payment is limited or capped so that even if your income increases, your payments never go higher than what you'd pay on the Standard Plan.	" />
      <Paragraph text="If your income goes down again, your servicer will recalculate your payment when your recertify (update your income information), and you'll go back to making payments that are based on your income again. You can always recertify earlier than your annual recertification date.	" />
      <Heading title="PAYE Income Cap" />
      <Paragraph text="To qualify for the PAYE plan, your monthly payment must be less than what you would pay under the Standard Repayment Plan with a 10-year repayment period. If your payment would be more under the Standard Plan, you wouldn't benefit from the PAYE Plan. That's because you'd end up paying off your loan before you'd be able to get IDR plan forgiveness.	" />
      <Paragraph text="Generally, you'll meet this requirement if your federal student loan debt is higher than your annual discretionary income or if it represents a significant portion of your annual income.	" />
      <Heading title="PAYE New Borrower Requirement" />
      <Paragraph text="To qualify for the PAYE Plan you must be a new borrower. This mean that you must have had no outstanding balance on a Direct Loan or FFEL Program loan when ou received a Direct Loan or FFEL Program loan on or after October 1, 2007, and you must have received a disbursment of a Direct Loan on or after October 1, 2011.	" />

      <Heading title="How is the PAYE Payment Calculated?	" />
      <p>
        Income Based Repayment Calculations depend on your income.
        <br />
        While the definition of income can change depending on the loan type, for student loans
        Adjusted Gross Income (AGI) is what matters.
        <br />
        Adjusted Gross Income
        <br />
        Example:
        <br />
        Bob&apos;s Income:
        <br />
        1. $50,000 salary/wages
        <br />
        2. $12,000 in rental income
        <br />
        3. $8,500 wages earned as a part-time Uber driver
        <br />
        4. $500 from interest from bonds
        <br />
        Gross Income = $71,000
        <br />
        <br />
        Adjustment from his gross income:
        <br />
        1. $250 in educator expenses
        <br />
        2. $2,500 in student loan interest
        <br />
        Adjustments = $2,750
        <br />
        <br />
        Subtracting allowable adjustments ($2,750) from the Total Income ($71,000), Bob&apos;s
        Adjusted Gross Income (AGI) is $68,250
        <br />
        <br />
        1. Determine your discretionary income
        <br />
        Start with AGI (Line 11 on the 1040 Tax Form) and subtract 150% of the federal poverty
        guideline level for your family size.
        <br />
        AGI - (150% x Poverty Level) = your discretionaty income
        <br />
        <br />
        2. Determine if loans are 100% Undergraduate loans or a combination of Undergraduate and
        Graduate Loans.
        <br />
        If loans are all Graduate Loans or a combination of both kinds, multiply discretionary
        income by 10% for PAYE.
        <br />
        [AGI - (150% x Poverty Level)] x 10% = annual payment for PAYE
        <br />
        <br />
        3. Divide the total from number 2 by 12 for monthly payments.
      </p>

      <Heading title="Calculating your PAYE monthly payment Explained:" />
      <Paragraph text="The PAYE payment is 10% of your Discretionary Income, a government measure based on poverty guidelines." />
      <Paragraph text="For the PAYE payment your Discretionary Income is the difference between your taxable income and 150 percent of the HHS Poverty Guideline amout for your family size and state.	" />
      <Paragraph text="Discretionary Income = Adjusted Gross Income - (150% x HHS federal poverty guidelines)" />
      <Paragraph text="Using the above formula to calculate your 2024 Disretionary Income for a family size of one, living in the 48 Contiguous States and a taxable income of $99,000:	" />
      <Paragraph text="Discretionary Income = $99,000 - (1.5 x $15,060) = $76,410.00" />
      <Paragraph text="The PAYE monthly payment 10% of your Discretionary Income divided by 12" />
      <Paragraph text="PAYE = 0.10 x $76,410 = $7,641 per year or $636.75 per month" />

      {/* div max w 45 percent */}
      {/* <div className=" max-w-[800px]">
        <Heading title="Loan Information" className="mt-8" />
        {loanInformation.map((data, index) => (
          <SpaceBetweenLabels key={index} {...data} />
        ))}
        <Heading title="Personal Information" />
        {personalInformation.map((data, index) => (
          <SpaceBetweenLabels key={index} {...data} />
        ))}
        <Heading title="Income Information" />
        {incomeInformation.map((data, index) => (
          <SpaceBetweenLabels key={index} {...data} />
        ))}

        <Heading title="Calculating your PAYE monthly payment:" />

        <Paragraph text="Calculating PAYE payment based on 10% of discretionary income" />
        <div className="max-w-40">
          <SpaceBetweenLabels label1="Annual PAYE Payment:" label2="$7,303.50" />
          <SpaceBetweenLabels label1="Monthly PAYE Payment:" label2="$608.63" />
        </div>
      </div>
      <Heading title="Additional:" />
      <p>
        ICR Discretionary Income is : AGI - Poverty Guidelines <br />
        Then
        <br />
        20% * ICR Discretionary Income divided by 12
        <br />
        <br />
        PAYE Discretionary Income is: AGI - (1.5 * Poverty Guidelines)
        <br />
        then
        <br />
        10% * PAYE Discretionary Income divided by 12
      </p> */}
    </div>
  );
};
