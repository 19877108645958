export const FederalLoanTypes = () => {
  return (
    <div className="p-6 text-gray-500">
      Currently, the main federal student loan types are:
      <br />
      <br />
      <span className="font-semibold text-gray-800">1. Direct Subsidized Loans:</span> These are
      need-based loans for undergraduate students where the government pays the interest while the
      student is in school, during the grace period, and during deferment periods.
      <br />
      <br />
      <span className="font-semibold text-gray-800">2. Direct Unsubsidized Loans:</span> These are
      non-need-based loans for undergraduate and graduate students where the student is responsible
      for paying all the interest.
      <br />
      <br />
      <span className="font-semibold text-gray-800">3. Direct PLUS Loans:</span> These are loans for
      graduate students and parents of dependent undergraduate students. They are not need-based.
      <br />
      <br />
      <span className="font-semibold text-gray-800">4. Direct Consolidation Loans:</span> These
      allow borrowers to combine multiple federal student loans into a single new loan with a fixed
      interest rate.
      <br />
      <br />
      <span className="font-semibold text-gray-800"> 5. Federal Perkins Loans:</span> These are
      need-based loans for undergraduate and graduate students administered directly by the
      school&apos;s financial aid office. However, this program ended in 2017 and no new Perkins
      Loans have been issued since then.
      <br />
      <br />
      The key differences between these loan types are the interest rates, eligibility requirements,
      and whether the government pays the interest while the student is in school (subsidized vs
      unsubsidized). Borrowers should review the terms and conditions of each loan type to determine
      which one best fits their financial situation.
      <br />
      <br />
      <br />
      Direct consolidated subsized
      <br />
      direct consolidated unsubsized
      <br />
      Direct Parent Plus
      <br />
      Direct Parent Plus Consolidated
      <br />
      Direct Plus Graduate
      <br />
      Direct Stafford Subsidized
      <br />
      Direct Stafford Subsidized SULA eligible
      <br />
      Direct Stafford Unsubsidized
      <br />
      FFEL Consolidated
      <br />
      FFEL Parent Plus
      <br />
      FFEL Parent Plus Consolidated
      <br />
      FFEL Plus Graduate
      <br />
      FFEL Stafford Subsidized
      <br />
      FFEL Stafford Unsubsidized
      <br />
      <br />
      <br />
      <span className="font-semibold text-gray-800">1. Direct Consolidated Subsidized Loans:</span>
      <br />
      - Definition: A Direct Consolidation Loan that includes one or more subsidized federal student
      loans.
      <br />
      - Key Understanding: The government pays the interest on the subsidized portion of the loan
      while the student is in school, during the grace period, and during deferment periods.
      <br />
      <br />
      <span className="font-semibold text-gray-800">
        2. Direct Consolidated Unsubsidized Loans:
      </span>
      <br />
      - Definition: A Direct Consolidation Loan that includes one or more unsubsidized federal
      student loans.
      <br />
      - Key Understanding: The borrower is responsible for paying all the interest on the
      unsubsidized portion of the loan.
      <br />
      <br />
      <span className="font-semibold text-gray-800">3. Direct Parent PLUS Loans:</span>
      <br />
      - Definition: Federal student loans made to parents of dependent undergraduate students to
      help pay for education expenses.
      <br />
      - Key Understanding: Parents, not students, are the borrowers and responsible for repayment.
      Interest accrues from the time the loan is disbursed.
      <br />
      <br />
      <span className="font-semibold text-gray-800">4. Direct Parent PLUS Consolidated Loans:</span>
      <br />
      - Definition: A Direct Consolidation Loan that includes one or more Direct Parent PLUS Loans.
      <br />- Key Understanding: Consolidates multiple Parent PLUS Loans into a single new loan with
      a fixed interest rate.
      <br />
      <br />
      <span className="font-semibold text-gray-800">
        5. Direct PLUS Loans for Graduate/Professional Students:
      </span>
      <br />
      - Definition: Federal student loans made to graduate or professional degree students to help
      pay for education expenses.
      <br />
      - Key Understanding: Graduate students, not parents, are the borrowers and responsible for
      repayment. Interest accrues from the time the loan is disbursed.
      <br />
      <br />
      <span className="font-semibold text-gray-800">6. Direct Stafford Subsidized Loans:</span>
      <br />
      - Definition: Need-based federal student loans for undergraduate students where the government
      pays the interest.
      <br />
      - Key Understanding: The government pays the interest while the student is in school, during
      the grace period, and during deferment periods.
      <br />
      <br />
      <span className="font-semibold text-gray-800">
        7. Direct Stafford Subsidized Loans - SULA Eligible:
      </span>
      <br />
      - Definition: Subsidized Stafford Loans subject to the Subsidized Usage Limit Applied (SULA)
      rule.
      <br />
      - Key Understanding: Borrowers can only receive subsidized loans for a maximum of 150% of the
      published length of their academic program.
      <br />
      <br />
      <span className="font-semibold text-gray-800">8. Direct Stafford Unsubsidized Loans:</span>
      <br />
      - Definition: Non-need-based federal student loans for undergraduate and graduate students
      where the borrower is responsible for all interest.
      <br />
      - Key Understanding: The borrower must pay all interest that accrues, even while in school.
      <br />
      <br />
      <span className="font-semibold text-gray-800">9. FFEL Consolidated Loans:</span>
      <br />
      - Definition: Consolidation loans made under the discontinued Federal Family Education Loan
      (FFEL) program.
      <br />
      - Key Understanding: These loans were made by private lenders, but are now managed by the
      federal government.
      <br />
      <br />
      10-15. The FFEL versions of Parent PLUS, Graduate PLUS, and Stafford (subsidized and
      unsubsidized) Loans function similarly to their Direct Loan counterparts, but were originated
      by private lenders rather than the federal government.
    </div>
  );
};
