import { useEffect, useState } from 'react';

import { FullNameLabel } from '../../components/labels/FullNameLabel';
import { useGetUsersForSelectorLazyQuery } from '../../graphql/generated';

import SelectField from './SelectField';

export const UserSelectorField = ({
  name,
  placeholder,
  selectPlaceholder,
  className,
  idToExclude,
  onValueChanged,
}: {
  name: string;
  placeholder?: string;
  selectPlaceholder?: string;
  className?: string;
  idToExclude?: number | null;
  onValueChanged?: (selectedId: string | undefined) => Promise<void>;
}) => {
  const [getUsers] = useGetUsersForSelectorLazyQuery();
  const [uiUsers, setUiUsers] = useState<
    { firstName: string; lastName?: string | null; id: number }[]
  >([]);

  useEffect(() => {
    async function load() {
      const { data } = await getUsers();

      setUiUsers(data!.users!.items!.filter((x) => x.id != idToExclude));
    }

    load();
  }, []);

  const options = uiUsers.map((d) => {
    return (
      <option value={d.id} key={d.id.toString()}>
        {FullNameLabel({
          firstName: d.firstName,
          lastName: d.lastName,
        })}
      </option>
    );
  });

  return (
    <SelectField
      // status={error ? 'error' : undefined}
      // showSearch
      name={name}
      label={placeholder}
      // defaultActiveFirstOption={false}
      // showArrow={true}
      // filterOption={false}
      // onSearch={onSearch}
      // onClear={() => onChange(null)}
      // notFoundContent={
      //   debouncedInputValue && !clients.length
      //     ? 'No clients found that match this criteria'
      //     : 'Start typing to search for clients...'
      // }
    >
      {options}
    </SelectField>
  );

  // return (
  // <div className={classNames('flex flex-1 flex-col', className)}>
  //   {placeholder ? (
  //     <label htmlFor={`form__field_${name}`} className="text-primary">
  //       <span>{placeholder}</span>
  //     </label>
  //   ) : null}
  //   <Select
  //     onFocus={() => context.setFieldTouched(name)}
  //     color={error ? 'failure' : undefined}
  //     // showSearch
  //     value={inputValue || undefined}
  //     placeholder={selectPlaceholder}
  //     // defaultActiveFirstOption={false}
  //     // showArrow={true}
  //     // filterOption={false}
  //     // onSearch={onSearch}
  //     // onClear={() => onChange(undefined)}
  //     // notFoundContent={
  //     //   debouncedInputValue && !users.length
  //     //     ? 'No users found that match this criteria'
  //     //     : 'Start typing to search for users...'
  //     // }
  //     onChange={(e) => {
  //       onChange(e.target.value);
  //     }}
  //   >
  //     {options}
  //   </Select>
  // </div>
  // );
};
