import { RepaymentPlanEnum } from './Calculator.types';

export const isOnIdrPlan = (repaymentPlanType: RepaymentPlanEnum) => {
  switch (repaymentPlanType) {
    case RepaymentPlanEnum.Standard:
    case RepaymentPlanEnum.Graduated:
    case RepaymentPlanEnum.Extended:
      return false;

    default:
      return true;
  }
};

export const hasAnyResults = (simulationResult: any, repaymentPlan: RepaymentPlanEnum): boolean => {
  if (!simulationResult.fr) return false;
  return (
    simulationResult.fr.length &&
    simulationResult.fr[0].filter((r: any) => r.key == repaymentPlan).length
  );
};

export const getRepaymentlabel = (repaymentPlanType: RepaymentPlanEnum) => {
  switch (repaymentPlanType) {
    case RepaymentPlanEnum.Standard:
      return 'STANDARD';

    case RepaymentPlanEnum.Icr:
      return 'ICR';
    case RepaymentPlanEnum.Ibr:
      return 'IBR';

    case RepaymentPlanEnum.Paye:
      return 'PAYE';

    case RepaymentPlanEnum.Repaye:
      return 'REPAYE';

    case RepaymentPlanEnum.Save:
      return 'SAVE';

    case RepaymentPlanEnum.Graduated:
      return 'GRADUATED';

    case RepaymentPlanEnum.Extended:
      return 'EXTENDED';

    default:
      return repaymentPlanType;
  }
};
