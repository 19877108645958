import { Heading } from '../../../components/Assumptions/Heading';
import { Paragraph } from '../../../components/Assumptions/Paragraph';

export const SaveCalculations = () => {
  return (
    <div className="text-gray-500 p-6">
      <Paragraph text="The Saving on a Valuable Education (SAVE) Plan is the newest income-driven repayment (IDR) plan. Like other IDR plans, the SAVE Plan calculates your monthly payment amount based on your income and family size. In addition, the SAVE Plan has unique benefits that will lower payments for many borrowers." />
      <Paragraph text="The SAVE Plan replaced the Revised Pay AS You Earn (REPAYE) Plan. Borrowers on the REPAYE Plan automatically get the benefits of the new SAVE Plan." />
      <Paragraph text="The SAVE Plan decreases monthly payments by increasing the income exemption from 150% to 225% of the poverty line. This means SAVE can significantly decrease your monthly payment amount compared to other IDR plans." />
      <Paragraph text="Because your required monthly payment is a percentage of your discretionary income, your payment will be $0 of your discretionary income is $0. For example, 225% of the Poverty Guideline amount for a family size of one (in the 48 contiguous states) is $32,800, which means that if your annual income is equal to or less than $32,800 and your family size is just yourself, your discretionary income is $0 andyour monthly payment will be $0. The same is true for a family size of four with an annual income of $67,500 or less." />
      <Heading title="Interest Benefits Stops your Balance from Growing" />
      <Paragraph text="The SAVE Plan eliminates 100% of remaining monthly interest for both subsidized and unsubsidized loans after you make a full scheduled payment. This means that if you make your monthly payment, your loan balance won't grow due to unpaid interest that accrued since your last payment." />
      <Paragraph text="For example: If $50 in interest accumulates each month and you have a $30 scheduled payment, the remaining $20 would not be charged once you make your monthly payment on time." />
      <Heading title="Excludes Spouse's Income if Filing Taxes Separately" />
      <Paragraph text="The SAVE Plan excludes spousal income for borrowers who are married and file taxes separately . Previously on the REPAYE Plan, your spouse's income was included as part of the total income used to determine your monthly payment amount, regardless of your tax filing status. Under the new SAVE Plan, if you file separately, your monthly payment amount will be based on your income only. This change also removes the need for your spouse to cosign your IDR application, resulting in a simploer application process." />
      <Heading title="How much will I pay each month?" />
      <Paragraph text="The SAVE Plan calculates your monthly payment based on your income and family size. If you're making $32,800 per year or less (roughly $15 dollars per hour), your monthly payment will be $0, and if you make more than that you'll still save at least $1,000 per year compared to what you would have paid under the REPAYE Plan." />
      <Heading title="How is a Basic SAVE Payment Calculated?" />
      <p>
        Income Based Repayment Calculations depend on your income. While the definition of income
        can change depending on the loan type, for student loans Adjusted Gross Income (AGI) is what
        matters. Adjusted Gross Income Example: Bob&apos;s Income:
      </p>
      <ul className="mt-4">
        <li>1. $50,000 salary/wages</li>
        <li>2. $12,000 in rental income</li>
        <li>3. $8,500 wages earned as a part-time Uber driver</li>
        <li>4. Bob&apos;s Discretionary Income: $37,120</li>
        <li>5. $500 from interest from bonds</li>
      </ul>
      <p className="mt-2">Gross Income = $71,000</p>
      <p>Adjustment from his gross income:</p>
      <ul className="mt-4">
        <li>1. $250 in educator expenses</li>
        <li>2. $2,500 in student loan interest</li>
      </ul>
      <p className="mt-2">Adjustments = $2,750</p>
      <Paragraph text="Subtracting allowable adjustments ($2,750) from the Total Income ($71,000), Bob's Adjusted Gross Income (AGI) is $68,250" />
      <p>
        The formula used will change based on what IDR plan you choose.
        <br /> Start with the general income-based repayment formula <br />
        <br />
        1. Determine your discretionary income
        <br /> Start with AGI (Line 11 on the 1040 Tax Form) and subtract 225% of the federal
        poverty guideline level for your family size.
        <br /> AGI - (225% x Poverty Level) = your discretionaty income on SAVE. <br />
        <br />
        3. Determine if loans are 100% Undergraduate loans or a combination of Undergraduate and
        Graduate Loans. <br />
        If all loans are Undergraduate loans, multiply discretionary income by 5%.
        <br /> If loans are all Graduate Loans or a combination of both kinds, multiply
        discretionary income by 10%. [AGI - (225% x Poverty Level)] x (number between 5% and 10%) =
        annual payment. <br />
        It is important to know the multiplier for the SAVE plan will flucuate. This number is
        calculated based on how much undergrad debt you have relative to graduate debt.
        <br />
        <br /> 4. Divide the total from number 2 by 12 for monthly payments.
      </p>
    </div>
  );
};
