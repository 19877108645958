import { Tabs } from 'flowbite-react';
import { useState } from 'react';
import { HiUserCircle } from 'react-icons/hi';
import { PiUserFill } from 'react-icons/pi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';

import { CreditCardSimulationResults } from './CreditCardSimulationResults';
import { ForgivenessResults } from './ForgivenessResults';
import { PrivateLoansSimulationResults } from './PrivateLoansSimulationResults';
import { Recap } from './Recap';
import { RepaymentPlansResultsTable } from './RepaymentPlansResultsTable';

const SimulationResult = () => {
  const location = useLocation();
  const { calculatorInput, simulationResults, initialData } = location.state;
  const { closeTopFlyout } = useFlyoutContext();
  const [activeTab, setActiveTab] = useState(0);
  const params = useParams();
  const navigate = useNavigate();

  const clientId = Number.parseInt(params.clientId!);

  if (!simulationResults) return null;

  if (
    !simulationResults?.paymentOptions?.federal &&
    !simulationResults?.paymentOptions?.private &&
    !simulationResults?.paymentOptions?.creditCard
  )
    return (
      <div className="flex items-center justify-center text-lg py-10">
        No results available for the current configuration. Please adjust your configuration and
        rerun the simulation.
      </div>
    );

  const tabs = [
    {
      title: 'Federal Loan Simulator',
      Icon: (
        <HiUserCircle
          className={activeTab === 0 ? `fill-primary-600` : 'fill-gray-500'}
          size={20}
        />
      ),
      content: simulationResults?.paymentOptions?.federal ? (
        <>
          <div className="text-gray-900 text-lg font-semibold">Overview</div>
          <Recap results={simulationResults?.paymentOptions?.federal} />
          <div className="text-gray-900 text-lg font-semibold mt-8">Repayment Plans</div>
          <RepaymentPlansResultsTable
            simulationResult={simulationResults?.paymentOptions.federal}
            calculatorInput={calculatorInput}
          />
          <ForgivenessResults
            amountForgiven={simulationResults.paymentOptions?.federal.amountForgiven}
            simulationResult={simulationResults?.paymentOptions.federal}
            calculatorInput={calculatorInput}
          />
        </>
      ) : null,
      active: activeTab === 0,
    },
    {
      title: 'Private Loan Planner',
      Icon: <PiUserFill className={activeTab === 1 ? `fill-primary-600` : 'fill-gray-500'} />,
      content:
        simulationResults?.paymentOptions?.private ||
        simulationResults?.paymentOptions?.creditCard ? (
          <>
            {!!simulationResults?.paymentOptions?.private && (
              <PrivateLoansSimulationResults
                simulationResult={simulationResults?.paymentOptions?.private}
              />
            )}
            {!!simulationResults?.paymentOptions?.creditCard && (
              <CreditCardSimulationResults
                calculatorInput={calculatorInput}
                simulationResult={simulationResults?.paymentOptions?.creditCard}
              />
            )}
          </>
        ) : null,
      active: activeTab === 1,
    },
  ];

  return (
    <>
      <FlyoutHeader
        secondaryButton={{
          children: 'Create Report',
          disabled: !simulationResults?.paymentOptions?.federal,
          onClick: () =>
            navigate(`?flyout=simulation-results,create-report&clientId=${clientId}`, {
              state: {
                calculatorInput: calculatorInput,
                initialData: initialData,
                simulationResults: simulationResults,
              },
            }),
        }}
        primaryButton={{
          children: 'Close',
          onClick: closeTopFlyout,
        }}
        label={`Simulation for ${initialData.client.fullName}`}
      />
      <Tabs onActiveTabChange={(index) => setActiveTab(index)} style="underline">
        {tabs
          .filter((tab) => tab.content)
          .map((tab, index) => (
            <Tabs.Item
              key={index}
              active={tab.active}
              title={
                <div className="flex items-center justify-center gap-1">
                  {tab.Icon}
                  {tab.title}
                </div>
              }
            >
              <div className="mt-8">{tab.content}</div>
            </Tabs.Item>
          ))}
      </Tabs>
    </>
  );
};

export const SimulationResultsFlyout = () => {
  return <Flyout id={`simulation-results`} size={'large'} content={<SimulationResult />}></Flyout>;
};
