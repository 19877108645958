import { Button } from 'flowbite-react';
import { useRef, useState } from 'react';

import { useFlyoutContext } from '../../components/flyout/Flyout';
import { FlyoutHeader } from '../../components/flyout/FlyoutHeader';
import { useResetPasswordClientMutation } from '../../graphql/generated';

export const ClientProfileFlyoutContent = () => {
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);

  const [resetPassword] = useResetPasswordClientMutation();
  const isLoading = useRef(false);
  const { closeTopFlyout } = useFlyoutContext();

  const handleResetPassword = async () => {
    isLoading.current = true;

    try {
      const response = await resetPassword();
      const { data } = response;
      if (data?.resetPassword!.success) {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }
    } catch (error) {
      setIsSuccess(false);
    } finally {
      isLoading.current = false;
    }
  };

  return (
    <>
      <FlyoutHeader
        primaryButton={{
          onClick: closeTopFlyout,
          children: 'Close',
        }}
        label={'Edit Profile'}
      />
      <div className="flex flex-col items-center justify-center gap-6">
        <p className="text-md py-6">
          We are pleased to inform you that our team is currently working diligently to implement
          additional features that will allow you to edit and update your profile information. Our
          aim is to provide you with a seamless and user-friendly experience when it comes to
          managing your account.
        </p>
        <p className="text-md">
          If you wish to change your password, please click on the button provided below. This will
          automatically send you an email from which you will be able to reset your password.
        </p>
        <Button
          onClick={handleResetPassword}
          disabled={isLoading.current && isSuccess != undefined}
        >
          Change Password
        </Button>
        {isSuccess != undefined && (
          <p
            className={
              `text-md mt-6 border p-2 border-solid rounded-md ` +
              (isSuccess ? ' border-green-500 text-green-500' : 'border-red text-red-500')
            }
          >
            {isSuccess
              ? 'An email has been sent to your inbox. Please continue from there.'
              : 'Something went wrong, please try again later.'}
          </p>
        )}
      </div>
    </>
  );
};
