import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { notification } from '../../util/notification.utils';

interface Props {
  customMessage?: string;
}

export const FormErrorNotification = ({ customMessage }: Props) => {
  const { isValid, isValidating, isSubmitting } = useFormikContext();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      notification.error({
        message:
          customMessage ||
          'Looks like some data is missing. Please fill out all the mandatory fields.',
        placement: 'bottom-center',
        // icon: <div>TODO_ICON</div>,
      });
    }
  }, [isSubmitting, isValid, isValidating]);

  return null;
};
