import { Button, Modal, TextInput } from 'flowbite-react';
import { Form, Formik, FormikErrors, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi';
import { HiPlus, HiOutlineX, HiOutlineCheck } from 'react-icons/hi';
import { MdOutlineModeEdit, MdOutlineFileCopy } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { useDebounceEffect } from '../../../components/images/useDebounceEffect';
import {
  CalculatorView,
  useDeleteScenarioMutation,
  useUpsertScenarioMutation,
} from '../../../graphql/generated';
import { WithTooltip } from '../../../hoc/WithTooltip';
import {
  convertFormDataToGqlScenarioSave,
  convertScenarioJsonToFormData,
  createInitialValues,
} from '../utils';

import { CalculatorFormValues } from './EditCalculatorDetails';

const scenarioLimit = 5;

const ScenarioTextInput = ({
  setFieldValue,
  submitForm,
  onCancel,
}: {
  setFieldValue: (field: string, value: any) => void;
  submitForm: () => void;
  onCancel: () => void;
}) => {
  return (
    <>
      <div className="w-full">
        <TextInput
          className="w-full"
          placeholder="Scenario Name"
          onChange={(e) => {
            setFieldValue('scenarioName', e.target.value);
          }}
          name={'scenarioName'}
        />
      </div>

      <div className="flex gap-1 pr-1">
        <HiOutlineX
          size={24}
          className="cursor-pointer  hover:bg-gray-200 text-gray-500"
          onClick={onCancel}
        />
        <HiOutlineCheck
          size={24}
          className="cursor-pointer bg-primary-700 hover:bg-primary-600 text-white rounded-md"
          onClick={submitForm}
        />
      </div>
    </>
  );
};

const useScenarioAutoSave = (
  onAutoSave: (values: CalculatorFormValues) => Promise<any>,
  deps: any[]
) => {
  const formik = useFormikContext<CalculatorFormValues>();
  const { initialValues } = formik;

  useDebounceEffect(
    async () => {
      if (!formik.dirty) return;

      if (formik.values.privateLoans.length) {
        initialValues.privateLoans = [];
      }

      await onAutoSave({ ...initialValues, ...formik.values });
    },
    500,
    [formik.values].concat(deps)
  );

  return null;
};

export const Scenarios = ({
  loadedCalculatorScenarios,
  initialData,
  setActiveScenario,
}: {
  loadedCalculatorScenarios: any[];
  initialData: CalculatorView;
  setActiveScenario: (scenario: any) => void;
}) => {
  const params = useParams();
  const clientId = Number.parseInt(params.clientId!);
  const {
    initialValues,
    resetForm,
    values: currentFormValues,
    validateForm,
  } = useFormikContext<CalculatorFormValues>();

  const newScenarioInitialData = useMemo(() => createInitialValues(initialData), [initialData]);

  const [calculatorScenarios, setCalculatorScenarios] = useState<any[]>(
    loadedCalculatorScenarios.length
      ? loadedCalculatorScenarios
      : [convertFormDataToGqlScenarioSave(initialValues, null, clientId, 'Default Scenario')]
  );

  const [scenarioToDelete, setScenarioToDelete] = useState<any>();
  const [scenarioToUpsert, setScenarioToUpsert] = useState<any>();
  const [activeScenarioId, setActiveScenarioId] = useState<number>(calculatorScenarios[0].id);

  const [upsertScenario] = useUpsertScenarioMutation();
  const [deleteScenario] = useDeleteScenarioMutation();

  useEffect(() => {
    setActiveScenario(calculatorScenarios.find((cs) => cs.id == activeScenarioId));
  }, [activeScenarioId, calculatorScenarios]);

  useScenarioAutoSave(
    async (newValues: CalculatorFormValues) => {
      const scenarioToSave = calculatorScenarios.find((cs) => cs.id == activeScenarioId);
      const scenarioData = convertFormDataToGqlScenarioSave(
        newValues,
        activeScenarioId,
        clientId,
        scenarioToSave.name
      );

      const { data } = await upsertScenario({
        variables: {
          input: scenarioData,
        },
      });

      scenarioToSave.id = data!.upsertScenario.id;
      scenarioToSave.jsonData = scenarioData.jsonData;

      setCalculatorScenarios([...calculatorScenarios]);
      setActiveScenarioId(data!.upsertScenario.id);
    },
    [activeScenarioId]
  );

  return (
    <div className="w-80 h-fit p-2 pb-4 rounded-lg border-gray-100 flex-col justify-start items-center gap-4 inline-flex z-10 top-[104px] sticky">
      <div className="w-80 h-fit p-2 pb-4 rounded-lg shadow border border-gray-100 flex-col justify-start items-center gap-4 inline-flex">
        <div className="self-stretch pt-1 flex-col justify-start items-start gap-2 flex">
          <Modal
            show={scenarioToDelete !== undefined}
            size="xl"
            onClose={() => setScenarioToDelete(undefined)}
          >
            <Modal.Header>Delete Scenario </Modal.Header>
            <Modal.Body>
              <div className="text-gray-900 text-sm font-medium">
                Are you sure you want to delete {scenarioToDelete?.name}?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="light"
                onClick={async () => {
                  setScenarioToDelete(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                color="failure"
                onClick={async () => {
                  const { data } = await deleteScenario({
                    variables: {
                      input: {
                        id: scenarioToDelete!.id,
                      },
                    },
                    refetchQueries: ['CalculatorScenarios'],
                  });

                  const newScenarios = calculatorScenarios.filter(
                    (cs) => cs.id != scenarioToDelete!.id
                  );

                  setCalculatorScenarios(newScenarios);
                  setScenarioToDelete(undefined);

                  if (activeScenarioId == scenarioToDelete!.id) {
                    const newFormData = convertScenarioJsonToFormData(newScenarios[0].jsonData);

                    setActiveScenarioId(newScenarios[0].id);

                    resetForm({ values: newFormData });

                    await validateForm(newFormData);
                  }
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="px-2 text-gray-500 text-base font-semibold">Scenarios</div>
          <div className="w-full py-2 flex-col justify-start items-center gap-2 inline-flex ">
            {calculatorScenarios.map((cs, index) => {
              if (scenarioToUpsert && cs.id == scenarioToUpsert.id && !!cs.id) {
                return (
                  <Formik
                    key={cs.id}
                    initialValues={{ scenarioName: scenarioToUpsert!.name }}
                    onSubmit={async (formValues) => {
                      const scenarioName = formValues.scenarioName;

                      const scenarioData = convertFormDataToGqlScenarioSave(
                        currentFormValues,
                        scenarioToUpsert!.id,
                        clientId,
                        formValues.scenarioName
                      );

                      const { data } = await upsertScenario({
                        variables: {
                          input: scenarioData,
                        },
                      });

                      if (scenarioToUpsert!.id) {
                        const scenarioToChange = calculatorScenarios.find(
                          (cs) => cs.id == scenarioToUpsert!.id
                        );

                        scenarioToChange.name = scenarioName;

                        setCalculatorScenarios([...calculatorScenarios]);
                      } else {
                        const newScenario = {
                          ...scenarioData,
                          id: data?.upsertScenario.id,
                        };

                        setCalculatorScenarios([calculatorScenarios.concat([newScenario])]);
                      }

                      setScenarioToUpsert(undefined);
                    }}
                    validate={(formValues) => {
                      const errors: FormikErrors<{ scenarioName: string }> = {};

                      if (!formValues.scenarioName) {
                        errors.scenarioName = `Required`;
                      }

                      return errors;
                    }}
                  >
                    {({ submitForm, setFieldValue }) => {
                      return (
                        <Form
                          className="flex flex-row justify-center items-center bg-gray-100 rounded-xl gap-1 p-2"
                          key={cs.id}
                        >
                          <ScenarioTextInput
                            setFieldValue={setFieldValue}
                            submitForm={submitForm}
                            onCancel={() => setScenarioToUpsert(undefined)}
                          />
                        </Form>
                      );
                    }}
                  </Formik>
                );
              }

              return (
                <div
                  key={cs.id}
                  className={`self-stretch group px-2 cursor-pointer ${
                    activeScenarioId === cs.id ? 'bg-primary-100 ' : 'hover:bg-gray-100'
                  } rounded-lg justify-start items-center gap-2 inline-flex `}
                  onClick={async () => {
                    if (cs.id == activeScenarioId) {
                      resetForm({
                        values: { ...initialValues },
                      });

                      await validateForm(initialValues);
                    } else {
                      const data = convertScenarioJsonToFormData(cs.jsonData);

                      setActiveScenarioId(cs.id);

                      resetForm({ values: data });

                      await validateForm(data);
                    }
                  }}
                >
                  <div className="flex-row justify-between items-center inline-flex w-full    ">
                    <div
                      className={`${
                        activeScenarioId === cs.id ? 'text-primary-600' : ''
                      } font-semibold truncate max-w-[200px] py-2.5 text-sm`}
                    >
                      {cs.name}
                    </div>
                    <div className="flex items-center gap-x-1">
                      {!!cs.id && (
                        <WithTooltip tooltip={{ content: 'Edit' }}>
                          <div
                            className={`${
                              activeScenarioId === cs.id
                                ? 'hover:bg-primary-200 '
                                : 'hover:bg-gray-200'
                            } rounded-md flex justify-center items-center p-1.5`}
                          >
                            <MdOutlineModeEdit
                              size={16}
                              className="text-gray-500 hover:text-gray-600 hover:cursor-pointer group-hover:block hidden"
                              onClick={() =>
                                setScenarioToUpsert({
                                  id: cs.id,
                                  name: cs.name,
                                })
                              }
                            />
                          </div>
                        </WithTooltip>
                      )}

                      {calculatorScenarios.length < scenarioLimit ? (
                        <WithTooltip tooltip={{ content: 'Duplicate' }}>
                          <div
                            className={`${
                              activeScenarioId === cs.id
                                ? 'hover:bg-primary-200 '
                                : 'hover:bg-gray-200'
                            } rounded-md flex justify-center items-center p-1.5`}
                          >
                            <MdOutlineFileCopy
                              size={16}
                              onClick={async () => {
                                const scenarioName = cs.name + ' (d)';

                                const scenarioData = {
                                  id: null,
                                  jsonData: cs.jsonData,
                                  name: scenarioName,
                                  clientId: clientId,
                                };

                                const { data } = await upsertScenario({
                                  variables: {
                                    input: scenarioData,
                                  },
                                });

                                const newScenario = {
                                  ...scenarioData,
                                  id: data?.upsertScenario.id,
                                };

                                setCalculatorScenarios(calculatorScenarios.concat([newScenario]));

                                setActiveScenarioId(data!.upsertScenario.id);
                              }}
                              className=" text-gray-500 hover:text-gray-600 hover:cursor-pointer group-hover:block hidden"
                            />
                          </div>
                        </WithTooltip>
                      ) : null}
                      {calculatorScenarios.length > 1 ? (
                        <WithTooltip tooltip={{ content: 'Delete' }}>
                          <div
                            className={`${
                              activeScenarioId === cs.id
                                ? 'hover:bg-primary-200 '
                                : 'hover:bg-gray-200'
                            } rounded-md flex justify-center items-center p-1.5`}
                          >
                            <HiOutlineTrash
                              size={16}
                              onClick={() => {
                                setScenarioToDelete({
                                  id: cs.id,
                                  name: cs.name,
                                });
                              }}
                              className=" text-gray-500 hover:text-gray-600 hover:cursor-pointer group-hover:block hidden  "
                            />
                          </div>
                        </WithTooltip>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
            {scenarioToUpsert?.id == null && scenarioToUpsert?.name != undefined ? (
              <Formik
                initialValues={{ scenarioName: scenarioToUpsert!.name }}
                onSubmit={async (formValues) => {
                  const scenarioName = formValues.scenarioName;

                  const privateLoans = initialData.privateLoans.map((x) => ({
                    ...x,
                    interestRate: x.interestRate * 100,
                  }));

                  const scenarioData = convertFormDataToGqlScenarioSave(
                    { ...newScenarioInitialData, ...currentFormValues, privateLoans },
                    scenarioToUpsert!.id,
                    clientId,
                    scenarioName
                  );

                  const { data } = await upsertScenario({
                    variables: {
                      input: scenarioData,
                    },
                  });

                  setCalculatorScenarios(
                    calculatorScenarios.concat([
                      convertFormDataToGqlScenarioSave(
                        { ...newScenarioInitialData, ...currentFormValues, privateLoans },
                        data!.upsertScenario.id,
                        clientId,
                        scenarioName
                      ),
                    ])
                  );
                  setScenarioToUpsert(undefined);
                }}
                validate={(formValues) => {
                  const errors: FormikErrors<{ scenarioName: string }> = {};

                  if (!formValues.scenarioName) {
                    errors.scenarioName = `Required`;
                  }

                  return errors;
                }}
              >
                {({ submitForm, setFieldValue }) => {
                  return (
                    <Form className="flex flex-row justify-center items-center bg-gray-100 rounded-xl gap-1 p-2">
                      <ScenarioTextInput
                        setFieldValue={setFieldValue}
                        submitForm={submitForm}
                        onCancel={() => setScenarioToUpsert(undefined)}
                      />
                    </Form>
                  );
                }}
              </Formik>
            ) : null}
          </div>
          <Button
            disabled={calculatorScenarios.length >= scenarioLimit || !!scenarioToUpsert}
            onClick={async () => {
              const scenarioData = convertFormDataToGqlScenarioSave(
                currentFormValues,
                null,
                clientId,
                'New Scenario'
              );

              setScenarioToUpsert({
                id: null,
                name: 'New Scenario',
              });
            }}
            color="secondaryLight"
            className="w-full"
            type="button"
          >
            <HiPlus /> &nbsp; Add Scenario
          </Button>
        </div>
      </div>
      <div className="w-full text-center text-gray-500 text-xs font-medium">
        Save up to {scenarioLimit} Planner states as scenarios to easily compare and identify the
        best repayment plan.
        <br />
        <br />
        Changes are automatically saved.
      </div>
    </div>
  );
};
