import dayjs from 'dayjs';
import { Dropdown } from 'flowbite-react';

import { ColumnsType } from '../../../components/table/FinologyTable';
import { FinologyTable } from '../../../components/table/FinologyTable';
import { useGlobalState } from '../../../context/AppContext';
import {
  SuperAdminUserView,
  useSuperAdmin_GetUsersLazyQuery,
  useToggleUserLockoutMutation,
} from '../../../graphql/generated';
import { toDollars } from '../../../util/currency.formatter';
import { notification } from '../../../util/notification.utils';

export const SuperAdminUsersList = () => {
  const { user } = useGlobalState();
  const [getUsers, query] = useSuperAdmin_GetUsersLazyQuery();
  const [toggleUserLockoutMutation] = useToggleUserLockoutMutation();

  const columns: ColumnsType<SuperAdminUserView> = [
    {
      title: '#',
      key: 'rowNum',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => text,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text, record) => text,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => text,
    },
    {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      render: (text, record) => (text ? dayjs(text).format('YYYY/MM/DD HH:mm') : null),
    },
    {
      title: 'Last Login Date',
      dataIndex: 'lastLoginDate',
      key: 'lastLoginDate',
      render: (text, record) => (text ? dayjs(text).format('YYYY/MM/DD HH:mm') : null),
    },
    {
      title: 'Login Count',
      dataIndex: 'numberOfLogins',
      key: 'numberOfLogins',
      render: (text, record) => text,
    },
    {
      title: 'Federal',
      dataIndex: 'federalDebt',
      key: 'federalDebt',
      render: (text, record) => toDollars(text),
    },
    {
      title: 'Private',
      dataIndex: 'privateDebt',
      key: 'privateDebt',
      render: (text, record) => toDollars(text),
    },
    {
      title: 'Card',
      dataIndex: 'creditCardDebt',
      key: 'creditCardDebt',
      render: (text, record) => toDollars(text),
    },
    {
      title: 'Company',
      dataIndex: 'tenantName',
      key: 'tenantName',
      render: (text, record) => text,
    },
    {
      title: 'RefId',
      dataIndex: 'signupRefId',
      key: 'signupRefId',
      render: (text, record) => text,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => {
        if (record.id == user?.id) return null;

        return (
          <Dropdown label="...">
            <Dropdown.Item
              disabled={record.isLockedOut}
              onClick={() => {
                location.href = `${process.env.REACT_APP_INTERNAL_API_HOST}/Redirect/ToImpersonate/${record.id}`;
              }}
            >
              Impersonate
            </Dropdown.Item>
            <Dropdown.Item
              onClick={async () => {
                await toggleUserLockoutMutation({
                  variables: {
                    input: {
                      userId: record.id,
                    },
                  },
                  refetchQueries: ['SuperAdmin_GetUsers'],
                });

                notification.success({
                  message: `User account ${record.isLockedOut ? 'unlocked' : 'locked'}`,
                  placement: 'bottom-center',
                });
              }}
            >
              {record.isLockedOut ? 'Enable Account' : 'Disable Account'}
            </Dropdown.Item>
          </Dropdown>
        );
      },
    },
  ];

  async function load(skip?: number, take?: number, filterValue?: string) {
    const { data } = await getUsers({
      variables: {
        filter: filterValue
          ? {
              or: [
                { firstName: { contains: filterValue } },
                { lastName: { contains: filterValue } },
                { email: { contains: filterValue } },
              ],
            }
          : undefined,
        skip: skip,
        take: take,
      },
    });

    return {
      rows: data!.superAdmin_Users!.items as SuperAdminUserView[],
      totalCount: data!.superAdmin_Users!.totalCount,
    };
  }

  return (
    <>
      <div className="flex">
        <div className="grow">
          <FinologyTable
            defaultPageSize={50}
            searchVisible={true}
            columns={columns}
            dataSourcePromise={load}
            reloadDependency={query.previousData}
            searchPlaceholder="Search by first name, last name or email..."
          />
        </div>
      </div>
    </>
  );
};
