import { Label, TextInput, Tooltip } from 'flowbite-react';
import { Field, useFormikContext } from 'formik';
import { FC, ReactNode, useEffect, useState } from 'react';
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi';
import { MdOutlinePercent } from 'react-icons/md';

const AntInput = ({ field, form, ...props }: any) => {
  const [value, setValue] = useState<string | undefined>(field.value);
  const error = (!!form.touched[field.name] || form.submitCount) && !!form.errors[field.name];

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  const { onChange, ...rest } = props;

  const currentPercentage =
    typeof value != 'number' && value ? parseFloat(value?.replace(',', '.')) : value;

  return (
    <TextInput
      id={`form__field_${field.name}`}
      type={props.type || 'text'}
      onKeyDown={(e) => {
        // Prevent form submission on enter
        if (e.key === 'Enter') {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        let value = e.target.value
          ? e.target.value
              .replace(/,/g, '.')
              .replace(/[^\d.-]/g, '')
              .split('.')
              .slice(0, 2)
              .join('.')
          : e.target.value;
        if (e.target.value === '-') {
          value = '-';

          setValue(value);
          onChange && onChange(0);
          return;
        }

        const isNaN = Number.isNaN(value);

        if (isNaN) {
          form.setFieldValue(field.name, ``);
          return;
        }

        setValue(value.toString());
        onChange && onChange(parseFloat(value.toString()));
      }}
      onFocus={(e) => form.setFieldTouched(field.name)}
      onBlur={() => {
        form.setFieldValue(field.name, currentPercentage);
      }}
      status={error ? 'error' : undefined}
      value={value}
      icon={MdOutlinePercent}
      {...rest}
    ></TextInput>
  );
};

const InputField = ({
  name,
  placeholder,
  className,
  type,
  suffix,
  prefix,
  step,
  onChange,
  labelSibling,
  disabled,
  tooltip,
  sizing,
  label,
}: {
  name: string;
  placeholder: string;
  type?: 'text' | 'email' | 'number';
  suffix?: ReactNode;
  prefix?: ReactNode;
  className?: string;
  step?: string;
  onChange?: (e: Event) => void;
  labelSibling?: JSX.Element;
  disabled?: boolean;
  tooltip: ReactNode;
  sizing?: 'sm' | 'md' | 'lg';
  label?: string;
}) => {
  const form = useFormikContext<any>();
  const error = form.errors[name];

  if (error != undefined && typeof error !== 'string' && typeof error !== 'number')
    throw new Error('Error must be of type string');

  return (
    <div className={className}>
      <div className="flex justify-between ">
        <Label
          color={error ? 'failure' : undefined}
          htmlFor={`form__field_${name}`}
          className="truncate"
        >
          {tooltip ? (
            <>
              <Tooltip
                theme={{
                  target: 'flex justify-between',
                }}
                content={tooltip}
              >
                {label || placeholder}&nbsp;
                <HiOutlineQuestionMarkCircle className="inline" />
              </Tooltip>
            </>
          ) : (
            label || placeholder
          )}
        </Label>
        {labelSibling}
      </div>

      <Field
        type={type}
        name={name}
        suffix={suffix}
        prefix={prefix}
        placeholder={placeholder}
        component={AntInput}
        step={step}
        onChange={onChange}
        disabled={disabled}
        sizing={sizing}
      />
      {error && !error ? null : <p className=" text-sm text-red-700">{error} &nbsp;</p>}
    </div>
  );
};

export const PercentageField: FC<{
  name: string;
  placeholder: string;
  step?: string;
  onChange?: (e: Event) => void;
  labelSibling?: JSX.Element;
  disabled?: boolean;
  tooltip?: ReactNode;
  sizing?: 'sm' | 'md' | 'lg';
  label?: string;
}> = ({
  name,
  placeholder,
  step,
  onChange,
  labelSibling,
  disabled,
  tooltip,
  label,
  sizing = 'lg',
}) => {
  return (
    <InputField
      placeholder={placeholder}
      name={name}
      prefix={'%'}
      step={step}
      tooltip={tooltip}
      onChange={onChange}
      labelSibling={labelSibling}
      disabled={disabled}
      sizing={sizing}
      label={label}
    />
  );
};
