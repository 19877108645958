import { Form, Formik, FormikErrors } from 'formik';

import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../components/forms/FormErrorNotification';
import { InviteClientInput, useInviteClientMutation } from '../../../graphql/generated';
import useFinologyTranslation from '../../../translations/useFinologyTranslation';
import { notification } from '../../../util/notification.utils';
import { TextInputField } from '../../formComponents/InputField';
import { TextAreaField } from '../../formComponents/TextAreaField';

type FormValues = Partial<InviteClientInput>;

const FlyoutContent = () => {
  const [createSimpleClient] = useInviteClientMutation();
  const { t } = useFinologyTranslation();
  const initialValues: FormValues = {};
  const { closeTopFlyout } = useFlyoutContext();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (v, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        const { data } = await createSimpleClient({
          variables: {
            input: {
              clientFirstName: v.clientFirstName!,
              clientLastName: v.clientLastName!,
              clientEmail: v.clientEmail!,
              emailContent: v.emailContent!,
            },
          },
        });

        notification.success({
          message: 'Invitation sent',
          placement: 'bottom-center',
        });

        closeTopFlyout();
      }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.clientFirstName) {
          errors.clientFirstName = 'Required';
        }
        if (!values.clientLastName) {
          errors.clientLastName = 'Required';
        }
        if (!values.clientEmail) {
          errors.clientEmail = 'Required';
        }
        if (!values.emailContent) {
          errors.emailContent = 'Required';
        }

        if (values.clientFirstName != undefined && values.clientFirstName.length > 100) {
          errors.clientFirstName = 'First Name must be less than 100 characters';
        }
        if (values.clientLastName != undefined && values.clientLastName.length > 100) {
          errors.clientLastName = 'Last Name must be less than 100 characters';
        }

        return errors;
      }}
    >
      {({ isSubmitting }) => {
        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'invite-client-form',
                children: `Invite ${t('borrower.uppercase')}`,
                disabled: isSubmitting,
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={`Invite New ${t('borrower.uppercase')}`}
            />
            <Form id="invite-client-form">
              <TextInputField className="text-xs" name="clientFirstName" placeholder="First Name" />

              <TextInputField className="text-xs" name="clientLastName" placeholder="Last Name" />

              <TextInputField
                className="text-xs"
                type="email"
                name="clientEmail"
                placeholder="Email"
              />

              <TextAreaField
                className="text-xs"
                name="emailContent"
                placeholder="Personal Message"
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export const InviteClientFlyout = () => {
  return <Flyout id={`invite-client`} size={'small'} content={<FlyoutContent />}></Flyout>;
};
