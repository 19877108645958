import React, { useEffect } from 'react';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import { Link } from 'react-router-dom';

import { useGlobalActions, useGlobalState } from '../../context/AppContext';

export const BreadcrumbsItem = ({
  to,
  children,
  back,
  rightArrow,
}: {
  to: string;
  children: React.ReactNode;
  back?: boolean;
  rightArrow?: boolean;
}) => {
  const { popBreadcrumb, pushBreadcrumb } = useGlobalActions();

  useEffect(() => {
    pushBreadcrumb({ to, children, back, rightArrow });

    return () => {
      popBreadcrumb();
    };
  }, []);

  return null;
};

export const Breadcrumbs = () => {
  const { breadcrumbs } = useGlobalState();

  return (
    <div className="flex flex-row gap-4">
      {breadcrumbs.map((breadcrumb, i) => {
        return (
          <div className="flex items-center text-2xl" key={i}>
            {breadcrumb.back && (
              <Link to={breadcrumb.to} className="group" key={breadcrumb.to}>
                {breadcrumb.rightArrow ? (
                  <HiArrowRight className="mr-4 hover:cursor-pointer group-hover:text-primary" />
                ) : (
                  <HiArrowLeft className="mr-4 hover:cursor-pointer group-hover:text-primary" />
                )}
              </Link>
            )}
            <div className="text-2xl">{breadcrumb.children}</div>
          </div>
        );
      })}
    </div>
  );
};
