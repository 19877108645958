import classNames from 'classnames';
import { Accordion, Button, Label, Modal, Radio, Tabs, Tooltip } from 'flowbite-react';
import { FieldArray, useFormikContext } from 'formik';
import { HiInformationCircle } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';

import { PanelHeader } from '../../../components/Accordion/PanelHeader';
import { Assumptions } from '../../../components/calculator/Assumptions';
import { MultipleLineChart } from '../../../components/charts';
import { FinoLink } from '../../../components/links/FinoLink';
import {
  LoansToPrioritize,
  MaritalStatus,
  PaydownMethods,
  TaxFilingType,
} from '../../../graphql/generated';
import { WithTooltip } from '../../../hoc/WithTooltip';
import useFinologyTranslation from '../../../translations/useFinologyTranslation';
import { CurrencyField } from '../../formComponents/CurrencyField';
import { PercentageField } from '../../formComponents/PercentageField';
import SelectField from '../../formComponents/SelectField';
import { SwitchField } from '../../formComponents/SwitchField';

import { CalculatorFormValues } from './EditCalculatorDetails';

const CURRENT_YEAR = new Date().getFullYear();

const OTHER_CHILDREN = [
  'firstChildBirthYear',
  'secondChildBirthYear',
  'thirdChildBirthYear',
  'fourthChildBirthYear',
  'fifthChildBirthYear',
  'sixthChildBirthYear',
  'seventhChildBirthYear',
  'eighthChildBirthYear',
  'ninthChildBirthYear',
  'tenthChildBirthYear',
];

const updateIncomeProjections = (
  baseName: string,
  value: number,
  index: number,
  agiInflationFieldName: string,
  setValues: (values: any) => void,
  values: any,
  agiInflation?: number
) => {
  let previousValue = value;

  const _agiInflation = agiInflation || values[agiInflationFieldName];

  const agiInflationPercentage = _agiInflation ? _agiInflation / 100 : 0;

  const newValues = values[baseName];

  newValues[index] = value;

  for (let updateIndex = index + 1; updateIndex < 25; updateIndex++) {
    const percentage =
      agiInflationPercentage === 0
        ? 0
        : agiInflationPercentage ||
          (values[agiInflationFieldName] ? values[agiInflationFieldName] / 100 : 0);
    const newValue = Math.round(previousValue * (1 + percentage));

    newValues[updateIndex] = newValue;

    previousValue = newValue;
  }

  values[baseName] = newValues;

  setValues({ ...values });
};

const ChangeToSpouseLink = ({
  clientId,
  spouseId,
  spouseName,
}: {
  clientId: number;
  spouseId: number;
  spouseName: string;
}) => {
  return (
    <WithTooltip
      tooltip={{
        content: `Switch to ${spouseName}`,
        display: true,
        placement: 'right',
      }}
    >
      <FinoLink
        to={location.href.replace(clientId.toString(), spouseId.toString())}
        label={'Switch to spouse'}
      />
    </WithTooltip>
  );
};

export const ClientInformationForm = ({
  clientName,
  spouseName,
}: {
  clientName: string;
  spouseName?: string | null;
}) => {
  const { t } = useFinologyTranslation();
  const { setFieldValue, values, setValues, errors } = useFormikContext<CalculatorFormValues>();

  const averageNumberOfPslfCertifiedMonths = values.loans.length
    ? values.loans.reduce((prev, next) => prev + next.pslfCount, 0) / values.loans.length
    : 0;

  return (
    <div>
      <div>
        <Modal
          show={values.isAssumptionsModalVisible}
          size="7xl"
          onClose={() => setFieldValue('isAssumptionsModalVisible', false)}
        >
          <Modal.Header>Assumptions</Modal.Header>
          <Modal.Body>
            <Assumptions />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setFieldValue('isAssumptionsModalVisible', false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Modal
        title="Projections"
        show={values.isProjectionsModalVisible}
        size="5xl"
        onClose={() => setFieldValue('isProjectionsModalVisible', false)}
      >
        <Modal.Header>Projections</Modal.Header>
        <Modal.Body>
          <Tabs style="underline">
            <Tabs.Item title={`${t('borrower.uppercase')}'s Projections`}>
              <div className="grid grid-cols-4 gap-x-9 mt-4">
                <FieldArray
                  name="clientAgiProjections"
                  render={() => {
                    return Array.from(Array(25).keys()).map((x) => {
                      return (
                        <CurrencyField
                          name={`clientAgiProjections[${x}]`}
                          placeholder={`${CURRENT_YEAR + x}`}
                          key={x}
                          onChange={(e: any) => {
                            updateIncomeProjections(
                              'clientAgiProjections',
                              e === '' ? 0 : e,
                              x,
                              'agiInflation',
                              setValues,
                              values
                            );
                            if (x === 0) {
                              setFieldValue('clientSalary', e);
                            }
                          }}
                        />
                      );
                    });
                  }}
                />
              </div>
            </Tabs.Item>
            {values.spouseId && (
              <Tabs.Item title="Spouse's Projections" key="2">
                <div className="grid grid-cols-4 gap-x-9 mt-4">
                  {Array.from(Array(25).keys()).map((x) => {
                    return (
                      <CurrencyField
                        name={`spouseAgiProjections[${x}]`}
                        placeholder={`${CURRENT_YEAR + x}`}
                        key={x}
                        onChange={(e: any) => {
                          updateIncomeProjections(
                            'spouseAgiProjections',
                            e,
                            x,
                            'spouseAgiInflation',
                            setValues,
                            values
                          );

                          if (x === 0) {
                            setFieldValue('spouseAnnualSalary', e);
                          }
                        }}
                      />
                    );
                  })}
                </div>
              </Tabs.Item>
            )}
            <Tabs.Item title="Chart">
              <MultipleLineChart
                data={[
                  Array.from(Array.from(Array(25)).keys()).map((x) => ({
                    name: 'Client',
                    year: CURRENT_YEAR + x,
                    usd: values.clientAgiProjections[x],
                  })),
                  Array.from(Array.from(Array(25)).keys()).map((x) => ({
                    name: 'Spouse',
                    year: CURRENT_YEAR + x,
                    usd: values.spouseAgiProjections[x],
                  })),
                ].flat()}
              />
            </Tabs.Item>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setFieldValue('isProjectionsModalVisible', false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <div className="flex justify-start items-center mb-4 gap-x-4">
        <div className="text-gray-900 text-2xl font-bold">{clientName}</div>
        {values.spouseId ? (
          <ChangeToSpouseLink
            clientId={values.id}
            spouseId={values.spouseId}
            spouseName={spouseName!}
          />
        ) : null}
      </div>
      <div>
        <Accordion>
          <Accordion.Panel collapseAll={true}>
            <Accordion.Title>
              <PanelHeader>Income and Household</PanelHeader>
            </Accordion.Title>
            <Accordion.Content>
              <div className="grid grid-cols-6 gap-y-6 gap-x-3 items-center max-[1025px]:flex max-[1025px]:flex-wrap">
                <div className="relative col-span-2">
                  <CurrencyField
                    name="clientSalary"
                    sizing="md"
                    className="-mt-1"
                    inputStyle={{
                      border: '1px solid #D1D5DB',
                      borderRadius: '0.375rem',
                    }}
                    placeholder={`${
                      values.isItemizedDeductionDisabled
                        ? `Adjusted Gross Income`
                        : `Client's Annual Salary`
                    } `}
                    onChange={(e: any) => {
                      updateIncomeProjections(
                        'clientAgiProjections',
                        e === '' ? 0 : e,
                        0,
                        'agiInflation',
                        setValues,
                        values
                      );
                      setFieldValue('clientSalary', e);
                    }}
                  />
                  <SwitchField
                    name="isItemizedDeductionDisabled"
                    label={`Annual Salary`}
                    className="absolute top-[21px] right-1 p-[10px] bg-gray-50 border border-gray-300 border-l-1 border-y-0 border-r-0"
                    sizing="sm"
                    onChange={() => {
                      setFieldValue('itemizedDeductions', undefined);
                    }}
                  />
                </div>
                <div className="col-span-1">
                  <PercentageField
                    step="0.01"
                    name="agiInflation"
                    sizing="md"
                    placeholder="Salary Increase Rate"
                    onChange={(e: any) => {
                      updateIncomeProjections(
                        'clientAgiProjections',
                        Number(values.clientSalary),
                        0,
                        'agiInflation',
                        setValues,
                        values,
                        Number(e)
                      );
                    }}
                  />
                </div>

                <Button
                  className="rounded-lg whitespace-nowrap col-span-2 max-w-60 focus:z-0"
                  color="primaryLight"
                  style={{
                    maxWidth: 240,
                  }}
                  onClick={() => setFieldValue('isProjectionsModalVisible', true)}
                >
                  View Income Projections
                </Button>
                <div className="col-span-1" />

                <CurrencyField
                  sizing="md"
                  name="itemizedDeductions"
                  className="col-span-2"
                  placeholder="Itemized Deductions"
                  disabled={values.isItemizedDeductionDisabled}
                />
                <CurrencyField
                  sizing="md"
                  className="col-span-2"
                  name="annualSalaryDeduction"
                  placeholder="Retirement Contribution"
                  tooltip="This reduces annual salary"
                />
                <SelectField
                  removeErrorPlaceholder
                  className="col-span-2 w-40"
                  name="state"
                  label="State"
                  styles={{
                    control: {
                      padding: 2,
                    },
                  }}
                >
                  <option value="">Select one</option>
                  <option value="AL"> AL</option>
                  <option value="AK"> AK</option>
                  <option value="AZ"> AZ</option>
                  <option value="AR"> AR</option>
                  <option value="CA"> CA</option>
                  <option value="CO"> CO</option>
                  <option value="CT"> CT</option>
                  <option value="DE"> DE</option>
                  <option value="DC"> DC</option>
                  <option value="FL"> FL</option>
                  <option value="GA"> GA</option>
                  <option value="HI"> HI</option>
                  <option value="ID"> ID</option>
                  <option value="IL"> IL</option>
                  <option value="IN"> IN</option>
                  <option value="IA"> IA</option>
                  <option value="KS"> KS</option>
                  <option value="KY"> KY</option>
                  <option value="LA"> LA</option>
                  <option value="ME"> ME</option>
                  <option value="MD"> MD</option>
                  <option value="MA"> MA</option>
                  <option value="MI"> MI</option>
                  <option value="MN"> MN</option>
                  <option value="MS"> MS</option>
                  <option value="MO"> MO</option>
                  <option value="MT"> MT</option>
                  <option value="NE"> NE</option>
                  <option value="NV"> NV</option>
                  <option value="NH"> NH</option>
                  <option value="NJ"> NJ</option>
                  <option value="NM"> NM</option>
                  <option value="NY"> NY</option>
                  <option value="NC"> NC</option>
                  <option value="ND"> ND</option>
                  <option value="OH"> OH</option>
                  <option value="OK"> OK</option>
                  <option value="OR"> OR</option>
                  <option value="PA"> PA</option>
                  <option value="PR"> PR</option>
                  <option value="RI"> RI</option>
                  <option value="SC"> SC</option>
                  <option value="SD"> SD</option>
                  <option value="TN"> TN</option>
                  <option value="TX"> TX</option>
                  <option value="US"> US</option>
                  <option value="UT"> UT</option>
                  <option value="VT"> VT</option>
                  <option value="VA"> VA</option>
                  <option value="QA"> WA</option>
                  <option value="WV"> WV</option>
                  <option value="WI"> WI</option>
                  <option value="WY"> WY</option>
                </SelectField>
                <SelectField
                  removeErrorPlaceholder
                  name="maritalStatus"
                  className="col-span-2"
                  label="Marital Status"
                  disabled
                  styles={{
                    control: {
                      padding: 2,
                    },
                  }}
                >
                  <option value={MaritalStatus.Divorced}>Divorced</option>
                  <option value={MaritalStatus.LifePartner}>Life Partner</option>
                  <option value={MaritalStatus.Married}>Married</option>
                  <option value={MaritalStatus.Separated}>Separated</option>
                  <option value={MaritalStatus.Single}>Single</option>
                  <option value={MaritalStatus.Widowed}>Widowed</option>
                </SelectField>
                <SelectField
                  removeErrorPlaceholder
                  name="taxFilingType"
                  label="Tax Filing Type"
                  className="col-span-2"
                  styles={{
                    control: {
                      padding: 2,
                    },
                  }}
                >
                  <option value={TaxFilingType.HeadOfHousehold}>Head Of Household </option>
                  <option value={TaxFilingType.MarriedFilingJointly}>
                    Married Filing Jointly{' '}
                  </option>
                  <option value={TaxFilingType.MarriedFilingSeparately}>
                    Married Filing Separately{' '}
                  </option>
                  <option value={TaxFilingType.Single}>Single </option>
                </SelectField>
                <div className="flex items-center gap-1 col-span-2">
                  <SwitchField name="isSelfEmployed" label="Self Employed" />
                  <Label>
                    <Tooltip
                      content="Select this toggle if Client is Self-Employed."
                      className="inline "
                    >
                      <HiInformationCircle />
                    </Tooltip>
                  </Label>
                </div>
                <div className="col-span-6">
                  {!!values.spouseId && (
                    <Accordion>
                      <Accordion.Panel>
                        <Accordion.Title>
                          <PanelHeader>{`Spouse's`} Salary</PanelHeader>
                        </Accordion.Title>
                        <Accordion.Content className="pb-8">
                          <div className="mb-4 grid grid-cols-7 gap-y-6 gap-x-3 items-center max-[1025px]:flex max-[1025px]:flex-wrap">
                            <div className="relative col-span-2">
                              <CurrencyField
                                name="spouseAnnualSalary"
                                sizing="md"
                                inputStyle={{
                                  border: '1px solid #D1D5DB',
                                  borderRadius: '0.375rem',
                                }}
                                placeholder={`${
                                  values.isSpouseItamizedDeductionDisabled
                                    ? `Adjusted Gross Income`
                                    : `Annual Salary`
                                } `}
                                onChange={(e: any) => {
                                  updateIncomeProjections(
                                    'spouseAgiProjections',
                                    e,
                                    0,
                                    'spouseAgiInflation',
                                    setValues,
                                    values
                                  );
                                  setFieldValue('spouseAnnualSalary', e);
                                }}
                              />
                              <SwitchField
                                name="isSpouseItamizedDeductionDisabled"
                                sizing="sm"
                                label={`Spouse Annual Salary`}
                                style={{
                                  height: 18,
                                }}
                                className="absolute top-[26px] right-1 p-[10px] bg-gray-50 border border-gray-300 border-l-1 border-r-0 border-y-0"
                                onChange={() => {
                                  setFieldValue('spouseItemizedDeductions', undefined);
                                }}
                              />
                            </div>

                            <div className="col-span-1">
                              <PercentageField
                                step="0.01"
                                name="spouseAgiInflation"
                                placeholder="Salary Increase Rate"
                                sizing="md"
                                onChange={(e: any) => {
                                  updateIncomeProjections(
                                    'spouseAgiProjections',
                                    Number(values.spouseAnnualSalary),
                                    0,
                                    'spouseAgiInflation',
                                    setValues,
                                    values,
                                    Number(e)
                                  );
                                }}
                              />
                            </div>

                            <CurrencyField
                              name="spouseItemizedDeductions"
                              placeholder="Itemized Deductions"
                              disabled={values.isSpouseItamizedDeductionDisabled}
                              className="col-span-2"
                              sizing="md"
                            />

                            <CurrencyField
                              name="spouseAnnualSalaryDeduction"
                              className="col-span-2"
                              sizing="md"
                              placeholder="Retirement Deductions"
                            />
                          </div>
                        </Accordion.Content>
                      </Accordion.Panel>
                    </Accordion>
                  )}
                </div>
                <SelectField
                  removeErrorPlaceholder
                  name="dependentsNumber"
                  className="col-span-2"
                  label="Number of children"
                  styles={{
                    control: {
                      padding: 2,
                    },
                  }}
                >
                  {Array.from(Array(11).keys()).map((x) => (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  ))}
                </SelectField>
                <SelectField
                  removeErrorPlaceholder
                  name="otherDependentsNumber"
                  label="Other qualifying dependents"
                  className="col-span-2"
                  styles={{
                    control: {
                      padding: 2,
                    },
                  }}
                >
                  {Array.from(Array(11).keys()).map((x) => (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  ))}
                </SelectField>
                <SelectField
                  removeErrorPlaceholder
                  name="plannedChildren"
                  label="Future dependents"
                  tooltip="Identify the dependents the Client plans to add to his/her household."
                  className="col-span-2"
                  styles={{
                    control: {
                      padding: 2,
                    },
                  }}
                >
                  {Array.from(Array(11).keys()).map((x) => (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  ))}
                </SelectField>
                <div className="col-span-6">
                  {!values.plannedChildren ? null : (
                    <div className="grid grid-cols-4 gap-x-9 ">
                      {Array.from(Array(values.plannedChildren).keys()).map((index: number) => (
                        <SelectField
                          removeErrorPlaceholder
                          name={OTHER_CHILDREN[index]}
                          label={`${index + 1}. Child`}
                          key={index}
                          styles={{
                            control: {
                              padding: 2,
                            },
                          }}
                        >
                          {Array.from(Array(20).keys()).map((x) => (
                            <option value={CURRENT_YEAR + x} key={x}>
                              {CURRENT_YEAR + x}
                            </option>
                          ))}
                        </SelectField>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>
              <PanelHeader>Forgiveness </PanelHeader>
            </Accordion.Title>
            <Accordion.Content>
              <div className="grid grid-cols-6 gap-y-6 gap-x-3 items-center max-[1025px]:flex max-[1025px]:flex-wrap">
                <div className="col-span-4 h-full">
                  <div className="flex flex-col justify-start items-start gap-4">
                    <div className="flex justify-start items-center gap-1 inline-flex">
                      <div className="text-sm font-medium text-gray-500 flex flex-row">
                        Choose Repayment Timeline
                      </div>
                    </div>

                    <div className="grid grid-cols-2 justify-start items-center gap-1 w-full">
                      <SwitchField
                        name="isTimeBasedForgivnessActive"
                        onChange={(newVal: boolean) => {
                          setFieldValue('isTimeBasedForgivnessActive', newVal);
                          setFieldValue('isPslfActive', !newVal);
                        }}
                        label="IDR Plan Summary"
                      />{' '}
                      <div className="flex flex-row">
                        <SwitchField
                          name="isPslfActive"
                          onChange={(newVal: boolean) => {
                            setFieldValue('isPslfActive', newVal);
                            setFieldValue('isTimeBasedForgivnessActive', !newVal);
                          }}
                          label="Public Service Loan Forgiveness"
                        />{' '}
                        <Tooltip
                          content={
                            <div>
                              If a Client is employed by a qualifying organization. Use this link to
                              qualify here: <br />
                              <NavLink
                                to="https://studentaid.gov/manage-loans/forgiveness-cancellation/public-service/qualifying-public-services"
                                target="_blank"
                              >
                                https://studentaid.gov/manage-loans/forgiveness-cancellation/public-service/qualifying-public-services
                              </NavLink>
                            </div>
                          }
                          className="inline"
                        >
                          <HiInformationCircle />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-2"></div>
                <div className="col-span-2">
                  <PercentageField
                    step="0.01"
                    name="rateOfReturnOnTaxSavingsBomb"
                    placeholder="Rate of Return on Savings for Tax Bomb"
                    sizing="md"
                    disabled={!values.isTimeBasedForgivnessActive}
                  />
                </div>
                <CurrencyField
                  name="currentSavingTowardsTaxBomb"
                  placeholder="Current Savings Balance Towards Tax Bomb"
                  className="col-span-2"
                  sizing="md"
                  disabled={!values.isTimeBasedForgivnessActive}
                />
                <CurrencyField
                  name="estimatedMonthlySavingTowardsTaxBomb"
                  className="col-span-2"
                  placeholder="Est. Monthly Savings Towards Tax Bomb"
                  sizing="md"
                  disabled={!values.isTimeBasedForgivnessActive}
                />
                <SwitchField
                  className="col-span-2"
                  name="isTeacherForgivnessActive"
                  label="Teacher Loan Forgiveness"
                />

                <fieldset className="flex flex-col col-span-2 gap-2">
                  <legend className="text-sm font-medium text-gray-500 truncate mb-1">
                    Repayment Timeline
                  </legend>
                  <div
                    className={classNames(
                      'flex justify-start gap-8',
                      values.isTeacherForgivnessActive ? '' : 'cursor-not-allowed opacity-50'
                    )}
                  >
                    <div className="flex items-center gap-4">
                      <Radio
                        id="tlfl-5000"
                        onChange={(e) => setFieldValue('teacherLoanForgivenessLimit', 5000)}
                        checked={values.teacherLoanForgivenessLimit == 5000}
                        disabled={!values.isTeacherForgivnessActive}
                      />
                      <Label className="flex flex-col gap-1" htmlFor="tlfl-5000">
                        <span className="text-gray-900 text-sm font-medium">Qualified</span>
                        <span className="text-gray-500 text-xs font-normal">Limit: $5,000</span>
                      </Label>
                    </div>
                    <div className="flex items-center gap-4">
                      <Radio
                        id="tlfl-17500"
                        onChange={(e) => setFieldValue('teacherLoanForgivenessLimit', 17500)}
                        checked={values.teacherLoanForgivenessLimit == 17500}
                        disabled={!values.isTeacherForgivnessActive}
                      />
                      <Label className="flex flex-col gap-1" htmlFor="tlfl-17500">
                        <span className="text-gray-900 text-sm font-medium">Highly Qualified</span>
                        <span className="text-gray-500 text-xs font-normal">Limit: $17,500</span>
                      </Label>
                    </div>
                  </div>
                  {errors['teacherLoanForgivenessLimit'] && (
                    <div className=" text-sm text-red-700">
                      {errors['teacherLoanForgivenessLimit']} &nbsp;
                    </div>
                  )}
                </fieldset>
              </div>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>
              <PanelHeader>Expenses and Extra Payment</PanelHeader>
            </Accordion.Title>
            <Accordion.Content>
              <div className="grid grid-cols-11 gap-y-6 gap-x-3 items-center max-[1025px]:flex max-[1025px]:flex-wrap">
                <CurrencyField
                  className="col-span-2"
                  name="monthlyExpenses"
                  placeholder="Monthly Expenses"
                  sizing="md"
                />
                <div className="col-span-3">
                  <PercentageField
                    step="0.01"
                    name="annualIncreaseOfMonthlyExpensesPercentage"
                    placeholder="Monthly Expenses Annual Increase"
                    sizing="md"
                  />
                </div>

                <SelectField
                  removeErrorPlaceholder
                  name="loansToPrioritize"
                  className="col-span-3"
                  label="Loans to Prioritize With Extra Payment"
                  styles={{
                    control: {
                      padding: 2,
                    },
                  }}
                >
                  <option value={LoansToPrioritize.Federal}>Federal</option>
                  <option value={LoansToPrioritize.Private}>Private</option>
                </SelectField>
                <SelectField
                  removeErrorPlaceholder
                  name="paydownMethod"
                  className="col-span-3"
                  label="Pay down Schedule"
                  styles={{
                    control: {
                      padding: 2,
                    },
                  }}
                >
                  <option value={PaydownMethods.Avalanche}>Avalanche</option>
                  <option value={PaydownMethods.SnowBall}>SnowBall</option>
                </SelectField>
                <div className="col-span-3 flex items-center gap-1 ">
                  <SwitchField
                    name="leftoverIncomeToUseTowardsLoansType"
                    label="Leftover income as percentage"
                  />
                  <Label>
                    <Tooltip
                      content={'Calculate the desired % of income leftover beyond expenses.'}
                      className="inline "
                    >
                      <HiInformationCircle />
                    </Tooltip>
                  </Label>
                </div>

                {values.leftoverIncomeToUseTowardsLoansType ? (
                  <div className="col-span-4">
                    <PercentageField
                      name="leftoverIncomeToUseTowardsLoans"
                      placeholder="Leftover Income to Use Towards Loans"
                      sizing="md"
                    />
                  </div>
                ) : (
                  <CurrencyField
                    name="leftoverIncomeToUseTowardsLoans"
                    placeholder="Monthly Leftover Income To Use Towards Loans"
                    className="col-span-4"
                    sizing="md"
                  />
                )}
                <CurrencyField
                  name="addedExcessCashFromOtherSources"
                  placeholder="Monthly Amount Added From Other Sources"
                  className="col-span-4"
                  sizing="md"
                />
              </div>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>
              <PanelHeader>Employer Contribution</PanelHeader>
            </Accordion.Title>
            <Accordion.Content>
              <div className="grid grid-cols-3 gap-y-6 gap-x-3 items-center max-[1025px]:flex max-[1025px]:flex-wrap ">
                <CurrencyField
                  name="employerMonthlyContribution"
                  placeholder="Monthly Employer Contribution"
                  className="col-span-1"
                  sizing="md"
                />
                <CurrencyField
                  className="col-span-1"
                  name="employerContributionLifetimeMaximum"
                  placeholder="Lifetime Maximum"
                  sizing="md"
                />
              </div>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      </div>
    </div>
  );
};
