import { Button, Card } from 'flowbite-react';
import { Form, Formik, FormikErrors } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ClientSelectorField } from '../../features/formComponents/ClientSelectorField';
import useFinologyTranslation from '../../translations/useFinologyTranslation';
import { PageHeader } from '../PageHeader';

export const CalculatorSearchClient: React.FC<any> = () => {
  const { t } = useFinologyTranslation();

  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        breadcrumbs={[{ to: '/calculator', text: `Liability Planner - Clients Search` }]}
      />
      <Card className="flex flex-1 flex-col max-w-lg">
        <div className="text-lg bold py-4">Liability Planner - Search</div>
        <Formik
          initialValues={{ client: '' }}
          validateOnMount={true}
          validate={(values) => {
            const errors: FormikErrors<{ clientd: string }> = {};
            if (!values.client) {
              errors.clientd = `${t('borrower.uppercase')} not selected`;

              return errors;
            }
          }}
          onSubmit={(values) => navigate(`/calculator/${values.client}`)}
        >
          {({ isValid }) => {
            return (
              <Form id={'upsert-private-loan-form'} className="flex gap-4">
                <ClientSelectorField
                  className="grow"
                  name="client"
                  selectPlaceholder={`Select ${t('borrower.uppercase')}`}
                />
                <Button disabled={!isValid} type="submit">
                  Go to Liability Planner
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </>
  );
};
