import dayjs from 'dayjs';

import { RepaymentPlanEnum } from '../../../components/calculator/Calculator.types';
import { StackColumnChart } from '../../../components/charts';

const additionalCheck = (i: number, differenceBetweenTimelineMonthAndCurrentMonth: number) => {
  if (differenceBetweenTimelineMonthAndCurrentMonth > 0) {
    return i === 0;
  }

  return false;
};

const RepaymentResultsGraph = ({
  formulaResults,
  calculatorInput,
  repaymentPlan,
  simulationResult,
}: {
  calculatorInput: any;
  formulaResults: { key: RepaymentPlanEnum; value: any }[][];
  repaymentPlan: RepaymentPlanEnum;
  simulationResult: any;
}) => {
  const graphDataMap = new Map<number, any[]>();
  const timelineStartDate = simulationResult.timelineStartDate;

  const monthOffset = simulationResult.resultIndexForRepaymentStart % 12;

  const timelineStartYear = dayjs(timelineStartDate).year();
  const repaymentStartYear = calculatorInput.repaymentStartDate.$y;
  const timelineStartMonth = dayjs(timelineStartDate).month();
  const currentDateMonth = dayjs().month();
  const differenceBetweenTimelineMonthAndCurrentMonth = timelineStartMonth - currentDateMonth;

  const results = { key: repaymentPlan };

  if (results.key == RepaymentPlanEnum.Paye) graphDataMap.set(RepaymentPlanEnum.Paye, ['PAYE']);

  if (results.key == RepaymentPlanEnum.Repaye)
    graphDataMap.set(RepaymentPlanEnum.Repaye, ['REPAYE']);

  if (results.key == RepaymentPlanEnum.Save) graphDataMap.set(RepaymentPlanEnum.Save, ['SAVE']);

  if (results.key == RepaymentPlanEnum.Ibr) graphDataMap.set(RepaymentPlanEnum.Ibr, ['IBR']);

  if (results.key == RepaymentPlanEnum.Icr) graphDataMap.set(RepaymentPlanEnum.Icr, ['ICR']);

  if (results.key == RepaymentPlanEnum.Standard)
    graphDataMap.set(RepaymentPlanEnum.Standard, ['STANDARD']);

  if (results.key == RepaymentPlanEnum.Extended)
    graphDataMap.set(RepaymentPlanEnum.Extended, ['EXTENDED']);

  if (results.key == RepaymentPlanEnum.Graduated)
    graphDataMap.set(RepaymentPlanEnum.Graduated, ['GRADUATED']);

  const columns: any[] = [];

  formulaResults.forEach((montlyResultInYear, index) => {
    graphDataMap.forEach((val) => {
      let formulaResult: { key: RepaymentPlanEnum; value: any } | null = null;

      if ((montlyResultInYear as any)?.length) {
        formulaResult = montlyResultInYear.filter((r) => r.key == repaymentPlan)[0];
      }

      if (formulaResult) {
        val.push({ pb: formulaResult.value.pb, ib: formulaResult.value.ib });
      }
    });
  });

  const data: any[] = [];

  graphDataMap.forEach((val) => {
    columns.push(val);
  });

  columns.forEach((column) => {
    const type = column.shift();

    const shouldAddLastElement = column.length % 12 !== monthOffset;
    let year = timelineStartYear;

    const disbursedData = column
      .map((x: any, index: number) => {
        if (index % 12 === monthOffset) {
          if (year < repaymentStartYear) {
            year += 1;
            return {
              name: 'Disbursed',
              usd: x.pb,
              year: index,
            };
          } else {
            year += 1;
            return {
              name: 'Disbursed',
              usd: 0,
              year: index,
            };
          }
        }
      })
      .filter((x: any) => x);

    year = timelineStartYear;

    const interestData = column
      .map((x: any, index: number) => {
        if (
          index % 12 === monthOffset ||
          additionalCheck(index, differenceBetweenTimelineMonthAndCurrentMonth)
        ) {
          if (year < repaymentStartYear) {
            year += 1;
            return {
              name: 'Interest',
              usd: 0,
              year: index,
            };
          } else {
            year += 1;
            return {
              name: 'Interest',
              usd: x.ib,
              year: index,
            };
          }
        }
      })
      .filter((x: any) => x);

    year = timelineStartYear;

    const principalData = column
      .map((x: any, index: number) => {
        if (
          index % 12 === monthOffset ||
          additionalCheck(index, differenceBetweenTimelineMonthAndCurrentMonth)
        ) {
          if (year < repaymentStartYear) {
            year += 1;
            return {
              name: 'Principal',
              usd: 0,
              year: index,
            };
          } else {
            year += 1;

            return {
              name: 'Principal',
              usd: x.pb,
              year: index,
            };
          }
        }
      })
      .filter((x: any) => x);

    if (shouldAddLastElement) {
      interestData.push({
        name: 'Interest',
        usd: column[column.length - 1].ib,
        year: year,
      });

      principalData.push({
        name: 'Principal',
        usd: column[column.length - 1].pb,
        year: year,
      });
    }

    data.push([disbursedData, principalData, interestData].flat());
  });

  return (
    <div className="flex justify-center items-center print:justify-start print:items-start">
      <div className="w-full">
        <StackColumnChart
          data={data.flat()}
          config={{
            colors:
              timelineStartYear != repaymentStartYear
                ? ['#999999', '#2563eb', '#06b6d4']
                : ['#2563eb', '#06b6d4'],
            xaxis: {
              labels: {
                formatter: (d: any, c: any, opts: any) => {
                  const index = opts?.i || c.dataPointIndex || 0;
                  return timelineStartDate
                    ? dayjs(timelineStartDate).add(index, 'year').format('YYYY')
                    : dayjs().add(index, 'year').format('YYYY');
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};
export default RepaymentResultsGraph;
