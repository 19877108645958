import { PageHeader } from '../components/PageHeader';
import { useGlobalState } from '../context/AppContext';
import { RepaymentPdfs } from '../features/clients/repayment-pdfs/RepaymentPdfs';

export const RepaymentPdfsPage = () => {
  const { user } = useGlobalState();

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            to: '/',
            text: 'Calculator',
          },
        ]}
        back
      />

      <RepaymentPdfs clientId={user!.finologyClientId!} />
    </>
  );
};
