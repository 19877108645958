
import { MultipleLineChart } from '../charts';

import { RepaymentPlanEnum } from './Calculator.types';

const OverviewResultsGraph = ({
  formulaResults,
  calculatorInput,
}: {
  calculatorInput: any;
  formulaResults: { key: RepaymentPlanEnum; value: any }[][];
}) => {
  const graphDataMap = new Map<number, string>();

  const hasRepaymentPlanType = (replaymentPlanType: RepaymentPlanEnum) => {
    return (
      formulaResults.length &&
      formulaResults[0].filter((r: any) => r.key == replaymentPlanType).length
    );
  };

  if (hasRepaymentPlanType(RepaymentPlanEnum.Paye))
    graphDataMap.set(RepaymentPlanEnum.Paye, 'PAYE');

  if (hasRepaymentPlanType(RepaymentPlanEnum.Repaye))
    graphDataMap.set(RepaymentPlanEnum.Repaye, 'REPAYE');

  if (hasRepaymentPlanType(RepaymentPlanEnum.Save))
    graphDataMap.set(RepaymentPlanEnum.Save, 'SAVE');

  if (hasRepaymentPlanType(RepaymentPlanEnum.Ibr)) graphDataMap.set(RepaymentPlanEnum.Ibr, 'IBR');

  if (hasRepaymentPlanType(RepaymentPlanEnum.Icr)) graphDataMap.set(RepaymentPlanEnum.Icr, 'ICR');

  if (hasRepaymentPlanType(RepaymentPlanEnum.Standard))
    graphDataMap.set(RepaymentPlanEnum.Standard, 'STANDARD');

  if (hasRepaymentPlanType(RepaymentPlanEnum.Extended))
    graphDataMap.set(RepaymentPlanEnum.Extended, 'EXTENDED');

  if (hasRepaymentPlanType(RepaymentPlanEnum.Graduated))
    graphDataMap.set(RepaymentPlanEnum.Graduated, 'GRADUATED');

  const data: any[] = [];

  formulaResults.forEach((formulaResult, index) => {
    if (index % 12 === 0 || formulaResults.length - 1 === index) {
      const montlyResultInYear = formulaResult;

      graphDataMap.forEach((val, key) => {
        let formulaResult: { key: RepaymentPlanEnum; value: any } | null = null;

        if ((montlyResultInYear as any)?.length) {
          formulaResult = montlyResultInYear.filter((r) => r.key == key)[0];
        }

        if (formulaResult) {
          data.push({
            name: graphDataMap.get(key),
            usd: formulaResult.value.pb + formulaResult.value.ib,
            year: index,
          });
        } else {
          data.push({
            name: graphDataMap.get(key),
            usd: 0,
            year: index,
          });
        }
      });
    }
  });

  return (
    <MultipleLineChart
      data={data.flat()}
      config={{
        xAxis: {
          label: {
            formatter: (d: any, c: any, index: any) => {
              return calculatorInput.repaymentStartDate.$y + d;
            },
          },
        },
      }}
    />
  );
};
export default OverviewResultsGraph;
