import { Alert } from 'flowbite-react';
import { Form, Formik, FormikErrors } from 'formik';

import { AccountType } from '../../../auth';
import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../components/forms/FormErrorNotification';
import { useGlobalState } from '../../../context/AppContext';
import { useInviteUserMutation } from '../../../graphql/generated';
import { notification } from '../../../util/notification.utils';
import { TextInputField } from '../../formComponents/InputField';
import { UserSelectorField } from '../../formComponents/UserSelectorField';

type FormValues = {
  firstName?: string;
  lastName?: string;
  email?: string;
  allowToImpersonateUserId?: number | null;
};

const FlyoutContent = () => {
  const [inviteUser] = useInviteUserMutation();
  const initialValues: FormValues = {};
  const { user } = useGlobalState();
  const { closeTopFlyout } = useFlyoutContext();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (v, form) => {
        form.setSubmitting(true);

        const { data } = await inviteUser({
          variables: {
            input: {
              firstName: v.firstName!,
              lastName: v.lastName!,
              email: v.email!,
              allowToImpersonateUserId: v.allowToImpersonateUserId!,
            },
          },
          refetchQueries: ['GetUsers'],
        });

        if (data?.inviteUser?.userId) {
          notification.success({
            message: 'Advisor account successfully created.',
            placement: 'bottom-center',
          });

          closeTopFlyout();
        } else {
          notification.error({
            message: 'Advisor account could not be created.',
            placement: 'bottom-center',
          });
        }
      }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.firstName) {
          errors.firstName = 'Required';
        }

        if (!values.lastName) {
          errors.lastName = 'Required';
        }

        if (!values.email) {
          errors.email = 'Required';
        }

        if (values.firstName != undefined && values.firstName.length > 100) {
          errors.firstName = 'First Name must be less than 100 characters';
        }
        if (values.lastName != undefined && values.lastName.length > 100) {
          errors.lastName = 'Last Name must be less than 100 characters';
        }

        return errors;
      }}
    >
      {({ isSubmitting }) => {
        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'invite-user-form',
                children: 'Invite',
                disabled: isSubmitting,
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={'New Advisor'}
            />

            <Form id="invite-user-form">
              <TextInputField name="firstName" placeholder="First Name" />
              <TextInputField name="lastName" placeholder="Last Name" />
              <TextInputField name="email" placeholder="Email" />
              <UserSelectorField
                className="col-span-2"
                name="allowToImpersonateUserId"
                placeholder="Assistant Account of"
                selectPlaceholder={'Select Account to Assist'}
              />
            </Form>
            {user?.accountType == AccountType.College ? null : (
              <Alert color="info" className="mt-12">
                <span>
                  Additional Advisor account costs <strong>$1,068.00</strong> per year, which will be
                  prorated based on the date you sign up. This means that the cost will be adjusted
                  to reflect the amount of time remaining in the current year.
                </span>
                <br />
                <br />
                <span>
                  Please note that your default payment method will be charged immediately upon
                  signing up for an Additional Advisor account. If you wish to use a different
                  payment method, please update your account information before proceeding with the
                  purchase.
                </span>
              </Alert>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export const InviteUserFlyout = () => {
  return <Flyout id={`invite-user`} size={'small'} content={<FlyoutContent />}></Flyout>;
};
