/* eslint-disable */
import { useEffect, useState } from 'react';
import { useGlobalState } from '../context/AppContext';
import { FinologyUser } from '../auth';

declare global {
  interface Window {
    pendo: any;
  }
}

export const usePendoTracking = () => {
  useEffect(() => {
    const usePendo = process.env.REACT_APP_USE_PENDO === 'true';
    if (!usePendo) return;

    const createElement = (p: any, e: any, n: any, d: any, o?: any) => {
      let v: any, w: any, x: any, y: any, z: any;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w) {
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      }

      y = e.createElement(n);
      y.async = true;
      y.src = 'https://cdn.pendo.io/agent/static/1d9f5d0b-5fe7-4998-7d45-b8baf6af04e9/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    };

    createElement(window, document, 'script', 'pendo');
  }, []);
};

export const initializePendoData = () => {
  const { user } = useGlobalState();

  useEffect(() => {
    const usePendo = process.env.REACT_APP_USE_PENDO === 'true';
    if (!usePendo) return;

    if (!user) return;

    function initializePendo(user: FinologyUser) {

      if (typeof window.pendo !== 'undefined') {
        window.pendo.initialize({
          visitor: {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.roles,
            accountCreationDate: user.createdAt,
          },

          account: {
            id: user.tenantId,
            accountName: user.tenantName,
            payingStatus: user.subscriptionStatus,
            businessTier: user.accountType,
          },
        });
      } else {
        setTimeout(() => initializePendo(user), 100);
      }
    }

    initializePendo(user);
  }, [user]);
};
