import { useLocation, useParams } from 'react-router-dom';

import { PageHeader } from '../components/PageHeader';
import { ClientDetails } from '../features/clients/details/ClientDetails';
import useFinologyTranslation from '../translations/useFinologyTranslation';

export const Client = () => {
  const params = useParams();
  const clientId = Number.parseInt(params.clientId!);
  const location = useLocation();
  const { t } = useFinologyTranslation();
  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            to: location.pathname.startsWith('/clients')
              ? '/clients'
              : `${location.pathname.replace(`/clients/${clientId}`, '')}`,
            text: `${t('borrower.uppercase')} Details`,
          },
        ]}
        back
      />

      <ClientDetails clientId={clientId} />
    </>
  );
};
