import { TextInput, Label } from 'flowbite-react';
import { Field } from 'formik';
import { ChangeEvent, ReactNode } from 'react';

const AntInput = ({ field, form, hideLabel, ...props }: any) => {
  const error = form.errors[field.name];

  const { onChange, allowEnterKeyDown, ...rest } = props;

  return (
    <>
      {props.placeholder && !hideLabel && (
        <Label
          color={error ? 'failure' : undefined}
          htmlFor={`form__field_${name}`}
          className="truncate"
        >
          <span>{props.label || props.placeholder}</span>
        </Label>
      )}
      <TextInput
        id={`form__field_${field.name}`}
        type={props.type || 'text'}
        onKeyDown={(e) => {
          // Prevent form submission on enter
          if (e.key === 'Enter' && allowEnterKeyDown !== true) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          form.setFieldValue(
            field.name,
            props.type == 'number' ? e.target.valueAsNumber : e.target.value
          );

          onChange && onChange(e);
        }}
        sizing={'lg'}
        onFocus={(e) => form.setFieldTouched(field.name)}
        color={error ? 'failure' : undefined}
        value={field.value}
        prefix={props.prefix}
        {...rest}
      ></TextInput>
      {error && !error ? null : <p className="text-sm text-red-700">{error} &nbsp;</p>}
    </>
  );
};

export const TextInputField = ({
  name,
  placeholder,
  className,
  type,
  suffix,
  prefix,
  onChange,
  onBlur,
  step,
  disabled,
  id,
  style,
  sizing,
  label,
  hideLabel,
  allowEnterKeyDown,
}: {
  name: string;
  placeholder: string;
  type?: 'text' | 'email' | 'number';
  suffix?: ReactNode;
  prefix?: ReactNode;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  step?: string;
  disabled?: boolean;
  id?: string;
  style?: React.CSSProperties;
  sizing?: 'sm' | 'md' | 'lg';
  label?: string;
  hideLabel?: boolean;
  allowEnterKeyDown?: boolean;
}) => {
  return (
    <div className={className} id={id}>
      <Field
        type={type}
        name={name}
        suffix={suffix}
        prefix={prefix}
        placeholder={placeholder}
        label={label}
        component={AntInput}
        onChange={onChange}
        onBlur={onBlur}
        step={step}
        disabled={disabled}
        style={style}
        sizing={sizing}
        hideLabel={hideLabel}
        allowEnterKeyDown={allowEnterKeyDown}
      />
    </div>
  );
};
