import { Card, Spinner, Button } from 'flowbite-react';
import { useState } from 'react';
import { FaRegCreditCard } from 'react-icons/fa';
import { FaCircleUser } from 'react-icons/fa6';
import { HiUsers, HiMiniBuildingOffice } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';

import { LinkCard } from '../../components/cards/Cards';
import { useGlobalState } from '../../context/AppContext';
import {
  useCreateCustomerPortalLinkMutation,
  useGetSubscriptionInformationQuery,
} from '../../graphql/generated';
import useFinologyTranslation from '../../translations/useFinologyTranslation';

import { SubscriptionDetails } from './SubscriptionDetails';

const AccountAndBillingCard = () => {
  const [createCustomerPortalLink] = useCreateCustomerPortalLinkMutation();
  const { data, loading } = useGetSubscriptionInformationQuery();
  const [redirecting, setRedirecting] = useState(false);

  const redirectToSubscriptionCreate = () => {
    window.location.href = `${process.env.REACT_APP_INTERNAL_API_HOST}/Redirect/ToSubscribe`;
  };

  const createCustomerPortalLinkAndRedirect = async () => {
    setRedirecting(true);

    const { data } = await createCustomerPortalLink();
    window.open(data!.createCustomerPortalLink!);

    setRedirecting(false);
  };

  if (redirecting) return <Spinner />;

  const subscriptionStatus = data?.tenant.subscriptionInfo?.subscriptionStatus;
  const isTrialing = subscriptionStatus === 'trialing' || !subscriptionStatus;
  const hasSubscription = !isTrialing && !!subscriptionStatus;

  return (
    <Card>
      <div className="flex justify-between items-center">
        <span className="font-bold">Account &amp; Billing</span>
        {hasSubscription && !isTrialing && (
          <Button
            color="light"
            onClick={createCustomerPortalLinkAndRedirect}
            className="hover:bg-gray-100 text-teal-900"
          >
            <div className="flex items-center gap-2">
              <FaRegCreditCard /> Manage Subscription
            </div>
          </Button>
        )}
      </div>
      <SubscriptionDetails
        isLoading={loading}
        isTrialing={isTrialing}
        subscriptionInfo={data?.tenant.subscriptionInfo}
        hasSubscription={hasSubscription}
      />
    </Card>
  );
};

export const Settings = () => {
  const navigate = useNavigate();
  const { user } = useGlobalState();
  const { t } = useFinologyTranslation();

  return (
    <div className="flex flex-col gap-y-9">
      <LinkCard
        title="My Profile"
        text="Manage your personal information"
        linkText="Manage Profile"
        linkIcon={<FaCircleUser />}
        onClick={() => navigate('/settings/profile')}
      />
      {user?.roles?.includes('Owner') && (
        <>
          <LinkCard
            title={t('pages.settings.labels.tenant_title')}
            text={t('pages.settings.labels.tenant_label')}
            linkText="Manage Company"
            linkIcon={<HiMiniBuildingOffice />}
            onClick={() => navigate('/settings/company')}
          />
          <LinkCard
            title="User Management"
            text="Manage user accounts"
            linkText="Manage Users"
            linkIcon={<HiUsers />}
            onClick={() => navigate('/settings/users')}
          />
          <AccountAndBillingCard />
        </>
      )}
    </div>
  );
};
