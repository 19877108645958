import dayjs from 'dayjs';
import { Button, Modal, Tabs, Tooltip } from 'flowbite-react';
import React, { ReactNode, useState } from 'react';

import { MultipleLineChart } from '../../../components/charts';
import { FinologyTable } from '../../../components/table/FinologyTable';
import { PrivateLoanIterationResult } from '../../../graphql/generated';
import { toDollars } from '../../../util/currency.formatter';
import { toPercentage } from '../../../util/number.formatter';
import { formatCurrency } from '../../../utils';

const PrivateLoansResult = (result: any) => {
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  let isLoanPaidOff = false;
  const originalStartDate = dayjs(result.startDate);

  const rows = result.privateLoansResult.iterations
    .map((x: PrivateLoanIterationResult, i: number) => {
      if (i % 12 == 11) {
        isLoanPaidOff = x.balanceBegin == 0;
        return {
          year: originalStartDate.add(i, 'month').year(),
          balanceBegin: x.balanceBegin,
          minimalPayment: x.minPayment,
          extraPayment: x.extraPayout,
          lastInstallment: x.totalPayout,
          interestCharged: x.interestCharged,
          totalBalance: x.toBalance,
        };
      }
    })
    .filter((x: any) => !!x);

  if (!isLoanPaidOff) {
    rows.push({
      year: Math.floor(result.privateLoansResult.iterations.length / 12) + 1,
      balanceBegin: 0,
      minimalPayment: 0,
      extraPayment: 0,
      lastInstallment: 0,
      interestCharged: 0,
      totalBalance: 0,
    });
  }

  const columns: any = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      render: (text: string) => `${text}.`,
    },
    {
      title: 'Balance Begin',
      dataIndex: 'balanceBegin',
      key: 'balanceBegin',
      render: (text: string) => `${formatCurrency(text)}`,
    },
    {
      title: 'Minimal Payment',
      dataIndex: 'minimalPayment',
      key: 'minimalPayment',
      render: (text: string) => `${formatCurrency(text)}`,
    },
    {
      title: 'Extra Payment',
      dataIndex: 'extraPayment',
      key: 'extraPayment',
      render: (text: string) => `${formatCurrency(text)}`,
    },
    {
      title: () => (
        <Tooltip content="Total Payment = Minimum Payment + Extra Payment.">
          <div>Total Payment</div>
        </Tooltip>
      ),
      dataIndex: 'lastInstallment',
      key: 'lastInstallment',
      render: (text: string) => `${formatCurrency(text)}`,
    },
    {
      title: 'Monthly Interest Charged',
      dataIndex: 'interestCharged',
      key: 'interestCharged',
      render: (text: string) => `${formatCurrency(text)}`,
    },
    {
      title: () => (
        <Tooltip content="Principal Payment Amount = The portion of a loan payment that is applied towards reducing the outstanding balance of the loan after deducting the interest charged by the lender.">
          <div>Principal Payment</div>
        </Tooltip>
      ),
      dataIndex: 'totalBalance',
      key: 'totalBalance',
      render: (text: string) => `${formatCurrency(text)}`,
    },
  ];

  return (
    <div>
      <FinologyTable
        type="no-promise"
        rows={rows}
        columns={columns}
        onRowClick={(record, index) => {
          setSelectedYear(Math.floor((index || 0) / 12));
        }}
      />
      <Modal size="5xl" show={selectedYear != null} onClose={() => setSelectedYear(null)}>
        <Modal.Header>Monthly results for {result.modalTitle}</Modal.Header>
        <Modal.Body>
          <YearModal
            columns={columns}
            year={selectedYear || 0}
            formulaResults={result.privateLoansResult.iterations}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setSelectedYear(null)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const YearModal = ({
  formulaResults,
  year,
  columns,
}: {
  formulaResults: any;
  year: number;
  columns: any[];
}) => {
  const rows: any[] = [];

  const newColumns = [...columns];

  newColumns.splice(1, 0, {
    title: 'Month',
    dataIndex: 'month',
    key: 'month',
    render: (text: string) => `${text}.`,
  });

  for (let i = year * 11 + year; i <= (year + 1) * 11 + year; i++) {
    const montlyResultInYear = formulaResults[i];
    if (!montlyResultInYear) {
      rows.push({
        year: year + 1,
        month: (i % 12) + 1,
        balanceBegin: 0,
        minimalPayment: 0,
        extraPayment: 0,
        lastInstallment: 0,
        interestCharged: 0,
        totalBalance: 0,
      });

      continue;
    }

    const formulaResultsInMonth = montlyResultInYear;
    if (!formulaResultsInMonth) {
      rows.push({
        year: year + 1,
        month: (i % 12) + 1,
        balanceBegin: 0,
        minimalPayment: 0,
        extraPayment: 0,
        lastInstallment: 0,
        interestCharged: 0,
        totalBalance: 0,
      });

      continue;
    }

    rows.push({
      year: year + 1,
      month: (i % 12) + 1,
      balanceBegin: formulaResultsInMonth.balanceBegin,
      minimalPayment: formulaResultsInMonth.minPayment,
      extraPayment: formulaResultsInMonth.extraPayout,
      lastInstallment: formulaResultsInMonth.totalPayout,
      interestCharged: formulaResultsInMonth.interestCharged,
      totalBalance: formulaResultsInMonth.toBalance,
    });
  }

  return <FinologyTable type="no-promise" rows={rows} columns={newColumns} />;
};

const FirstRowItem = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <div className="bg-cyan-100 rounded-lg shadow flex-col justify-end items-center inline-flex">
      <div className="self-stretch py-4 flex-col justify-center items-center gap-1 flex">
        <div className="flex-col justify-start items-start flex">
          <div className="text-cyan-700 text-2xl font-bold">{value}</div>
        </div>
      </div>
      <div className="self-stretch py-3 bg-white rounded-bl-lg rounded-br-lg justify-center items-start gap-2.5 inline-flex">
        <div className="text-gray-800 text-xs font-medium">{label}</div>
      </div>
    </div>
  );
};

const SecondRowItem = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <div className="bg-gray-100 rounded-lg shadow flex-col justify-end items-center inline-flex">
      <div className="self-stretch py-4 flex-col justify-center items-center gap-1 flex">
        <div className="flex-col justify-start items-start flex">
          <div className="text-gray-700 text-2xl font-bold">{value}</div>
        </div>
      </div>
      <div className="self-stretch py-3 bg-white rounded-bl-lg rounded-br-lg justify-center items-start gap-2.5 inline-flex">
        <div className="text-gray-800 text-xs font-medium">{label}</div>
      </div>
    </div>
  );
};

export const PrivateLoansSimulationResults = ({ simulationResult }: any) => {
  const data: any[] = [];

  const graphDataMap = new Map<any, string>();

  simulationResult.loans.forEach((loan: any) => {
    graphDataMap.set(loan, loan.name);

    loan.iterations.forEach((iteration: any, index: any) => {
      if (index % 12 === 0 || loan.iterations.length - 1 === index) {
        const montlyResultInYear = iteration;

        if (montlyResultInYear) {
          data.push({
            name: loan.name,
            usd: iteration.balanceBegin,
            year: index,
          });
        } else {
          data.push({
            name: loan.name,
            usd: 0,
            year: index,
          });
        }
      }
    });
  });

  return (
    <>
      <div className="text-gray-900 text-lg font-semibold mt-8">Private Loans Overview</div>

      <div className="grid grid-cols-3 gap-4 mt-8">
        <FirstRowItem label="Total Loan Balance" value={toDollars(simulationResult.totalBalance)} />
        <FirstRowItem
          label="Sum of Payments"
          value={toDollars(simulationResult.totalOutOfPocket)}
        />
        <FirstRowItem label="Average Rate" value={toPercentage(simulationResult.averageRate)} />
      </div>
      <div className="text-gray-900 text-lg font-semibold mt-8">Private Loans Repayment</div>
      <div className="my-8">
        <Tabs style="underline">
          {simulationResult.loans.length >= 1 ? (
            <Tabs.Item
              title={
                simulationResult.loans.length === 1 ? simulationResult.loans[0].name : 'OVERVIEW'
              }
              key="0"
            >
              <div className="rounded-lg shadow p-4">
                <div className="flex justify-center items-center print:justify-start print:items-start">
                  <div className="w-full">
                    <MultipleLineChart
                      data={data.flat()}
                      config={{
                        yAxis: {
                          label: {
                            formatter: (text: string) => formatCurrency(text),
                          },
                        },
                        xAxis: {
                          label: {
                            formatter: (d: any, c: any, index: any) => {
                              return dayjs().add(d, 'year').format('YYYY');
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </Tabs.Item>
          ) : null}
          {/* {privateLoansResult.loans.map((loan: any) => {
            return (
              <Tabs.Item title={loan.name} key="1">
              </Tabs.Item>
            );
          })} */}
        </Tabs>
      </div>
    </>
  );
};
