import { useEffect } from 'react';

export const useHubspot = () => {
  useEffect(() => {
    const useHubspot = process.env.REACT_APP_USE_HUBSPOT === 'true';
    if (!useHubspot) return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = 'https://js.hs-scripts.com/20249495.js';

    document.body.appendChild(script);
  }, []);
};
