import classNames from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export const FinoLink = ({
  to,
  label,
  className,
  icon,
  onClick,
}: {
  to: string;
  label: ReactNode;
  className?: string;
  icon?: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <Link
      onClick={onClick}
      className={classNames(
        'inline-flex gap-2 items-center hover:underline',
        className || 'text-primary-700 hover:text-primary-800'
      )}
      to={to}
    >
      {icon} {label}
    </Link>
  );
};
