interface Props {
  street?: string | null;
  streetNumber?: string | null;
  state?: string | null;
  city?: string | null;
  zipCode?: string | null;
}

export const AddressLabel = ({ street, streetNumber, city, state, zipCode }: Props) => {
  let fullAddress = '';

  if (street) fullAddress = street;

  if (streetNumber) {
    if (street) fullAddress += ` ${streetNumber}`;
    else fullAddress = streetNumber;
  }

  if (state) {
    if (streetNumber || street) fullAddress += `, ${state}`;
    else fullAddress += state;
  }

  if (city) {
    if (state || streetNumber || street) fullAddress += `, ${city}`;
    else fullAddress += city;
  }

  if (zipCode) {
    if (city || state || streetNumber || street) fullAddress += ` ${zipCode}`;
    else fullAddress += zipCode;
  }

  return <>{fullAddress}</>;
};
