import { PageHeader } from '../../components/PageHeader';
import { SuperAdminDashboard } from '../../features/super-admin/dashboard/SuperAdminDashboard';

export const SuperAdminDashboardPage = () => {
  return (
    <>
      <PageHeader breadcrumbs={[{ to: '/super-admin/dashboard', text: 'Super Admin Dashboard' }]} />

      <SuperAdminDashboard />
    </>
  );
};
