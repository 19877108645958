export enum RepaymentPlanEnum {
  Paye,
  Repaye,
  Ibr,
  Icr,
  Standard,
  Extended,
  Graduated,
  Save
}

export enum ForgivenessType {
  Pslf,
  TimeBased,
  Teacher,
}
