import { Button } from 'flowbite-react';
import { useEffect } from 'react';

import { AccountType } from '../auth';
import { PageHeader } from '../components/PageHeader';
import { REGISTRATION_PROCESS_LOCAL_STORAGE_ID } from '../constants';
import { useGlobalState } from '../context/AppContext';
import { useFlyoutNavigate } from '../hooks/useFlyoutNavigate';
import useFinologyTranslation from '../translations/useFinologyTranslation';

import { Demo } from './Demo';

export const WelcomePage = () => {
  const { user } = useGlobalState();
  const { t } = useFinologyTranslation();

  const { navigate } = useFlyoutNavigate();

  useEffect(() => {
    return () => sessionStorage.removeItem(REGISTRATION_PROCESS_LOCAL_STORAGE_ID);
  }, []);

  switch (user?.accountType) {
    case AccountType.Organization:
    case AccountType.College:
      return <Demo />;

    default:
      return (
        <>
          <PageHeader
            breadcrumbs={[{ to: '', text: `${user?.firstName} ${user?.lastName || ''}` }]}
          />

          <div className="mt-44 flex items-center justify-center flex-col font">
            <div className="text-3xl">
              Hey {`${user?.firstName}${user?.lastName ? ' ' + user?.lastName : ''}`}! Your account
              is now created!
            </div>
            <div className="text-2xl	mt-10">
              We just need a bit more info from you to get you started.
            </div>
            <Button
              className="mt-6"
              onClick={() =>
                navigate(`upload-nslds`, `clientId=${user!.finologyClientId}&redirect=true`)
              }
            >
              NSLDS Upload
            </Button>
            <div className="mt-2 text-xs">Go to studentaid.gov/xxx to generate your file.</div>
            <div className="text-s text-fino-grey mt-4">OR</div>
            <Button
              className="mt-5"
              onClick={() =>
                navigate(
                  'upsert-federal-loan',
                  `createNewVersion=true&clientId=${user?.finologyClientId}`
                )
              }
            >
              Manual Entry
            </Button>
          </div>
        </>
      );
  }
};
