import dayjs from 'dayjs';
import { Button, Modal, Tabs } from 'flowbite-react';
import React, { ReactNode, useState } from 'react';

import { MultipleLineChart } from '../../../components/charts';
import { FinologyTable } from '../../../components/table/FinologyTable';
import { toDollars } from '../../../util/currency.formatter';
import { toPercentage } from '../../../util/number.formatter';

import { chunkArray } from './results.helpers';

const COLUMNS: {
  title: string | (() => JSX.Element);
  dataIndex: string;
  key: string;
  render: (value: number) => JSX.Element;
}[] = [
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
    render: (value: number) => <span className="text-primary cursor-pointer">{value}</span>,
  },
  {
    title: 'Interest',
    dataIndex: 'ti',
    key: 'ti',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
  {
    title: 'Principal',
    dataIndex: 'tp',
    key: 'tp',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
  {
    title: 'Total Amount',
    dataIndex: 'to',
    key: 'to',
    render: (value: number) => <span>{toDollars(value)}</span>,
  },
];

const YearModal = ({
  yearResults,
  year,
  repaymentStartMonth,
  maxYear,
}: {
  yearResults: any[];
  year: number;
  repaymentStartMonth: number;
  maxYear: number;
}) => {
  let isLoanPaid = false;

  const rows: any[] = [];

  for (let i = 0; i < 12; i++) {
    const monthlyResultInYear = yearResults[i];

    if (!monthlyResultInYear) {
      break;
    }
    if (monthlyResultInYear.to === 0 && maxYear === year + 1) {
      isLoanPaid = true;
      break;
    }
    const month = i + 1;

    rows.push({
      month: month + (year === 0 ? repaymentStartMonth : 0),
      tp: monthlyResultInYear.tp,
      ti: monthlyResultInYear.ti,
      to: monthlyResultInYear.to,
    });
  }

  return (
    <div>
      <FinologyTable
        type={'no-promise'}
        rows={rows}
        columns={[
          {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
            render: (value: number) => <span>{value}.</span>,
          },
          ...COLUMNS.filter((r) => r.key != 'year'),
        ]}
      />
      {isLoanPaid && (
        <div className="p-4 text-center text-2xl font-bold bg-fino-grey-shade">
          Loan(s) are paid off.
        </div>
      )}
    </div>
  );
};

const RepaymentTable = ({ iterations, calculatorInput }: any) => {
  //use for modal
  const [selectedYear, setSelectedYear] = useState<{ index: number; year: number } | null>(null);
  const [isShortenedTable, setIsShortenedTable] = useState(true);

  if (!iterations.length) return null;

  const rows: any[] = [];

  //TODO
  const timelineStartDate = dayjs();

  const timelineStartYear = dayjs(timelineStartDate).year();
  const timelineStartMonth = dayjs(timelineStartDate).month();

  let maxYear = 0;

  const itemsToSlice = 12 - timelineStartMonth;

  //slice the first year

  const firstYear = [iterations.slice(0, itemsToSlice)];
  const rest = iterations.slice(itemsToSlice);

  const chunks = chunkArray(rest, 12);
  const resultByYear = [...firstYear, ...chunks];

  resultByYear.forEach((monthlyResult: any, i: number) => {
    const monthlyResultInYear = monthlyResult[0];

    if (!monthlyResultInYear) {
      return;
    }
    maxYear += 1;

    rows.push({
      year: Math.ceil(timelineStartYear + i),
      ti: monthlyResultInYear.ti,
      tp: monthlyResultInYear.tp,
      to: monthlyResultInYear.to,
    });
  });

  return (
    <>
      <FinologyTable
        type="no-promise"
        rows={rows.filter((row) => {
          if (isShortenedTable) {
            const isRepaymentStartYearOr5YearsFromIt =
              calculatorInput.repaymentStartDate.$y <= row.year &&
              row.year < calculatorInput.repaymentStartDate.$y + 5;

            return isRepaymentStartYearOr5YearsFromIt;
          }

          return true;
        })}
        onRowClick={(record: any, index) => {
          setSelectedYear({ index: rows.indexOf(record), year: record.year });
        }}
        columns={COLUMNS}
      />
      {rows.length >= 5 && (
        <Button color="light" className="mt-4" onClick={() => setIsShortenedTable((val) => !val)}>
          {isShortenedTable ? 'Show All Years' : 'Collapse'}
        </Button>
      )}
      <Modal show={selectedYear !== null} size={'5xl'} onClose={() => setSelectedYear(null)}>
        <Modal.Header>Results for year {selectedYear?.year}</Modal.Header>
        <Modal.Body>
          <YearModal
            year={selectedYear?.index || 0}
            yearResults={resultByYear[selectedYear?.index || 0]}
            repaymentStartMonth={timelineStartMonth}
            maxYear={maxYear}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setSelectedYear(null)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const FirstRowItem = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <div className="bg-cyan-100 rounded-lg shadow flex-col justify-end items-center inline-flex">
      <div className="self-stretch py-4 flex-col justify-center items-center gap-1 flex">
        <div className="flex-col justify-start items-start flex">
          <div className="text-cyan-700 text-2xl font-bold">{value}</div>
        </div>
      </div>
      <div className="self-stretch py-3 bg-white rounded-bl-lg rounded-br-lg justify-center items-start gap-2.5 inline-flex">
        <div className="text-gray-800 text-xs font-medium">{label}</div>
      </div>
    </div>
  );
};

export const CreditCardSimulationResults = ({ simulationResult, calculatorInput }: any) => {
  const aggregationResults = simulationResult.aggregationResults;
  const data: any[] = [];

  const graphDataMap = new Map<any, string>();

  simulationResult.cards.forEach((loan: any) => {
    graphDataMap.set(loan, loan.name);

    loan.iterations.forEach((iteration: any, index: any) => {
      if (index % 12 === 0 || loan.iterations.length - 1 === index) {
        const montlyResultInYear = iteration;

        if (montlyResultInYear) {
          data.push({
            name: loan.name,
            usd: iteration.totalOwed,
          });
        } else {
          data.push({
            name: loan.name,
            usd: 0,
          });
        }
      }
    });
  });

  return (
    <>
      <div className="text-gray-900 text-lg font-semibold">Credit Card Loans Overview</div>

      <div className="grid grid-cols-3 gap-4 mt-8">
        <FirstRowItem
          label="Total Loan Balance"
          value={toDollars(aggregationResults.totalLoanBalance)}
        />
        <FirstRowItem
          label="Min. Mo. Payment to Avoid Interest"
          value={toDollars(aggregationResults.initialInterests)}
        />
        <FirstRowItem
          label="Weighted Avg. Interest Rate"
          value={toPercentage(aggregationResults.weightedAverageInterestRate)}
        />
      </div>
      <div className="text-gray-900 text-lg font-semibold mt-8">Credit Card Loans Repayment</div>
      <div className="mt-8">
        <Tabs style="underline">
          <Tabs.Item title={'OVERVIEW'} key="0">
            <div className="rounded-lg shadow p-4">
              <div className="flex justify-center items-center print:justify-start print:items-start">
                <div className="w-full">
                  <MultipleLineChart
                    data={data.flat()}
                    config={{
                      xAxis: {
                        label: {
                          formatter: (index: any) => {
                            return calculatorInput.repaymentStartDate.$y + index;
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <RepaymentTable
              iterations={aggregationResults.iterations}
              calculatorInput={calculatorInput}
            />
          </Tabs.Item>
        </Tabs>
      </div>
    </>
  );
};
