import { Form, Formik, FormikErrors } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../components/forms/FormErrorNotification';
import { ClientSimpleCreateInput, useCreateSimpleClientMutation } from '../../../graphql/generated';
import useFinologyTranslation from '../../../translations/useFinologyTranslation';
import { TextInputField } from '../../formComponents/InputField';
import { NsldsUploadField } from '../../formComponents/NsldsUploadField';

type FormValues = Partial<ClientSimpleCreateInput>;

const FlyoutContent = () => {
  const navigate = useNavigate();
  const { t } = useFinologyTranslation();
  const [createSimpleClient] = useCreateSimpleClientMutation();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const spouseId = params.get('spouseId');
  const parsedSpouseId = spouseId ? Number.parseInt(spouseId) : null;

  const initialValues: FormValues = {};
  const { closeTopFlyout } = useFlyoutContext();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (v, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        const { data } = await createSimpleClient({
          variables: {
            input: {
              firstName: v.firstName!,
              lastName: v.lastName!,
              spouseId: parsedSpouseId,
              uploadId: v.uploadId!,
              source: v.source!,
            },
          },
          refetchQueries: ['GetClientDashboard'],
        });

        if (parsedSpouseId) closeTopFlyout();
        else navigate(`/clients/${data?.createSimpleClient!.clientId}`);
      }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.firstName) {
          errors.firstName = 'Required';
        }
        if (!values.lastName) {
          errors.lastName = 'Required';
        }
        if (!values.uploadId) {
          errors.uploadId = 'Required';
        }
        if (!values.source) {
          errors.source = 'Required';
        }

        if (values.firstName != undefined && values.firstName.length > 100) {
          errors.firstName = 'First Name must be less than 100 characters';
        }
        if (values.lastName != undefined && values.lastName.length > 100) {
          errors.lastName = 'Last Name must be less than 100 characters';
        }

        return errors;
      }}
    >
      {({ isSubmitting }) => {
        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'add-client-simple-form',
                children: 'Save',
                disabled: isSubmitting,
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={`New ${t('borrower.uppercase')}`}
            />
            <Form id="add-client-simple-form">
              <TextInputField name="firstName" placeholder="First Name" />

              <TextInputField name="lastName" placeholder="Last Name" />

              <NsldsUploadField name="uploadId" placeholder="NSLDS File" />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export const AddClientFlyoutSimple = () => {
  return (
    <Flyout
      id={`create-client-simple`}
      size={'small'}
      requiredParams={['spouseId']}
      content={<FlyoutContent />}
    ></Flyout>
  );
};
