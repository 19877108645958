export const range = (start: number, end: number) => {
  const list: number[] = [];

  for (let i = start; i <= (Number.isFinite(end) ? end : 0); i++) {
    list.push(i);
  }

  return list;
};

export const times = <T>(times: number, func: (index: number) => T) => {
  const list: T[] = [];

  for (let i = 0; i < (Number.isFinite(times) ? times : 0); i++) {
    const callResult = func(i);

    list.push(callResult);
  }

  return list;
};
