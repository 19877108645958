import { format } from 'fecha';

import { FinologyUser } from './auth/index';

export const formatDate = (date: Date | string | null) => {
  if (!date) return null;
  if (typeof date === 'string') {
    const newDate = new Date(date);
    return format(newDate, 'MM/DD/YYYY');
  } else {
    return format(date, 'MM/DD/YYYY');
  }
};
export const formatCurrency = (value: number | string) => {
  const number = typeof value === 'string' ? parseFloat(value) : value;

  const numUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return numUSD.format(number);
};

export const formatCurrencyToCompact = (value: number | string) => {
  const number = typeof value === 'string' ? parseFloat(value) : value;

  const numUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    maximumFractionDigits: 1,
  });

  return numUSD.format(number);
};

export const dataURLtoFile = (dataurl: any, filename: any) => {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

// Name potentially can be a full name so surname is optional.
export const getInitials = (name: string, surname?: string) => {
  if (!surname) {
    const splitName = name.split(' ');

    const firstName = splitName[0];
    const lastName = splitName[splitName.length - 1] || '';

    if (lastName[0]) return `${firstName[0]}${lastName[0]}`;

    return firstName[0];
  }

  return `${name[0]}${surname[0]}`;
};

export const isClient = (user?: FinologyUser | null) => {
  return user?.roles?.includes('Borrower');
};
