import { Checkbox, Label } from 'flowbite-react';
import { Field } from 'formik';

const AntCheckbox = ({ field, form, ...props }: any) => {
  return (
    <div className="flex gap-x-2 items-center">
      {props.isLabelFirst && (
        <Label
          className={props.disabled ? `cursor-not-allowed` : 'cursor-pointer'}
          htmlFor={field.name}
        >
          {props.label}
        </Label>
      )}
      <Checkbox
        id={field.name}
        className={props.disabled ? `cursor-not-allowed` : 'cursor-pointer'}
        checked={field.value}
        disabled={props.disabled}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.checked);
        }}
      />
      {!props.isLabelFirst && (
        <Label
          className={props.disabled ? `cursor-not-allowed` : 'cursor-pointer'}
          htmlFor={field.name}
        >
          {props.label}
        </Label>
      )}
    </div>
  );
};

export const CheckboxField = ({
  name,
  label,
  className,
  disabled,
  isLabelFirst,
}: {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  isLabelFirst?: boolean;
}) => {
  return (
    <div className={className}>
      <Field
        name={name}
        label={label}
        component={AntCheckbox}
        disabled={disabled}
        isLabelFirst={isLabelFirst}
      />
    </div>
  );
};
