import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';

//Support for Do
dayjs.extend(advancedFormat);

//Parse string as UTC
dayjs.extend(utc);

export const toDateWithTimeLongString = (dateWithTime: string) => {
  if (!dateWithTime) return dateWithTime;

  return dayjs.utc(dateWithTime).local().format('MMMM Do, YYYY [at] HH:mm:ss');
};

export const toDateLongString = (dateWithTime: string) => {
  if (!dateWithTime) return dateWithTime;

  return dayjs.utc(dateWithTime).local().format('MMMM Do, YYYY');
};

export const toDateShortString = (dateWithTime: string) => {
  if (!dateWithTime) return dateWithTime;

  return dayjs.utc(dateWithTime).local().format('MM-DD-YYYY');
};
