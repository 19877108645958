import { ForgivenessType } from '../../graphql/generated';

const translations = {
  [ForgivenessType.TimeBased]: 'Time Based',
  [ForgivenessType.Teacher]: 'Teached',
  [ForgivenessType.Pslf]: 'PSLF',
};

export const ForgivenessTypeLabel = ({ forgivenessType }: { forgivenessType: ForgivenessType }) => {
  if (!forgivenessType) return null;

  const translation = translations[forgivenessType];

  return <>{translation}</>;
};
