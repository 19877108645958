import { toast, ToastPosition } from 'react-toastify';

export const notification = {
  success: ({
    placement,
    message,
    hideProgressBar = false,
  }: {
    placement?: ToastPosition;
    message: string;
    hideProgressBar?: boolean;
  }) => {
    toast.success(message, {
      position: placement,
      hideProgressBar,
      style: {
        width: 650,
      },
    });
  },
  error: ({
    placement,
    message,
    hideProgressBar = false,
  }: {
    placement?: ToastPosition;
    message: string;
    hideProgressBar?: boolean;
  }) => {
    toast.error(message, {
      position: placement,
      hideProgressBar,
      style: {
        width: 650,
      },
    });
  },
};
