import numeral from 'numeral';

const formatter = (
  val: number | null | undefined,
  formatString: string
): string | null | undefined => {
  if (val === null || val === undefined) return val;

  return numeral(val).format(formatString);
};

export const toDollars = (value: number | null | undefined) => {
  return formatter(value || 0, '$0,0');
};

export const toDollarsWithDecimals = (value: number | null | undefined) => {
  return formatter(value || 0, '$0,0.00');
};
