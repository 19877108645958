import { Button } from 'flowbite-react';

import { PageHeader } from '../components/PageHeader';
import { useGlobalState } from '../context/AppContext';
import { ClientDetails } from '../features/clients/details/ClientDetails';
import { AdvisorDashboard } from '../features/dashboard/AdvisorDashboard';
import { useFlyoutNavigate } from '../hooks/useFlyoutNavigate';
import { isClient } from '../utils';

export const CompanyLogoComponent = () => {
  const { user } = useGlobalState();
  const { navigate } = useFlyoutNavigate();

  if (user?.companyLogo) {
    return (
      <div
        style={{
          maxWidth: 120,
        }}
      >
        <img src={user.companyLogo} />
      </div>
    );
  }

  if (!user?.roles?.includes('Owner')) return null;

  return (
    <Button
      color="light"
      onClick={() => navigate('edit-company')}
      className="items-center font-normal"
    >
      Upload Logo
    </Button>
  );
};

export const Dashboard = () => {
  const { user } = useGlobalState();
  const { navigate: flyoutNavigate } = useFlyoutNavigate();

  return isClient(user) ? (
    <>
      <PageHeader
        rightContent={
          <>
            <Button
              onClick={() => flyoutNavigate('upload-nslds', `clientId=${user!.finologyClientId}`)}
            >
              Upload
            </Button>
          </>
        }
        breadcrumbs={[]}
      />

      <ClientDetails clientId={user!.finologyClientId!} />
    </>
  ) : (
    <>
      <PageHeader
        breadcrumbs={[{ to: '/dashboard', text: 'Hi ' + user!.firstName }]}
        rightContent={<CompanyLogoComponent />}
      />

      <AdvisorDashboard />
    </>
  );
};
