import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';

import { ForgivenessType } from '../../../components/calculator/Calculator.types';
import { FinologyTable } from '../../../components/table/FinologyTable';
import { toDollars } from '../../../util/currency.formatter';
import { formatDate } from '../../../utils';

const objectsWithForgivenessType = (array: any, keyToCheck: ForgivenessType) => {
  return array.filter((obj: any) => obj.forgivenessType === keyToCheck);
};

const RowItem = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <div className="bg-sky-100 rounded-lg shadow flex-col justify-end items-center inline-flex">
      <div className="self-stretch py-4 flex-col justify-center items-center gap-1 flex">
        <div className="flex-col justify-start items-start flex">
          <div className="text-gray-700 text-2xl font-bold">{value}</div>
        </div>
      </div>
      <div className="self-stretch py-3 bg-white rounded-bl-lg rounded-br-lg justify-center items-start gap-2.5 inline-flex">
        <div className="text-gray-800 text-xs font-medium">{label}</div>
      </div>
    </div>
  );
};

const calculateProFormaTaxedAmount = (simulationResult: any, calculatorInput: any) => {
  const finalLoansIteration = simulationResult?.finalIterationDate
    ? dayjs(simulationResult?.finalIterationDate)
    : undefined;

  const federalYearsToRun =
    finalLoansIteration && calculatorInput.repaymentStartDate
      ? finalLoansIteration.year() - calculatorInput.repaymentStartDate.$y
      : 0;

  const estimatedYearlySavingTowardsTaxBomb =
    (calculatorInput.estimatedMonthlySavingTowardsTaxBomb || 0) * 12;
  const rateOfReturn = (calculatorInput.rateOfReturnOnTaxSavingsBomb || 0) / 100;

  let proFormaAssetValue = calculatorInput.currentSavingTowardsTaxBomb || 0;

  for (let i = 1; i <= federalYearsToRun; i++) {
    proFormaAssetValue += estimatedYearlySavingTowardsTaxBomb;
    proFormaAssetValue *= 1 + rateOfReturn;
  }

  return proFormaAssetValue;
};

export const ForgivenessResults = ({
  amountForgiven,
  simulationResult,
  calculatorInput,
}: {
  amountForgiven: number;
  simulationResult: any;
  calculatorInput: any;
}) => {
  const forgivenessResults: any[] = simulationResult.fgr;

  const [isTimeBasedTaxesModalVisible, setIsTimeBasedTaxesModalVisible] = useState(false);

  if (!forgivenessResults?.length) return null;

  const { isPslfActive, isTeacherForgivnessActive, isTimeBasedForgivnessActive } = calculatorInput;

  const columns = [
    {
      title: 'Loan',
      dataIndex: 'type',
      key: 'type',
    },
    {
      key: 'targetEndDate',
      title: 'Target End Date',
      render: (text: number, record: any) => formatDate(record.d),
    },
    {
      key: 'amount',
      title: 'Amount',
      render: (text: number, record: any) => toDollars(record.a),
    },
    {
      key: 'pfta',
      title: 'Pro Forma Taxed Amount',
      render: (_: any, record: any) => toDollars(record.pfta),
    },
  ];

  let rows: any[] = [];

  if (isTimeBasedForgivnessActive) {
    rows = forgivenessResults.map((i) => {
      return objectsWithForgivenessType(i.f, ForgivenessType.TimeBased).map((t: any) => ({
        ...t,
        type: i.t,
      }));
    });
  } else {
    rows = forgivenessResults.map((i) => {
      return objectsWithForgivenessType(i.f, ForgivenessType.Pslf).map((t: any) => ({
        ...t,
        type: i.t,
      }));
    });
  }

  rows = rows.reduce((a, b) => a.concat(b), []);

  const proFormaTaxedAmount = rows.reduce((prev, next) => prev + next.pfta, 0);
  const proFormaAssetValue = calculateProFormaTaxedAmount(simulationResult, calculatorInput);
  const netDifference = proFormaTaxedAmount - proFormaAssetValue;

  return (
    <>
      <div className="text-gray-900 text-lg font-semibold mt-8">
        {isTimeBasedForgivnessActive ? 'IDR' : 'PSLF'} Summary
      </div>

      <div className="mt-8">
        <div className="grid grid-cols-4 gap-4">
          <RowItem label="Total Projected Forgiveness" value={toDollars(amountForgiven)}></RowItem>
          <RowItem label="Pro Forma Taxed Amount" value={toDollars(proFormaTaxedAmount)}></RowItem>
          <RowItem label="Pro Forma Asset Value" value={toDollars(proFormaAssetValue)}></RowItem>
          <RowItem
            label="Net Difference"
            value={
              netDifference > 0 ? (
                <span className="text-red-500">({toDollars(netDifference)})</span>
              ) : (
                <span className="text-green-500">{toDollars(netDifference * -1)}</span>
              )
            }
          ></RowItem>
        </div>
        <div className="mt-4">
          <FinologyTable type="no-promise" rows={rows} columns={columns} />
        </div>
        {/* <table className="table p-4 w-full">
          <thead>
            <tr>
              <th></th>
              {isPslfActive ? (
                <th className="text-sm text-start pl-4" colSpan={2}>
                  PSLF based forgiveness:
                </th>
              ) : (
                <th className="text-md flex flex-column text-start pl-4" colSpan={2}>
                  Time based forgiveness:
                  {!simulationResult.timeBasedTaxesResults ? (
                    <span>
                      {simulationResult?.timeBasedTaxesResults && (
                        <div onClick={() => setIsTimeBasedTaxesModalVisible(true)}>
                          <svg
                            className="h-3 w-3 "
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </div>
                      )}
                    </span>
                  ) : null}
                </th>
              )}
              <th />
              <th />
              {isTeacherForgivnessActive && (
                <th className="text-md text-start pl-4" colSpan={2}>
                  Teacher forgiveness:
                </th>
              )}
            </tr>
            <tr>
              <th className="text-sm text-gray text-start border-t-1 border-b-1 border-l-0 border-r-1 border-solid border-fino-dark">
                Loan
              </th>
              <th className="text-sm text-gray text-start border-t-1 border-b-1 border-x-0 border-solid border-fino-dark">
                Target End Date
              </th>
              <th className="text-sm text-gray text-start border-t-1 border-b-1 border-x-0 border-solid border-fino-dark">
                Amount
              </th>
              <th className="text-sm text-gray text-start border-t-1 border-b-1 border-x-0 border-solid border-fino-dark">
                Pro Forma Taxed Amount
              </th>

              {isTeacherForgivnessActive && (
                <th className="text-sm text-gray text-start border-t-1 border-b-1 border-l-1 border-r-0 border-solid border-fino-dark">
                  Target End Date
                </th>
              )}
              {isTeacherForgivnessActive && (
                <th className="text-sm text-gray text-start border-t-1 border-b-1 border-x-0 border-solid border-fino-dark">
                  Amount
                </th>
              )}
              {isTeacherForgivnessActive && (
                <th className="text-sm text-gray text-start border-t-1 border-b-1 border-x-0 border-solid border-fino-dark">
                  Pro Forma Taxed Amount
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {forgivenessResults.map((f, i) => {
              const pslf = objectWithKey(f.f, ForgivenessType.Pslf);
              const timeBased = objectWithKey(f.f, ForgivenessType.TimeBased);
              const teacher = objectWithKey(f.f, ForgivenessType.Teacher);

              return (
                <tr key={f.t + i}>
                  <td className="border-l-0 border-r-1 border-y-0 border-solid border-fino-dark">
                    {f.t}
                  </td>
                  {isPslfActive && <td>{formatDate(pslf ? pslf.value.d : null)}</td>}
                  {isPslfActive && <td>{formatCurrency(pslf ? pslf.value.a : null)}</td>}
                  {!isPslfActive && <td>{formatDate(timeBased ? timeBased.value.d : null)}</td>}
                  {!isPslfActive && <td>{formatCurrency(timeBased ? timeBased.value.a : null)}</td>}
                  {!isPslfActive && (
                    <td>
                      {timeBased
                        ? formatCurrency(
                            calculateProFormaTaxedAmount(timeBased.value.a, timeBased.value.d)
                          )
                        : null}
                    </td>
                  )}
                  {isPslfActive && (
                    <td>
                      {pslf
                        ? formatCurrency(calculateProFormaTaxedAmount(pslf.value.a, pslf.value.d))
                        : null}
                    </td>
                  )}

                  {isTeacherForgivnessActive && (
                    <td className="border-r-0 border-l-1 border-y-0 border-solid border-fino-dark">
                      {formatDate(teacher ? teacher.value.d : null)}
                    </td>
                  )}
                  {isTeacherForgivnessActive && (
                    <td>
                      {formatCurrency(teacher ? teacher.value.a : null)}
                      {teacher ? `(${getRepaymentlabel(teacher.value.ft)})` : null}
                    </td>
                  )}
                  {isTeacherForgivnessActive && (
                    <td>
                      {teacher
                        ? formatCurrency(
                            calculateProFormaTaxedAmount(teacher.value.a, teacher.value.d)
                          )
                        : null}
                    </td>
                  )}
                </tr>
              );
            })}
            <tr className=" border-t-1 border-b-0 border-x-0 border-solid border-fino-dark">
              <td className="text-base font-bold border-l-0 border-r-1 border-y-0 border-solid bg-gray-100">
                Total projected forgiveness:
              </td>
              {isPslfActive && <td className="bg-gray-100"></td>}
              {isPslfActive && (
                <td className="text-base bg-gray-100 text-fino-purple">
                  {formatCurrency(
                    forgivenessResults.reduce((prev, next) => {
                      const _pslf = objectWithKey(next.f, ForgivenessType.Pslf);

                      return prev + (_pslf ? _pslf.value.a : 0);
                    }, 0)
                  )}
                </td>
              )}

              {!isPslfActive && <td className="bg-gray-100"></td>}
              {!isPslfActive && (
                <td className="text-base bg-gray-100 text-fino-purple">
                  {formatCurrency(timeBasedForgivness)}
                </td>
              )}
              {isTeacherForgivnessActive && <td className="bg-gray-100 "></td>}

              <td
                className={`bg-gray-100 ${
                  isTeacherForgivnessActive
                    ? `border-r-0 border-l-1 border-y-0 border-solid border-fino-dark`
                    : ``
                } `}
              ></td>
              {isTeacherForgivnessActive && (
                <td className="text-base bg-gray-100  text-fino-purple">
                  {formatCurrency(teacherForgivness)}
                </td>
              )}
              {isTeacherForgivnessActive && <td className="bg-gray-100"></td>}
            </tr>
            <tr>
              <td className="text-base font-bold bg-gray-100 border-l-0 border-r-1 border-y-0 border-solid ">
                Pro Forma Taxed Amount (Tax Bomb):
              </td>
              <td className="bg-gray-100"></td>
              <td className="bg-gray-100"></td>
              <td className="text-base bg-gray-100 ">
                {formatCurrency(isPslfActive ? 0 : timeBasedProFormaTaxedAmount)}
              </td>
              {isTeacherForgivnessActive && (
                <td className="bg-gray-100 border-r-0 border-l-1 border-y-0 border-solid border-fino-dark"></td>
              )}
              {isTeacherForgivnessActive && <td className="bg-gray-100 "></td>}
              {isTeacherForgivnessActive && (
                <td className="text-base bg-gray-100 ">
                  {formatCurrency(proFormaTeacherTaxedAmount)}
                </td>
              )}
            </tr>
            <tr>
              <td className="text-base font-bold bg-gray-100 border-l-0 border-r-1 border-y-0 border-solid ">
                Pro Forma Asset Value:
              </td>
              <td className="bg-gray-100 "></td>
              <td className="bg-gray-100 "></td>
              <td className="text-base bg-gray-100 ">{formatCurrency(proFormaAssetValue)}</td>
              {isTeacherForgivnessActive && (
                <td className="bg-gray-100 border-r-0 border-l-1 border-y-0 border-solid border-fino-dark"></td>
              )}
              {isTeacherForgivnessActive && <td className="bg-gray-100 "></td>}
              {isTeacherForgivnessActive && (
                <td className="text-base bg-gray-100 ">{formatCurrency(proFormaAssetValue)}</td>
              )}
            </tr>
            <tr>
              <td className="text-base font-bold bg-gray-100 border-t-0 border-b-1 border-l-0 border-r-1 border-solid border-fino-dark">
                Net difference:
              </td>
              <td className="bg-gray-100 border-t-0 border-b-1 border-x-0 border-solid border-fino-dark"></td>
              <td className="bg-gray-100 border-t-0 border-b-1 border-x-0 border-solid border-fino-dark"></td>
              <td
                className={`text-base bg-gray-100 border-t-0 border-b-1 border-x-0 border-solid  ${
                  timeBasedProFormaTaxedAmount - proFormaAssetValue >= 0 ? `text-red` : `text-green`
                } border-fino-dark `}
              >
                {formatCurrency((timeBasedProFormaTaxedAmount - proFormaAssetValue) * -1)}
              </td>
              {isTeacherForgivnessActive && (
                <td className="bg-gray-100 border-t-0 border-b-1 border-l-1 border-r-0 border-solid border-fino-dark"></td>
              )}
              {isTeacherForgivnessActive && (
                <td className="bg-gray-100 border-t-0 border-b-1 border-x-0 border-solid border-fino-dark"></td>
              )}
              {isTeacherForgivnessActive && (
                <td
                  className={`text-base bg-gray-100  border-t-0 border-b-1 border-x-0 border-solid border-fino-dark ${
                    proFormaTeacherTaxedAmount - proFormaAssetValue >= 0
                      ? `text-red-500`
                      : `text-green-500`
                  } `}
                >
                  {formatCurrency((proFormaTeacherTaxedAmount - proFormaAssetValue) * -1)}
                </td>
              )}
            </tr>
          </tbody>
        </table> */}
      </div>
    </>
  );
};
