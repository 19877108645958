import { Button, ButtonProps, Tooltip } from 'flowbite-react';

interface WithOptionalTooltipProps extends ButtonProps {
  tooltip?: string;
}

const withOptionalTooltip = <P extends ButtonProps>(Component: React.ComponentType<P>) => {
  const WrappedComponent: React.FC<WithOptionalTooltipProps> = ({
    tooltip,
    ...props
  }: WithOptionalTooltipProps) => {
    if (tooltip) {
      return (
        <Tooltip content={tooltip}>
          <Component {...(props as P)} />
        </Tooltip>
      );
    }

    return <Component {...(props as P)} />;
  };

  WrappedComponent.displayName = `withOptionalTooltip(Button)`;

  return WrappedComponent;
};

export const ButtonWithTooltip = withOptionalTooltip(Button);
