import { Button, Spinner } from 'flowbite-react';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PageHeader } from '../components/PageHeader';
import { useGlobalActions } from '../context/AppContext';
import { Calculator } from '../features/calculator/Calculator';
import { useCalculatorQuery } from '../graphql/generated';
import { useFlyoutNavigate } from '../hooks/useFlyoutNavigate';

export const CalculatorPage = () => {
  const { triggerCollapseSideNavigation } = useGlobalActions();
  useEffect(() => {
    if (window.innerWidth < 1100) {
      triggerCollapseSideNavigation(true);
    }
  }, []);

  const params = useParams();
  const clientId = Number.parseInt(params.clientId!);
  const { loading, data } = useCalculatorQuery({
    variables: { clientId },
  });
  const location = useLocation();
  const { navigate: flyoutNavigate } = useFlyoutNavigate();

  if (loading || !data) return <Spinner />;
  
  const clientPageUrl = `/clients/${clientId}`;

  return (
    <div>
      <PageHeader
        rightContent={
          <>
            <Button
              color="secondaryLight"
              onClick={() => flyoutNavigate('comparison-tool', `clientId=${clientId}`)}
            >
              {' '}
              Comparison Tool
            </Button>
            <Button
              type="submit"
              id="run-simulation"
              className="print:hidden"
              form="configure-client-calculator-details"
            >
              Run Simulation
            </Button>
          </>
        }
        breadcrumbs={[
          {
            to: location.pathname.startsWith('/calculator')
              ? '/calculator'
              : location.pathname.replace('/calculator', ''),
            text: 'Liability Planner',
          },
          {
            to: clientPageUrl,
            rightArrow: true,
            text: <span id="calculator-client-name"></span>,
          },
        ]}
        back
      />

      <Calculator calculatorDetails={data} clientId={clientId} />
    </div>
  );
};
