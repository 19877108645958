import { createRoot } from 'react-dom/client';

import App from './App';

import './translations/i18n';
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import './index.css';

const root = createRoot(document.getElementById('root')!);

root.render(<App />);
