export const getBackUrl = (search: string, firstFlyoutId: string) => {
  const firstRequiredParams = document.getElementById(`flyout-container-0`)!.dataset.requiredParams;
  const secondFlyout = document.getElementById(`flyout-container-1`);

  if (!secondFlyout) {
    return window.location.pathname;
  } else {
    let newParams: string[] = [];
    if (firstRequiredParams) {
      newParams = firstRequiredParams!.split(',');
    }

    let newUrl = `?flyout=${firstFlyoutId}`;

    const searchParams = new URLSearchParams(search);

    newParams.forEach((param) => {
      const value = searchParams.get(param);
      newUrl += `&${param}=${value}`;
    });

    return newUrl;
  }
};

export const getSearchParams = (search: string) => {
  const params = new URLSearchParams(search);

  return params.get('flyout')?.split(',') || [];
};

export const getQueryParams = (search: string) => {
  const startIndex = search.indexOf('&');
  if (startIndex === -1) {
    return '';
  }
  return search.slice(startIndex + 1);
};
