import { Spinner } from 'flowbite-react';
import { Form, Formik, FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Flyout, useFlyoutContext } from '../../../components/flyout/Flyout';
import { FlyoutHeader } from '../../../components/flyout/FlyoutHeader';
import { FormErrorNotification } from '../../../components/forms/FormErrorNotification';
import {
  useGetAssistantAccountDetailsLazyQuery,
  useUpdateAssistantAccountMutation,
} from '../../../graphql/generated';
import { useFlyoutNavigate } from '../../../hooks/useFlyoutNavigate';
import { notification } from '../../../util/notification.utils';
import { UserSelectorField } from '../../formComponents/UserSelectorField';

type FormValues = {
  allowToImpersonateUser: number | null;
};

const FlyoutContent = () => {
  const [initialValues, setInitialValues] = useState<FormValues | undefined>();

  const [updateAssistantAccount] = useUpdateAssistantAccountMutation();

  const { closeTopFlyout } = useFlyoutContext();
  const { navigate } = useFlyoutNavigate();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const userId = params.get('userId');
  const parsedUserId = Number.parseInt(userId!);

  const [lazyAssistantAccountQuery] = useGetAssistantAccountDetailsLazyQuery();

  useEffect(() => {
    async function loadAssistantAccount() {
      const result = await lazyAssistantAccountQuery({
        variables: {
          assistantAccountOf: parsedUserId,
        },
      });

      if (result.data?.assistantAccountDetails)
        setInitialValues({
          allowToImpersonateUser: result.data!.assistantAccountDetails!.id,
        });
      else {
        setInitialValues({
          allowToImpersonateUser: null,
        });
      }
    }

    loadAssistantAccount();
  }, []);

  if (!initialValues) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (v, form) => {
        form.setSubmitting(true);

        const { data } = await updateAssistantAccount({
          variables: {
            input: {
              userId: parsedUserId,
              assistantAccountUserId: v.allowToImpersonateUser!,
            },
          },
        });

        notification.success({
          message: 'Assistant account configuration updated.',
          placement: 'bottom-center',
        });

        closeTopFlyout();
      }}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};

        return errors;
      }}
    >
      {({ isSubmitting }) => {
        return (
          <>
            <FormErrorNotification />
            <FlyoutHeader
              primaryButton={{
                form: 'manage-assistant-form',
                children: 'Update',
                disabled: isSubmitting,
              }}
              secondaryButton={{
                onClick: closeTopFlyout,
                children: 'Cancel',
              }}
              label={'Manage Assistant'}
            />

            <Form id="manage-assistant-form">
              <UserSelectorField
                className="col-span-2"
                name="allowToImpersonateUser"
                placeholder="Can be Assisted by"
                idToExclude={parsedUserId}
                selectPlaceholder={'Select Account to be Assisted by'}
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export const ManageAdvisorAssistantFlyout = () => {
  return <Flyout id={`edit-advisor-assistant`} size={'small'} content={<FlyoutContent />}></Flyout>;
};
