import { Button } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';

import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) =>
  centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );

const toDataURL = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const CropImage = ({ src, onComplete, aspect }: any) => {
  const [savedImage, setSavedImage] = useState();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);

  const setInitialCropConfig = () => {
    setScale(1);
    setCrop(undefined);
    setCompletedCrop(undefined);
  };

  useEffect(() => {
    if (src) {
      toDataURL(src).then((img: any) => {
        setSavedImage(img);
      });
    }
    setInitialCropConfig();
  }, [src]);

  const handleCrop = () => {
    onComplete(savedImage);
    setSavedImage(undefined);
    setInitialCropConfig();
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          (image: any) => setSavedImage(image)
        );
      }
    },
    100,
    [completedCrop, scale]
  );

  return (
    <div>
      <div className="flex flex-col items-center text-lg ">
        <span>Zoom options:</span>
        <div className="flex gap-4">
          <HiOutlineMinus
            size={32}
            className="cursor-pointer hover:bg-gray-100"
            onClick={(e) => setScale(scale ? scale - 0.1 : -0.1)}
          />
          <HiOutlinePlus
            className="cursor-pointer hover:bg-gray-100"
            size={32}
            onClick={(e) => setScale(scale ? scale + 0.1 : 0.1)}
          />
        </div>
      </div>

      <div className="flex items-center justify-center mt-4">
        <div
          style={{
            display: 'block',
          }}
        >
          {!!src && (
            <ReactCrop
              className="border-2 border-gray-400"
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop Image"
                src={src}
                style={{ transform: `scale(${scale})` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
      </div>

      <div className="flex justify-center items-center mt-8 ">
        <div className="flex justify-center items-center flex-row">
          {!!completedCrop && (
            <div>
              <div className="text-xl bold">Preview:</div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
          )}
        </div>
        <div className="ml-8">
          <Button size="lg" onClick={handleCrop}>
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
};
